import { createModel } from "@rematch/core";
import * as constants from "../constants/logviewer";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import {
  compareLevelStateObjects,
  DEFAULT_LOG_VIEWER_STATE,
  extractCheckedLevels,
  LogLevelState,
  LogViewerInfo,
  LogViewerState,
  LogViewerTableResult,
  RawLogRecord,
  TableInfo,
  TimeRange,
} from "../types/logviewer";
import { FetchError } from "../types/error";
import { I18NString } from "../types/modal";
import { ALERT_LEVEL_INFO } from "../constants/alert";
import { dispatchError } from "../services/alert";

const initialState: LogViewerState = DEFAULT_LOG_VIEWER_STATE;

export const logviewer = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendLogRecords(state, payload: LogViewerTableResult) {
      const { start, end } = payload;
      return {
        ...state,
        loading: false,
        info: { ...parseToLogViewerInfo(payload) },
        firstTimestamp: start,
        lastTimestamp: end,
      };
    },
    sendTimeRange(state, payload: TimeRange | null) {
      return { ...state, timeRange: payload || undefined };
    },
    sendLogLevels(state, payload: LogLevelState) {
      return { ...state, showLevels: payload };
    },
    sendLogViewerLoading(state) {
      return { ...state, loading: true };
    },
    sendLogViewerError(state, error: FetchError) {
      return { ...state, loading: false, error };
    },
    sendLimit(state, limit: number) {
      return { ...state, limit };
    },
    setOffset(state, offset: number) {
      return { ...state, offset: offset.toString() };
    },
    sendCurrentLogRecord(state, timestamp: string) {
      return { ...state, currentRecordTimestamp: timestamp };
    },
    sendSearchInput(state, searchInput: string) {
      return { ...state, searchInput };
    },
  },
  effects: (dispatch) => ({
    showLogRecordDetails: (timestamp: string) => {
      dispatch.logviewer.sendCurrentLogRecord(timestamp);
    },
    showLogRecordFilter: (result: LogLevelState, s) => {
      if (!result) {
        return;
      }
      const checkedLevelsLabels = extractCheckedLevels(result);

      if (
        s &&
        !compareLevelStateObjects(result, s.showLevels) &&
        checkedLevelsLabels.length !== 0
      ) {
        const limit = s?.limit || DEFAULT_LOG_VIEWER_STATE.limit;

        let tableInfo: any = {
          pageable: true,
          offset: "",
          limit,
          olderRecords: true,
          showLevels: checkedLevelsLabels,
        };
        dispatch.logviewer.sendLogLevels(result);
        dispatch.logviewer.fetchLogRecords(tableInfo);
      }
    },
    fetchLogRecords: async (tableInfo: TableInfo, s) => {
      try {
        const logViwerState = s[constants.LOGVIEWER];
        if (logViwerState && logViwerState.loading) {
          return;
        }
        // tableInfo.searchInput = logViwerState.searchInput;

        if (
          logViwerState &&
          logViwerState.showLevels &&
          Object.keys(logViwerState.showLevels).length > 0
        ) {
          tableInfo.showLevels = extractCheckedLevels(logViwerState.showLevels);
        } else {
          tableInfo.showLevels = extractCheckedLevels(
            DEFAULT_LOG_VIEWER_STATE.showLevels
          );
        }
        logViwerState && (tableInfo.search = logViwerState.searchInput);
        dispatch.logviewer.sendLogViewerLoading();
        const records: LogViewerTableResult = await fetchLogRecordsImpl(
          tableInfo
        );
        if (records.total === 0) {
          const message: I18NString = { id: "LOG_VIEWER_NO_MORE_RECORDS" };
          dispatch.alert.addAlert({ type: ALERT_LEVEL_INFO, message });
        }
        dispatch.logviewer.sendLogRecords(records);

        // dispatch(setOffset(tableInfo.offset));
      } catch (e) {
        if (e instanceof ServerError) {
          dispatch.logviewer.sendLogViewerError({
            code: e.code,
            message: e.message,
          });
        } else if (typeof (e as any).message == "string") {
          dispatch.logviewer.sendLogViewerError({
            code: -1,
            message: (e as any).message,
          });
        } else {
          dispatch.logviewer.sendLogViewerError({
            code: -1,
            message: "Unknown error",
          });
        }
        dispatchError("LOG_VIEWER_FETCH_ERROR", e, dispatch);
      }
    },
    fetchLogRecordsNearTimestamp: (approximatedTimestamp: string, s) => {
      const logViwerState = s[constants.LOGVIEWER];
      if (!logViwerState) {
        return;
      }
      // getNearestTimestampImpl(approximatedTimestamp).then(tableRequest => {
      //     const timestamp = tableRequest.offset;
      //     if (timestamp && timestamp.trim() !== '') {
      //         let tableInfo: TableInfo = { limit: logViwerState.limit, olderRecords: tableRequest.olderRecords, offset: timestamp };
      //         dispatch(fetchLogRecords(tableInfo));
      //     } else {
      //         const messge: I18NString = { id: "LOG_VIEWER_NO_MORE_RECORDS" };
      //         dispatch(addAlert(ALERT_LEVEL_INFO, messge));
      //     }
      // });
    },
    setLimit: (limit: number, s) => {
      const logViwerState = s[constants.LOGVIEWER];
      if (!logViwerState) {
        return;
      }
      let tableInfo: TableInfo = {
        olderRecords: false,
        offset: "",
        limit: limit,
      };
      dispatch.logviewer.sendLimit(limit);
      dispatch.logviewer.fetchLogRecords(tableInfo);
    },
    setSearch: (value: string, s) => {
      const logViwerState = s[constants.LOGVIEWER];
      if (!logViwerState) {
        return;
      }

      let tableInfo: TableInfo = {
        olderRecords: false,
        limit: logViwerState.limit,
      };
      dispatch.logviewer.sendSearchInput(value.trim());
      dispatch.logviewer.fetchLogRecords(tableInfo);
    },
  }),
});

function getLogLevelCode(logLevel: string) {
  switch (logLevel) {
    case constants.INFO_LEVEL:
      return "I";
    case constants.TRACE_LEVEL:
      return "T";
    case constants.DEBUG_LEVEL:
      return "D";
    case constants.ERROR_LEVEL:
      return "E";
    case constants.FATAL_LEVEL:
      return "F";
  }
  return "";
}

async function fetchLogRecordsImpl(
  tableInfo: TableInfo
): Promise<LogViewerTableResult> {
  let url = "/rest/logviewer/list";
  let query: string[] = [];
  if (tableInfo?.limit) {
    query.push(`l=${tableInfo?.limit}`);
  }
  if (tableInfo.endTime && tableInfo.startTime) {
    query.push(`st=${tableInfo?.startTime}`);
    query.push(`et=${tableInfo?.endTime}`);
  }
  if (tableInfo?.offset) {
    query.push(`t=${tableInfo?.offset}`);
  }
  if (tableInfo?.olderRecords) {
    query.push(`old=${tableInfo?.olderRecords}`);
  }
  if (tableInfo?.showLevels) {
    const levels = tableInfo?.showLevels.map((lvl: string) =>
      getLogLevelCode(lvl)
    );
    query.push(`lv=${levels.join(",")}`);
  }
  if (query.length > 0) {
    url += `?${query.join("&")}`;
  }

  let resp = await fetch(url);

  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }

  const logs = await resp.json();
  return logs;
}

export async function getNearestTimestampImpl(
  timestamp: string
): Promise<LogViewerTableResult> {
  let resp = await fetch("/rest/logviewer/nearest/" + timestamp);
  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.json();
}

function parseToLogViewerInfo(result: LogViewerTableResult): LogViewerInfo {
  let info: LogViewerInfo = {
    timestamps: [],
    levels: {},
    messages: {},
    details: {},
    threadIdentifiers: {},
    sources: {},
  };
  const rawLogRecords = result.recordList;
  rawLogRecords.forEach((item: RawLogRecord) => {
    const timestamp = item.ts;
    info.timestamps.push(timestamp);
    info.levels[timestamp] = getLogLevel(item.l);
    info.messages[timestamp] = item.m;
    info.threadIdentifiers[timestamp] = item.tid;
    info.sources[timestamp] = item.src;
    info.details[timestamp] = item.d || "";
  });
  return info;
}
function getLogLevel(rawLevel: string) {
  switch (rawLevel) {
    case "I":
      return constants.INFO_LEVEL;
    case "T":
      return constants.TRACE_LEVEL;
    case "D":
      return constants.DEBUG_LEVEL;
    case "W":
      return constants.WARN_LEVEL;
    case "E":
      return constants.ERROR_LEVEL;
    case "F":
      return constants.FATAL_LEVEL;
  }
  return "";
}
