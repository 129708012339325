export const PREFERENCE = "preference";

export const SEND_PREFERENCES = 'SEND_PREFERENCES';
export type SEND_PREFERENCES = typeof SEND_PREFERENCES;

export const EDIT_PREFERENCE = 'EDIT_PREFERENCE ';
export type EDIT_PREFERENCE = typeof EDIT_PREFERENCE;

export const SEND_PREFERENCE_ERROR = 'SEND_PREFERENCE_ERROR';
export type SEND_PREFERENCE_ERROR = typeof SEND_PREFERENCE_ERROR;

export const SEND_PREFERENCE_LOADING = 'SEND_PREFERENCE_LOADING';
export type SEND_PREFERENCE_LOADING = typeof SEND_PREFERENCE_LOADING; 

export const SEND_LIMIT = 'SEND_LIMIT';
export type SEND_LIMIT = typeof SEND_LIMIT;

export const SEND_OFFSET = 'SEND_OFFSET';
export type SEND_OFFSET = typeof SEND_OFFSET;

export const SEND_ITEM_CHECKED = 'SEND_ITEM_CHECKED';
export type SEND_ITEM_CHECKED = typeof SEND_ITEM_CHECKED;

export const SEND_ALL_ITEM_CHECKED = 'SEND_ALL_ITEM_CHECKED';
export type SEND_ALL_ITEM_CHECKED = typeof SEND_ALL_ITEM_CHECKED;

export const SEND_START_EDIT = 'SEND_START_EDIT';
export type SEND_START_EDIT = typeof SEND_START_EDIT;

export const SEND_STOP_EDIT = 'SEND_STOP_EDIT';
export type SEND_STOP_EDIT = typeof SEND_STOP_EDIT;

export const SEND_SEARCH_INPUT_PREFERENCE = 'SEND_SEARCH_INPUT_PREFERENCE';
export type SEND_SEARCH_INPUT_PREFERENCE = typeof SEND_SEARCH_INPUT_PREFERENCE;

export const PREFERENCE_SAVE_URL = "/rest/preference/entity";
export const PREFERENCE_DELETE_URL = '/rest/preference/deletebatch';
export const PREFERENCE_LIST_URL = '/rest/preference/list';
export const PREFERENCE_EDIT_URL = '/rest/preference/entity';
export const PREFERENCE_UPLOAD_URL = '/rest/preference/entity/list';