import React, {
  CSSProperties,
  FC,
  RefObject,
  useEffect,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import shortid from "shortid";
interface InplaceProps {
  text: string;
  id: string;
  style?: CSSProperties;
  className?: string;
  change: (e: any) => void;
  children?: any;
}
interface InplaceState {
  edit: boolean;
}
export class Inplace extends React.Component<InplaceProps, InplaceState> {
  private ref: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: InplaceProps) {
    super(props);
    this.state = {
      edit: false,
    };
  }
  componentDidMount() {
    document.addEventListener("click", this.hideInplace);
  }
  componentWillUnmount(): void {
    document.removeEventListener("click", this.hideInplace);
  }

  setEdit = (edit: boolean) => {
    this.setState({ edit });
  };
  hideInplace = (e: any) => {
    const { id } = this.props;
    const { edit } = this.state;

    if (!this.ref.current?.contains(e.target) && edit) {
      // if (!e.target.closest(`#${id}`) && edit) {

      this.setEdit(false);
    }
  };

  renderText = () => {
    const { text, change, className } = this.props;
    const { edit } = this.state;
    if (edit) {
      return (
        <Form.Control
          autoFocus
          size="sm"
          style={{ width: "unset" }}
          value={text}
          onChange={change}
        />
      );
    }
    return <span className={className}>{text}</span>;
  };

  showInput = () => {
    const { edit } = this.state;
    if (!edit) {
      this.setEdit(true);
    } else {
      this.setEdit(false);
    }
  };
  renderChildren = () => {
    const { children } = this.props;
    if (!children) {
      return null;
    }
    return (
      <div onClick={this.showInput} style={{ cursor: "pointer" }}>
        {children}
      </div>
    );
  };
  render() {
    const { children, id } = this.props;
    return (
      <div className="d-flex" id={id} ref={this.ref}>
        {this.renderChildren()}
        {this.renderText()}
      </div>
    );
  }
}
