export const MANDATORY_ERROR = { id: "OBJECTCARD_FIELD_IS_MANDATORY" };
export const MINIMUM_ITEMS_ERROR = { id: "OBJECTCARD_LESS_THAN_MINIMUM_ITEMS" };
export const MAXIMUM_ITEMS_ERROR = { id: "OBJECTCARD_MORE_THAN_MAXIMUM_ITEMS" };

//Actions
export const SEND_SUBJECT = "SEND_SUBJECT";
export type SEND_SUBJECT = typeof SEND_SUBJECT;
export const SEND_SUBJECT_NODE_LOADING = "SEND_SUBJECT_NODE_LOADING";
export type SEND_SUBJECT_NODE_LOADING = typeof SEND_SUBJECT_NODE_LOADING;

export const SEND_SUBJECT_CHANGE_DATA = "SEND_SUBJECT_CHANGE_DATA";
export type SEND_SUBJECT_CHANGE_DATA = typeof SEND_SUBJECT_CHANGE_DATA;

export const SEND_SUBJECT_EDITING = "SEND_SUBJECT_EDITING";
export type SEND_SUBJECT_EDITING = typeof SEND_SUBJECT_EDITING;

export const SEND_SUBJECT_NEW = "SEND_SUBJECT_NEW";
export type SEND_SUBJECT_NEW = typeof SEND_SUBJECT_NEW;

export const SEND_SUBJECT_REMOVE_FROM_STORE = "SEND_SUBJECT_REMOVE_FROM_STORE";
export type SEND_SUBJECT_REMOVE_FROM_STORE =
  typeof SEND_SUBJECT_REMOVE_FROM_STORE;

export const SEND_SUBJECT_START_SAVE = "SEND_SUBJECT_START_SAVE";
export type SEND_SUBJECT_START_SAVE = typeof SEND_SUBJECT_START_SAVE;

export const SEND_SUBJECT_NOT_NEW = "SEND_SUBJECT_NOT_NEW";
export type SEND_SUBJECT_NOT_NEW = typeof SEND_SUBJECT_NOT_NEW;

export const SEND_SUBJECT_COMPONENT_UPDATED = "SEND_SUBJECT_COMPONENT_UPDATED";
export type SEND_SUBJECT_COMPONENT_UPDATED =
  typeof SEND_SUBJECT_COMPONENT_UPDATED;

export const SEND_SUBJECT_CHANGE_SAVE_STATE = "SEND_SUBJECT_CHANGE_SAVE_STATE";
export type SEND_SUBJECT_CHANGE_SAVE_STATE =
  typeof SEND_SUBJECT_CHANGE_SAVE_STATE;

export const SEND_SUBJECT_CANCEL_SAVE = "SEND_SUBJECT_CANCEL_SAVE";
export type SEND_SUBJECT_CANCEL_SAVE = typeof SEND_SUBJECT_CANCEL_SAVE;

export const SEND_SUBJECT_SAVE_WAIT = "SEND_SUBJECT_SAVE_WAIT";
export type SEND_SUBJECT_SAVE_WAIT = typeof SEND_SUBJECT_SAVE_WAIT;

export const SEND_SUBJECT_SAVE_DONE = "SEND_SUBJECT_SAVE_DONE";
export type SEND_SUBJECT_SAVE_DONE = typeof SEND_SUBJECT_SAVE_DONE;

export const SEND_SUBJECT_LOADING = "SEND_SUBJECT_LOADING";
export type SEND_SUBJECT_LOADING = typeof SEND_SUBJECT_LOADING;

export const SEND_SUBJECT_LOCK_LOADING = "SEND_SUBJECT_LOCK_LOADING";
export type SEND_SUBJECT_LOCK_LOADING = typeof SEND_SUBJECT_LOCK_LOADING;

export const SEND_SUBJECT_DESTROY = "SEND_SUBJECT_DESTROY";
export type SEND_SUBJECT_DESTROY = typeof SEND_SUBJECT_DESTROY;

export const SEND_SUBJECT_ERROR = "SEND_SUBJECT_ERROR";
export type SEND_SUBJECT_ERROR = typeof SEND_SUBJECT_ERROR;

export const SEND_SUBJECT_CHANGE_LOADING = "SEND_SUBJECT_CHANGE_LOADING";
export type SEND_SUBJECT_CHANGE_LOADING = typeof SEND_SUBJECT_CHANGE_LOADING;

export const SEND_CACHED_LAYOUT = "SEND_CACHED_LAYOUT";
export type SEND_CACHED_LAYOUT = typeof SEND_CACHED_LAYOUT;

export const SEND_LAYOUT_LOADING = "SEND_LAYOUT_LOADING";
export type SEND_LAYOUT_LOADING = typeof SEND_LAYOUT_LOADING;

export const CHANGE_TAB = "CHANGE_TAB";
export type CHANGE_TAB = typeof CHANGE_TAB;

export const SEND_ENUMERATION = "SEND_ENUMERATION";
export type SEND_ENUMERATION = typeof SEND_ENUMERATION;

export const SEND_ENUMERATION_LOADING = "SEND_ENUMERATION_LOADING";
export type SEND_ENUMERATION_LOADING = typeof SEND_ENUMERATION_LOADING;

export const SEND_ENUMERATION_ERROR = "SEND_ENUMERATION_ERROR";
export type SEND_ENUMERATION_ERROR = typeof SEND_ENUMERATION_ERROR;

export const SEND_FRAGMENT_TREE = "SEND_FRAGMENT_TREE";
export type SEND_FRAGMENT_TREE = typeof SEND_FRAGMENT_TREE;

export const SEND_FRAGMENT_TREE_LOADING = "SEND_FRAGMENT_TREE_LOADING";
export type SEND_FRAGMENT_TREE_LOADING = typeof SEND_FRAGMENT_TREE_LOADING;

export const SEND_FRAGMENT_TREE_ERROR = "SEND_FRAGMENT_TREE_ERROR";
export type SEND_FRAGMENT_TREE_ERROR = typeof SEND_FRAGMENT_TREE_ERROR;

export const SEND_FRAGMENT_TREE_SELECTED = "SEND_FRAGMENT_TREE_SELECTED";
export type SEND_FRAGMENT_TREE_SELECTED = typeof SEND_FRAGMENT_TREE_SELECTED;

//UI

////////////
// Panels //
////////////
export const UI_PANEL = "p";
export type UI_PANEL = typeof UI_PANEL;

///////////////
// Splitters //
///////////////
export const UI_SPLITTER_CONTAINER = "s-c";
export type UI_SPLITTER_CONTAINER = typeof UI_SPLITTER_CONTAINER;

export const UI_SPLITTER_ELEMENT = "s-e";
export type UI_SPLITTER_ELEMENT = typeof UI_SPLITTER_ELEMENT;

export const UI_SPLITTER_PANEL = "s-p";
export type UI_SPLITTER_PANEL = typeof UI_SPLITTER_PANEL;

//////////
// Grid //
//////////
export const UI_GRID = "g";
export type UI_GRID = typeof UI_GRID;

export const UI_GRID_CELL = "g-c";
export type UI_GRID_CELL = typeof UI_GRID_CELL;

export const UI_PLUGIN = "u-plg";
export type UI_PLUGIN = typeof UI_PLUGIN;

////////////
// Indicators //
////////////
export const UI_BULB_INDICATOR = "b-ind";
export type UI_BULB_INDICATOR = typeof UI_BULB_INDICATOR;
export const UI_ICON_INDICATOR = "i-ind";
export type UI_ICON_INDICATOR = typeof UI_ICON_INDICATOR;
////////////
// Inputs //
////////////
export const UI_TEXT = "i-txt";
export type UI_TEXT = typeof UI_TEXT;

export const UI_FLOAT = "i-flt";
export type UI_FLOAT = typeof UI_FLOAT;

export const UI_INT = "i-int";
export type UI_INT = typeof UI_INT;

export const UI_DATE = "i-d";
export type UI_DATE = typeof UI_DATE;

export const UI_DATE_TIME = "i-dt";
export type UI_DATE_TIME = typeof UI_DATE_TIME;

export const UI_CHECKBOX = "i-chk";
export type UI_CHECKBOX = typeof UI_CHECKBOX;

export const UI_FRAGMENT = "i-frg";
export type UI_FRAGMENT = typeof UI_FRAGMENT;

export const UI_FILE = "i-fl";
export type UI_FILE = typeof UI_FILE;

export const UI_LABEL = "i-lbl";
export type UI_LABEL = typeof UI_LABEL;

export const UI_DESCRIPTION = "i-dsc";
export type UI_DESCRIPTION = typeof UI_DESCRIPTION;

export const UI_COMBOBOX = "i-cmb";
export type UI_COMBOBOX = typeof UI_COMBOBOX;

export const UI_BUTTON = "u-btn";
export type UI_BUTTON = typeof UI_BUTTON;

export const UI_LINK = "u-lnk";
export type UI_LINK = typeof UI_LINK;

export const UI_INPUT_FORMAT: { [type: string]: string } = {
  [UI_TEXT]: "string",
  [UI_FLOAT]: "float",
  [UI_INT]: "int",
  [UI_DATE]: "date",
  [UI_DATE_TIME]: "dateTime",
};
////////////////////
// Tables && Tabs //
////////////////////
export const UI_REF_TABLE = "t-ref";
export type UI_REF_TABLE = typeof UI_REF_TABLE;

export const UI_OBJECT_TABLE = "t-obj";
export type UI_OBJECT_TABLE = typeof UI_OBJECT_TABLE;

export const UI_TAB_NAV = "t-n";
export type UI_TAB_NAV = typeof UI_TAB_NAV;

export const UI_TAB = "t-i";
export type UI_TAB = typeof UI_TAB;

///////////////
// Utilities //
///////////////
export const UI_IMAGE = "u-img";
export type UI_IMAGE = typeof UI_IMAGE;

export const UI_CARD = "u-c";
export type UI_CARD = typeof UI_CARD;

export const UI_RPA_SETPOINTS = "u-sp";
export type UI_RPA_SETPOINTS = typeof UI_RPA_SETPOINTS;

export const UI_VIEW = "u-vw";
export type UI_VIEW = typeof UI_VIEW;

///////////////
// Status //
///////////////
export const STATUS_READY = "ready";
export type STATUS_READY = typeof STATUS_READY;
export const STATUS_LOADING = "loading";
export type STATUS_LOADING = typeof STATUS_LOADING;

///////////////
// Operations //
///////////////
export const SUBJECT_OPERATION_INIT = "SUBJECT_OPERATION_INIT";
export type SUBJECT_OPERATION_INIT = typeof SUBJECT_OPERATION_INIT;
export const SUBJECT_OPERATION_CREATE = "SUBJECT_OPERATION_CREATE";
export type SUBJECT_OPERATION_CREATE = typeof SUBJECT_OPERATION_CREATE;
export const SUBJECT_OPERATION_GET = "SUBJECT_OPERATION_GET";
export type SUBJECT_OPERATION_GET = typeof SUBJECT_OPERATION_GET;
export const SUBJECT_OPERATION_LOCK = "SUBJECT_OPERATION_LOCK";
export type SUBJECT_OPERATION_LOCK = typeof SUBJECT_OPERATION_LOCK;
export const SUBJECT_OPERATION_UNLOCK = "SUBJECT_OPERATION_UNLOCK";
export type SUBJECT_OPERATION_UNLOCK = typeof SUBJECT_OPERATION_UNLOCK;
export const SUBJECT_OPERATION_SAVE = "SUBJECT_OPERATION_SAVE";
export type SUBJECT_OPERATION_SAVE = typeof SUBJECT_OPERATION_SAVE;

///////////////
// Save status //
///////////////
export const SAVE_STATE_START = "start";
export type SAVE_STATE_START = typeof SAVE_STATE_START;

export const SAVE_STATE_SHOW_ERRORS = "show_errors";
export type SAVE_STATE_SHOW_ERRORS = typeof SAVE_STATE_SHOW_ERRORS;

export const SAVE_STATE_UPLOADS_READY = "uploads_ready";
export type SAVE_STATE_UPLOADS_READY = typeof SAVE_STATE_UPLOADS_READY;

export const SAVE_STATE_WAIT_SERVER = "wait_server";
export type SAVE_STATE_WAIT_SERVER = typeof SAVE_STATE_WAIT_SERVER;

export const PROFILE_EDITOR_BASE_URL = "developer/profileeditor";
export const SUBJECT_EXPORT_URL = "rest/subject/export";
export const SUBJECT_NEW_URL = "/rest/subject/new";
export const VALIDATE_LINK_URL = "/rest/subject/validate";
export const UPLOAD_URL = "/rest/file/upload";

export const ADD_NEW_SUBJECT_EVENT = "addNewSubject";
export const NEW_SUBJECT_ADDED_EVENT = "newSubjectAdded";

// const EXCEL_EXT = "xlsx";
// const WORD_EXT = "docx";

export const EXCEL_EXT = "xlsx";
export type EXCEL_EXT = typeof EXCEL_EXT;

export const WORD_EXT = "docx";
export type WORD_EXT = typeof WORD_EXT;

export type EXPORT_TYPE = WORD_EXT | EXCEL_EXT;

//Enums
export enum CheckBoxKeys {
  YES = "yes",
  NO = "no",
  NOTHING = "nothing",
}
