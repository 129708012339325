import { ServerError } from "../actions/utils";
import { DbInfo } from "../types/db";

export function fetchDbInfoImpl(): Promise<DbInfo> {
  return fetch("/scm/db/info")
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as DbInfo;
    });
}

export function changeDbInfoImpl(db: DbInfo): Promise<DbInfo> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(db),
  };
  return fetch("/scm/db/info", opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as DbInfo;
    });
}

export function checkDbImpl(db: DbInfo): Promise<DbInfo> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(db),
  };
  return fetch("/scm/db/check", opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as DbInfo;
    });
}
