import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { createSelected, setupRange } from "../reducers/utils";
import { dispatchError } from "../services/alert";
import { RootState } from "../store";
import { FragmentDataAccessRaw, FragmentTableInfo } from "../types/fragment";
import { LocaleState } from "../types/locale";

import { I18NString } from "../types/modal";
import {
  RoleState,
  RoleCardType,
  BriefRole,
  BriefRoleRaw,
  RoleData,
  RoleDataNode,
  RoleTableInfo,
  RoleTreeState,
  RoleFilter,
  RoleInfo,
  RoleCard,
} from "../types/role";
import { SecurityItem, SecurityItemResponse } from "../types/security";
import { BaseTableInfo } from "../types/user";
import { parseBaseTableInfo, parseFragmentTableInfo } from "./user";

const initialState: RoleState = { cardType: RoleCardType.SIMPLE };

export const role = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendRoleList(state, payload: { roleList: RoleData[]; total: number }) {
      const rolesInfo: RoleInfo = {
        ...state.roles,
        table: parseListToTableInfo(payload.roleList),
        search: "",
        pagination: {},
      };
      return { ...state, roles: rolesInfo, loading: false };
    },
    sendBriefRole(state, payload: BriefRoleRaw) {
      const roleCard: RoleCard = {
        ...state.roleCard,
        data: { ...parseRole(payload) },
      };
      return { ...state, roleCard: { ...roleCard, loading: false } };
    },
    sendBriefRoleSave(state, save?: boolean) {
      return { ...state, saved: save };
    },
    sendRoleEdit(state, editing: boolean) {
      return { ...state, editRole: editing };
    },
    sendRoleListLoading(state, loading: boolean = true) {
      return { ...state, loading };
    },
    sendRoleCardType(state, cardType: RoleCardType) {
      return { ...state, cardType };
    },
    sendBriefRoleLoading(state, loading: boolean = true) {
      const roleCard: RoleCard = { ...state.roleCard, loading };
      return { ...state, roleCard: { ...roleCard } };
    },
    sendRoleTree(state, payload: { list: RoleDataNode[]; parentId?: string }) {
      const { list, parentId } = payload;
      const treeState = { ...state.tree };
      const loading = treeState?.loading || {};
      if (parentId) {
        loading[parentId] = false;
      }
      const newTreeState: RoleTreeState = parseReducerRoleList(
        treeState || {},
        list,
        parentId
      );
      newTreeState.treeError = undefined;
      newTreeState.treeLoading = false;
      newTreeState.loading = loading;
      return { ...state, tree: newTreeState };
    },
    sendRoleTreeLoading(state, loading: boolean = true) {
      const treeState = { ...state.tree, treeLoading: loading };
      return { ...state, tree: treeState };
    },
    sendRoleActive(state, active: string) {
      const newTreeState = { ...state.tree };
      const prevActive = newTreeState.active;
      const selected = newTreeState.selected;
      newTreeState.active = active;
      if (
        prevActive &&
        prevActive === active &&
        (!selected || (selected && !selected.includes(active)))
      ) {
        newTreeState.active = undefined;
      }

      return { ...state, tree: newTreeState };
    },
    sendRoleSelect(state, id?: string) {
      const selected = id;
      let newTreeState = { ...state.tree };
      let selectedList = newTreeState.selected;
      let active = newTreeState.active;
      newTreeState.selected = createSelected(
        selectedList || null,
        active || null,
        selected || null
      );
      return { ...state, tree: newTreeState };
    },
    sendRoleClearSelect(state) {
      let newTreeState = { ...state.tree };
      newTreeState.selected = undefined;
      newTreeState.active = undefined;
      return { ...state, tree: newTreeState };
    },
    sendRoleRange(state, id?: string) {
      const ranged = id;
      if (!state.tree) {
        return state;
      }
      return {
        ...state,
        tree: setupRange<RoleTreeState>(ranged || null, state.tree),
      };
    },
    sendRoleTableSearch(state, search: string) {
      return { ...state, search };
    },
    sendRoleUpdateFilter(state, filter: RoleFilter) {
      return { ...state, filter };
    },
  },
  effects: (dispatch) => ({
    fetchRoleTree: async (_: void, s) => {
      try {
        dispatch.role.sendRoleTreeLoading();
        const response: SecurityItemResponse = await fetchRoleListImpl(
          null,
          null
        );
        const list: RoleDataNode[] = parseRequestRoleList(response.itemList);
        dispatch.role.sendRoleTree({ list });
      } catch (e) {
        dispatch.role.sendRoleTreeLoading(false);
      }
    },
    fetchUserRoleTree: async (_: void, s) => {
      try {
        dispatch.role.sendRoleTreeLoading();
        const response: SecurityItemResponse = await fetchUserRoleListImpl(
          null,
          null
        );
        const list: RoleDataNode[] = parseRequestRoleList(response.itemList);
        dispatch.role.sendRoleTree({ list });
      } catch (e) {
        dispatch.role.sendRoleTreeLoading(false);
      }
    },
    fetchRoleList: async (data: { filter?: string }, s) => {
      const { filter } = data;
      try {
        dispatch.role.sendRoleListLoading();
        // parseUserList
        const search = s.role?.search || null;
        const response: SecurityItemResponse = await fetchRoleListImpl(
          filter || null,
          search
        );
        const total = response.total;
        const list = parseRequestRoleList(response.itemList);
        dispatch.role.sendRoleList({ roleList: list, total });
      } catch (e) {
        dispatch.role.sendRoleListLoading(false);
        dispatchError("ROLE_FETCH_ROLES_ERROR", e, dispatch);
        // if (e instanceof ServerError) {
        //     dispatch(sendUserListError({ code: e.code, message: e.message }));
        // } else if (typeof e.message == 'string') {
        //     dispatch(sendUserListError({ code: -1, message: e.message }));
        // } else {
        //     dispatch(sendUserListError({ code: -1, message: "Unknown error" }));
        // }
      }
    },
    fetchRole: async (data: { rdfId?: string }, s) => {
      const { rdfId } = data;
      try {
        dispatch.role.sendBriefRoleLoading();
        // parseUserList
        const role: BriefRoleRaw = rdfId
          ? await fetchRoleImpl(rdfId)
          : await fetchNewRoleImpl();
        dispatch.role.sendBriefRole(role);
      } catch (e) {
        dispatch.role.sendBriefRoleLoading(false);
        dispatchError("ROLE_FETCH_ROLE_ERROR", e, dispatch);
      }
    },
    saveRole: async (
      data: { role: BriefRole; callback?: (rdfId?: string) => void },
      s
    ) => {
      const { role, callback } = data;
      try {
        if (s && typeof s.role != "undefined" && s.role.loading) {
          return;
        }
        dispatch.role.sendBriefRoleLoading();
        dispatch.role.sendBriefRoleSave(false);
        dispatch.role.sendRoleEdit(false);
        // parseUserList

        const savedRole: BriefRoleRaw = await fetchRoleSaveImpl(role);
        savedRole.rdfId && dispatch.role.fetchRole({ rdfId: savedRole.rdfId });
        callback && callback(savedRole.rdfId);
        dispatch.role.sendBriefRoleLoading(false);
      } catch (e) {
        dispatch.role.sendBriefRoleLoading(false);
        dispatch.role.fetchRole({ rdfId: role.rdfId });
        dispatchError("ROLE_SAVE_ERROR", e, dispatch);
      }
    },
    confirmDelete: (data: { body: string; callback: () => void }, s) => {
      const { body, callback } = data;
      dispatch.modal.openModal({
        id: "cron.confirmDelete",
        type: "confirmDelete",
        options: { title: "FRAGMENT_CONFIRM", body: body },
        okCallback: async (result) => {
          if (result) {
            callback();
          }
        },
      });
    },
    clearSelected: (field: keyof RoleFilter) => {
      switch (field) {
        case "fragments":
          dispatch.fragments.sendFragmentClearSelect();
          break;
        case "rights":
          dispatch.right.sendRightsClearSelect();
          break;
      }
    },
  }),
});

export function parseReducerRoleList(
  state: RoleTreeState,
  list: RoleDataNode[],
  parentId?: string
) {
  let newRoleState = { ...state };
  let rootNodesIds = newRoleState.rootNodesIds || [];
  const childrenIds = newRoleState.childrenIds || {};
  const nodeById = newRoleState.nodeById || {};
  // const loading = newFragmentState.loading || {};
  if (!parentId) {
    const roots: string[] = [];
    list.forEach((f) => f.rdfId && roots.push(f.rdfId));
    rootNodesIds = roots;
  } else {
    const childrenList: string[] = [];
    list.forEach((f) => f.rdfId && childrenList.push(f.rdfId));
    childrenIds[parentId] = childrenList;
    // loading[parentId] = false;
  }

  list.forEach((f) => {
    const { rdfId } = f;
    rdfId && (nodeById[rdfId] = { ...f, parentId: parentId });
  });

  newRoleState = { ...newRoleState, rootNodesIds, nodeById, childrenIds };
  return newRoleState;
}

const parseListToTableInfo = (rolesList: RoleData[]) => {
  const roleTableInfo: RoleTableInfo = {
    description: {},
    id: {},
    label: {},
    rdfId: [],
    alias: {},
  };
  rolesList.forEach((u) => {
    const { description, label, id, rdfId, alias } = u;
    if (rdfId) {
      roleTableInfo.rdfId.push(rdfId);
      roleTableInfo.id[rdfId] = id;
      roleTableInfo.label[rdfId] = label;
      roleTableInfo.description[rdfId] = description;
      roleTableInfo.alias[rdfId] = alias;
    }
  });
  return roleTableInfo;
};

function parseRole(rawRole: BriefRoleRaw) {
  const { accessList, fragmentList, ...other } = rawRole;

  const accessInfo: BaseTableInfo = parseBaseTableInfo(accessList);
  const fragmentInfo: FragmentTableInfo = parseFragmentTableInfo(fragmentList);

  const briefUser: BriefRole = { ...other, accessInfo, fragmentInfo };
  return briefUser;
}

export function createFilter(s: RootState) {
  const rightTree = s.right?.tree;
  const fragmentTree = s.fragments;

  const rights = rightTree?.selected
    ? rightTree.selected
    : rightTree?.active
    ? [rightTree.active]
    : [];
  const fragments = fragmentTree?.selected
    ? fragmentTree.selected
    : fragmentTree?.active
    ? [fragmentTree.active]
    : [];

  const filter: RoleFilter = { rights, fragments };
  return filter;
}

export function parseFilterToQuery(filter: RoleFilter) {
  let { fragments, rights } = filter;
  if (fragments.length === 0 && rights.length === 0) {
    return "";
  }
  fragments = fragments.map((f) => `f=${f}`);
  rights = rights.map((f) => `a=${f}`);

  const query = `?${[...fragments, ...rights].join("&")}`;
  return query;
}

function parseRequestRoleList(rawList: SecurityItem[]) {
  const roleList: RoleData[] = [];
  rawList.forEach((rl) => {
    const { d, l, i, r, u, a } = rl;
    const role: RoleData = {
      id: i,
      rdfId: r,
      description: d,
      label: l,
      alias: a || "",
    };
    roleList.push(role);
  });
  return roleList;
}

function parseToRawRole(briefRole: BriefRole) {
  const { accessInfo, fragmentInfo, ...other } = briefRole;
  const accessList: SecurityItem[] = [];
  const fragmentList: FragmentDataAccessRaw[] = [];

  accessInfo.rdfId.forEach((r) => {
    const l = accessInfo.label[r];
    const item: SecurityItem = { r, l } as SecurityItem;
    accessList.push(item);
  });
  fragmentInfo.rdfId.forEach((r) => {
    const l = fragmentInfo.label[r];
    const wr = fragmentInfo.write[r];
    const rd = fragmentInfo.read[r];
    const dl = fragmentInfo.delete[r];
    const item: FragmentDataAccessRaw = {
      r,
      l,
      rd,
      wr,
      dl,
    } as FragmentDataAccessRaw;
    fragmentList.push(item);
  });
  const role: BriefRoleRaw = { ...other, accessList, fragmentList };
  return role;
}

export async function fetchRoleListImpl(
  filter: string | null,
  search: string | null
): Promise<SecurityItemResponse> {
  // return FAKE_ROLES;
  let encodedSearch = search
    ? encodeURIComponent(search).replace("%5C", "%5C%5C")
    : null;
  const query = filter
    ? `${filter}${encodedSearch ? "&q=" + encodedSearch : ""}`
    : encodedSearch
    ? `?q=${encodedSearch}`
    : "";

  let url = `/rest/role/list${query}`;
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.json();
}
export async function fetchUserRoleListImpl(
  filter: string | null,
  search: string | null
): Promise<SecurityItemResponse> {
  // return FAKE_ROLES;
  let encodedSearch = search
    ? encodeURIComponent(search).replace("%5C", "%5C%5C")
    : null;
  const query = filter
    ? `${filter}${encodedSearch ? "&q=" + encodedSearch : ""}`
    : encodedSearch
    ? `?q=${encodedSearch}`
    : "";
  let url = `/rest/user/roles${query}`;
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.json();
}
async function fetchRoleImpl(rdfId: string): Promise<BriefRoleRaw> {
  // return FAKE_ONE_ROLE as any;
  const response = await fetch(`/rest/role/entity/${rdfId}`);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}
async function fetchNewRoleImpl(): Promise<BriefRoleRaw> {
  const response = await fetch(`/rest/role/new`);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}

async function fetchRoleSaveImpl(role: BriefRole): Promise<BriefRoleRaw> {
  const response = await fetch(`/rest/role/entity`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(parseToRawRole(role)),
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}
