import { Action } from "redux";
import * as c from "../constants/statistics";
import { ThreadState } from "../constants/statistics";
import { I18NString } from "./modal";

export interface Point {
  x: number;
  y: number;
}

export interface Slice {
  value: number;
  percent: number;
  color: string;
}
export interface SliceData {
  slices: Slice[];
  value: number;
  percent: number;
}
// export interface WorkLoadData {
//     value: number
//     maxValue: number
// }

export interface Bar {
  value: number;
  lenght: number;
  color: string;
}

// export enum NodeStatus {
//     LOADING = 1,
//     ON = 2,
//     OFF = 3
// }
export interface Legend {
  color: string;
  label: string;
}
export interface SeriesData {
  points: Point[];
}
export interface AxisFormat {
  dim: I18NString;
  multiplier: number;
}
export interface PlotData {
  count: number;
  xLabel?: AxisFormat;
  yLabel?: AxisFormat;
  legends: Legend[];
  series: SeriesData[];
}
export interface ChartData {
  count: 24;
  points: Point[];
}

export interface DynamiCpuData {
  systemLoadAvg: number | null;
  freePhysicalMemSize: number;
  totalPhysicalMemSize: number;
  committedVirtualMemorySize: number | null;
  freeSwapSpaceSize: number;
}
export interface DynamiCpuInfoReq extends DynamiCpuData {
  processCpuTime: number;
  systemCPULoad: number;
  processCPULoad: number;
}

export interface MemoryCommonInfo {
  committed: number;
  max: number | null;
  init: number | null;
}
export interface MemoryData extends MemoryCommonInfo {
  used: number;
}

export interface ThreadStatDataCommon {
  daemonThreadCount: number;
  peakThreadCount: number;
  threadCount: number;
  totalStartedThreadCount: number;
}
//DTO from statistics request
export interface MemoryStatistics {
  heap: MemoryData;
  nonHeap: MemoryData;
}

export interface DeveloperData {
  memStat: MemoryStatistics;
  cpuStat: DynamiCpuInfoReq;
}

export interface ThreadData {
  id: number;
  name: string;
  suspended: boolean;
  lockName: string;
  lockOwnerName: string;
  state: string;
  blockedTime: number;
  waitedTime: number;
  userTime: number;
  cpuTime: number;
}

export interface ThreadStatData extends ThreadStatDataCommon {
  data: ThreadData[];
}
/////

/// DTO for reducer
export interface MemoryState extends MemoryCommonInfo {
  used: ChartData;
}
export interface MemoryInfo {
  heap: MemoryState;
  nonHeap: MemoryState;
}
export interface StaticCpuInfo {
  arch: string;
  osVersion: string;
  osName: string;
  availableProcessors: number;
}

export interface DynamiCpuInfo extends DynamiCpuData {
  processCpuTime: ChartData;
  systemCpuLoad: ChartData;
  processCpuLoad: ChartData;
}

export interface CpuInfo {
  dynamicCpuInfo: DynamiCpuInfo;
}
export interface DeveloperInfo {
  memStatistics?: MemoryInfo;
  cpuStatistics?: CpuInfo;
}

export interface ThreadDataNormal {
  ids: number[];
  names: { [ID: number]: string };
  lockNames: { [ID: number]: string };
  lockOwnerNames: { [ID: number]: string };
  suspended: { [ID: number]: boolean };
  states: { [ID: number]: string };
}
export interface ThreadStatDataInfo extends ThreadStatDataCommon {
  data: ThreadDataNormal;
}

export interface DeadlockedThreads {
  loading: boolean;
  data: ThreadData[];
}
export interface ThreadStatInfo {
  loading?: boolean;
  error?: I18NString;
  info?: ThreadStatData;
  deadlocked?: DeadlockedThreads;
}

export enum ActiveDevDashboard {
  COMMON = "common",
  THREADS = "threads",
}

export interface DeveloperState {
  info?: DeveloperInfo;
  staticCpuInfo?: StaticCpuInfo;
  staticCpuInfoLoading?: boolean;
  loading?: boolean;
  error?: I18NString;
  activeDashboard: ActiveDevDashboard;
  threadStatInfo?: ThreadStatInfo;
}

export interface SendDeveloperData extends Action<c.SEND_STATISTICS_DATA> {
  payload: DeveloperData;
}
export interface SendStaticCpuInfo extends Action<c.SEND_STATIC_CPU_INFO> {
  payload: StaticCpuInfo;
}
export interface SendStaticCpuLoading
  extends Action<c.SEND_STATIC_CPU_LOADING> {
  payload: boolean;
}

export interface SendDeveloperDashboardActive
  extends Action<c.SEND_STATISTICS_DASHBOARD_ACTIVE> {
  payload: ActiveDevDashboard;
}
export interface SendDeveloperDataError
  extends Action<c.SEND_STATISTICS_DATA_ERROR> {
  payload: I18NString;
}

export interface SendDeveloperDataLoading
  extends Action<c.SEND_STATISTICS_DATA_LOADING> {
  payload: boolean;
}

//Thread statistics
export interface SendThreadStatData extends Action<c.SEND_THREAD_STAT_DATA> {
  payload: ThreadStatData;
}
export interface SendThreadStatDataError
  extends Action<c.SEND_STATISTICS_THREAD_STAT_DATA_ERROR> {
  payload: I18NString;
}
export interface SendThreadStatLoading
  extends Action<c.SEND_THREAD_STAT_LOADING> {
  payload: boolean;
}

//Deadlocked threads
export interface SendDeadlockedThreadList
  extends Action<c.SEND_DEADLOCKED_THREAD_LIST> {
  payload: ThreadData[];
}
export interface SendDeadlockedThreadListLoading
  extends Action<c.SEND_DEADLOCKED_THREAD_LIST_LOADING> {
  payload: boolean;
}

export type DeveloperAction =
  | SendDeveloperData
  | SendDeveloperDataLoading
  | SendDeveloperDashboardActive
  | SendDeveloperDataError
  | SendStaticCpuInfo
  | SendStaticCpuLoading
  | SendThreadStatData
  | SendThreadStatDataError
  | SendThreadStatLoading
  | SendDeadlockedThreadList
  | SendDeadlockedThreadListLoading;

export function getThreadStateLabel(thread: string) {
  switch (thread.toLocaleLowerCase()) {
    case ThreadState.NEW.toLocaleLowerCase():
      return "DEVELOPER_THREAD_STATE_NEW";
    case ThreadState.BLOCKED.toLocaleLowerCase():
      return "DEVELOPER_THREAD_STATE_BLOCKED";
    case ThreadState.RUNNABLE.toLocaleLowerCase():
      return "DEVELOPER_THREAD_STATE_RUNNABLE";
    case ThreadState.TERMINATED.toLocaleLowerCase():
      return "DEVELOPER_THREAD_STATE_TERMINATED";
    case ThreadState.WAITING.toLocaleLowerCase():
      return "DEVELOPER_THREAD_STATE_WAITING";
    case ThreadState.TIMED_WAITING.toLocaleLowerCase():
      return "DEVELOPER_THREAD_STATE_TIMED_WAITING";
    default:
      return null;
  }
}
export function getThreadStateColor(thread: string) {
  switch (thread.toLocaleLowerCase()) {
    case ThreadState.NEW.toLocaleLowerCase():
      return "#2196F3";
    case ThreadState.BLOCKED.toLocaleLowerCase():
      return "#f44336";
    case ThreadState.RUNNABLE.toLocaleLowerCase():
      return "#4CAF50";
    case ThreadState.TERMINATED.toLocaleLowerCase():
      return "#607D8B";
    case ThreadState.WAITING.toLocaleLowerCase():
      return "#FF9800";
    case ThreadState.TIMED_WAITING.toLocaleLowerCase():
      return "#FF5722";
  }
}
