import { createModel } from "@rematch/core";
import { RootModel } from ".";
import * as Action from "../constants/modal";
import {
  getSearchData,
  receiveHideQuery,
  receiveSearchParameters,
} from "../services/location";
import { LocationState, SearchData } from "../types/location";
import { ModalStatus } from "../types/modal";

const initialState: LocationState = {
  params: {},
  contextPath: "/",
  hideQuery: false,
};
export const location = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendSearchParameters(
      state,
      payload: { params: SearchData; reset: boolean }
    ) {
      return receiveSearchParameters(state, payload);
    },
    sendHideQuery(state, hideQuery: boolean) {
      return receiveHideQuery(state, { hideQuery });
    },
  },
  effects: (dispatch) => ({
    changeSearch(
      payload: {
        dataChanges: SearchData;
        reset?: boolean;
      },
      state
    ) {
      const { reset, dataChanges } = payload;
      const locationState = state.location;
      const selectionState = state.selection;
      const searchData: { [k: string]: string } = {
        ...locationState.params,
        ...selectionState.info,
      };
      const data = reset
        ? Object.assign({}, dataChanges)
        : Object.assign({}, searchData, dataChanges);
      for (let param in data) {
        if (data[param] === null || data[param] === "") {
          delete data[param];
        }
      }
      if (dataChanges.object || dataChanges.type || reset) {
        dispatch.selection.sendSelection({
          object: data.object || "",
          type: data.type || "",
        });
      }
      delete data.object;
      delete data.type;
      dispatch.location.sendSearchParameters({
        params: data,
        reset: reset || false,
      });
    },
    removeSearchParam(key: string, state) {
      let data = getSearchData();
      if (!data[key]) {
        return;
      }
      dispatch.location.changeSearch({ dataChanges: { [key]: "" } });
    },
    removeSearchParams(keys: string[]) {
      let changeObj: { [k: string]: string } = {};
      let data = getSearchData();
      let changed = false;
      for (let key of keys) {
        if (!data[key]) {
          continue;
        }
        changed = true;
        changeObj[key] = "";
      }
      if (!changed) {
        return;
      }
      dispatch.location.changeSearch({ dataChanges: changeObj });
    },
  }),
});
