import * as React from "react";
import { Nav } from "react-bootstrap";
import * as ReactIntl from "react-intl";
import { isLoggedInUser, isLoginStatus, MenuData } from "../../types/security";
import { Logout } from "./Menu";
import { ToggleWithoutArrow, UserMenu, UserMenuProps } from "./UserMenu";
import styles from "./MenuControls.module.css";
import { UnreadMessages } from "./UnreadMessages";
import { FormattedMessage } from "react-intl";
import { BulbComponent } from "../bulb/BulbComponent";

import { messages } from "../..";
import { MenuControlsProps } from "./MenuControls";
import { RootState, Store } from "../../store";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";
import { setupHomePage } from "../../model/menu";

export const MinifiedMenuControls: React.FC<MenuControlsProps> = ({
  changePassword,
  invokeAvatarHelp,
  isSuperUser,
  authorities,
  selection,
  badge,
  isAvatar,
  language,
  logout,
  setupHomePage,
  userLabel: label,
  systemMenu,
  className,
}) => {
  const userLabel = !isAvatar ? (
    label
  ) : (
    <FormattedMessage id="NPT_AVATAR_LABEL" />
  );
  const menuBadge = badge;
  console.log("menuBadge", menuBadge);
  return (
    <Nav className="ml-auto mr-2">
      <div className="mx-1 d-flex justify-content-center align-items-center text-white">
        <span style={{ fontSize: ".8rem" }}>{userLabel}</span>
      </div>
      <UserMenu
        language={language}
        extended={true}
        logout={logout}
        isSuperUser={isSuperUser}
        badge={menuBadge}
        setupHomePage={setupHomePage}
        systemMenu={systemMenu}
        changePassword={changePassword}
        as={ToggleWithoutArrow}
        authorities={authorities}
        selection={selection}
      />
    </Nav>
  );
};

export const undearBadgeWithStore = (store: Store) => () => {
  const state = store.getState();
  console.log("get undread", state);
  let componentProps: any = {};
  if (isLoggedInUser(state.security?.loginStatus)) {
    componentProps = {
      unread: state.security?.loginStatus?.messages?.unread,
    };
  }

  return class extends React.Component {
    render() {
      return <UnreadMessages {...componentProps} />;
    }
  };
};

export const menuComponentWithStore = (store: Store) => () => {
  const state = store.getState();
  const dispatch: RematchDispatch<RootModel> = store.dispatch;
  let componentProps: any = {
    language: state.locale?.language || "",
    userLabel: "",
    dark: false,
  };
  const loginStatus = state.security?.loginStatus;
  if (isLoggedInUser(loginStatus)) {
    let isSuperUser = isLoginStatus(loginStatus)
      ? loginStatus?.superUser
      : false;
    const authorities =
      (isLoginStatus(loginStatus) && loginStatus?.authorities) || undefined;
    const selection = state.selection;
    componentProps = {
      language: state.locale.language,
      userLabel: loginStatus.label,
      systemMenu: loginStatus.systemMenu,
      isSuperUser,
      selection,
      authorities,
    };
  }
  let functions = {
    changePassword: () => {
      dispatch.menu.changePassword();
    },
    setupHomePage: () => {
      setupHomePage();
    },
    logout: () => {
      // FIXME: not sure
      dispatch.security.logout();
    },
  };
  const UnreadBadge = undearBadgeWithStore(store)();
  return class extends React.Component {
    render() {
      const Intl = ReactIntl.IntlProvider;

      return (
        <Intl
          locale={state.locale?.language}
          messages={messages[state.locale.language]}
        >
          <MinifiedMenuControls
            {...componentProps}
            {...this.props}
            {...functions}
            badge={<UnreadBadge />}
          />
        </Intl>
      );
    }
  };
};
