const EVERY_CHAR_EXPR = /^\*$/;
const NO_MATTER_CHAR_EXPR = /^\?$/;

const DIGIT_WITH_L_CHAR_EXPR = /^\d{1,2}L$/;
const L_CHAR_RANGE_EXPR = /^L-\d{1,2}$/;
const W_CHAR_EXPR = /^\d{1,2}W$/;
const WEEK_DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const DIGIT_AT_THE_BEGINING_EXPR = /^\d{1,2}/;
const DIGIT_EXPR = /\d{1,2}/y;
//not allowed:   */1/2/*/4/3....
const INCREMENT_INVALID_EXPR = /(\d{1,2}|\*)\/\d{1,2}(\/(\d{1,2}|\*)?)+/;
const INCREMENT_RANGE_INVALID_EXPR = /(\d{1,2}|\*)\/\d{1,2}-\d{1,2}/;
//not allowed: 1-2-3-4-4...
const RANGE_INVALID_EXPR = /\d{1,2}-\d{1,2}(-\d{1,2})+/;
//names of months or weeks is  converted to numbers before parse
//so user may enter JANJAN and it'll be valid
//to prevent this behaviour add this check before converting
const MORE_THAN_3_LETTERS = /([A-Z]{3})([A-Z])+/;

//////////////////////////////////RECOURSIVE PARSER//////////////////
export function validateTimeSegment(segmentName: string, segmentValue: string) {
  let newSegmentValue = segmentValue.slice().trim();
  if (isMoreThan3Letters(segmentValue)) {
    return false;
  }
  newSegmentValue = replaceDaysStringToNumbers(
    newSegmentValue,
    segmentName,
    MONTHS
  );
  newSegmentValue = replaceDaysStringToNumbers(
    newSegmentValue,
    segmentName,
    WEEK_DAYS
  );
  let valid = parse(segmentName, newSegmentValue);
  return valid;
}

function parse(
  segmentName: string,
  segmentValue: string,
  index: number = NaN,
  previousNumber: number = NaN
): boolean {
  //null means that there are no predefined expressions  in the segment value go next
  const isPredefinedExpressionsValid = validatePredefinedExpressions(
    segmentValue,
    index
  );
  if (isPredefinedExpressionsValid != null) {
    return isPredefinedExpressionsValid;
  }
  //null means that there are no special symbols  in the segment value go next
  const isSpecialSymbolsValid = validateSpecialSymbols(
    segmentValue,
    segmentName
  );
  if (isSpecialSymbolsValid != null) {
    return isSpecialSymbolsValid;
  }

  if (isNaN(index)) {
    index = 0;
  }

  const token = getNextToken(segmentValue, index);
  if (token == "") {
    return false;
  }

  //null means that there are no numbers in the segment value  go next
  const isNumbersValid = validateNumbers(
    segmentValue,
    segmentName,
    token,
    index,
    previousNumber
  );
  if (isNumbersValid != null) {
    return isNumbersValid;
  }

  //if token one of ', / -' get another next token(nextTocken) and check if itnumber
  //if so parse number or false
  //if nextToken * go to parse
  const newIndex = index + 1;
  const nextToken = getNextToken(segmentValue, newIndex);
  if (nextToken === "") {
    return false;
  }
  if (token === ",") {
    // 1,*/2
    if (nextToken === "*") {
      return parse(segmentName, segmentValue, newIndex);
    }
    return DIGIT_AT_THE_BEGINING_EXPR.test(nextToken)
      ? parse(segmentName, segmentValue, newIndex)
      : false;
  }
  if (token === "/") {
    return DIGIT_AT_THE_BEGINING_EXPR.test(nextToken)
      ? parse(segmentName, segmentValue, newIndex)
      : false;
  }
  if (token === "-") {
    return DIGIT_AT_THE_BEGINING_EXPR.test(nextToken)
      ? parse(segmentName, segmentValue, newIndex, previousNumber)
      : false;
  }
  if (token === "*") {
    return parse(segmentName, segmentValue, newIndex);
  }
  return false;
}

function validatePredefinedExpressions(
  segmentValue: string,
  index: number
): boolean | null {
  if (
    INCREMENT_INVALID_EXPR.test(segmentValue) ||
    RANGE_INVALID_EXPR.test(segmentValue) ||
    INCREMENT_RANGE_INVALID_EXPR.test(segmentValue)
  ) {
    return false;
  }

  if (
    (!isNaN(index) && segmentValue.length === index) ||
    EVERY_CHAR_EXPR.test(segmentValue) ||
    NO_MATTER_CHAR_EXPR.test(segmentValue)
  ) {
    return true;
  }
  return null;
}

function validateSpecialSymbols(
  segmentValue: string,
  segmentName: string
): boolean | null {
  if (
    segmentValue === "L" &&
    (segmentName === "day" || segmentName === "weekDay")
  ) {
    return true;
  }

  if (segmentValue === "LW" && segmentName === "day") {
    return true;
  }

  let digit = NaN;
  if (
    (DIGIT_WITH_L_CHAR_EXPR.test(segmentValue) && segmentName === "weekDay") ||
    (W_CHAR_EXPR.test(segmentValue) && segmentName === "day")
  ) {
    digit = Number(segmentValue.slice(0, segmentValue.length - 1));
  }
  if (L_CHAR_RANGE_EXPR.test(segmentValue)) {
    digit = Number(segmentValue.slice(2, segmentValue.length));
  }

  return !isNaN(digit) ? validateTimeSegmentRange(segmentName, digit) : null;
}

function validateNumbers(
  segmentValue: string,
  segmentName: string,
  token: string,
  index: number,
  previousNumber: number
): boolean | null {
  if (DIGIT_AT_THE_BEGINING_EXPR.test(token)) {
    const digit = Number(token);
    const newIndex = !isNaN(index) ? index + token.length : token.length;
    if (validateTimeSegmentRange(segmentName, digit)) {
      if (digit < previousNumber) {
        return false;
      }

      if (newIndex === segmentValue.length) {
        return parse(segmentName, segmentValue, newIndex);
      }

      const nextToken = getNextToken(segmentValue, newIndex);
      if (nextToken === "") {
        return false;
      }
      if (nextToken === "," || nextToken === "/") {
        return parse(segmentName, segmentValue, newIndex);
      }
      if (nextToken === "-") {
        return parse(segmentName, segmentValue, newIndex, digit);
      }
    }
  }
  return null;
}

function validateTimeSegmentRange(segmentName: string, digit: number): boolean {
  return (
    (segmentName === "weekDay" && digit < 8 && digit > 0) ||
    (segmentName === "day" && digit < 32 && digit > 0) ||
    ((segmentName === "second" || segmentName === "minute") &&
      digit < 60 &&
      digit >= 0) ||
    (segmentName === "hour" && digit < 24 && digit >= 0) ||
    (segmentName === "month" && digit < 13 && digit > 0)
  );
}

function getNextToken(segmentValue: string, index: number): string {
  const char = segmentValue.charAt(index);
  if (char && char === ",") {
    return ",";
  }
  if (char && char === "*") {
    return "*";
  }
  if (char && char === "/") {
    return "/";
  }
  if (char && char === "-") {
    return "-";
  }

  DIGIT_EXPR.lastIndex = index;
  const digitResult = DIGIT_EXPR.exec(segmentValue);
  if (digitResult) {
    return digitResult.toString();
  }

  const day = segmentValue.substring(index, 3);
  if (day && WEEK_DAYS.includes(day)) {
    return (WEEK_DAYS.indexOf(day) + 1).toString();
  }
  if (day && MONTHS.includes(day)) {
    return (MONTHS.indexOf(day) + 1).toString();
  }
  return "";
}

//Replace SUN to 1 in cron segment value:   SUN-FRI/2 => 1-5/2
function replaceDaysStringToNumbers(
  segmentValue: string,
  segmentName: string,
  daysMassive: string[]
) {
  if (segmentName !== "day" && segmentName !== "weekDay") {
    return segmentValue;
  }
  let newSegmentValue = segmentValue.slice(0, segmentValue.length);
  for (let i = 0; i < daysMassive.length; i++) {
    if (newSegmentValue.includes(daysMassive[i])) {
      var reg = new RegExp(daysMassive[i], "g");
      const dayIndex = i + 1;
      newSegmentValue = newSegmentValue.replace(reg, dayIndex.toString());
    }
  }

  return newSegmentValue;
}

function isMoreThan3Letters(segmentValue: string) {
  if (MORE_THAN_3_LETTERS.test(segmentValue)) {
    return true;
  }
  return false;
}
//////////////////////////////////END RECOURSIVE PARSER//////////////////

// create single line cron task from time segments
export const cronExpressionCreator = function createCronComand(task: any) {
  let space = " ";
  let timeSegments = [
    task.second.trim(),
    task.minute.trim(),
    task.hour.trim(),
    task.day.trim(),
    task.month.trim(),
    task.weekDay.trim(),
  ];
  for (let segment in timeSegments) {
    segment = leadingZeroAppender(segment);
  }
  let cronComand = timeSegments.join(space);
  return cronComand;
};
//if user enter one digit in manual mode it is necessary to add a leading zero (1 -> 01)
export const leadingZeroAppender = function addLeadingZero(
  timeSegment: string
) {
  if (/^\d$/.test(timeSegment)) {
    timeSegment = "0" + timeSegment;
  }
  return timeSegment;
};

///////Constants for select elements
export const MINUTES_CONSTANTS = (function generateMinutes() {
  let minutes: any[] = [
    {
      id: "CRON_TASK_OPTION_EVERY_MINUTE",
      value: "*",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_2_MINUTES",
      value: "*/2",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_3_MINUTES",
      value: "*/3",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_4_MINUTES",
      value: "*/4",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_5_MINUTES",
      value: "*/5",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_6_MINUTES",
      value: "*/6",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_10_MINUTES",
      value: "*/10",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_15_MINUTES",
      value: "*/15",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_20_MINUTES",
      value: "*/20",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_30_MINUTES",
      value: "*/30",
    },
  ];
  for (let i = 0; i < 60; i++) {
    let minuteValue = i < 10 ? "0" + i : i;
    minutes.push({ value: minuteValue });
  }
  return minutes;
})();
export const SECONDS_CONSTANTS = (function generateSeconds() {
  let seconds: any[] = [
    {
      id: "CRON_TASK_OPTION_EVERY_SECOND",
      value: "*",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_2_SECONDS",
      value: "*/2",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_3_SECONDS",
      value: "*/3",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_4_SECONDS",
      value: "*/4",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_5_SECONDS",
      value: "*/5",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_6_SECONDS",
      value: "*/6",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_10_SECONDS",
      value: "*/10",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_15_SECONDS",
      value: "*/15",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_20_SECONDS",
      value: "*/20",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_30_SECONDS",
      value: "*/30",
    },
  ];
  for (let i = 0; i < 60; i++) {
    let secondValue = i < 10 ? "0" + i : i;
    seconds.push({ value: secondValue });
  }
  return seconds;
})();
export const HOURS_CONSTANTS = (function generateHours() {
  let hours: any[] = [
    {
      id: "CRON_TASK_OPTION_EVERY_HOUR",
      value: "*",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_2_HOURS",
      value: "*/2",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_3_HOURS",
      value: "*/3",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_4_HOURS",
      value: "*/4",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_6_HOURS",
      value: "*/6",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_8_HOURS",
      value: "*/8",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_12_HOURS",
      value: "*/12",
    },
  ];
  for (let i = 0; i < 24; i++) {
    let hourValue = i < 10 ? "0" + i : i;
    hours.push({ value: hourValue });
  }
  return hours;
})();
export const DAYS_CONSTANTS = (function generateDays() {
  let days: any[] = [
    {
      label: "CRON_TASK_OPTION_NO_MATTER",
      value: "?",
    },
    {
      label: "CRON_TASK_OPTION_EVERY_DAY",
      value: "*",
    },
  ];
  for (let i = 1; i < 32; i++) {
    let dayValue = i < 10 ? "0" + i : i;
    days.push({ value: dayValue });
  }
  return days;
})();
export const MONTHS_CONSTANTS = (function generateMonths() {
  let months: any[] = [
    {
      id: "CRON_TASK_OPTION_EVERY_MONTH",
      value: "*",
    },
    {
      id: "CRON_TASK_OPTION_JANUARY",
      value: "01",
    },
    {
      id: "CRON_TASK_OPTION_FEBRUARY",
      value: "02",
    },
    {
      id: "CRON_TASK_OPTION_MARCH",
      value: "03",
    },
    {
      id: "CRON_TASK_OPTION_APRIL",
      value: "04",
    },
    {
      id: "CRON_TASK_OPTION_MAY",
      value: "05",
    },
    {
      id: "CRON_TASK_OPTION_JUNE",
      value: "06",
    },
    {
      id: "CRON_TASK_OPTION_JULY",
      value: "07",
    },
    {
      id: "CRON_TASK_OPTION_AUGUST",
      value: "08",
    },
    {
      id: "CRON_TASK_OPTION_SEPTEMBER",
      value: "09",
    },
    {
      id: "CRON_TASK_OPTION_OCTOBER",
      value: "10",
    },
    {
      id: "CRON_TASK_OPTION_NOVEMBER",
      value: "11",
    },
    {
      id: "CRON_TASK_OPTION_DECEMBER",
      value: "12",
    },
  ];
  return months;
})();
export const WEEK_DAYS_CONSTANTS = (function generateWeekDays() {
  let weekDays: any[] = [
    {
      id: "CRON_TASK_OPTION_NO_MATTER",
      value: "?",
    },
    {
      id: "CRON_TASK_OPTION_EVERY_DAY",
      value: "*",
    },
    {
      id: "CRON_TASK_OPTION_MONDAY",
      value: "01",
    },
    {
      id: "CRON_TASK_OPTION_TUESDAY",
      value: "02",
    },
    {
      id: "CRON_TASK_OPTION_WEDNESDAY",
      value: "03",
    },
    {
      id: "CRON_TASK_OPTION_THURSDAY",
      value: "04",
    },
    {
      id: "CRON_TASK_OPTION_FRIDAY",
      value: "05",
    },
    {
      id: "CRON_TASK_OPTION_SATURDAY",
      value: "06",
    },
    {
      id: "CRON_TASK_OPTION_SUNDAY",
      value: "07",
    },
  ];
  return weekDays;
})();
