import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import { isI18NString, ModalInfo, ModalStatus } from "../../../types/modal";
import ModalView from "../ModalView";

interface DeleteModalProps {
  modal: ModalInfo;
  closeModal: (status: ModalStatus, result: any) => void;
}

export default class DeleteModal extends React.Component<DeleteModalProps> {
  private title: any = { id: "REMOVE_CLASS" };
  constructor(props: DeleteModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    const title = this.props.modal.options.title;
    this.title.id = isI18NString(title) ? title.id : title || "";
  }
  renderTemplate(): React.ReactElement {
    const { body, title } = this.props.modal.options;

    this.title = title ? title : "DELETE TITLE";
    const bodyMess =
      typeof body === "string" ? (
        <FormattedMessage
          id={body.toString()}
          defaultMessage="Delete attention"
        />
      ) : body ? (
        <FormattedMessage
          id={body.id}
          values={body.values}
          defaultMessage="Delete attention"
        />
      ) : (
        <></>
      );

    return (
      <Form.Group as={Row} controlId="deleteClass">
        <Col md={10}>
          <Form.Text>{bodyMess}</Form.Text>
        </Col>
      </Form.Group>
    );
  }

  closeModal(status: ModalStatus, result: any) {
    if (status == MODAL_STATUS_OK) {
      this.props.closeModal(status, true);
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, false);
    }
  }

  render() {
    const modal = { ...this.props.modal };

    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}
