import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SockJS from "sockjs-client";
import * as StompJs from "@stomp/stompjs";
import { IMessage } from '@stomp/stompjs';
import { OneSessionState, SessionAction, SessionActionDispatcher, SessionActionFunction, SessionContextInterface, SessionCreateEvent, SessionDropEvent, SessionStateEvent, SessionUpdateEvent, SessionValueChange, SessionWorkerEvent, isSessionWorkerEvent } from '../../types/session';
import { connect } from 'react-redux';
import { createEmptySession, createSessionPath } from '../../model/session';
import { RootState } from '../../store';
import { RematchDispatch } from '@rematch/core';
import { RootModel } from '../../model';

export type SessionCheckReadyFunction = (state: { [P: string]: any }) => boolean

export interface SessionOnLoadEvent {
    target: SessionImpl
}

export interface SessionWithoutActionsProps extends OneSessionState {
    //Path
    path: string

}

export interface SessionImplProps extends SessionWithoutActionsProps {
    reload: (smooth?: boolean) => void
    sync: () => void
    create: () => void
    drop: () => void
    applyStateChanges: (change: SessionValueChange) => void
    sessionDispatchAction: (name: string, parameters: any[]) => void
    handleStateEvent: (event: SessionStateEvent) => void
    handleWorkerEvent: (event: SessionWorkerEvent) => void
    handleDropEvent: (event: SessionDropEvent) => void
    //User specified properties
    checkReady?: boolean | string | SessionCheckReadyFunction
    onLoad?: (event: SessionOnLoadEvent) => void
    //Children to be rendered
    children: any
    //Specified by wrapper (requires web socket connection)
    parentSession: boolean
}

export const SessionContext = React.createContext<SessionContextInterface>({
    path: null,
    model: null
});

const TIMEOUT = 10000

class SessionImpl extends React.PureComponent<SessionImplProps> {

    private stomp: StompJs.CompatClient | null = null;

    //Make public (it is also used in handleOnLoad)
    public dispatch: SessionActionDispatcher = {};

    //Previous action list that was used ti create dispatch
    private prevActionList: SessionAction[] = []

    constructor(props: SessionImplProps) {
        super(props)
    }

    componentDidMount() {
        if (this.props.parentSession) {
            if (this.props.uuid.length == 0) {
                this.props.reload()
            } else {
                this.connect()
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<SessionImplProps>): void {
        if (this.props.parentSession) {
            if (prevProps.uuid != this.props.uuid || prevProps.prototypeKey != this.props.prototypeKey) {
                this.reconnectImmediatly()
            }
        }
    }

    componentWillUnmount(): void {
        if (this.stomp) {
            this.stomp.disconnect();
            this.stomp = null;
        }
    }

    applyStateChanges = (change: SessionValueChange) => {
        //Send changes to store and server
        this.props.applyStateChanges(change)
    }

    setStateValue = (key: string, value: object) => {
        this.applyStateChanges({
            type: "SET",
            key, value
        })
    }

    toggleValue = (key: string) => {
        this.applyStateChanges({
            type: "TOGGLE",
            key, value: null
        })
    }

    addValue = (key: string, value: number) => {
        this.applyStateChanges({
            type: "ADD",
            key, value
        })
    }

    pushValue = (key: string, value: any) => {
        this.applyStateChanges({
            type: "PUSH",
            key, value
        })
    }

    clearValue = (key: string) => {
        this.applyStateChanges({
            type: "CLEAR",
            key, value: null
        })
    }

    setFieldValue = (key: string, field: string, value: any) => {
        this.applyStateChanges({
            type: "SET",
            key, field, value
        })
    }

    toggleField = (key: string, field: string) => {
        this.applyStateChanges({
            type: "TOGGLE",
            key, field, value: null
        })
    }

    addToField = (key: string, field: string, value: number) => {
        this.applyStateChanges({
            type: "ADD",
            key, field, value
        })
    }

    drop = () => {
        this.props.drop()
    }

    reload = (smooth?: boolean) => {
        this.props.reload(smooth)
    }

    create = () => {
        this.props.create();
    }

    isCreated = () => {
        return this.props.uuid.length > 0
    }

    reconnectImmediatly = () => {
        this.disconnect();
        this.connect();
    }

    disconnect = () => {
        if (this.stomp) {
            try {
                this.stomp.disconnect();
            } catch (e) {
                console.error("Failed to call stomp disconnect", e);
            }
            this.stomp = null;
        }
    }

    connect = () => {
        if (this.stomp) { //Already connected
            return
        }
        try {
            const location = window.location;
            const url = location.protocol + "//" + location.host + "/ws/event";

            console.log("Connecting...", url);

            const stomp = StompJs.Stomp.over(() => {
                const socket = new SockJS(url);
                socket.onerror = (e: any) => {
                    console.error("Web socket error: ", e);
                }
                return socket;
            })

            stomp.onStompError = (frame: StompJs.IFrame) => {
                console.error("Stomp error: ", frame);
            }

            stomp.onWebSocketError = (evt: any) => {
                console.error("Stomp web socket erro: ", evt);
            }

            stomp.reconnectDelay = TIMEOUT

            this.stomp = stomp;
            stomp.connect({}, () => { //Connected
                if (this.props.uuid.length > 0) { //Session is already created
                    //Session event handlers
                    const stateTopic = "sess-" + this.props.uuid;
                    stomp.subscribe('/topic/' + stateTopic, (message: IMessage) => {
                        if (message.body) {
                            const event = JSON.parse(message.body) as SessionUpdateEvent
                            if (isSessionWorkerEvent(event)) {
                                this.props.handleWorkerEvent(event);
                            } else {
                                this.props.handleStateEvent(event)
                            }
                        }
                    });
                    //Drop session handler
                    const dropTopic = "sess-drop-" + this.props.uuid;
                    stomp.subscribe('/topic/' + dropTopic, (message: IMessage) => {
                        const event = JSON.parse(message.body) as SessionDropEvent;
                        this.props.handleDropEvent(event)
                    });
                    //Resync session after connection
                    this.props.sync();
                } else if (typeof this.props.prototypeKey == 'string') { //Wait for session to be created
                    const createTopic = "sess-create-" + this.props.prototypeKey;
                    stomp.subscribe('/topic/' + createTopic, (message: IMessage) => {
                        const event = JSON.parse(message.body) as SessionCreateEvent;
                        console.log("Session create event: ", event)
                        this.reload(true);
                    });
                }
                //Run onload handler with small timeout (for server to make all subscriptions)
                window.setTimeout(this.handleOnLoad, 100);

            })
        } catch (e) {
            console.error("Failed to connect to session updates: " + e);
        }
    }

    handleOnLoad = () => {
        //Check event handler
        const { onLoad } = this.props;
        if (typeof onLoad == 'function') {
            //Ensure that this.dispatch is ready
            this.createDispatch()
            //Then call event handler
            onLoad({
                target: this
            });
        }
    }

    createDispatch = () => {
        if (this.props.actionList != this.prevActionList) {
            this.prevActionList = this.props.actionList
            this.dispatch = {}
            if (Array.isArray(this.props.actionList)) {
                for (let act of this.props.actionList) {
                    this.dispatch[act.name] = this.createActionDispatcher(act.name)
                }
            }
            console.log(this.props.parentSession? "RECREATE DISPATCH PARENT": "RECREATE DISPATCH CHILD", this.dispatch)
        }
        return this.dispatch;
    }

    createActionDispatcher = (name: string): SessionActionFunction => {
        return (...args: any[]) => {
            this.props.sessionDispatchAction(name, args)
        }
    }

    getCheckReadyFunction = (): SessionCheckReadyFunction => {
        const { checkReady } = this.props;
        if (typeof checkReady == 'boolean') {
            return () => checkReady;
        }
        if (typeof checkReady == 'string') {
            return (sessionState) => sessionState[checkReady] ? true : false;
        }
        if (typeof checkReady == 'function') {
            return checkReady;
        }
        return () => true;

    }

    renderChild() {

        const checkReady = this.getCheckReadyFunction();
        if (!checkReady(this.props.sessionState)) {
            return <FillPlaceholder>
                <LoadingPlaceholder />
            </FillPlaceholder>;
        }

        const { children, ...rest } = this.props;

        const childProps = {
            ...rest,
            state: this.props.sessionState,
            workers: this.props.workersState,
            dispatch: this.createDispatch(),
            reload: this.reload,
            create: this.create,
            isCreated: this.isCreated,
            drop: this.drop,
            setStateValue: this.setStateValue,
            toggleValue: this.toggleValue,
            addValue: this.addValue,
            clearValue: this.clearValue,
            pushValue: this.pushValue,
            setFieldValue: this.setFieldValue,
            toggleField: this.toggleField,
            addToField: this.addToField
        }

        const count = React.Children.count(children)
        if (count == 0) {
            return null
        } else if (count == 1) {
            const el = React.Children.only(children);
            if (React.isValidElement<any>(el)) {
                return React.cloneElement(el, childProps)
            }
            console.error("Invalid element type in session", el)
            return null;
        } else {
            return <>
                {
                    React.Children.map(children, (el) => {
                        if (React.isValidElement<any>(el)) {
                            return React.cloneElement(el, childProps)
                        }
                        console.error("Invalid element type in session", el)
                        return null
                    })
                }
            </>
        }
    }

    render() {
        if (this.props.error) {
            return <FillPlaceholder>
                <ErrorPlaceholder fetchData={this.reload} />
            </FillPlaceholder>;
        }
        if (this.props.loading) {
            return <FillPlaceholder>
                <LoadingPlaceholder />
            </FillPlaceholder>;
        }
        return <SessionContext.Provider value={this.props}>
            {this.renderChild()}
        </SessionContext.Provider>
    }

}

const FillPlaceholder = React.memo((props) => <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    {props.children}
</div>);

interface ErrorPlaceholderProps {
    fetchData: () => void
}
const ErrorPlaceholder: React.FunctionComponent<ErrorPlaceholderProps> = React.memo((props) => <div className="alert alert-danger">
    <FormattedMessage
        id="NPT_SESSION_ERROR"
        defaultMessage="Error occured during session information fetch"
        description="Fetch error placeholder"
    />
    <button className="btn btn-danger ml-2" onClick={props.fetchData}>
        <i className="fa fa-refresh"></i>
    </button>
</div>);

const LoadingPlaceholder = React.memo(() => <div className="d-flex align-items-center alert alert-info">
    <i className="fa fa-circle-o-notch fa-spin fa-2x mr-2"></i>
    <FormattedMessage
        id="NPT_SESSION_LOADING"
        defaultMessage="Loading..."
        description="Session loading placeholder"
    />
</div>);

export interface ConnectedSessionRequiredProps {
    path: string
    model: string
    systemObject?: string
}

const ConnectedSession = connect((state: RootState, { path, model, systemObject }: ConnectedSessionRequiredProps) => {
    const existing = state.session.sessions[path];
    if (typeof existing != 'undefined') {
        const props: SessionWithoutActionsProps = {
            path,
            ...existing,
        }
        return props;
    }
    console.log("No session found", path, model, systemObject)
    const empty = createEmptySession(model, systemObject);
    const props: SessionWithoutActionsProps = {
        path,
        ...empty,
    }
    return props;
}, (dispatch: RematchDispatch<RootModel>, { path, model, systemObject }: ConnectedSessionRequiredProps) => {
    return {
        reload: (smooth?: boolean) => dispatch.session.reload({ model, systemObject, smooth }),
        sync: () => dispatch.session.sync({ model, systemObject }),
        create: () => dispatch.session.create({ model, systemObject }),
        drop: () => dispatch.session.drop({ model, systemObject }),
        applyStateChanges: (change: SessionValueChange) => dispatch.session.applyStateChanges({ path, change }),
        sessionDispatchAction: (name: string, parameters: any[]) => dispatch.session.sessionDispatchAction({ model, systemObject, name, parameters }),
        handleStateEvent: (event: SessionStateEvent) => dispatch.session.handleStateEvent({ ...event, path }),
        handleWorkerEvent: (event: SessionWorkerEvent) => dispatch.session.notifyWorkerEvent({ ...event, path }),
        handleDropEvent: (event: SessionDropEvent) => dispatch.session.handleDropEvent({ ...event, path })
    }
})(SessionImpl);


export interface SessionRequiredProps {
    session: string
    systemObject?: string
    checkReady?: boolean | string | SessionCheckReadyFunction
    onLoad?: (event: SessionOnLoadEvent) => void
    children: any
    [P: string]: any
}


const Session = ({ session, systemObject, checkReady, onLoad, children, ...rest }: SessionRequiredProps) => {

    const [path, setPath] = useState('');

    useEffect(() => {
        if (typeof session == 'string' && session.length > 0) {
            const path = createSessionPath(session, systemObject);
            setPath(path)
        }
    }, [session, systemObject])

    //Child session
    if (path == '') {
        //Render only if parent path will not be null
        return <SessionContext.Consumer>
            {parent => parent.path && parent.model && <ConnectedSession
                parentSession={false}
                path={parent.path}
                model={parent.model}
                systemObject={parent.systemObject}
                checkReady={checkReady}
                onLoad={onLoad}
                {...rest}>

                {children}

            </ConnectedSession>}
        </SessionContext.Consumer>
    }

    //Parent session
    return <ConnectedSession
        parentSession={true}
        path={path}
        model={session}
        systemObject={systemObject}
        checkReady={checkReady}
        onLoad={onLoad}
        {...rest}>

        {children}

    </ConnectedSession>
}


export default Session;