import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { isEditable } from "../../../services/layout";
import { Dispatch, RootState } from "../../../store";
import { I18NString } from "../../../types/modal";
import { isSubject, LayoutNode, SubjectComment } from "../../../types/subject";
import DateTimePicker from "../../datetimepicker/DateTimePicker";
import { YearSelector } from "../../datetimepicker/YearSelector";
import BasicInput from "./BasicInput";

export interface DateInputProps {
  subjectKey: string;
  nodeId: string;
  visible: boolean;
  node?: LayoutNode;
  value?: string;
  editable?: boolean;
  cardEditable?: boolean;
  error?: I18NString | string;
  valid?: boolean;
  comment?: SubjectComment;
  change: (data: string | object, options?: any) => void;
}

class DateInput extends React.Component<DateInputProps> {
  changeData = (date: Date | null) => {
    if (date === null) {
      this.props.change({});
    }
    date !== null && this.props.change(moment(date).format("YYYY-MM-DD"));
  };
  changeYear = (year: number | null) => {
    if (year === null) {
      this.props.change({});
      return;
    }
    const date = new Date();
    date.setFullYear(year);
    this.props.change(date.toISOString());
  };
  renderInput(node: LayoutNode) {
    const {
      subjectKey,
      nodeId,
      editable,
      cardEditable,
      value,
      valid,
      visible,
      error,
      comment,
    } = this.props;
    if (node?.options?.year) {
      let year: number | undefined = undefined;
      if (value) {
        year = moment(value).year();
      }
      return (
        <YearSelector
          valid={valid}
          disabled={!editable}
          year={year}
          changeYear={this.changeYear}
        />
      );
    }
    return (
      <DateTimePicker
        valid={valid}
        date={value}
        disabled={!editable}
        getCurrentDate={this.changeData}
        calendarPosition={"fixed"}
      />
    );
  }
  render() {
    const {
      node,
      subjectKey,
      nodeId,
      editable,
      cardEditable,
      value,
      valid,
      visible,
      error,
      comment,
    } = this.props;
    if (!node) {
      return null;
    }
    return (
      <BasicInput
        id={subjectKey + "." + nodeId}
        error={error}
        editable={editable}
        cardEditable={cardEditable}
        node={node}
        visible={visible}
        comment={comment}
      >
        {this.renderInput(node)}
        {/* <DateTimePicker
          valid={valid}
          date={value}
          disabled={!editable}
          getCurrentDate={this.changeData}
          calendarPosition={"fixed"}
        /> */}
      </BasicInput>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    const subject = state.subject && state.subject.subjects[subjectKey];

    if (!isSubject(subject)) {
      return { locale: state.locale.language, visible: false };
    }
    const value = subject.values[nodeId];

    const serverLock = subject.subjectData.$lock;
    const serverLockReady = serverLock && serverLock.status;
    const isNew = subject.isNew;
    const cardEditable = serverLockReady || isNew;

    const node = subject.nodeById[nodeId];
    const editable = isEditable(subject, nodeId, cardEditable);

    const visible = subject.visibility[ownProps.nodeId] ? true : false;

    const error = subject.validation[nodeId];
    let valid = !error || !cardEditable ? true : false;
    let mandatory = subject.mandatorySet[node.id];
    if (
      mandatory &&
      (!value || (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      valid = false;
    }

    return {
      node,
      value: typeof value == "string" ? value : undefined,
      editable,
      cardEditable,
      visible,
      error,
      valid,
      comment: subject.comment[nodeId],
    };
  },
  (dispatch: Dispatch, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      change: (data: string | object, options?: any) =>
        dispatch.subject.change({ subjectKey, nodeId, data, options }),
    };
  }
)(DateInput);
