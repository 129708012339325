import { createModel } from "@rematch/core"
import { RootModel } from "."
import { ServerError } from "../actions/utils"
import { dispatchError, dispatchSuccess } from "../services/alert"
import { store } from "../store"
import { DEFAULT_PREFERENCE_STATE, PreferenceState } from "../types/preference"
import {
  EventSourceData,
  EventSourceInfo,
  EventSourceState,
  isESInfo,
  isTaskInfo,
  SourceInfo,
  SourceType,
  SporadicTaskData,
  SporadicTaskInfo,
  SporadicTaskState,
  TableRequest,
  TableResult,
} from "../types/sporadictask"

const initialState: SporadicTaskState = { eventSource: {} }

export const DEFAULT_SPORADIC_STATE = {
  offset: 0,
  limit: 20,
  totalNumberOfElements: 0,
}

const parseTasks = (tasks: SporadicTaskData[]) => {
  const taskInfo: SporadicTaskInfo = {
    ids: [],
    descriptions: {},
    events: {},
    labels: {},
    scriptPaths: {},
    sources: {},
    checked: {},
  }
  for (const t of tasks) {
    const { description, event, id, label, scriptPath, server } = t
    if (!id) {
      continue
    }
    taskInfo.ids.push(id)
    taskInfo.checked[id] = false
    taskInfo.descriptions[id] = description
    taskInfo.events[id] = event
    taskInfo.labels[id] = label
    taskInfo.scriptPaths[id] = scriptPath
    taskInfo.sources[id] = server
  }
  return taskInfo
}
const parseEventSources = (eventSources: EventSourceData[]) => {
  const eventSourceInfo: EventSourceInfo = {
    keys: [],
    servers: {},
    passwords: {},
    usernames: {},
    checked: {},
  }
  for (const s of eventSources) {
    const { key, password, server, username } = s
    if (!key) {
      continue
    }
    eventSourceInfo.keys.push(key)
    eventSourceInfo.checked[key] = false
    eventSourceInfo.passwords[key] = password
    eventSourceInfo.servers[key] = server
    eventSourceInfo.usernames[key] = username
  }
  return eventSourceInfo
}
function parseSources(sources: SourceInfo[]) {
  const sourceObj: SourceType = {}
  sources.forEach((s) => {
    const { key } = s
    sourceObj[key] = s
  })
  return sourceObj
}

export const sporadictask = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendSporadicTaskEdit(state, id?: number) {
      return { ...state, editedTask: id }
    },
    sendServerEventEdit(state, key?: string) {
      return { ...state, editedServerEvent: key }
    },
    sendEventSources(state, esTableResult: EventSourceData[]) {
      const list = esTableResult
      const info: EventSourceInfo = parseEventSources(list)
      const eventSourceState: EventSourceState = { info, loading: false }
      return { ...state, eventSource: eventSourceState }
    },
    sendESLoading(state, loading: boolean = true) {
      const newEventSourceState = { ...state?.eventSource }
      newEventSourceState.loading = true
      return { ...state, eventSource: newEventSourceState }
    },
    sendESLimit(state, payload: { limit: number }) {
      const limit = payload.limit
      const newEventSourceState = { ...state?.eventSource }
      newEventSourceState.limit = limit
      return { ...state, eventSource: newEventSourceState }
    },
    sendESOffset(state, payload: { offset: number }) {
      const offset = payload.offset
      const newEventSourceState = { ...state?.eventSource }
      newEventSourceState.offset = offset
      return { ...state, eventSource: newEventSourceState }
    },
    sendESSearch(state, search: string) {
      // SEND_SEARCH_INPUT_EVENT_SOURCE not in action????
    },
    sendESAllItemsChecked(state, payload: { checked: boolean }) {
      const checked = payload.checked
      const newEventSourceState = { ...state?.eventSource }
      if (newEventSourceState.info) {
        const checkedObj = { ...newEventSourceState.info.checked }
        Object.keys(checkedObj).forEach((k) => {
          checkedObj[k] = checked
        })
        newEventSourceState.info.checked = checkedObj
        newEventSourceState.isAllItemsChecked = checked
        return { ...state, eventSource: newEventSourceState }
      }
      return state
    },
    sendESItemChecked(state, payload: { checked: boolean; id: string }) {
      const { checked, id } = payload
      const newEventSourceState = { ...state?.eventSource }
      newEventSourceState.info = { ...(state?.eventSource.info as any) }
      if (!isESInfo(newEventSourceState.info)) {
        return state
      }
      newEventSourceState.info.checked[id] = checked
      return { ...state, eventSource: newEventSourceState }
    },
    sendTasks(state, taskTableResult: TableResult<SporadicTaskData>) {
      const { total, list } = taskTableResult
      const tasksInfo: SporadicTaskInfo = parseTasks(list)
      return {
        ...state,
        info: { ...tasksInfo },
        totalNumberOfElements: total,
        loading: false,
      }
    },
    sendDeleteLoading(state, loading: boolean = true) {
      return { ...state, deleteLoading: loading }
    },
    sendLoading(state, loading: boolean = true) {
      return { ...state, loading: loading }
    },
    sendSourceLoading(state, loading: boolean = true) {
      return { ...state, sourceLoading: loading }
    },
    sendLimit(state, payload: { limit: number }) {
      const limit = payload.limit
      return { ...state, limit }
    },
    sendOffset(state, payload: { offset: number }) {
      const offset = payload.offset
      return { ...state, offset }
    },
    sendSearch(state, payload: { search: string }) {
      const search = payload.search
      return { ...state, searchInput: search }
    },
    sendScriptList(state, scripts: string[]) {
      return { ...state, scripts: scripts, scriptLoading: false }
    },
    sendSourceList(state, sources: SourceInfo[]) {
      return {
        ...state,
        sources: parseSources(sources),
        sourceLoading: false,
      }
    },
    sendScriptLoading(state, loading: boolean = true) {
      return { ...state, scriptLoading: loading }
    },
    sendAllItemsChecked(state, payload: { checked: boolean }) {
      const checked = payload.checked
      const newState = { ...state }
      if (newState.info) {
        const checkedObj = { ...newState.info.checked }
        Object.keys(checkedObj).forEach((k) => {
          checkedObj[parseInt(k)] = checked
        })
        newState.info.checked = checkedObj
        newState.isAllItemsChecked = checked
        return newState
      }
      return state
    },
    sendItemChecked(state, payload: { checked: boolean; id: string }) {
      const { checked, id } = payload
      const newInfo = { ...state.info }
      if (!isTaskInfo(newInfo)) {
        return state
      }
      newInfo.checked[parseInt(id)] = checked
      return { ...state, info: newInfo }
    },
  },
  effects: (dispatch) => ({
    async fetchSourcesListImpl(): Promise<SourceInfo[]> {
      const response = await fetch(`/rest/event/sources`)
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      return await response.json()
    },
    async fetchScriptListImpl(): Promise<string[]> {
      const response = await fetch(`/rest/event/script/list`)
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      return await response.json()
    },
    async fetchAddTaskImpl(task: SporadicTaskData) {
      const response = await fetch(`/rest/event/entity`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(task),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async fetchAddEventSource(eventSource: EventSourceData) {
      const response = await fetch(`/rest/event/source`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(eventSource),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async fetchSaveSporadicTaskListImpl(tasks: SporadicTaskData[]) {
      const response = await fetch(`/rest/event/entity/batch`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(tasks),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async fetchDeleteTaskBatchImpl(ids: number[]) {
      const response = await fetch(`/rest/event/delete/batch`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(ids),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async fetchDeleteESBatchImpl(key: string) {
      const response = await fetch(`/rest/event/source/${key}`, {
        method: "DELETE",
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async fetchTasksImpl(tableInfo: any): Promise<TableResult<SporadicTaskData>> {
      let url = "/rest/event/list"
      let query: string[] = []
      if (tableInfo) {
        for (const k of Object.keys(tableInfo)) {
          const v = tableInfo[k]
          if (v === undefined || String(v).trim().length === 0) {
            continue
          }
          query.push(`${k}=${v}`)
        }
      }
      if (query.length > 0) {
        url += `?${query.join("&")}`
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      const profiles = await response.json()
      return profiles
    },
    async fetchEventSourceImpl(): Promise<EventSourceData[]> {
      let url = "/rest/event/sources"

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      const es = await response.json()

      return es
    },
    async fetchTasks(payload: { tableRequest?: TableRequest }, state) {
      try {
        const s = state
        if (s && s.sporadictask && s.sporadictask.loading) {
          return
        }
        let tableInfo: TableRequest | undefined = payload.tableRequest

        if (!tableInfo) {
          tableInfo = {}
          tableInfo.limit = 20
          tableInfo.offset = 0
          if (s.sporadictask) {
            const { limit: l, offset: o, searchInput: sh } = s.sporadictask
            tableInfo.limit = l
            tableInfo.offset = o
            tableInfo.search = sh
          }
        }

        dispatch.sporadictask.sendLoading()
        dispatch.sporadictask.sendTasks(await dispatch.sporadictask.fetchTasksImpl(tableInfo))
      } catch (e) {
        dispatch.sporadictask.sendLoading(false)
        dispatchError("SPORADIC_TASKS_FETCH_ERROR", e, dispatch)
      }
    },
    async fetchScripts() {
      try {
        dispatch.sporadictask.sendScriptLoading()
        dispatch.sporadictask.sendScriptList(await dispatch.sporadictask.fetchScriptListImpl())
      } catch (e) {
        dispatch.sporadictask.sendScriptLoading(false)
        dispatchError("SPORADIC_TASKS_FETCH_SCRIPTS_ERROR", e, dispatch)
      }
    },

    async fetchSources() {
      try {
        dispatch.sporadictask.sendSourceLoading()
        dispatch.sporadictask.sendSourceList(await dispatch.sporadictask.fetchSourcesListImpl())
      } catch (e) {
        dispatch.sporadictask.sendSourceLoading(false)
        dispatchError("SPORADIC_TASKS_FETCH_SOURCES_ERROR", e, dispatch)
      }
    },
    async fetchEventSources(force: boolean = false, state) {
      try {
        const s = state
        if (s && s.sporadictask && s.sporadictask.eventSource.loading) {
          return
        }
        if (s && s.sporadictask && s.sporadictask.eventSource.info && !force) {
          return
        }

        dispatch.sporadictask.sendESLoading()
        dispatch.sporadictask.sendEventSources(await dispatch.sporadictask.fetchEventSourceImpl())
      } catch (e) {
        dispatch.sporadictask.sendESLoading(false)
        dispatchError("SPORADIC_TASKS_FETCH_ERROR", e, dispatch)
      }
    },
    async setESOffset(offset: number) {
      dispatch.sporadictask.sendESOffset({ offset })
    },
    async setESLimit(limit: number, state) {
      const sporadicTaskState = state.sporadictask
      if (!sporadicTaskState) {
        return
      }
      let tableInfo: TableRequest = { offset: 0, limit: limit }
      dispatch.sporadictask.sendESLimit({ limit })
      dispatch.sporadictask.fetchTasks({ tableRequest: tableInfo })
    },
    async setOffset(offset: number) {
      dispatch.sporadictask.sendOffset({ offset })
    },
    async setLimit(limit: number, state) {
      const sporadicTaskState = state.sporadictask
      if (!sporadicTaskState) {
        return
      }
      let tableInfo: TableRequest = { offset: 0, limit: limit }
      dispatch.sporadictask.sendLimit({ limit })
      dispatch.sporadictask.fetchTasks({ tableRequest: tableInfo })
    },
    async setSearch(value: string, state) {
      const taskState = state.sporadictask
      if (!taskState) {
        return
      }
      let tableInfo: TableRequest = {
        offset: 0,
        search: value,
        limit: taskState.limit || 20,
      }
      dispatch.sporadictask.sendSearch({ search: value })
      dispatch.sporadictask.fetchTasks({ tableRequest: tableInfo })
    },
    async deleteTasksModal(_: void, state) {
      const taskState = state

      if (taskState.sporadictask && taskState.sporadictask.info) {
        const info = taskState.sporadictask.info
        const deletedClass: number[] = []
        Object.entries(info.checked).forEach((e) => {
          const [id, checked] = e
          if (checked) {
            deletedClass.push(+id)
          }
        })
        if (deletedClass.length !== 0) {
          try {
            dispatch.sporadictask.sendDeleteLoading()
            await Promise.resolve(dispatch.sporadictask.fetchDeleteTaskBatchImpl(deletedClass))
            dispatch.sporadictask.fetchTasks({})
            dispatch.sporadictask.sendDeleteLoading(false)
            dispatchSuccess("SPORADIC_TASK_REMOVE_SUCCESS", dispatch)
          } catch (e) {
            dispatch.sporadictask.sendDeleteLoading(false)
            dispatchError("SPORADIC_TASK_REMOVE_ERROR", e, dispatch)
          }
        }
      }
    },
    async deleteES(_: void, state) {
      const title = "MSG_CONFIRM_ACTION"
      const info = state.sporadictask?.eventSource?.info
      if (!info) {
        return null
      }

      if (info) {
        // const info = state.sporadictask.info;
        const deleteKeys: string[] = []
        Object.entries(info.checked).forEach((e) => {
          const [id, checked] = e
          if (checked) {
            deleteKeys.push(id)
          }
        })
        if (deleteKeys.length !== 0) {
          try {
            // dispatch(sendDeleteLoading())
            for (let key of deleteKeys) {
              await dispatch.sporadictask.fetchDeleteESBatchImpl(key)
            }
            dispatch.sporadictask.fetchEventSources(true)
            // dispatch(sendDeleteLoading(false))
            dispatchSuccess("EVENT_SOURCE_REMOVE_SUCCESS", dispatch)
          } catch (e) {
            // dispatch(sendDeleteLoading(false))
            dispatchError("EVENT_SOURCE_REMOVE_ERROR", e, dispatch)
          }
        }
      }
    },
    async saveTaskModal(payload: { id?: string }, state) {
      dispatch.sporadictask.fetchScripts()
      dispatch.sporadictask.fetchSources()
      const st = state.sporadictask
      let body = undefined
      if (payload.id && st && st.info) {
        const { descriptions: d, events: e, ids: i, labels: l, scriptPaths: sp, sources: s } = st.info
        const editedEvent: SporadicTaskData = {
          server: s[+payload.id],
          description: d[+payload.id],
          event: e[+payload.id],
          label: l[+payload.id],
          scriptPath: sp[+payload.id],
          id: +payload.id,
        }
        body = JSON.stringify(editedEvent)
      }
      dispatch.modal.openModal({
        id: "confirm.addSporadicTask",
        type: "addSporadicTask",
        options: { body },
        okCallback: async (result) => {
          if (result) {
            try {
              await dispatch.sporadictask.fetchAddTaskImpl(result)
              dispatch.sporadictask.fetchTasks({})
              dispatchSuccess("SPORADIC_TASK_SAVE_SUCCESS", dispatch)
            } catch (e) {
              dispatch.sporadictask.sendDeleteLoading(false)
              dispatchError("SPORADIC_TASK_SAVE_ERROR", e, dispatch)
            }
          }
        },
      })
    },
    async addNewTask(task: SporadicTaskData) {
      try {
        await dispatch.sporadictask.fetchAddTaskImpl(task)
        dispatch.sporadictask.fetchTasks({})
        dispatchSuccess("SPORADIC_TASK_SAVE_SUCCESS", dispatch)
      } catch (e) {
        dispatch.sporadictask.sendDeleteLoading(false)
        dispatchError("SPORADIC_TASK_SAVE_ERROR", e, dispatch)
      }
    },
    async saveEventSourceModal(payload: { id?: string }, state) {
      const es = state.sporadictask?.eventSource
      let body = undefined
      if (payload.id && es && es.info) {
        const { keys: k, passwords: p, servers: s, usernames: u } = es.info
        const editedEvent: EventSourceData = {
          server: s[payload.id],
          key: payload.id,
          password: p[payload.id],
          username: u[payload.id],
        }
        body = JSON.stringify(editedEvent)
      }
      dispatch.modal.openModal({
        id: "confirm.addEventSource",
        type: "addEventSource",
        options: { body },
        okCallback: async (result) => {
          if (result) {
            try {
              await dispatch.sporadictask.fetchAddEventSource(result)
              dispatch.sporadictask.fetchEventSources(true)
              dispatchSuccess("EVENT_SOURCE_SAVE_SUCCESS", dispatch)
            } catch (e) {
              // dispatch(sendDeleteLoading(false))
              dispatchError("EVENT_SOURCE_SAVE_ERROR", e, dispatch)
            }
          }
        },
      })
    },
    async addNewEventSource(eventSource: EventSourceData, state) {
      const es = state.sporadictask?.eventSource
      try {
        await dispatch.sporadictask.fetchAddEventSource(eventSource)
        dispatch.sporadictask.fetchEventSources(true)
        dispatchSuccess("EVENT_SOURCE_SAVE_SUCCESS", dispatch)
      } catch (e) {
        // dispatch(sendDeleteLoading(false))
        dispatchError("EVENT_SOURCE_SAVE_ERROR", e, dispatch)
      }
    },
    async downloadSporadicTasks(_: void, state) {
      const tasks = state.sporadictask
      if (tasks && tasks.info) {
        const tasksValues = tasks.info.ids
        const tasksList: SporadicTaskData[] = []
        for (const id of tasksValues) {
          const description = tasks.info.descriptions[id]
          const scriptPath = tasks.info.scriptPaths[id]
          const event = tasks.info.events[id]
          const server = tasks.info.sources[id]
          const label = tasks.info.labels[id]
          const task: SporadicTaskData = {
            description,
            label,
            scriptPath,
            event,
            server,
          }
          tasksList.push(task)
        }
        if (tasksList.length === 0) {
          return
        }
        const a = document.createElement("a")
        a.download = "sporadic_tasks.json"
        const tasksText = JSON.stringify(tasksList, null, "\t")
        const tasksBlob = new Blob([tasksText], { type: "text/plain" })
        a.href = URL.createObjectURL(tasksBlob)
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    async validateField(payload: { name: string; field: any; label: string }) {
      if (!payload.field) {
        dispatchError("SPORADIC_TASKS_IS_INVALID", payload.name + " field is not valid ", dispatch, { name: payload.label })
        return false
      }
      return true
    },
    // FIXME: GIGA not sure
    async validateTask(task: SporadicTaskData) {
      const { id, description, event, label, scriptPath, server } = task
      if (!dispatch.sporadictask.validateField({ name: "description", field: description, label })) {
        return false
      }
      if (!dispatch.sporadictask.validateField({ name: "event", field: event, label })) {
        return false
      }
      if (!dispatch.sporadictask.validateField({ name: "label", field: label, label })) {
        return false
      }
      if (!dispatch.sporadictask.validateField({ name: "scriptPath", field: scriptPath, label })) {
        return false
      }
      if (!dispatch.sporadictask.validateField({ name: "server", field: server, label })) {
        return false
      }
      return true
    },
    async upload(tasksRaw: any, state) {
      let isSegmentsValid = true
      let tasks: SporadicTaskData[] = []
      const taskState = state.sporadictask
      try {
        tasks = JSON.parse(tasksRaw)
      } catch (e) {
        dispatchError("CRON_TASK_EMPTY_FILE", e, dispatch)
        return
      }
      if (!tasks || !tasks.length || tasks.length === 0) {
        dispatchError("CRON_TASK_EMPTY_FILE", "file is empty", dispatch)
        return
      }
      for (const task of tasks) {
        if (!dispatch.sporadictask.validateTask(task)) {
          return
        }
      }
      try {
        await dispatch.sporadictask.fetchSaveSporadicTaskListImpl(tasks)
        dispatch.sporadictask.fetchTasks({})
        dispatchSuccess("CRON_TASK_IMPORT_SUCCESS", dispatch)
      } catch (e) {
        dispatchError("CRON_TASK_IMPORT_ERROR", e, dispatch)
      }
    },
  }),
})
