import { IconProp } from "@fortawesome/fontawesome-svg-core";
import * as React from "react";
import styles from "./BulbComponent.module.css";
interface BulbComponentProps {
  type?: "success" | "danger" | "warning";
  pulse?: boolean;
  children?: any;
  style?: React.CSSProperties;
}
export const BulbComponent: React.FC<BulbComponentProps> = ({
  type,
  style,
  pulse: p,
  children,
}) => {
  let concreteBulb = styles.pulseRed;
  switch (type) {
    case "success":
      concreteBulb = styles.pulseGreen;
      break;
    case "warning":
      concreteBulb = styles.pulseYellow;
      break;
  }
  const pulse = p || true;
  return (
    <div
      style={{ height: "25px", width: "25px" }}
      className={`  ${pulse ? styles.pulse : ""} ${concreteBulb}`}
    >
      {children}
    </div>
  );
};
