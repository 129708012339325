import { Action } from "redux";
import * as c from "../constants/sporadictask";
export type ErrorType = { [FIELD: string]: string };

export interface SourceInfo {
  key: string;
  server: string;
  username: string;
  password: string;
}

export type SourceType = { [SERVER: string]: SourceInfo };

export interface TableRequest {
  limit?: number;
  offset?: number;
  search?: string;
}

export interface EventSourceState {
  loading?: boolean;
  info?: EventSourceInfo;
  totalNumberOfElements?: number;
  limit?: number;
  offset?: number;
  searchInput?: string;
  isAllItemsChecked?: boolean;
}

export interface SporadicTaskState {
  info?: SporadicTaskInfo;
  loading?: boolean;
  deleteLoading?: boolean;
  scriptLoading?: boolean;
  sourceLoading?: boolean;
  offset?: number;
  limit?: number;
  searchInput?: string;
  totalNumberOfElements?: number;
  isAllItemsChecked?: boolean;
  scripts?: string[];
  sources?: SourceType;
  eventSource: EventSourceState;
  editedTask?: number;
  editedServerEvent?: string;
}

export interface SporadicTaskInfo {
  ids: number[];
  events: { [ID: number]: string };
  labels: { [ID: number]: string };
  descriptions: { [ID: number]: string };
  scriptPaths: { [ID: number]: string };
  sources: { [ID: number]: string };
  checked: { [ID: number]: boolean };
}

// ==========Types  Profile =============
//class + package + namespace
export interface SporadicTaskData {
  id?: number;
  event: string;
  label: string;
  description: string;
  scriptPath: string;
  server: string;
}

export interface TableResult<TData> {
  total: number;
  list: TData[];
}

export interface EventSourceData {
  key: string;
  server: string;
  username: string;
  password: string;
}

export interface EventSourceInfo {
  keys: string[];
  servers: { [ID: string]: string };
  usernames: { [ID: string]: string };
  passwords: { [ID: string]: string };
  checked: { [ID: string]: boolean };
}

// ========= Action Interfaces
export interface SendEventSourceLoading
  extends Action<c.SEND_EVENT_SOURCES_LOADING> {
  payload: boolean;
}

export interface SendEventSources extends Action<c.SEND_EVENT_SOURCES> {
  payload: EventSourceData[];
}
export interface SendSporadicTaskEdit
  extends Action<c.SEND_SPORADIC_TASKS_EDIT> {
  payload: number | undefined;
}
export interface SendServerEventEdit extends Action<c.SEND_SERVER_EVENT_EDIT> {
  payload: string | undefined;
}

export interface SendESLimit extends Action<c.SEND_EVENT_SOURCE_LIMIT> {
  payload: number;
}
export interface SendESOffset extends Action<c.SEND_EVENT_SOURCE_OFFSET> {
  payload: number;
}
export interface SendESSearch extends Action<c.SEND_SEARCH_INPUT_EVENT_SOURCE> {
  payload: string;
}
export interface SendESAllItemsChecked
  extends Action<c.SEND_EVENT_SOURCE_ALL_ITEMS_CHECKED> {
  payload: boolean;
}
export interface SendESItemChecked
  extends Action<c.SEND_EVENT_SOURCE_ITEM_CHECKED> {
  payload: { checked: boolean; id: string };
}

export interface SendLoading extends Action<c.SEND_SPORADIC_TASKS_LOADING> {
  payload: boolean;
}

export interface SendTasks extends Action<c.SEND_SPORADIC_TASKS> {
  payload: TableResult<SporadicTaskData>;
}

export interface SendLimit extends Action<c.SEND_SPORADIC_TASKS_LIMIT> {
  payload: number;
}
export interface SendOffset extends Action<c.SEND_SPORADIC_TASKS_OFFSET> {
  payload: number;
}
export interface SendSearch extends Action<c.SEND_SEARCH_INPUT_SPORADIC_TASKS> {
  payload: string;
}
export interface SendAllItemsChecked
  extends Action<c.SEND_SPORADIC_TASKS_ALL_ITEMS_CHECKED> {
  payload: boolean;
}
export interface SendItemChecked
  extends Action<c.SEND_SPORADIC_TASKS_ITEM_CHECKED> {
  payload: { checked: boolean; id: string };
}
export interface SendDeleteLoading
  extends Action<c.SEND_SPORADIC_TASKS_DELETE_LOADING> {
  payload: boolean;
}

export interface SendScriptLoading
  extends Action<c.SEND_SPORADIC_SCRIPT_LOADING> {
  payload: boolean;
}

export interface SendSourceLoading
  extends Action<c.SEND_SPORADIC_SOURCE_LOADING> {
  payload: boolean;
}

export interface SendScriptList extends Action<c.SEND_SPORADIC_SCRIPT_LIST> {
  payload: string[];
}

export interface SendSourceList extends Action<c.SEND_SPORADIC_SOURCE_LIST> {
  payload: SourceInfo[];
}

export type SporadicTaskAction =
  | SendTasks
  | SendLoading
  | SendLimit
  | SendOffset
  | SendSearch
  | SendAllItemsChecked
  | SendItemChecked
  | SendDeleteLoading
  | SendScriptList
  | SendScriptLoading
  | SendSourceList
  | SendSourceLoading
  | SendEventSourceLoading
  | SendEventSources
  | SendESLimit
  | SendESOffset
  | SendESSearch
  | SendESAllItemsChecked
  | SendESItemChecked
  | SendSporadicTaskEdit
  | SendServerEventEdit;

export function isTaskInfo(
  info: SporadicTaskInfo | {} | undefined
): info is SporadicTaskInfo {
  return (
    typeof info != "undefined" &&
    typeof (info as SporadicTaskInfo).ids !== "undefined"
  );
}
export function isESInfo(
  info: EventSourceInfo | {} | undefined
): info is EventSourceInfo {
  return (
    typeof info != "undefined" &&
    typeof (info as EventSourceInfo).keys !== "undefined"
  );
}

export function isTaskData(
  data: SporadicTaskData | undefined | ErrorType
): data is SporadicTaskData {
  return (
    typeof data != "undefined" &&
    typeof (data as SporadicTaskData).event !== "undefined"
  );
}
export function isEventSourceData(
  data: EventSourceData | undefined | ErrorType
): data is EventSourceData {
  return (
    typeof data != "undefined" &&
    typeof (data as EventSourceData).key !== "undefined"
  );
}

export interface ErrorData {
  error: ErrorType;
}

export function isErrorType(
  data: EventSourceData | undefined | ErrorData
): data is ErrorData {
  return (
    typeof data != "undefined" &&
    typeof (data as ErrorData).error !== "undefined"
  );
}
