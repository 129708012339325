export const SEND_MODEL_PROFILES = "SEND_MODEL_PROFILES";
export type SEND_MODEL_PROFILES = typeof SEND_MODEL_PROFILES;

export const SEND_MODEL_PROFILES_WITH_SUBJECTS =
  "SEND_MODEL_PROFILES_WITH_SUBJECTS";
export type SEND_MODEL_PROFILES_WITH_SUBJECTS =
  typeof SEND_MODEL_PROFILES_WITH_SUBJECTS;

export const SEND_MODEL_LOADING = "SEND_MODEL_LOADING";
export type SEND_MODEL_LOADING = typeof SEND_MODEL_LOADING;

export const SEND_MODEL_SELECT_LOADING = "SEND_MODEL_SELECT_LOADING";
export type SEND_MODEL_SELECT_LOADING = typeof SEND_MODEL_SELECT_LOADING;

export const SEND_MODEL_SUBJECTS = "SEND_MODEL_SUBJECTS";
export type SEND_MODEL_SUBJECTS = typeof SEND_MODEL_SUBJECTS;

export const SEND_MODEL_LIMIT = "SEND_MODEL_LIMIT";
export type SEND_MODEL_LIMIT = typeof SEND_MODEL_LIMIT;

export const SEND_MODEL_OFFSET = "SEND_MODEL_OFFSET";
export type SEND_MODEL_OFFSET = typeof SEND_MODEL_OFFSET;

export const SEND_SEARCH_INPUT_MODEL = "SEND_SEARCH_INPUT_MODEL";
export type SEND_SEARCH_INPUT_MODEL = typeof SEND_SEARCH_INPUT_MODEL;

export const SEND_MODEL_EXPORT_RDF_LOADING = "SEND_MODEL_EXPORT_RDF_LOADING";
export type SEND_MODEL_EXPORT_RDF_LOADING =
  typeof SEND_MODEL_EXPORT_RDF_LOADING;

export const SEND_MODEL_DELETE_RDF_LOADING = "SEND_MODEL_DELETE_RDF_LOADING";
export type SEND_MODEL_DELETE_RDF_LOADING =
  typeof SEND_MODEL_DELETE_RDF_LOADING;

export const MODEL = "model";

export const PROFILE_REMOVE_CLASS_BATCH_URL =
  "/rest/profileeditor/removeclass/batch";
export const PROFILE_EXPORT_RDF_URL = `/rest/rdfmodel/export/profile`;
