import { ServerError } from "../actions/utils";
import { LogInfo } from "../types/postgres";

export async function downloadBackup(): Promise<void> {
  const response = await fetch(`/scm/postgres/backup`, {
    method: "POST",
    headers: { "Content-Type": "application/json;charset=UTF-8" },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  const rdf = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(rdf);
  const contentDesp = response.headers.get("Content-Disposition");
  let filename = "backup.sql";
  if (contentDesp) {
    filename = contentDesp.split("filename=")[1].replace(/\+/g, " ");
    filename = filename.slice(1, filename.length - 1);
  }
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}

export async function fetchBackupUpload(file: any): Promise<boolean> {
  const response = await fetch(`/scm/postgres/restore`, {
    method: "POST",
    body: file,
  });

  const { status, statusText } = response;
  if (!response.ok) {
    const cause = await response.text();
    throw new ServerError(status, cause);
  }
  return true;
}
export async function fetchPostgresLogsImpl(): Promise<LogInfo> {
  const response = await fetch(`/scm/postgres/logs`, {
    method: "GET",
  });

  const { status, statusText } = response;
  if (!response.ok) {
    const cause = await response.text();
    return {
      error: new ServerError(status, cause),
      out: null,
      backup: null,
      restore: null,
    };
  }
  return await response.json();
}
