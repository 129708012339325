import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { SAVE_STATE_START } from "../../constants/subject";
import { round } from "../../services/app";
import { Dispatch, RootState } from "../../store";
import { SaveStateType } from "../../types/subject";

interface UploadStatusProps {
  saveState?: SaveStateType;
  uploadFiles: (handleProgress: (percent: number) => void) => void;
}
interface UploadStatusStates {
  uploadProgress: number | null;
}

class UploadStatus extends React.Component<
  UploadStatusProps,
  UploadStatusStates
> {
  constructor(props: UploadStatusProps) {
    super(props);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.state = { uploadProgress: null };
  }

  componentDidUpdate(
    prevProps: UploadStatusProps,
    prevState: UploadStatusStates
  ) {
    if (prevProps.saveState != this.props.saveState) {
      if (this.props.saveState == SAVE_STATE_START) {
        this.setState({ uploadProgress: 0 }); //Signal to show upload progress bar
        this.props.uploadFiles(this.handleUploadProgress);
      } else {
        this.setState({ uploadProgress: null }); //Signal to hide upload progress bar
      }
    }
  }

  //Total upload progress callback
  handleUploadProgress(uploadProgress: number) {
    if (!this.props.saveState) {
      //somebody clicked cancel
      return false;
    }
    this.setState({ uploadProgress });
    return true;
  }

  render() {
    if (!this.state.uploadProgress) {
      return null;
    }
    return (
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={this.state.uploadProgress}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: `${this.state.uploadProgress}%` }}
        >
          <FormattedMessage
            id="OBJECTCARD_UPLOAD_COMPLETED"
            defaultMessage="{progress}% Completed"
            description="Upload status"
            values={{ progress: round(this.state.uploadProgress, 0) }}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string }) => {
    const { subjectKey } = ownProps;

    return {
      saveState: state.subject.saveState[subjectKey],
    };
  },
  (dispatch: Dispatch, ownProps: { subjectKey: string }) => {
    return {
      uploadFiles: (handleProgress: (percent: number) => void) =>
        dispatch.subject.uploadFiles({
          subjectKey: ownProps.subjectKey,
          handleUploadProgress: handleProgress,
        }),
    };
  }
)(UploadStatus);
