import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { setupRange } from "../reducers/utils";

import { I18NString } from "../types/modal";
import {
  RightData,
  RightDataNode,
  RightState,
  RightTreeState,
  RightType,
} from "../types/right";
import { SecurityItem, SecurityItemResponse } from "../types/security";

const initialState: RightState = {};

export const right = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendRightList(
      state,
      payload: { list: RightDataNode[]; parentId?: string }
    ) {
      const { list, parentId } = payload;
      let newTreeState: RightTreeState = { ...state.tree };
      const loading = newTreeState.loading || {};
      if (parentId) {
        loading[parentId] = false;
      }
      newTreeState = parseRightList(newTreeState || {}, list, parentId);
      newTreeState.treeError = undefined;
      newTreeState.treeLoading = false;
      newTreeState.loading = loading;

      return { ...state, tree: newTreeState };
    },
    sendRightActive(state, id: string) {
      const active = id;
      let newTreeState = { ...state.tree };
      const prevActive = newTreeState.active;
      const selected = newTreeState.selected;
      newTreeState.active = active;
      if (
        prevActive &&
        prevActive === active &&
        (!selected || (selected && !selected.includes(active)))
      ) {
        newTreeState.active = undefined;
      }
      return { ...state, tree: newTreeState };
    },
    sendRightsLoaing(state, loading: boolean = true) {
      let newTreeState = { ...state.tree, treeLoading: loading };
      return { ...state, tree: newTreeState };
    },
    sendRightSelect(state, id?: string) {
      const selected = id;
      let newTreeState = { ...state.tree };
      let selectedList = newTreeState.selected;
      let active = newTreeState.active;
      newTreeState.selected = createSelected(
        selectedList || null,
        active || null,
        selected || null
      );
      return { ...state, tree: newTreeState };
    },
    sendRightsClearSelect(state) {
      let newTreeState = { ...state.tree };
      newTreeState.selected = undefined;
      newTreeState.active = undefined;
      return { ...state, tree: newTreeState };
    },
    sendRightRange(state, id?: string) {
      const ranged = id;
      if (!state.tree) {
        return state;
      }
      return {
        ...state,
        tree: setupRange<RightTreeState>(ranged || null, state.tree),
      };
    },
  },
  effects: (dispatch) => ({
    fetchRightTree: async (type: RightType, s) => {
      try {
        dispatch.right.sendRightsLoaing();
        // parseUserList
        const response: SecurityItemResponse = await fetchRightsTreeImpl(type);
        const list: RightDataNode[] = parseToRightData(response.itemList);
        dispatch.right.sendRightList({ list });
      } catch (e) {
        dispatch.right.sendRightsLoaing(false);
      }
    },
  }),
});

function parseToRightData(items: SecurityItem[]) {
  const rights: RightDataNode[] = [];
  items.forEach((si) => {
    const { d: description, i: id, l: label, r: rdfId } = si;
    const right: RightData = { rdfId, description, id, label };
    rights.push(right);
  });
  return rights;
}

export async function fetchRightsTreeImpl(
  type: RightType,
  parentId?: any
): Promise<SecurityItemResponse> {
  // return FAKE_RIGHTS;
  let url = `/rest/${type}/rights`;
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  if (!resp.ok) {
    throw new ServerError(resp.status, resp.statusText);
  }
  return await resp.json();
}

export function parseRightList(
  state: RightTreeState,
  list: RightDataNode[],
  parentId?: string
) {
  let newRightState = { ...state };
  let rootNodesIds = newRightState.rootNodesIds || [];
  const childrenIds = newRightState.childrenIds || {};
  const nodeById = newRightState.nodeById || {};
  // const loading = newFragmentState.loading || {};
  if (!parentId) {
    const roots: string[] = [];
    list.forEach((f) => f.rdfId && roots.push(f.rdfId));
    rootNodesIds = roots;
  } else {
    const childrenList: string[] = [];
    list.forEach((f) => f.rdfId && childrenList.push(f.rdfId));
    childrenIds[parentId] = childrenList;
    // loading[parentId] = false;
  }

  list.forEach((f) => {
    const { rdfId } = f;
    rdfId && (nodeById[rdfId] = { ...f, parentId: parentId });
  });

  newRightState = { ...newRightState, rootNodesIds, nodeById, childrenIds };
  return newRightState;
}

function createSelected(
  selectedList: string[] | null,
  active: string | null,
  selected: string | null
) {
  if (!selected) {
    return undefined;
  }
  if (!selectedList) {
    selectedList = [];
    if (active && !selectedList.includes(active)) {
      selectedList.push(active);
    }
  }
  if (selectedList.includes(selected)) {
    selectedList = selectedList.filter((i) => i !== selected);
  } else {
    selectedList.push(selected);
  }
  if (selectedList.length === 0) {
    selectedList = null;
  }
  // newTreeState.selected = selectedList;
  return selectedList;
}
