import { FetchError } from './error';
import { Action } from 'redux';

import * as constants from '../constants/process';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';


export interface PageTitle {
    icon: IconDefinition | string,
    textId: string,
    ref?: string,
    //params for FormattedMessage
    params?: { [n: string]: string }
}

export interface Module {
    dev: number,
    type: string;
    addr: string;
    descr: string;
    error: string;
}

export type PState = "running" | "stopped" | "blocked" | "failed"

export interface Process {
    fc: number;
    src: number;
    bin: string;
    path: string;
    state: PState,
    modules: Module[]
};

export interface LicenseKey {
    error: number;
    value: string;
}

export interface License {
    active: boolean;
    key: LicenseKey
}

export type ProcessManagerMode = "auto" | "manual"

export interface ProcessManagerInfo {
    mode: ProcessManagerMode
    date: string;
    license: License;
    proc: Process[];     
}

export type PAction = "auto" | "manual" | "start" | "stop" | "block";

export interface ProcessActionResult {
    action: PAction
    address?: string
}

export type LogRecordLevel = 
    "T" | //Trance
    "D" | //Debug
    "I" | //Info
    "W" | //Warn
    "E" | //Error
    "F"   //Fatal

export interface LogRecord {
    //Level of log record
    l: LogRecordLevel
    //Message
    m: string
    //Seconds UTC from epoch
    ts: number
    //Nanoseconds in second
    tn: number
}

export interface LogFile {
    recordList: LogRecord[]
}

export interface LogInfo {
    fc: number
    src: number
    module: Module
    loading?: boolean
    logFile?: LogFile | FetchError
}

export function isProcessInfo(info: FetchError | ProcessManagerInfo | undefined): info is ProcessManagerInfo {
    return typeof info != 'undefined' && Array.isArray((info as ProcessManagerInfo).proc);
}

export function isProcessInfoError(info: FetchError | ProcessManagerInfo | undefined): info is FetchError {
    return typeof info != 'undefined' && typeof (info as FetchError).message == 'string';
}

export function isActionError(act: ProcessActionResult | ProcessActionError): act is ProcessActionError {
    return typeof (act as ProcessActionError).message == 'string';
}

export function isLogFile(info: FetchError | LogFile | undefined): info is LogFile {
    return typeof info != 'undefined' && Array.isArray((info as LogFile).recordList);
}

export function getProcessAddress(fc: number, src: number) {
    return 'FC' + fc.toString().padStart(2, '0') + '_SRC' + src.toString().padStart(3, '0');
}

export function getModuleAddress(fc: number, src: number, dev: number) {
    return getProcessAddress(fc, src) + '_DEV' + dev.toString().padStart(2, '0');
}

///////////
//Actions//
///////////
export interface SendProcessInfo extends Action {
    type: constants.SEND_PROCESS_INFO;
    payload: {
        processInfo: ProcessManagerInfo;
    }
}

export interface SendProcessInfoError extends Action {
    type: constants.SEND_PROCESS_INFO_ERROR;
    payload: {
        error: FetchError;
    }
}

export interface SendProcessClearInfo extends Action {
    type: constants.SEND_PROCESS_CLEAR_INFO;
}

export interface SendProcessInfoLoading extends Action {
    type: constants.SEND_PROCESS_INFO_LOADING;
}

export interface SendProcessActionResult extends Action {
    type: constants.SEND_PROCESS_ACTION_RESULT;
    payload: {
        result: ProcessActionResult;
    }
}

export interface ProcessActionError extends FetchError {
    action: PAction
    address?: string
}

export interface SendProcessActionError extends Action {
    type: constants.SEND_PROCESS_ACTION_ERROR;
    payload: {
        error: ProcessActionError;
    }
}

export interface SendProcessClearAction extends Action {
    type: constants.SEND_PROCESS_CLEAR_ACTION;
}

export interface SendProcessLog extends Action {
    type: constants.SEND_PROCESS_LOG;
    payload: {
        fc: number
        src: number
        dev: number
        logFile: LogFile
    }
}

export interface SendPrefferedLogLevels extends Action<constants.SEND_PREFFERED_LOG_LEVELS>{
    payload:LogRecordLevel[]
}

export interface SendProcessLogError extends Action {
    type: constants.SEND_PROCESS_LOG_ERROR;
    payload: {
        fc: number
        src: number
        dev: number
        error: FetchError
    }
}

export interface SendProcessClearLog extends Action {
    type: constants.SEND_PROCESS_CLEAR_LOG;
}

export interface SendProcessLogLoading extends Action {
    type: constants.SEND_PROCESS_LOG_LOADING;
    payload: {
        fc: number
        src: number
        dev: number
    }
}

export type ProcessAction = 
    SendProcessInfo | 
    SendProcessInfoError | 
    SendProcessInfoLoading | 
    SendProcessClearInfo |
    SendProcessActionResult | 
    SendProcessActionError |
    SendProcessClearAction |
    SendProcessLog |
    SendProcessLogError |
    SendProcessLogLoading |
    SendProcessClearLog |
    SendPrefferedLogLevels;

/////////
//State//
/////////
export interface ProcessState {
    loading?: boolean,
    processInfo?: ProcessManagerInfo | FetchError,
    processLabels?:{[ID:string]:string}
    lastAction?: ProcessActionResult | ProcessActionError
    logInfo?: LogInfo
    prefferedLogLevels?:LogRecordLevel[]
}

//extract numbers from module id 
export function parseModuleId(id:string){ 
    if(/^FC[0-9]{2}_SRC[0-9]{3}_DEV[0-9]{2}$/.test(id)){
        const groups = id.match(/^FC([0-9]{2})_SRC([0-9]{3})_DEV([0-9]{2})$/);
        if(groups?.length===4){
            groups.shift()
            const ids = groups.map(g=>parseInt(g));
            //[91,891,01]
            return ids;
        }
    }
    return null;
}