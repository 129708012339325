import { Action, AnyAction } from "redux";
import * as constants from "../constants/logviewer";
import { FetchError } from "./error";

export type LogViewerType = "logviewer";
const defaultLogLevelsState: LogLevelState = {
  [constants.TRACE_LEVEL]: true,
  [constants.INFO_LEVEL]: true,
  [constants.DEBUG_LEVEL]: true,
  [constants.WARN_LEVEL]: true,
  [constants.ERROR_LEVEL]: true,
  [constants.FATAL_LEVEL]: true,
};
export interface TimeRange {
  start?: string;
  end?: string;
}
export const DEFAULT_LOG_VIEWER_STATE = {
  info: {
    timestamps: [],
    threadIdentifiers: {},
    levels: {},
    messages: {},
    sources: {},
    details: {},
  },

  totalNumberOfElements: 0,
  currentRecordTimestamp: "",
  showLevels: defaultLogLevelsState,
  loading: false,
  error: null,
  searchInput: "",
  limit: 20,
  offset: "",
};

export interface LogLevelState {
  [label: string]: boolean;
}

export interface LogViewerState {
  info: LogViewerInfo;
  firstTimestamp?: string;
  lastTimestamp?: string;
  totalNumberOfElements: number;
  currentRecordTimestamp?: string;
  showLevels: LogLevelState;
  loading?: boolean;
  error?: null | FetchError;
  searchInput?: string;
  limit: number;
  offset: string;
  timeRange?: TimeRange;
}

export interface LogViewerInfo {
  timestamps: string[];
  levels: { [timestamp: string]: string };
  messages: { [timestamp: string]: string };
  threadIdentifiers: { [timestamp: string]: string };
  sources: { [timestamp: string]: string };
  details: { [timestamp: string]: string };
}

export interface LogViewerTableResult {
  end: string;
  start: string;
  recordList: RawLogRecord[];
  total: number;
}
export type RawLogRecord = {
  ts: string;
  l: string;
  m: string;
  tid: string;
  src: string;
  d?: string;
};

export type LogRecord = {
  timestamp: string;
  level: string;
  message: string;
  threadIdentifier: string;
  source: string;
  details?: string;
};

export type TableInfo = {
  limit?: number;
  olderRecords: boolean;
  showLevels?: string[];
  offset?: string;
  search?: string;
  startTime?: string;
  endTime?: string;
};
///////////
//Actions//
///////////

export interface SendLogRecords extends Action<constants.SEND_LOG_RECORDS> {
  logViewerType: LogViewerType;
  payload: LogViewerTableResult;
}

export interface SendCurrentLogRecord
  extends Action<constants.SEND_CURRENT_LOG_RECORD> {
  logViewerType: LogViewerType;
  payload: string;
}

export interface SendLogLevels extends Action<constants.SEND_LOG_LEVELS> {
  logViewerType: LogViewerType;
  payload: LogLevelState;
}

export interface SendLogViewerError
  extends Action<constants.SEND_LOG_VIEWER_ERROR> {
  logViewerType: LogViewerType;
  payload: {
    error: FetchError;
  };
}

export interface SendLogViewerLoading
  extends Action<constants.SEND_LOG_VIEWER_LOADING> {
  logViewerType: LogViewerType;
}

export interface SendLimit extends Action<constants.SEND_LOG_LIMIT> {
  logViewerType: LogViewerType;
  payload: number;
}

export interface SendOffset extends Action<constants.SEND_LOG_OFFSET> {
  logViewerType: LogViewerType;
  payload: number;
}

export interface SendSearchInput
  extends Action<constants.SEND_SEARCH_INPUT_LOG> {
  logViewerType: LogViewerType;
  payload: string;
}
export interface SendTimeRange extends Action<constants.SEND_LOG_TIME_RANGE> {
  payload: TimeRange | undefined;
}

export type LogViewerAction =
  | SendLogRecords
  | SendLogViewerError
  | SendLogViewerLoading
  | SendLimit
  | SendOffset
  | SendSearchInput
  | SendCurrentLogRecord
  | SendLogLevels
  | SendTimeRange;

export function isLogViewerAction(
  action: AnyAction
): action is LogViewerAction {
  return typeof action.type == "string";
}

export function compareLevelStateObjects(
  ls1: LogLevelState,
  ls2: LogLevelState
): boolean {
  if (ls1 && !ls2) {
    return false;
  }
  for (const level in ls1) {
    if (ls2[level] !== ls1[level]) {
      return false;
    }
  }
  return true;
}

export function extractCheckedLevels(levelsState: LogLevelState): string[] {
  let checkedLevels = [];
  for (const levelLabel in levelsState) {
    if (levelsState[levelLabel]) {
      checkedLevels.push(levelLabel);
    }
  }
  return checkedLevels;
}
