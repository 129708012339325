import React, { useEffect, useState } from "react";
import { SessionContext } from "./Session";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { OneSessionState } from "../../types/session";
import debounce from "../debounce/debounce";

export interface SessionChangeEvent {
  target: SessionListenerImpl;
}

export interface SessionListenerImplProps {
  //Path to sesion
  path: string;
  //Versions of session keys
  sessionVersion: { [P: string]: number };
  //Name of model in session to listen to
  modelName?: string;
  //Array of keys to listen to
  keys?: string[];
  //Arrays of keys that are required by model
  modelKeys?: string[];
  //How many millisecond to wait before calling callback
  debounce?: number;
  //Change callback
  onChange?: (evt: SessionChangeEvent) => void;
}

class SessionListenerImpl extends React.PureComponent<SessionListenerImplProps> {
  constructor(props: SessionListenerImplProps) {
    super(props);
    const tm = typeof props.debounce == "number" ? props.debounce : 100;
    this.handleChange = debounce(this.handleChange, tm, false);
  }

  handleChange = () => {
    const { onChange } = this.props;
    if (typeof onChange == "function") {
      onChange({ target: this });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<SessionListenerImplProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    //Check changed keys
    if (prevProps.sessionVersion != this.props.sessionVersion) {
      const { keys, modelKeys } = this.props;
      let changed = false;
      if (Array.isArray(keys)) {
        for (let key of keys) {
          const v1 = prevProps.sessionVersion[key];
          const v2 = this.props.sessionVersion[key];
          if (v2 != v1) {
            changed = true;
            break;
          }
        }
      }
      if (!changed && Array.isArray(modelKeys)) {
        for (let key of modelKeys) {
          const v1 = prevProps.sessionVersion[key];
          const v2 = this.props.sessionVersion[key];
          if (v2 != v1) {
            changed = true;
            break;
          }
        }
      }
      if (changed) {
        this.handleChange();
      }
    }
  }

  render() {
    return null;
  }
}

export interface ConnectedSessionListenerRequiredProps {
  path: string;
  //Name of model in session to listen to
  modelName?: string;
  //Array of keys to listen to
  keys?: string[];
}

const ConnectedSessionListener = connect(
  (
    state: RootState,
    { path, modelName }: ConnectedSessionListenerRequiredProps
  ) => {
    const session: OneSessionState | undefined = state.session.sessions[path];
    const sessionVersion = session?.sessionVersion || {};
    const modelKeys = modelName
      ? session?.modelNamesToKeys?.[modelName]
      : undefined;
    return {
      sessionVersion,
      modelKeys,
    };
  }
)(SessionListenerImpl);

export interface SessionListenerProps {
  //Name of model in session to listen to
  modelName?: string;
  //Array of keys to listen to
  keys?: string[];
  //How many millisecond to wait before calling callback
  debounce?: number;
  //Change callback
  onChange?: (evt: SessionChangeEvent) => void;
}

const SessionListener = ({
  modelName,
  keys,
  debounce,
  onChange,
}: SessionListenerProps) => {
  return (
    <SessionContext.Consumer>
      {(parent) =>
        parent.path &&
        parent.model && (
          <ConnectedSessionListener
            path={parent.path}
            modelName={modelName}
            keys={keys}
            debounce={debounce}
            onChange={onChange}
          />
        )
      }
    </SessionContext.Consumer>
  );
};

export default SessionListener;
