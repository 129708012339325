import { ServerError } from "../actions/utils";
import { ClusterInfo } from "../types/cluster";

export function fetchClusterInfoImpl(): Promise<ClusterInfo> {
  return fetch("/rest/config/cluster")
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as ClusterInfo;
    });
}

export function changeClusterInfoImpl(
  cluster: ClusterInfo
): Promise<ClusterInfo> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cluster),
  };
  return fetch("/rest/config/cluster", opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as ClusterInfo;
    });
}

export function checkClusterImpl(cluster: ClusterInfo): Promise<ClusterInfo> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cluster),
  };
  return fetch("/rest/config/cluster_check", opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as ClusterInfo;
    });
}
