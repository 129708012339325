import {
  faAngleDown,
  faAngleUp,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faLevelDownAlt,
  faLevelUpAlt,
  faPlusSquare,
  faSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Select from "react-select";

import { isCardEdit, isEditable } from "../../../services/layout";
import { FragmentData, FragmentNodeMap } from "../../../types/fragment";
import {
  FragmentDataRaw,
  isSubject,
  LayoutNode,
  SubjectComment,
} from "../../../types/subject";
import LargeInput from "./LargeInput";
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from "./Style";
import { ActionMeta, ValueType } from "react-select/src/types";

import styles from "./FragmentBox.module.css";
import { Dispatch, RootState } from "../../../store";

export const MSG_SELECT_LOADING = (
  <FormattedMessage
    id="MSG_SELECT_LOADING"
    defaultMessage="Loading..."
    description="Available predicates is loading"
  />
);

export const MSG_SELECT_PLACEHOLDER = (
  <FormattedMessage
    id="MSG_SELECT_PLACEHOLDER"
    defaultMessage="Select..."
    description="User must enter text to find predicate"
  />
);

export const MSG_SELECT_NO_RESULTS = (
  <FormattedMessage
    id="MSG_SELECT_NO_RESULTS"
    defaultMessage="No results found"
    description="There's no results corresponding to user's filter"
  />
);

function generateRemoveHandler(index: any, callback: any) {
  return function (event: any) {
    callback(event, index);
  };
}
function convertToRaw(fragment: FragmentData) {
  const { rdfId, label, description } = fragment;
  const data: FragmentDataRaw = {
    $description: description,
    $label: label,
    $rdfId: rdfId || "",
  };
  return data;
}
function convertFromRaw(fragment: FragmentDataRaw) {
  const { $rdfId, $label, $description } = fragment;
  const data: FragmentData = {
    description: $description,
    label: $label,
    rdfId: $rdfId,
  };
  return data;
}

interface SelectOption {
  value: FragmentData | null;
  label: any;
}
interface FragmentBoxProps {
  subjectKey: string;
  nodeId: string;
  visible: boolean;
  getFragmentsBranch: (rdfId: string) => void;
  editable?: boolean;
  cardEditable?: boolean;
  nestingLevel?: number;
  node?: LayoutNode;
  value: FragmentData | FragmentData[];
  // fragmentList?: FragmentData[]
  valid?: boolean;
  error?: string;
  children?: { [RDF_ID: string]: string[] };
  change: (data: FragmentDataRaw | null, options?: any) => void;
  add: (data: any) => void;
  remove: (indexList: number[] | number) => void;
  fetchFragments: (parentId?: string) => void;
  firstFragment?: FragmentData;
  upLevelId?: string;
  downLevelId?: string;
  fragmentNodes?: FragmentNodeMap;
  fragmentChildren?: { [id: string]: string[] };
  roots?: string[];
  comment?: SubjectComment;
}

interface FragmentBoxStates {
  // editableValue: FragmentData|FragmentData[]|null
  checkedValue: FragmentData | null;
  parentId: string | null;
  nestingLevel: number;
  fragments: FragmentData[];
}

class FragmentBox extends React.Component<FragmentBoxProps, FragmentBoxStates> {
  private optionMap: any;
  constructor(props: FragmentBoxProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.removeClicked = this.removeClicked.bind(this);

    this.state = {
      checkedValue: null,
      parentId: null,
      nestingLevel: 1,
      fragments: [],
    };
  }

  componentDidMount() {
    const { getFragmentsBranch, value, fetchFragments } = this.props;
    if (value && Array.isArray(value)) {
      value.forEach((v) => {
        v?.rdfId && getFragmentsBranch(v.rdfId);
      });
    } else if (value && !Array.isArray(value)) {
      value?.rdfId && getFragmentsBranch(value.rdfId);
    } else if (!value) {
      fetchFragments();
    }
  }

  async componentDidUpdate(
    prevProps: FragmentBoxProps,
    prevStates: FragmentBoxStates
  ) {
    const { nestingLevel, parentId } = this.state;

    // if (parentId !== prevStates.parentId) {
    //     if (parentId === null) {
    //         this.setState({ nestingLevel: 1 });
    //         return;
    //     }
    //     const nestingLevel = this.getNestingLevel(parentId || null);
    //     // const newNestingLevel = await this.getNestingLevel(parentId);
    //     this.setState({ nestingLevel });
    // }
  }

  addClicked(event: any) {
    // this.props.add(this.state.editableValue);
    // this.setState({ editableValue: null });
  }

  removeClicked(event: any, index: number) {
    this.props.remove(index);
  }

  getNestingLevel(rdfId: string | null) {
    if (!rdfId) {
      return 1;
    }

    const { fragmentNodes } = this.props;
    if (!fragmentNodes) {
      return 1;
    }
    let nestingLevel = 2;
    let nodeId = rdfId;
    let node = fragmentNodes[rdfId];
    while (node && node.parentId) {
      nestingLevel++;
      node = fragmentNodes[node.parentId];
    }

    return nestingLevel;
  }

  goToChildren() {
    const { fetchFragments } = this.props;
    const { checkedValue } = this.state;
    if (!checkedValue) {
      return;
    }
    // checkedValue?.r && fetchFragments(checkedValue.r);
    const newParent = checkedValue?.rdfId;
    if (!newParent) {
      return;
    }

    this.setState({ checkedValue, parentId: newParent }, () =>
      fetchFragments(newParent)
    );
  }
  goToParent() {
    const { fetchFragments, fragmentNodes } = this.props;
    const { checkedValue, parentId } = this.state;
    if (!parentId) {
      return;
    }

    const newParent =
      (fragmentNodes && fragmentNodes[parentId]?.parentId) || undefined;
    fetchFragments(newParent);
    this.setState({ checkedValue: checkedValue, parentId: newParent || null });
  }
  handleChange =
    (oldSelected: SelectOption) =>
    (option: any, actionMeta: ActionMeta<SelectOption>) => {
      const opt: SelectOption = option as SelectOption;
      const { node, change, add, fetchFragments, fragmentNodes } = this.props;
      // let value = this.optionMap[opt.value];
      // console.log("change1",value)
      if (!node) {
        return;
      }
      if (actionMeta && actionMeta.action === "clear") {
        if (oldSelected.value?.parentId && fragmentNodes) {
          const parentFrag = fragmentNodes[oldSelected.value?.parentId];
          parentFrag && this.props.change(convertToRaw(parentFrag));
        }
      } else {
        if (!opt.value && !oldSelected.value?.parentId) {
          this.props.change(null);
        } else if (!opt.value && oldSelected.value?.parentId && fragmentNodes) {
          const parentFrag = fragmentNodes[oldSelected.value?.parentId];
          parentFrag && this.props.change(convertToRaw(parentFrag));
        }
        let path = node.id;

        // if (value) {
        //     value = { ...this.optionMap[opt.value] };
        // } else {
        //     value = null;
        // }
        // if (this.props.node.multiple) {
        //     this.setState({ editableValue: value });
        // } else {
        //     this.props.change(convertToRaw(value));
        // }

        // this.setState({ checkedValue: value });
        // fetchFragments(value.rdfId);

        // console.log("value",value)
        if (opt.value) {
          this.props.change(convertToRaw(opt.value));
          fetchFragments(opt.value.rdfId);
        }
      }
    };

  checkValue(data: FragmentData, value: string) {
    if (!value) {
      return false;
    }
    //Check rdfId
    return value === data.rdfId;
  }

  getDepth() {
    const { node } = this.props;
    if (!node) {
      return null;
    }
    return node.options.classRelationInfo.peerClass.enumerationDepth;
  }

  generateEmptySelect(level: any, forceDisabled: boolean) {
    const { editable } = this.props;
    return (
      <select
        key={level}
        disabled={!editable || forceDisabled}
        className="form-control"
      >
        <option value="">--</option>
      </select>
    );
  }
  createLabel(label: string) {
    const { nestingLevel } = this.props;
    if (nestingLevel && nestingLevel > 1) {
      return (
        <div className="d-flex justify-content-beetwen">
          <span className="rounded bg-primary text-white p-1 mr-1">
            {nestingLevel}
          </span>
          <span className="mt-1">{label}</span>
        </div>
      );
    }
    return <span>{label}</span>;
  }

  getFragmentList = (parentId?: string) => {
    const { fragmentNodes, fragmentChildren, roots } = this.props;
    let fragmentList: FragmentData[] = [];
    let idList = roots || [];
    if (parentId) {
      idList = (fragmentChildren && fragmentChildren[parentId]) || [];
    } else {
      idList = roots || [];
    }
    if (idList.length === 0) {
      return [];
    }
    if (!fragmentNodes) {
      return [];
    }
    fragmentList = Object.values(fragmentNodes).filter(
      (f) => f.rdfId && idList.includes(f.rdfId)
    );
    return fragmentList;
  };

  getFragmentsOptions(parentId?: string) {
    const fragmentList = this.getFragmentList(parentId);
    let options: SelectOption[] = [];
    if (fragmentList && Array.isArray(fragmentList)) {
      for (let fragment of fragmentList) {
        options.push({ value: fragment, label: fragment.label });
      }
    }
    return options;
  }

  renderSelect(selected: SelectOption, key: string, parentId?: string) {
    const { editable, nestingLevel, fragmentChildren } = this.props;
    let defaultVal: SelectOption = {
      value: null,
      label: this.createLabel("--"),
    };
    let options: SelectOption[] = [
      defaultVal,
      ...this.getFragmentsOptions(parentId),
    ];

    return (
      <Select
        key={key}
        searchable="true"
        classNamePrefix="fb-select"
        loadingPlaceholder={MSG_SELECT_LOADING}
        placeholder={MSG_SELECT_PLACEHOLDER}
        noResultsText={MSG_SELECT_NO_RESULTS}
        simpleValue={true}
        value={selected}
        onChange={this.handleChange(selected)}
        disabled={!editable}
        isClearable
        className="minified-react-select card-input npt-select "
        options={options}
        menuPosition="fixed"
        menuShouldBlockScroll={true}
      />
    );
  }

  getFragmentBranchList(fr: FragmentData) {
    const { fragmentNodes, fragmentChildren } = this.props;
    if (!fr.rdfId || !fragmentNodes) {
      return [];
    }

    const fragmentWithParent = fragmentNodes[fr.rdfId || ""];
    const fragments: FragmentData[] = [fragmentWithParent];

    if (!fragmentNodes) {
      return fragments;
    }
    let currentFragment = { ...fragmentWithParent };
    while (currentFragment.parentId) {
      currentFragment = { ...fragmentNodes[currentFragment.parentId] };
      fragments.unshift(currentFragment);
    }
    return fragments;
  }
  wrapWithCaret(select: any, key: string, visible: boolean = true) {
    return (
      <div key={key} className="mb-1 d-flex ">
        <div className="flex-grow-1">{select}</div>
        <div style={{ opacity: visible ? 1 : 0 }} className="ml-2 mr-2 mt-2">
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
      </div>
    );
  }
  renderFragmentTreeValue(value?: FragmentData) {
    const { fragmentChildren } = this.props;
    let emptySelected: SelectOption = { value: null, label: "--" };
    let fragmentSelectList: any[] = [];
    if (value) {
      const fragments = this.getFragmentBranchList(value);
      fragments.forEach((f, idx) => {
        let selected: SelectOption = { value: f, label: f.label };
        fragmentSelectList.push(
          this.renderSelect(selected, `f-${idx}`, f.parentId)
        );
      });
      const lastRdfId: string = fragments[fragments.length - 1].rdfId || "";

      if (lastRdfId && fragmentChildren?.[lastRdfId]?.length) {
        fragmentSelectList.push(
          this.renderSelect(emptySelected, `empty`, lastRdfId)
        );
      }
      fragmentSelectList = fragmentSelectList.map(
        (l: any, idx: number, list: any[]) => {
          return idx < list.length - 1
            ? this.wrapWithCaret(l, `c-${idx}`)
            : this.wrapWithCaret(l, `c-${idx}`, false);
        }
      );
    } else if (!value) {
      fragmentSelectList.push(this.renderSelect(emptySelected, `empty`));
    }

    return fragmentSelectList;
  }

  // generateSelect(currentValue: string | null, forceDisabled: boolean) {
  //     const { editable, nestingLevel, fragmentChildren } = this.props;
  //     const { checkedValue } = this.state;
  //     //Map of options
  //     this.optionMap = {};
  //     //Selected option
  //     // let selected = "";
  //     let selected: SelectOption = { value: '', label: this.createLabel("--") };
  //     //Options
  //     let options: SelectOption[] = [{ value: '', label: "--" }];
  //     //Put default option
  //     // options.push(<option key={""} value="">--</option>);
  //     const fragmentList = this.getFragmentList();
  //     if (fragmentList && Array.isArray(fragmentList)) {
  //         for (let fragment of fragmentList) {
  //             let opt = fragment.rdfId || '';
  //             //Add option into the map
  //             this.optionMap[opt] = fragment;
  //             //Check if option is selected
  //             if (checkedValue && checkedValue.rdfId && this.checkValue(fragment, checkedValue.rdfId)) {
  //                 selected = { value: opt, label: this.createLabel(fragment.label) };
  //             }
  //             // //Generate options
  //             // let option = (<option key={opt} value={opt}>{fragment.label}</option>);
  //             options.push({ value: opt, label: fragment.label });
  //         }
  //     }
  //     return (
  //         <Select
  //             searchable="true"
  //             loadingPlaceholder={MSG_SELECT_LOADING}
  //             placeholder={MSG_SELECT_PLACEHOLDER}
  //             noResultsText={MSG_SELECT_NO_RESULTS}
  //             simpleValue={true}
  //             value={selected}
  //             onChange={this.handleChange}
  //             disabled={!editable || forceDisabled}
  //             // isDisabled={!this.isEditable()}
  //             clearable={false}
  //             // filterOptions={this.filterOptions.bind(this)}
  //             className="minified-react-select card-input npt-select"
  //             options={options} />
  //     )

  // }

  wrapInRow(key: any, select: any, button: any = null) {
    return (
      <div key={key}>
        <div>{select}</div>
        {button && <div>{button}</div>}
      </div>
    );
  }
  addFragment() {
    const { node, change, add } = this.props;
    const { checkedValue } = this.state;
    if (!node || !checkedValue) {
      return;
    }
    if (node.multiple) {
      add(convertToRaw(checkedValue));
    } else {
      change(convertToRaw(checkedValue));
    }
  }

  deleteFragment() {
    const { remove, change, node } = this.props;
    if (!node) {
      return null;
    }
    if (node.multiple) {
    } else {
    }
  }

  render() {
    const { editable, cardEditable, node, children, visible, error, comment } =
      this.props;
    const { checkedValue } = this.state;
    let { value } = this.props;
    let rowList = [];
    //Get data
    //Validate data
    let selectList;
    if (!node) {
      return null;
    }
    if (node.multiple) {
      if (!Array.isArray(value)) {
        value = [];
      }
      //First add editable select

      if (editable) {
        // let addButton = null;
        // if (editableValue) {
        //     addButton = (<i className="fa fa-plus-circle fa-fw" style={ADD_BUTTON_ICON_STYLE} aria-hidden="true" onClick={this.addClicked}></i>);
        // }
        const val = checkedValue?.rdfId || null;
        // selectList = this.renderFragmentTreeValue(value)
        // rowList.push(this.wrapInRow("editValue", selectList, addButton));
      }
      //Then add existing options
      // for (let i in value) {
      //     let currentValue = value[i];
      //     let selectList = this.generateSelect(currentValue.r||'', true);
      //     let removeButton = null;
      //     if (editable) {
      //         let removeHandler = generateRemoveHandler(i, this.removeClicked);
      //         removeButton = (<i className="fa fa-minus-circle fa-fw" style={REMOVE_BUTTON_ICON_STYLE} aria-hidden="true" onClick={removeHandler}></i>);
      //     }
      //     rowList.push(this.wrapInRow("row" + i, selectList, removeButton));
      // }
    } else {
      if (!Array.isArray(value)) {
        selectList = this.renderFragmentTreeValue(value);
        // if (editable) {
        //     selectList = this.wrapInControls(false, selectList);
        // }
        // rowList.push(this.wrapInRow("singleValue", selectList));
      }
    }
    // if (rowList.length == 0) { //Empty row list

    //     return <LargeInput id={this.props.subjectKey + "." + this.props.nodeId} node={node}>
    //         "--"
    //     </LargeInput >
    // }
    // //Create table of selections
    // let table = (<div>{rowList}</div>);

    return (
      <LargeInput
        id={this.props.subjectKey + "." + this.props.nodeId}
        error={error}
        node={node}
        editable={editable}
        cardEditable={cardEditable}
        visible={visible}
        comment={comment}
      >
        {selectList}
      </LargeInput>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return { visible: false };
    }
    const node = subject && subject.nodeById[ownProps.nodeId];
    let value = subject && subject.values[ownProps.nodeId];
    if (value) {
      value = convertFromRaw(value);
    }
    // const fragmentList = subject && subject.fragmentList;
    const fragmentTree = state.subject?.fragmentTree;
    const selectedFragment = fragmentTree?.selected;
    const nodeById = fragmentTree?.nodeById;
    const roots = fragmentTree?.rootNodesIds;
    const children = fragmentTree?.childrenIds;
    let fragmentList: FragmentData[] = [];
    if (nodeById && fragmentTree && roots) {
      let ids: string[] = [];
      if (!selectedFragment) {
        ids = roots;
      } else {
        ids = (children && children[selectedFragment]) || [];
      }
      fragmentList = Object.values(nodeById).filter(
        (n) => n.rdfId && ids.includes(n.rdfId)
      );
    }
    let nestingLevel = 1;
    let upLevelId;
    let downLevelPId;
    if (nodeById && fragmentList && fragmentList[0]) {
      let fragment = fragmentList[0];
      upLevelId = fragment.parentId;

      while (fragment && fragment.parentId) {
        nestingLevel++;
        fragment = nodeById[fragment.parentId];
      }
    }
    const cardEditable = isCardEdit(subject);
    const editable = isEditable(subject, nodeId, cardEditable);
    const error = subject.validation[nodeId];

    let valid = !error || !cardEditable ? true : false;
    let mandatory = subject.mandatorySet[node.id];
    if (
      mandatory &&
      (!value || (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      valid = false;
    }

    const visible = subject.visibility[nodeId] ? true : false;
    return {
      upLevelId,
      node,
      value,
      fragmentList,
      nestingLevel,
      editable,
      cardEditable,
      children,
      valid,
      error: error,
      fragmentNodes: nodeById || {},
      roots: roots || [],
      fragmentChildren: children || {},
      visible,
      comment: subject.comment[nodeId],
    };
  },
  (dispatch: Dispatch, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      change: (data: FragmentDataRaw | null, options?: any) =>
        dispatch.subject.change({
          subjectKey: ownProps.subjectKey,
          nodeId: ownProps.nodeId,
          data,
          options,
        }),
      add: (data: any) => dispatch.subject.add({ subjectKey, nodeId, data }),
      remove: (indexList: number[] | number) =>
        dispatch.subject.remove({ subjectKey, nodeId, indexList }),
      getFragmentsBranch: (rdfId: string) =>
        dispatch.subject.getFragmentsBranch(rdfId),
      fetchFragments: (parentId?: string) => {
        dispatch.subject.fetchFragments(parentId);
      },
    };
  }
)(FragmentBox);
