import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  SubjectHistoryFileInfo,
  SubjectHistoryFileRecord,
} from "../../types/subjecthistory";
import { LocalSimpleTableApi } from "../../types/table";
import AsyncTable, {
  NptTableColumnProps,
  NptTableColumns,
} from "../table/AsyncTable";

function getPredicateName(record: SubjectHistoryFileRecord) {
  if (!record.predicate) {
    return "-";
  }
  return record.predicate.label;
}

function getLabel(record: SubjectHistoryFileRecord) {
  if (!record.label) {
    return "-";
  }
  return record.label;
}

function getDescription(record: SubjectHistoryFileRecord) {
  if (!record.description) {
    return "-";
  }
  return record.description;
}

function fileFormatter(
  cell: SubjectHistoryFileInfo,
  row: SubjectHistoryFileRecord
) {
  if (cell == null) {
    return (
      <FormattedMessage
        id="SUBJECT_HISTORY_FILE_REMOVED"
        defaultMessage="File was removed"
        description="Inform user that file was removed"
      />
    );
  }
  let filename = cell.originalName;
  return (
    <a
      className="w-100 h-100 d-flex align-items-center justify-content-center p-2"
      href={`/rest/file/download/${cell.sha1}`}
      download={filename}
    >
      <i className="fa fa-download" aria-hidden="true"></i>
    </a>
  );
}

export const getOperationValue = (operation: string) => {
  switch (operation.toLocaleLowerCase()) {
    case "add":
      return 0;
    case "update":
      return 1;
    case "remove":
      return 2;
  }
  return -1;
};

function operationFormatter(cell: any, row: SubjectHistoryFileRecord) {
  let message: string | ReactElement = "-";
  if (cell == 0) {
    message = (
      <FormattedMessage
        id="SUBJECT_HISTORY_FILE_OPER_ADD"
        defaultMessage="Added"
        description="File was added"
      />
    );
  }
  if (cell == 1) {
    message = (
      <FormattedMessage
        id="SUBJECT_HISTORY_FILE_OPER_UPDATE"
        defaultMessage="Updated"
        description="File was updated"
      />
    );
  }
  if (cell == 2) {
    message = (
      <FormattedMessage
        id="SUBJECT_HISTORY_FILE_OPER_REMOVE"
        defaultMessage="Removed"
        description="File was removed"
      />
    );
  }
  return <span className="p-2">{message}</span>;
}

interface FilesHistoryProps {
  records?: SubjectHistoryFileRecord[] | null;
}
const FilesHistory: React.FunctionComponent<FilesHistoryProps> = React.memo(
  (props) => {
    const intl = useIntl();
    const [isMount, setIsMount] = React.useState(true);
    React.useEffect(() => {
      setIsMount(false);
    }, []);

    const getTableData = () => {
      if (!props.records || props.records.length === 0) {
        return [];
      }
      return props.records.map((record) => {
        return {
          id: record.id,
          name: getPredicateName(record),
          label: getLabel(record),
          description: getDescription(record),
          operation: record.operation,
          file: record.file,
        };
      });
    };
    const [tableData, setTableData] = React.useState(getTableData());
    React.useEffect(() => {
      if (isMount) {
        return;
      }
      setTableData(getTableData());
    }, [props.records]);

    if (!props.records || props.records.length === 0) {
      return null;
    }
    const localApi: LocalSimpleTableApi = {
      external: false,
      scrollable: false,
      pageable: false,
    };
    const columns: NptTableColumnProps[] = [
      {
        format: "string",
        field: "name",
        name: intl.formatMessage({ id: "SUBJECT_HISTORY_FILE_PREDICATE_NAME" }),
        filterType: null,
      },
      {
        format: "string",
        field: "label",
        name: intl.formatMessage({ id: "SUBJECT_HISTORY_FILE_LABEL" }),
        filterType: null,
      },
      {
        format: "string",
        field: "description",
        name: intl.formatMessage({ id: "SUBJECT_HISTORY_FILE_DESCRIPTION" }),
        filterType: null,
      },
      {
        format: "string",
        field: "operation",
        name: intl.formatMessage({ id: "SUBJECT_HISTORY_FILE_OPERATION" }),
        filterType: null,
        dataFormat: operationFormatter,
      },
      {
        format: "string",
        field: "file",
        name: intl.formatMessage({ id: "SUBJECT_HISTORY_FILE_VALUE" }),
        filterType: null,
        dataFormat: fileFormatter,
      },
    ];
    return (
      <div>
        <h4>
          <FormattedMessage
            id="SUBJECT_HISTORY_FILE_HEADER"
            defaultMessage="File changes:"
            description="File changes table header"
          />
        </h4>
        <AsyncTable
          localApi={localApi}
          rows={tableData}
          autoresize={true}
          columns={columns}
        ></AsyncTable>
      </div>
    );
  }
);

export default FilesHistory;
