import { FC } from "react";
import { connect } from "react-redux";
import { normalizePredicate } from "../../../services/layout";
import { obtainData } from "../../../services/subject";
import { RootState } from "../../../store";
import { I18NString } from "../../../types/modal";
import { isSubject, LayoutNode } from "../../../types/subject";

export type ColorType =
  | "warning"
  | "success"
  | "danger"
  | "primary"
  | "secondary"
  | "secondary";

interface Variant {
  color: ColorType;
  value: any | null;
  icon: string;
}

interface IconIndicatorProps {
  subjectKey: string;
  nodeId: string;
  visible?: boolean;
  error?: string | I18NString;
  node?: LayoutNode;
  values?: {
    [K: string]: any;
  };
  value?: number;
}

const IconIndicator: FC<IconIndicatorProps> = ({
  node,
  visible,
  error,
  values,
}) => {
  const getNodeValue = () => {
    if (!node?.options || !values) {
      return null;
    }
    const { watchPredicate, path } = node.options;

    const predicate = normalizePredicate(watchPredicate);
    const predicateValue = values[predicate];
    console.log("predicateValue", predicateValue);
    if (typeof predicateValue !== "object") {
      return predicateValue;
    }
    if (!path) {
      return null;
    }
    try {
      return obtainData(path, predicateValue);
    } catch (e) {
      console.log(
        `Error occured while getting value of predicate ${watchPredicate} by path ${path}`
      );
    }
    return null;
  };

  const getVariant = (): Variant => {
    let defaultValue = false;
    let color: ColorType = node?.options?.defaultColor || "secondary";
    let icon: ColorType = node?.options?.defaultIcon || "times";
    const value = getNodeValue();

    const variants: Variant[] | undefined = node?.options?.variants;
    if (!variants?.length || typeof value === "undefined" || value === null) {
      return { value: defaultValue, color, icon };
    }
    const activeVariant = variants.find((v) => v.value == value);
    console.log("variants,", variants, value);
    if (!activeVariant) {
      return { value: defaultValue, color, icon };
    }
    return activeVariant;
  };
  // const props = getVariants();
  if (!visible) {
    return null;
  }

  const getClass = () => {
    const size = node?.options?.size;
    const spin = node?.options?.spin;
    const rotate = node?.options?.rotate;
    const { icon, color } = getVariant();

    let iconClass = ["fa", `fa-${icon} text-${color}`];
    if (size) {
      iconClass.push(`fa-${size}`);
    }
    if (spin) {
      iconClass.push(`fa-spin`);
    }
    if (rotate) {
      iconClass.push(`fa-rotate-${rotate}`);
    }
    return iconClass.join(" ");
  };
  return <i className={getClass()} aria-hidden="true"></i>;
};

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    const subject = state.subject && state.subject.subjects[subjectKey];

    if (!isSubject(subject)) {
      return { visible: false };
    }

    const serverLock = subject.subjectData.$lock;
    const serverLockReady = serverLock && serverLock.status;
    const isNew = subject.isNew;
    const cardEditable = serverLockReady || isNew;

    const node = subject.nodeById[nodeId];

    const error = subject.validation[nodeId];

    let valid = !error || !cardEditable ? true : false;

    const visible = subject.visibility[nodeId] ? true : false;

    return {
      error: error,
      node,
      values: subject.values,
      valid,
      visible,
      comment: subject.comment[nodeId],
    };
  },
  null
)(IconIndicator);
