import { SelectionState } from "../types/selection";

const historyLimit = 10;

export function receiveSelection(
  state: SelectionState,
  payload: { object: string; type: string }
) {
  const { object, type } = payload;
  const nextState = { ...state };
  const selectionInfo = Object.assign({}, nextState.info, { object, type });
  if (!nextState.history) {
    nextState.history = {
      past: [],
      present: selectionInfo,
      future: [],
    };
  } else {
    nextState.history = { ...nextState.history };
    nextState.history.past = nextState.history.past.slice();
    nextState.history.past.push(nextState.history.present);
    if (nextState.history.past.length > historyLimit) {
      nextState.history.past.splice(
        0,
        nextState.history.past.length - historyLimit
      );
    }
    nextState.history.present = selectionInfo;
    nextState.history.future = [];
  }
  nextState.info = nextState.history.present;
  return nextState;
}

export function receiveSelectionUndo(state: SelectionState) {
  if (!state.history) {
    return state;
  }
  const nextState = { ...state };
  nextState.history = { ...state.history };
  nextState.history.past = nextState.history.past.slice();
  nextState.history.future = nextState.history.future.slice();
  nextState.history.future.unshift(nextState.history.present);
  nextState.history.present = nextState.history.past.pop();
  nextState.info = nextState.history.present;
  return nextState;
}

export function receiveSelectionRedo(state: SelectionState) {
  if (!state.history?.future.length) {
    return state;
  }
  const nextState = { ...state };
  nextState.history = { ...state.history };
  nextState.history.past = nextState.history.past.slice();
  nextState.history.future = nextState.history.future.slice();
  nextState.history.past.push(nextState.history.present);
  nextState.history.present = nextState.history.future.shift();
  nextState.info = nextState.history.present;
  return nextState;
}
