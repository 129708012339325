import * as React from "react";
import { connect } from "react-redux";
import { LoggedInUser } from "../../types/security";
import { Column } from "../../types/table";
import { TableProps } from "./Table";

import FinderContainer from "../finder/FinderContainer";
import Finder from "../finder/Finder";
import TableToolbar from "./TableToolbar";

import styles from "./TableFinder.module.css";
import { RootState } from "../../store";

interface TableFinderContainerProps extends TableProps {
  /**List of selected dynamic columns */
  selectedDynamicColumns: Column[] | null;
  /** List of user rights */
  userAcl: string[];
}

/********************************
 *    Table Finder Component    *
 ********************************/
const TableFinderContainer: React.FunctionComponent<
  React.PropsWithChildren<TableFinderContainerProps>
> = React.memo((props) => {
  if (!props.finderOptions) {
    return <>{props.children}</>;
  }
  const renderToolbar = () => {
    const { toolbar } = props;
    if (!toolbar) {
      return null;
    }
    if (Array.isArray(toolbar)) {
      return (
        <TableToolbar
          tableId={props.tableId}
          loadingData={Boolean(props.loadingData)}
          parameters={props.parameters}
          fields={props.fields}
          filterChanges={props.filterChanges}
          gantOptions={props.gantOptions}
          gantData={props.gantData}
          reports={props.reports}
          toolbar={toolbar}
          userAcl={props.userAcl}
          columns={props.selectedDynamicColumns || props.columns}
          totalRowsLength={props.totalRowsLength}
          downloadReport={props.downloadReport}
          buttonClick={props.buttonClick}
          changeField={props.changeField}
          changeViewType={props.changeViewType}
          changeGroup={props.changeGroup}
          confirmFilterChanges={props.confirmFilterChanges}
          denyFilterChanges={props.denyFilterChanges}
          saveTable={props.saveTable}
        />
      );
    }
    const ToolbarComponent = toolbar;
    return <ToolbarComponent />;
  };
  return (
    <FinderContainer>
      {renderToolbar()}
      <Finder finderId={props.tableId} model={props.tableId}>
        {props.children}
      </Finder>
    </FinderContainer>
  );
});

export default connect((state: RootState) => {
  return {
    userAcl:
      (state.security.loginStatus as LoggedInUser)?.generalAccessRules || [],
  };
})(TableFinderContainer);
