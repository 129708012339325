import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";

interface ReduxProps {
  redux: string;
  payload?: any;
  onChange: (data: { payload: any }) => void;
}
const Redux: React.FunctionComponent<ReduxProps> = React.memo((props) => {
  React.useEffect(() => {
    props.onChange({ payload: props.payload });
  }, [props.payload]);
  return <>{props.children}</>;
});

export default connect((state: RootState, ownProps: ReduxProps) => {
  const { redux } = ownProps;
  if (!redux) {
    return {};
  }
  let path = redux.split(".");
  let payload = state as any;
  for (let p of path) {
    if (typeof payload == "object" && payload != null) {
      payload = payload[p];
    } else {
      payload = null;
      break;
    }
  }
  return { payload };
})(Redux);
