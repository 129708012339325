import React from "react";
import { connect } from "react-redux";
import { Tabs, TabsProps } from "react-bootstrap";
import { SearchData } from "../../types/location";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";
import { RootState } from "../../store";

export interface ConnectedTabsProps extends TabsProps {
  tabs?: string;
  defaultActiveKey?: string;
  mountOnEnter?: boolean;
  map?: (value: any) => string;
  changeParamName?: string;
  value: any; //value from store
  children: React.ReactElement[];
  changeSearch: (data: SearchData) => void;
}

const defaultMap = (value: any): string => {
  if (!value) {
    return "";
  }
  return value.toString();
};

const ConnectedTabs = (props: ConnectedTabsProps) => {
  const { tabs, map, changeParamName, value, changeSearch, ...tabsProps } =
    props;

  if (!Array.isArray(tabsProps.children)) {
    return <Tabs {...tabsProps} />;
  }

  const onSelect = (value: any) => {
    if (props.changeParamName) {
      props.changeSearch({ [props.changeParamName]: value });
    }
  };

  //Find all available active keys
  const activeKeySet: { [K: string]: boolean } = {};
  for (let child of tabsProps.children) {
    if (
      typeof child.props == "object" &&
      child.props != null &&
      typeof child.props.eventKey == "string"
    ) {
      activeKeySet[child.props.eventKey] = true;
    }
  }

  let activeKey = props.defaultActiveKey;
  if (map) {
    activeKey = map(value);
  } else {
    activeKey = defaultMap(value);
  }

  if (!activeKeySet[activeKey]) {
    activeKey = props.defaultActiveKey;
  }
  return <Tabs {...tabsProps} activeKey={activeKey} onSelect={onSelect} />;
};

export default connect(
  (state: RootState, ownProps: ConnectedTabsProps) => {
    const { tabs } = ownProps;
    if (!tabs) {
      return { value: null };
    }
    let path = tabs.split(".");
    let value = state as any;
    for (let p of path) {
      if (typeof value == "object" && value != null) {
        value = value[p];
      } else {
        value = null;
        break;
      }
    }
    return { value };
  },
  (dispatch: RematchDispatch<RootModel>) => {
    return {
      changeSearch: (data: SearchData) => {
        dispatch.location.changeSearch({ dataChanges: data });
      },
    };
  }
)(ConnectedTabs);
