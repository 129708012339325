import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { getNavigatorLanguage } from "..";
import { LocaleState } from "../types/locale";

import { I18NString } from "../types/modal";

const initialState: LocaleState = { language: getNavigatorLanguage() };

export const locale = createModel<RootModel>()({
  state: initialState,
  reducers: {
    changeLanguage(state, language: string) {
      return { ...state, language };
    },
  },
  effects: (dispatch) => ({}),
});
