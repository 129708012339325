import * as React from "react";
import { ComponentType } from "react";
import { Link as ReactLink } from "react-router-dom";

import styles from "./Navigation.module.css";

export const Mark = (props: { num: number }) => {
  const { num } = props;
  return (
    <div
      style={{ top: `${10}px`, left: `${15}px` }}
      className={styles.markBorder}
    >
      <div
        className={`${styles.markPulse}  font-weight-bold d-flex justify-content-center`}
      >
        <span>{num}</span>
      </div>
    </div>
  );
};
