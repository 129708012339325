import { createModel, RematchDispatch } from "@rematch/core";
import { RootModel } from ".";
import { ALERT_LEVEL_SUCCESS } from "../constants/alert";
import { dispatchError } from "../services/alert";
import { CacheEditorState } from "../types/cache";
import { FetchError } from "../types/error";
const CACHE_LIST_PATH = "/rest/cache/list";
const CACHE_CLEAR_ALL_PATH = "/rest/cache/clear";
const CACHE_CLEAR_PATH = CACHE_CLEAR_ALL_PATH + "/";

const initialState: CacheEditorState = {
  loading: true,
  error: false,
  list: [],
};

export const cache = createModel<RootModel>()({
  state: initialState,
  reducers: {
    listReceived(state, list: string[]) {
      return listReceived(state, { list });
    },
    listErrorReceived(state) {
      return errorReceived(state);
    },
  },
  effects: (dispatch) => ({
    fetchList: () => {
      ajaxFetchList(dispatch);
    },
    clearCache: (id: string) => {
      ajaxClearCache(dispatch, id);
    },
    clearAllCache: () => {
      ajaxClearAllCache(dispatch);
    },
  }),
});

function listReceived(
  state: CacheEditorState,
  { list }: { list: string[] }
): CacheEditorState {
  return Object.assign({}, state, { loading: false, list });
}

function errorReceived(state: CacheEditorState): CacheEditorState {
  return Object.assign({}, state, { loading: false, error: true });
}

function ajaxFetchList(dispatch: RematchDispatch<RootModel>) {
  fetch(CACHE_LIST_PATH)
    .then((resp) => {
      if (!resp.ok) {
        dispatch.cache.listErrorReceived();
        return;
      }
      return resp.json();
    })
    .then((json) => {
      dispatch.cache.listReceived(json);
    });
}

function ajaxClearCache(dispatch: RematchDispatch<RootModel>, id: string) {
  fetch(CACHE_CLEAR_PATH + id).then(function (result) {
    if (!result.ok) {
      const { status, statusText } = result;
      const error: FetchError = { code: status, message: statusText };
      dispatchError("CACHE_CLEAR_ERROR", error, dispatch, { id: id });
      return;
    }
    dispatch.alert.addAlert({
      type: ALERT_LEVEL_SUCCESS,
      message: {
        id: "CACHE_CLEAR_SUCCESS",
        values: { id: id },
      },
    });
  });
}

function ajaxClearAllCache(dispatch: RematchDispatch<RootModel>) {
  fetch(CACHE_CLEAR_ALL_PATH).then(function (result) {
    if (!result.ok) {
      const { status, statusText } = result;
      const error: FetchError = { code: status, message: statusText };
      dispatchError("CACHE_CLEAR_ALL_ERROR", error, dispatch);
      return;
    }
    dispatch.alert.addAlert({
      type: ALERT_LEVEL_SUCCESS,
      message: { id: "CACHE_CLEAR_ALL_SUCCESS" },
    });
  });
}
