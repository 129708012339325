export const SEND_STATISTICS_DATA = "SEND_STATISTICS_DATA";
export type SEND_STATISTICS_DATA = typeof SEND_STATISTICS_DATA;

export const SEND_STATIC_CPU_INFO = "SEND_STATIC_CPU_INFO";
export type SEND_STATIC_CPU_INFO = typeof SEND_STATIC_CPU_INFO;

export const SEND_STATIC_CPU_LOADING = "SEND_STATIC_CPU_LOADING";
export type SEND_STATIC_CPU_LOADING = typeof SEND_STATIC_CPU_LOADING;

export const SEND_STATISTICS_DATA_LOADING = "SEND_STATISTICS_DATA_LOADING";
export type SEND_STATISTICS_DATA_LOADING = typeof SEND_STATISTICS_DATA_LOADING;

export const SEND_STATISTICS_DASHBOARD_ACTIVE =
  "SEND_STATISTICS_DASHBOARD_ACTIVE";
export type SEND_STATISTICS_DASHBOARD_ACTIVE =
  typeof SEND_STATISTICS_DASHBOARD_ACTIVE;

export const SEND_STATISTICS_DATA_ERROR = "SEND_STATISTICS_DATA_ERROR";
export type SEND_STATISTICS_DATA_ERROR = typeof SEND_STATISTICS_DATA_ERROR;

export const SEND_THREAD_STAT_DATA = "SEND_THREAD_STAT_DATA";
export type SEND_THREAD_STAT_DATA = typeof SEND_THREAD_STAT_DATA;

export const SEND_THREAD_STAT_LOADING = "SEND_THREAD_STAT_LOADING";
export type SEND_THREAD_STAT_LOADING = typeof SEND_THREAD_STAT_LOADING;

export const SEND_STATISTICS_THREAD_STAT_DATA_ERROR =
  "SEND_STATISTICS_THREAD_STAT_DATA_ERROR";
export type SEND_STATISTICS_THREAD_STAT_DATA_ERROR =
  typeof SEND_STATISTICS_THREAD_STAT_DATA_ERROR;

export const SEND_DEADLOCKED_THREAD_LIST = "SEND_DEADLOCKED_THREAD_LIST";
export type SEND_DEADLOCKED_THREAD_LIST = typeof SEND_DEADLOCKED_THREAD_LIST;

export const SEND_DEADLOCKED_THREAD_LIST_LOADING =
  "SEND_DEADLOCKED_THREAD_LIST_LOADING";
export type SEND_DEADLOCKED_THREAD_LIST_LOADING =
  typeof SEND_DEADLOCKED_THREAD_LIST_LOADING;

export const MB_DIV = Math.pow(2, 20);
export const NANO_TO_SEC = 1000000000;

export enum ThreadState {
  NEW = "new",
  RUNNABLE = "runnable",
  BLOCKED = "blocked",
  WAITING = "waiting",
  TIMED_WAITING = "timed_waiting",
  TERMINATED = "terminated",
}

export enum TimeDimention {
  MS,
  SEC,
  MIN,
  HOUR,
}
