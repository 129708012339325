import { createModel } from "@rematch/core"
import { RootModel } from "."
import { ServerError } from "../actions/utils"
import { ALERT_LEVEL_DANGER, ALERT_LEVEL_INFO, ALERT_LEVEL_SUCCESS, ALERT_LEVEL_WARNING } from "../constants/alert"
import * as constants from "../constants/fragment"
import { FETCH_FRAGMENTS_URL, FETCH_SAVE_FRAGMENT_URL } from "../constants/fragment"
import { createSelected, setupRange } from "../reducers/utils"
import { dispatchError } from "../services/alert"
import { FetchError } from "../types/error"
import {
  FragmentCtxMenu,
  FragmentData,
  FragmentDataRaw,
  FragmentFields,
  FragmentNodeMap,
  FragmentState,
  FragmentTreeData,
  MoveFragmentRequest,
} from "../types/fragment"
import { I18NString } from "../types/modal"

const initialState: FragmentState = {}

function sortFunction(l1: string, l2: string) {
  return l1.localeCompare(l2, ["en", "ru"]) >= 0
}

function validateParentToItsChildren(nodeId: string, newParentId: string, nodes: FragmentNodeMap) {
  const movedNode = nodes[nodeId]
  let parentId: string | undefined = newParentId
  let valid: boolean = true
  while (parentId) {
    if (parentId === nodeId) {
      valid = false
      break
    }
    if (!parentId) {
      break
    }
    parentId = nodes[parentId].parentId
  }
  return valid
}

function updateFragment(fragment: FragmentData, state: FragmentState): FragmentState {
  const { rdfId, parentId } = fragment

  if (!rdfId) {
    return state
  }
  let nodes = { ...state.nodeById }
  let childrenIds = { ...state.childrenIds } || {}
  let roots = state.rootNodesIds ? [...state.rootNodesIds] : []
  if (!nodes || roots.length === 0) {
    return state
  }

  const oldFragment = nodes[rdfId]
  const oldParent = oldFragment.parentId
  if ((typeof oldParent !== undefined || typeof parentId !== undefined) && oldParent !== parentId) {
    if (parentId) {
      if (!childrenIds[parentId]) {
        childrenIds[parentId] = []
      }
      childrenIds[parentId] = [...childrenIds[parentId], rdfId]
    } else {
      roots.push(rdfId)
    }

    if (oldParent) {
      childrenIds[oldParent] && (childrenIds[oldParent] = childrenIds[oldParent].filter((i) => i !== rdfId))
    } else {
      roots = roots.filter((i) => i !== rdfId)
    }
  }
  nodes[rdfId] = fragment
  const newState = { ...state, nodeById: nodes, treeLoading: false, childrenIds, rootNodesIds: roots }
  sortFragments(newState, [rdfId], parentId)
  return newState
}

function addFragment(fragment: FragmentData, state: FragmentState): FragmentState {
  const { rdfId, parentId } = fragment
  if (!rdfId) {
    return state
  }

  let nodes = { ...state.nodeById }
  let roots = state.rootNodesIds ? [...state.rootNodesIds] : []
  let children = { ...state.childrenIds }

  if (!nodes) {
    nodes = { [rdfId]: fragment }
    roots = [rdfId]
    return { ...state, nodeById: nodes, rootNodesIds: roots }
  }

  if (parentId) {
    if (!children[parentId]) {
      children[parentId] = []
    }
    children[parentId].push(rdfId)
  } else {
    roots.push(rdfId)
  }
  nodes[rdfId] = fragment
  state.nodeById = nodes
  state.childrenIds = children
  state.rootNodesIds = roots
  const newState = { ...state, nodeById: nodes, rootNodesIds: roots, childrenIds: children, treeLoading: false }
  sortFragments(newState, [rdfId], parentId)
  return newState
}

function deleteFragment(ids: string[], state: FragmentState): FragmentState {
  let nodes = { ...state.nodeById }
  let roots = state.rootNodesIds ? [...state.rootNodesIds] : []
  let children = { ...state.childrenIds }

  for (const id of ids) {
    const deletedFragment = nodes[id]
    delete nodes[id]
    const { parentId } = deletedFragment
    if (parentId) {
      if (children[parentId]) {
        children[parentId] = children[parentId].filter((i) => i !== id)
      }
    } else {
      roots = roots.filter((i) => i !== id)
    }
  }

  const active = state.active
  const newActive = active && ids.includes(active) ? undefined : active
  return { ...state, nodeById: nodes, rootNodesIds: roots, childrenIds: children, treeLoading: false, active: newActive }
}

function moveFragment(fragments: FragmentData[], state: FragmentState): FragmentState {
  let nodes = { ...state.nodeById }
  let children = { ...state.childrenIds }
  let roots = state.rootNodesIds ? [...state.rootNodesIds] : []
  if (!nodes || !fragments.length) {
    return state
  }
  for (const fragment of fragments) {
    const { rdfId, parentId } = fragment
    if (!rdfId) {
      continue
    }
    const oldParent = nodes[rdfId].parentId
    if (oldParent) {
      if (children[oldParent]) {
        children[oldParent] = children[oldParent].filter((i) => i !== rdfId)
      }
    } else {
      roots = roots.filter((id) => id !== rdfId)
    }

    if (parentId) {
      if (!children[parentId]) {
        children[parentId] = []
      }
      children[parentId].push(rdfId)
    } else {
      roots.push(rdfId)
    }
    nodes[rdfId] = fragment
  }
  const newState = { ...state, nodeById: nodes, childrenIds: children, rootNodesIds: roots, treeLoading: false }
  const movedIds: string[] = []
  fragments.forEach((f) => f.rdfId && movedIds.push(f.rdfId))
  const parentId = fragments[0].parentId
  if (movedIds.length) {
    sortFragments(newState, movedIds, parentId)
  }

  return newState
}

function sortFragments(state: FragmentState, nodeIds: string[], parentId?: string) {
  let rootNodesIds = state.rootNodesIds
  const childrenIds = state.childrenIds || {}
  const nodeById = state.nodeById
  if (!rootNodesIds || !nodeById) {
    return state
  }

  const sortedNodes: FragmentData[] = Object.values(nodeById).filter((f) => f.rdfId && nodeIds.includes(f.rdfId))
  if (sortedNodes.length === 0) {
    return state
  }

  if (!parentId && rootNodesIds.length > 1) {
    for (let node of sortedNodes) {
      rootNodesIds = sortOne(node, rootNodesIds, nodeById)
    }
  } else if (parentId && childrenIds[parentId] && childrenIds[parentId].length > 1) {
    for (let node of sortedNodes) {
      childrenIds[parentId] = sortOne(node, childrenIds[parentId], nodeById)
    }
  }

  state.rootNodesIds = rootNodesIds
  state.childrenIds = childrenIds
}

function sortOne(sortedNode: FragmentData, list: string[], nodeById: FragmentNodeMap) {
  const newList: string[] = []
  if (!sortedNode) {
    return list
  }
  let templFragment: FragmentData | null = { ...sortedNode }
  const { rdfId: nodeId, label } = templFragment
  list.forEach((r) => {
    const node = nodeById[r]
    if (node.rdfId !== nodeId) {
      if (templFragment && !sortFunction(label, node.label)) {
        nodeId && newList.push(nodeId)
        templFragment = null
      }
      newList.push(r)
    }
  })
  if (templFragment && nodeId) {
    newList.push(nodeId)
  }
  return newList
}

function parseFragment(json: any) {
  const rawFragment: FragmentDataRaw = json
  const { d: description, l: label, r: rdfId, pr: parentId } = rawFragment
  const data: FragmentData = { description, label, rdfId, parentId }
  return data
}

function convertToFragmentRow(data: FragmentData) {
  const { description: d, parentId: pr, rdfId: r, label: l } = data
  const rawFragment: FragmentDataRaw = { l, d, pr, r }
  return rawFragment
}

export function parseFragments(json: any) {
  const fragments: FragmentData[] = []
  if (Array.isArray(json)) {
    json.forEach((o) => {
      fragments.push(parseFragment(o))
    })
    return fragments
  }
  return []
}
export function parseFragmentList(state: FragmentTreeData, list: FragmentData[], parentId?: string) {
  let newFragmentState = { ...state }
  let rootNodesIds = newFragmentState.rootNodesIds || []
  const childrenIds = newFragmentState.childrenIds || {}
  const nodeById = newFragmentState.nodeById || {}
  // const loading = newFragmentState.loading || {};
  if (!parentId) {
    const roots: string[] = []
    list.forEach((f) => f.rdfId && roots.push(f.rdfId))
    rootNodesIds = roots
  } else {
    const childrenList: string[] = []
    list.forEach((f) => f.rdfId && childrenList.push(f.rdfId))
    childrenIds[parentId] = childrenList
    // loading[parentId] = false;
  }

  list.forEach((f) => {
    const { rdfId } = f
    rdfId && (nodeById[rdfId] = { ...f, parentId })
  })

  newFragmentState = { ...newFragmentState, rootNodesIds, nodeById, childrenIds }
  return newFragmentState
}

export const fragments = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendFragments(state, payload: { list: FragmentData[]; parentId?: string }) {
      const { list, parentId } = payload
      const loading = state.loading || {}
      if (parentId) {
        loading[parentId] = false
      }
      const newFrData = parseFragmentList(state, list, parentId)
      return { ...state, ...newFrData, treeError: undefined, treeLoading: false, loading }
    },
    sendFragmentLoading(state, payload: { id?: string; loading?: boolean }) {
      // FIXME: not sure how to correctly implement (loading: boolean = true)
      const { id, loading: l = true } = payload
      if (!id) {
        return { ...state, treeLoading: l }
      }
      const loading = { ...state.loading }
      loading[id] = l
      return { ...state, loading }
    },
    sendFragmentError(state, payload: { error: FetchError; id?: string }) {
      const { id, error: e } = payload
      if (!id) {
        return { ...state, treeError: e }
      }
      const error = { ...state.error }
      error[id] = e
      return { ...state, error }
    },
    sendFragmentToggle(state, payload: { id: string }) {
      const expanded = { ...state.expanded }
      const id = payload.id
      if (expanded[id]) {
        expanded[id] = false
      } else {
        expanded[id] = true
      }
      return { ...state, expanded }
    },
    sendFragmentTogglePath(state, payload: { ids: string[] }) {
      const expanded = { ...state.expanded }
      const ids = payload.ids
      ids.forEach((id: string) => {
        if (expanded[id]) {
          expanded[id] = false
        } else {
          expanded[id] = true
        }
      })
      return { ...state, expanded }
    },
    sendFragmentCtxMenu(state, payload: { ctx: FragmentCtxMenu }) {
      const ctxMenu = payload.ctx
      return { ...state, ctxMenu }
    },
    sendFragmentSaveEdited(state, payload: { id: string }) {
      const hasSaved = payload.id
      return { ...state, hasSaved }
    },
    sendFragmentStopEdit(state, payload: { id: string }) {
      const { id } = payload
      let edited = state.edited
      if (!edited) {
        return state
      }
      delete edited[id]
      return { ...state, edited, hasSaved: undefined }
    },
    sendMovedFragment(state, payload: { data: FragmentData[] }) {
      const fragmenta = payload.data
      return moveFragment(fragmenta, state)
    },
    sendUpdatedFragment(state, payload: { data: FragmentData }) {
      const fragment = payload.data
      return updateFragment(fragment, state)
    },
    sendDeletedFragment(state, payload: { id: string[] }) {
      const id = payload.id
      if (id.length === 0) {
        return state
      }
      return deleteFragment(id, state)
    },
    sendAddedFragment(state, payload: { data: FragmentData }) {
      const fragment = payload.data
      return addFragment(fragment, state)
    },
    sendFragmentStartEdit(state, payload: { data: FragmentData }) {
      const fragment = payload.data
      let edited = state.edited
      if (!edited) {
        edited = {}
      }
      fragment.rdfId && (edited[fragment.rdfId] = fragment)
      return { ...state, edited }
    },
    sendFragmentEditedUpdate(state, payload: { id: string; field: FragmentFields; value: string }) {
      const { field, id, value } = payload
      let edited = state.edited
      if (!edited) {
        edited = {}
      }
      let editedFragment: FragmentData = edited[id]
      if (!editedFragment) {
        editedFragment = { label: "", description: "", rdfId: id }
      }
      editedFragment = { ...editedFragment, [field]: value }
      edited[id] = editedFragment
      state.edited = edited
      return { ...state, edited }
    },
    sendFragmentUploadNodeOver(state, payload: { isOver: boolean }) {
      const nodeUploadOver = payload.isOver
      return { ...state, nodeUploadOver }
    },
    sendFragmentCutTarget(state, payload: { id: string }) {
      const cutTarget = payload.id
      return { ...state, cutTarget }
    },
    sendFragmentRange(state, payload: { id?: string }) {
      const ranged = payload.id
      if (!state) {
        return state
      }
      return { ...setupRange<FragmentState>(ranged || null, state) }
    },
    sendFragmentClearSelect(state) {
      return { ...state, selected: undefined, active: undefined }
    },
    sendFragmentSelect(state, payload: { id?: string }) {
      const selected = payload.id
      let selectedList = state.selected
      let active = state.active
      return { ...state, selected: createSelected(selectedList || null, active || null, selected || null) }
    },
    sendFragmentActive(state, payload: { id: string }) {
      const active = payload.id
      const prevActive = state.active
      const selected = state.selected
      if (prevActive && prevActive === active && (!selected || (selected && !selected.includes(active)))) {
        return { ...state, active: undefined }
      }
      return { ...state, active }
    },
  },

  effects: (dispatch) => ({
    async uploadImpl(payload: { fragments: FragmentData[]; parentId: string | null }) {
      try {
        const response = await fetch(constants.FRAGMENT_UPLOAD_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload.fragments),
        })
        if (!response.ok) {
          throw new ServerError(response.status, response.statusText)
        }
        const savedFragments = await response.json()
        return savedFragments
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async downloadFragments(_:void, state) {
      try {
        const fragmentState = state.fragments
        if (fragmentState?.nodeById) {
          const active = fragmentState.active
          const fragmentsList: FragmentData[] = Object.values(fragmentState.nodeById)

          if (fragmentsList.length === 0) {
            return
          }
          const a = document.createElement("a")
          a.download = "fragments.json"
          const fragmentsText = JSON.stringify(fragmentsList, null, "\t")
          const fragmentsBlob = new Blob([fragmentsText], { type: "text/plain" })
          a.href = URL.createObjectURL(fragmentsBlob)
          a.click()
          URL.revokeObjectURL(a.href)
        }
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async upload(payload: { fragments: FragmentData[]; parentId: string | null }) {
      try {
        await dispatch.fragments.uploadImpl({ fragments: payload.fragments, parentId: payload.parentId })
        dispatch.fragments.fetchFragments({ parentId: payload.parentId || undefined })
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async deleteFragmentImpl(rdfId: string) {
      try {
        const resp = await fetch(`${constants.FRAGMENT_URL}/delete/${rdfId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        })
        const { status, statusText } = resp
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return true
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async fetchFragmentsPathImpl(rdfId: string) {
      try {
        const resp = await fetch(`${constants.FRAGMENT_URL}/path/${rdfId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        const { status, statusText } = resp
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return await resp.json()
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async deleteFragmentsImpl(rdfIds: string[]) {
      try {
        const resp = await fetch(`${constants.FRAGMENT_URL}/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rdfIds),
        })
        const { status, statusText } = resp
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return true
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async moveFragmentImpl(moveRequest: MoveFragmentRequest) {
      try {
        let url = `${constants.FRAGMENT_URL}/move`

        const resp = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(moveRequest),
        })
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return parseFragments(await resp.json())
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async fetchFragmentsImpl(parentId?: any) {
      try {
        let url = FETCH_FRAGMENTS_URL
        if (parentId) {
          url += `/${parentId}`
        }
        const resp = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        })

        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return await resp.json()
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async fetchSaveFragmentImpl(data: FragmentData) {
      try {
        let url = FETCH_SAVE_FRAGMENT_URL

        const resp = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(convertToFragmentRow(data)),
        })

        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return parseFragment(await resp.json())
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async fetchFragments(payload: { parentId?: string }, state) {
      try {
        const s = state.fragments
        if (payload.parentId && s && s.childrenIds && s.childrenIds[payload.parentId]) {
          return
        }

        dispatch.fragments.sendFragmentLoading({ id: payload.parentId })
        const fragmentTreeBranch = await dispatch.fragments.fetchFragmentsImpl(payload.parentId)
        const { l, r } = fragmentTreeBranch
        const fragments: FragmentData[] = parseFragments(l)
        dispatch.fragments.sendFragments({ list: fragments, parentId: r })
      } catch (e: any) {
        dispatch.fragments.sendFragmentLoading({ id: payload.parentId, loading: false })
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async confirmMove(payload: { movedId: string; target: string | null; callback: () => void }, state) {
      const fragment = state.fragments
      const nodes = fragment?.nodeById
      if (!fragment || !nodes) {
        return null
      }
      const movedNode = nodes[payload.movedId]
      const targetNode = payload.target ? nodes[payload.target] : null

      const selectedList = fragment.selected
      const selectedNodes =
        selectedList && selectedList.length !== 0
          ? Object.values(nodes)
              .filter((n) => n.rdfId && selectedList.includes(n.rdfId))
              .map((n) => n.label)
          : []

      const title: I18NString = { id: "FRAGMENT_CONFIRM_MOVE" }
      const name = !selectedNodes.length ? movedNode.label : selectedNodes.join(", ")

      if (!targetNode) {
        title.id = "FRAGMENT_CONFIRM_MOVE_TO_ROOT"
        title.values = { moved: name }
      } else {
        title.values = { target: targetNode.label, moved: name }
      }
      // FIXME: not sure
      dispatch.modal.openModal({
        id: "fragment.confirmDelete",
        type: "confirmDelete",
        options: { title: "FRAGMENT_CONFIRM", body: title },
        okCallback: async (result) => {
          if (result) {
            payload.callback()
          }
        },
      })
    },

    async moveFragment(payload: { movedId: string; newParentId?: string }, state) {
      try {
        const s = state
        const fragmentState = s.fragments
        const nodes = fragmentState && fragmentState.nodeById
        if (!nodes) {
          return
        }
        const data = nodes[payload.movedId]
        if (!data || !data.rdfId) {
          return
        }
        dispatch.fragments.sendFragmentLoading({ id: undefined, loading: undefined })
        const selectedList = fragmentState && fragmentState.selected
        let movedIsInvalid = true
        const movedIds: string[] = []
        if (selectedList && selectedList.length !== null && selectedList.includes(data.rdfId)) {
          for (let id of selectedList) {
            const node = nodes[id]
            movedIsInvalid = dispatch.fragments.validateMovedFragment({
              movedFragment: node,
              nodes: nodes,
              newParentId: payload.newParentId,
            })
            if (!movedIsInvalid) {
              break
            }
            movedIds.push(id)
          }
        } else {
          movedIsInvalid = dispatch.fragments.validateMovedFragment({
            movedFragment: data,
            nodes,
            newParentId: payload.newParentId,
          })
          if (movedIsInvalid) {
            movedIds.push(data.rdfId)
          } else {
            movedIsInvalid = false
          }
        }

        if (movedIsInvalid && movedIds.length !== 0) {
          const moveRequest: MoveFragmentRequest = {
            l: movedIds,
            pr: payload.newParentId,
          }
          const movedFragments = await dispatch.fragments.moveFragmentImpl(moveRequest)
          // not sure
          dispatch.fragments.sendMovedFragment({ data: movedFragments! })
          const messge: I18NString = { id: "FRAGMENT_MOVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } else {
          dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
          dispatchError("FRAGMENT_MOVE_ERROR", null, dispatch)
        }
      } catch (e) {
        dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
        dispatchError("FRAGMENT_MOVE_ERROR", e, dispatch)
      }
    },
    validateMovedFragment(payload: { movedFragment: FragmentData; nodes: FragmentNodeMap; newParentId?: string }) {
      if (payload.movedFragment.rdfId === payload.newParentId) {
        const messge: I18NString = {
          id: "FRAGMENT_MOVE_ITSELF_ERROR",
          values: { name: payload.movedFragment.label },
        }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_WARNING, message: messge })
        return false
      } else if (
        payload.movedFragment.parentId === payload.newParentId ||
        (typeof payload.movedFragment.parentId === undefined && typeof payload.newParentId === undefined)
      ) {
        const messge: I18NString = {
          id: "FRAGMENT_MOVE_SAME_PARENT_ERROR",
          values: { name: payload.movedFragment.label },
        }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_WARNING, message: messge })
        return false
      } else if (
        payload.movedFragment.rdfId &&
        payload.newParentId &&
        !validateParentToItsChildren(payload.movedFragment.rdfId, payload.newParentId, payload.nodes)
      ) {
        const messge: I18NString = {
          id: "FRAGMENT_MOVE_PARENT_TO_CHILDREN_ERROR",
          values: {
            nameP: payload.nodes[payload.movedFragment.rdfId].label,
            nameC: payload.nodes[payload.newParentId].label,
          },
        }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_WARNING, message: messge })
        return false
      }
      return true
    },
    async startEditFragment(id: string, state) {
      try {
        const s = state.fragments
        if (!s) {
          return
        }
        const edited = s.nodeById && s.nodeById[id]
        if (!edited) {
          return
        }
        dispatch.fragments.sendFragmentStartEdit({ data: edited })
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.fragments.sendFragmentError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.fragments.sendFragmentError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async initializeFragments(nodeId: string, state) {
      try {
        const fragState = state.fragments
        const expanded = fragState?.expanded
        const nodeById = fragState?.nodeById
        if (fragState?.loading) {
          return
        }

        const path = await dispatch.fragments.fetchFragmentsPathImpl(nodeId)

        if (!path || !path.length) {
          return
        }

        console.log("path", path)

        const active = path.pop()
        if (path.length === 0 && active) {
          dispatch.fragments.sendFragmentActive(active)
          return
        }

        dispatch.fragments.sendFragmentTogglePath(path)
        active && dispatch.fragments.sendFragmentActive(active)

        if (path.length) {
          path.forEach((p: string | undefined) => {
            dispatch.fragments.fetchFragments({ parentId: p })
          })
        } else {
          dispatch.fragments.fetchFragments(active)
        }
      } catch (e) {
        dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
        dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch)
      }
    },
    async addFragment(fragmentResult: FragmentData, state) {
      const s = state.fragments
      const fragmentState = s

      if (fragmentState && fragmentResult != null) {
        try {
          dispatch.fragments.sendFragmentLoading({})
          const fragment: FragmentData = fragmentResult
          // if(!fragment.pr){
          //     fragment.pr = parentId;
          // }
          const savedFragment = await dispatch.fragments.fetchSaveFragmentImpl(fragment)
          // not sure abt !
          dispatch.fragments.sendAddedFragment({ data: savedFragment! })
          const messge: I18NString = { id: "FRAGMENT_SAVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
          dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch)
        }
      }
    },

    async saveFragment(data: FragmentData, state) {
      try {
        if (!data.rdfId) {
          return
        }
        const fState = state.fragments
        if (!fState || !fState.nodeById) {
          return
        }
        dispatch.fragments.sendFragmentLoading({})
        const oldFragment = fState.nodeById[data.rdfId]
        const { description: d, label: l, parentId: pr } = oldFragment
        const { label: nl, description: nd, parentId: npr } = data
        if (d === nd && l === nl && ((typeof pr === undefined && typeof npr === undefined) || pr === npr)) {
          dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
          const messge: I18NString = { id: "FRAGMENT_SAVE_EQUAL" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_INFO, message: messge })
          return
        }

        if ((pr || npr) && pr !== npr) {
          dispatch.fragments.moveFragment({ movedId: data.rdfId, newParentId: npr })
        }

        const updatedFragment = await dispatch.fragments.fetchSaveFragmentImpl(data)
        // not sure !
        dispatch.fragments.sendUpdatedFragment({ data: updatedFragment! })
        // dispatch(fetchFragments(data.pr));
        dispatch.fragments.sendFragmentStopEdit({ id: data.rdfId })

        const messge: I18NString = { id: "FRAGMENT_SAVE_SUCCESS" }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
      } catch (e) {
        dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
        dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch)
      }
    },
    async daleteFragment(id: string, state) {
      try {
        const s = state.fragments
        const fragmentState = s
        const data = fragmentState && fragmentState.nodeById && fragmentState.nodeById[id]
        if (!data) {
          return
        }
        dispatch.fragments.sendFragmentLoading({})
        dispatch.fragments.sendFragmentStopEdit({ id })
        const isDeleted = await dispatch.fragments.deleteFragmentImpl(id)
        if (isDeleted) {
          dispatch.fragments.sendDeletedFragment({ id: [id] })
          const messge: I18NString = { id: "FRAGMENT_REMOVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } else {
          dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
          const messge: I18NString = { id: "FRAGMENT_REMOVE_ERROR" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_DANGER, message: messge })
        }
      } catch (e) {
        dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
        dispatchError("FRAGMENT_REMOVE_ERROR", e, dispatch)
      }
    },
    async daleteSelectedFragments(_:void, state) {
      try {
        const fragmentState = state.fragments
        const nodes = fragmentState && fragmentState.nodeById
        const selected = fragmentState && fragmentState.selected
        const active = fragmentState && fragmentState.active
        if (!nodes) {
          return
        }
        dispatch.fragments.sendFragmentLoading({})
        if (selected) {
          selected.forEach((s: any) => {
            dispatch.fragments.sendFragmentStopEdit({ id: s })
          })
        }

        if (!selected && !active) {
          return
        }
        const deletedFragments = selected ? selected : active ? [active] : []
        if (deletedFragments.length === 0) {
          return
        }
        const isDeleted = await dispatch.fragments.deleteFragmentsImpl(deletedFragments)
        if (isDeleted) {
          dispatch.fragments.sendDeletedFragment({ id: deletedFragments })
          const messge: I18NString = { id: "FRAGMENT_REMOVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } else {
          dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })
          const messge: I18NString = { id: "FRAGMENT_REMOVE_ERROR" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_DANGER, message: messge })
        }
      } catch (e) {
        dispatch.fragments.sendFragmentLoading({ id: undefined, loading: false })

        dispatchError("FRAGMENT_REMOVE_ERROR", e, dispatch)
      }
    },
    async confirmDelete(payload: { callback: () => void; id?: string }, state) {
      const fragmentS = state.fragments
      const nodes = fragmentS?.nodeById
      if (!nodes) {
        return
      }
      const bodyMess = {
        id: "FRAGMENT_CONFIRM_DELETE_MODAL",
        values: { name: "" },
      }
      const selected = fragmentS?.selected
      const active = fragmentS?.active
      let name = ""
      if (payload.id) {
        name = nodes[payload.id].label
      } else if (selected && selected.length !== 0) {
        name = Object.values(nodes)
          .filter((f) => f.rdfId && selected.includes(f.rdfId))
          .map((f) => f.label)
          .join(", ")
      } else if (active) {
        name = nodes[active].label
      }
      bodyMess.values.name = name
      dispatch.modal.openModal({
        id: "fragment.confirmDelete",
        type: "confirmDelete",
        options: {
          title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
          body: bodyMess,
        },
        okCallback: async (result) => {
          if (result) {
            payload.callback()
          }
        },
      })
    },
  }),
})
