import {
  CEditorTheme,
  CODE_EDITOR_THEME,
  NptTheme,
  THEME_KEY,
} from "../constants/theme";
import { UIVariant } from "../types/security";

interface ThemeObject {
  platformTheme: NptTheme | null;
  codeEditorTheme: CEditorTheme | null;
}

export class ThemeService {
  static getTheme(): ThemeObject {
    const platformTheme = localStorage.getItem(THEME_KEY) as NptTheme;
    const codeEditorTheme = localStorage.getItem(THEME_KEY) as CEditorTheme;
    return { platformTheme, codeEditorTheme };
  }
  static getCodeEditorTheme(variant?: UIVariant) {
    const codeEditorTheme = localStorage.getItem(CODE_EDITOR_THEME);
    const platformTheme = localStorage.getItem(THEME_KEY) as NptTheme;
    if (codeEditorTheme) {
      return codeEditorTheme === "blackboard" ? "vs-dark" : "light";
    } else {
      if (
        (!platformTheme && variant === "scm") ||
        platformTheme === NptTheme.CUSTOM_DARK
      ) {
        return "vs-dark";
      } else {
        return "light";
      }
    }
  }
  static setTheme(thmObj: ThemeObject) {
    let theme = null;
    switch (thmObj.platformTheme) {
      case NptTheme.CUSTOM_DARK: {
        theme = NptTheme.CUSTOM_DARK;
        break;
      }
      case NptTheme.CUSTOM_DARK_COMPACT: {
        theme = NptTheme.CUSTOM_DARK_COMPACT;
        break;
      }
      case NptTheme.DEFAULT: {
        theme = NptTheme.DEFAULT;
        break;
      }
      case NptTheme.DEFAULT_COMPACT: {
        theme = NptTheme.DEFAULT_COMPACT;
        break;
      }
    }
    if (theme) {
      localStorage.setItem(THEME_KEY, theme);
      const codeTheme = thmObj.codeEditorTheme;
      if (codeTheme) {
        localStorage.setItem(CODE_EDITOR_THEME, codeTheme);
      }
      window.location.reload();
    }
  }
}
