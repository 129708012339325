import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { fetchSCMInfoImpl } from "../services/scm";
import { FetchError } from "../types/error";
import { SCMInformation, SCMState } from "../types/scm";

const initialState: SCMState = {};

export const scm = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendSCMInfo(state, info: SCMInformation) {
      return { ...state, info: info, loading: false };
    },
    sendSCMInfoError(state, error: FetchError) {
      return { ...state, info: error, loading: false };
    },
    sendSCMInfoLoading(state) {
      return { ...state, loading: true };
    },
  },
  effects: (dispatch) => ({
    fetchSCMInfo: async (_: void, s) => {
      try {
        if (s.scm.loading) {
          return;
        }
        dispatch.scm.sendSCMInfoLoading();
        dispatch.scm.sendSCMInfo(await fetchSCMInfoImpl());
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.scm.sendSCMInfoError({ code: e.code, message: e.message });
        } else if (typeof e.message == "string") {
          dispatch.scm.sendSCMInfoError({ code: -1, message: e.message });
        } else {
          dispatch.scm.sendSCMInfoError({ code: -1, message: "Unknown error" });
        }
      }
    },
  }),
});
