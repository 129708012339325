import React from "react";
import { connect } from "react-redux";
import { FetchError } from "../../types/error";
import { createObject } from "../../types/selection";
import { TreeNode } from "../../types/tree";

import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";
import { RootState } from "../../store";

interface TreeBreadcrumbsProps {
  treeBreadcrumbs: string;
  error?: FetchError;
  loading?: boolean;
  active?: string | null;
  nodeById?: { [k: string]: TreeNode };
  selectNode: (node: TreeNode) => void;
}
const TreeBreadcrumbs: React.FunctionComponent<TreeBreadcrumbsProps> =
  React.memo((props) => {
    const selectNode = (node: TreeNode) => {
      if (node.id === props.active) {
        return;
      }
      props.selectNode(node);
    };
    return (
      <Breadcrumbs
        error={props.error}
        loading={props.loading}
        active={props.active}
        nodeById={props.nodeById || {}}
        selectNode={selectNode}
      />
    );
  });

export default connect(
  (state: RootState, ownProps: TreeBreadcrumbsProps) => {
    const treeState = state.tree.treeInfo[ownProps.treeBreadcrumbs];
    return {
      error: treeState?.error,
      loading: treeState?.loading?.[ownProps.treeBreadcrumbs],
      active: treeState?.active,
      nodeById: treeState?.nodeById,
    };
  },
  (dispatch: RematchDispatch<RootModel>, ownProps: TreeBreadcrumbsProps) => {
    return {
      selectNode: (node: TreeNode) => {
        if (!node.data) {
          return;
        }
        const treeId = ownProps.treeBreadcrumbs;
        const rdfId = node.data.$rdfId;
        const namespace = node.data.$namespace;
        const type = node.typeId;
        dispatch.tree.sendTreeNodeActive({ path: treeId, nodeId: node.id });
        if (rdfId) {
          dispatch.selection.sendSelection({
            object: createObject(rdfId, namespace),
            type: type || "",
          });
        }
      },
    };
  }
)(TreeBreadcrumbs);
