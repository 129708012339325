import React from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect, ReactReduxContext } from "react-redux";

import { FetchError } from "../../types/error";
import { SelectionInfo } from "../../types/selection";
import {
  isSubject,
  SaveStateType,
  Subject,
  SubjectData,
} from "../../types/subject";
import {
  SAVE_STATE_SHOW_ERRORS,
  SAVE_STATE_START,
  SAVE_STATE_UPLOADS_READY,
  SAVE_STATE_WAIT_SERVER,
  STATUS_LOADING,
} from "../../constants/subject";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CardForm from "./CardForm";
import UploadStatus from "./UploadStatus";
import { Dispatch, RootState } from "../../store";

interface SubjectOptions {
  className: string;
  parent: any;
  parentRef: any;
  closeOnReady: boolean;
  _prototype: any;
}

export interface ObjectCardProps {
  data?: SubjectData;
  subjectKey: string;
  saveState?: SaveStateType;
  fixed?: boolean;
  error?: any;

  editCard?: boolean;
  loading?: boolean;
  options?: SubjectOptions;
  isNew?: boolean;
  subjects?: { [SUBJECT_KEY: string]: Subject | FetchError };
  saveSubject: () => void;
  getSubject: (subjectKey: string) => void;
  saveChangesModal: (name: string, ok: Function, cancel: Function) => void;
  startSave: () => void;
  unlockSubject: () => void;
  cancelSave: () => void;
  toolbarComponent?: any;
  selection?: SelectionInfo;
}

/**
 * Object card which contains card toolbar and form
 */
class ObjectCard extends React.PureComponent<ObjectCardProps> {
  private closeOnReady: boolean = false;
  private afterEdit: Function | null = null;

  componentDidMount() {
    const { subjectKey, isNew } = this.props;
    if (this.props.options) {
      if (this.props.options.className) {
        this.closeOnReady = this.props.options.closeOnReady;
      } else {
        subjectKey && this.props.getSubject(subjectKey);
        this.closeOnReady = this.props.options.closeOnReady;
      }
    } else {
      subjectKey && !isNew && this.props.getSubject(subjectKey);
    }
  }

  onCancel() {
    if (
      this.props.saveState &&
      this.props.saveState != SAVE_STATE_SHOW_ERRORS
    ) {
      //Save is in progress
      this.props.cancelSave(); //Try to stop save progress
      return; //But now we cannot do anything more so return false
    }
    // if (this.closeOnReady) {
    //     window.close();
    //     return;
    // }
    if (this.props.isNew) {
      // if (typeof this.props.object == 'string') { //Fetch selected object
      //     this.props.fetchSubject(this.props.object, this.props.namespace);
      // } else { //Nothing to do but destroy the store
      //     this.props.destroyStore();
      // }
    } else {
      this.props.unlockSubject();
    }
  }

  showSaveChages(data: SubjectData, callback: Function) {
    const name = data.$label || "";
    const { startSave, cancelSave } = this.props;
    const options = {
      title: { id: "OBJECTCARD_SAVE_CHANGES_TITLE" },
      body: { id: "OBJECTCARD_SAVE_CHANGES_BODY", values: { name } },
    };
    const _this = this;
    this.props.saveChangesModal(
      name,
      function () {
        //okCallback
        _this.afterEdit = callback;
        startSave();
      },
      function () {
        //cancelCallback
        if (data.$isNew) {
          //Nothing to do!
          _this.afterEdit = null; //Clean previous after edit callback
          callback();
        } else {
          _this.afterEdit = callback;
          cancelSave();
        }
      }
    );
  }

  componentDidUpdate(prevProps: ObjectCardProps) {
    const {
      getSubject,
      subjectKey,
      editCard,
      saveState,
      fixed,
      saveSubject,
      data,
    } = prevProps;
    const nextProps = this.props;
    if (saveState != nextProps.saveState) {
      //Check if save is ready
      if (nextProps.saveState == SAVE_STATE_UPLOADS_READY) {
        //Call save if uploads are ready
        saveSubject();
      }
    }

    if (fixed) {
      return;
    }
    //Monitor selected objects
    const namespace = this.props.data?.$namespace;
    const prevNamespace = prevProps.data?.$namespace;
    const isEditing = this.props.editCard;

    if (
      !isEditing &&
      (subjectKey != nextProps.subjectKey || namespace != prevNamespace)
    ) {
      getSubject(nextProps.subjectKey);
    }
  }

  getUploadStatus() {
    const style: any = {};
    if (this.props.saveState != SAVE_STATE_START) {
      style.display = "none";
    }
    return (
      <div className="row mx-1" style={style}>
        <div className="col-md-12">
          <UploadStatus {...this.props} />
        </div>
      </div>
    );
  }
  renderLoading() {
    return (
      <div
        style={{ minHeight: "4rem" }}
        className="m-1 position-relative d-flex justify-content-center"
      >
        <Alert
          variant="info"
          style={{ top: "1rem", zIndex: 1 }}
          className="position-absolute"
        >
          <FontAwesomeIcon className="mr-1" icon={faSpinner} spin />
          <FormattedMessage id="SUBJECT_LOADING" />
        </Alert>
      </div>
    );
  }
  renderNoSubject() {
    return (
      <div
        style={{ minHeight: "4rem" }}
        className="m-1 position-relative d-flex justify-content-center"
      >
        <Alert variant="info" className="position-absolute">
          <FormattedMessage id="SUBJECT_NOT_SELECTED" />
        </Alert>
      </div>
    );
  }
  render() {
    const { subjectKey, loading, toolbarComponent } = this.props;
    const status = loading ? (
      this.renderLoading()
    ) : !subjectKey ? (
      this.renderNoSubject()
    ) : (
      <></>
    );
    return (
      <div className="npt-objectcard text-dark overflow-auto h-100 d-flex flex-column">
        {/* return <div  className="npt-objectcard d-flex flex-grow-1 flex-column text-dark overflow-auto" > */}
        {/* <ScopedToolbar subjectKey={subjectKey} /> */}
        {toolbarComponent && <div className="mt-1">{toolbarComponent}</div>}
        <>
          {this.getUploadStatus()}
          {status}
        </>

        {!loading && (
          <CardForm
            subjectKey={subjectKey}
            style={{ overflowX: "hidden", overflowY: "auto" }}
          />
        )}
      </div>
    );
  }
}

const ConnectedObjectCard = connect(
  (state: RootState, ownProps: { subjectKey: string }) => {
    let { subjectKey } = ownProps;
    const subject = state.subject.subjects[subjectKey];
    const loadingSubject = state.subject.loading[subjectKey];
    const loadingLock = state.subject && state.subject.loadingLock[subjectKey];
    //show loading when subject is being loaded by fetch subject
    let loading = loadingSubject && !loadingLock;
    if (!isSubject(subject)) {
      return {
        locale: state.locale.language,
        loading,
        subjects: state.subject && state.subject.subjects,
      };
    }
    const loadingLayout =
      state.subject.layoutsStatus[subject.className] === STATUS_LOADING;
    loading = loading || loadingLayout;

    const serverLock = subject.subjectData.$lock;
    const serverLockReady = serverLock && serverLock.status;
    const isNew = subject.isNew;
    const editCard = serverLockReady || isNew;

    const saveState = state.subject && state.subject.saveState[subjectKey];
    const error = subject.subjectData.$error;
    const errorOperation = subject.subjectData.$errorOperation;

    return {
      data: subject.subjectData,
      selection: state.selection.info,
      editCard,
      saveState,
      error,
      loading,
      isNew,
      subjects: state.subject && state.subject.subjects,
    };
  },
  (dispatch: Dispatch, ownProps: { subjectKey: string }) => {
    return {
      saveSubject: () =>
        dispatch.subject.saveSubject({ subjectKey: ownProps.subjectKey }),
      cancelSave: () =>
        dispatch.subject.sendSubjectSaveCancel(ownProps.subjectKey),
      startSave: () =>
        dispatch.subject.sendSubjectStartSave(ownProps.subjectKey),
      getSubject: (subjectKey: string) =>
        dispatch.subject.fetchSubject(subjectKey),
      saveChangesModal: (name: string, ok: Function, cancel: Function) =>
        dispatch.subject.showSaveChanges({
          subjectKey: ownProps.subjectKey,
          name,
          okCallback: ok,
          cancelCallback: cancel,
        }),
      unlockSubject: () =>
        dispatch.subject.fetchLock({
          subjectKey: ownProps.subjectKey,
          acquire: false,
        }),
    };
  }
)(ObjectCard);

interface ObjectCardConnectorProps {
  subjectKey: string;
  toolbarComponent?: any;
}
const ObjectCardConnector: React.FunctionComponent<ObjectCardConnectorProps> =
  React.memo((props) => {
    return (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          return <ConnectedObjectCard {...props} />;
        }}
      </ReactReduxContext.Consumer>
    );
  });

export default ObjectCardConnector;
