import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import {
  downloadBackup,
  fetchBackupUpload,
  fetchPostgresLogsImpl,
} from "../services/postgres";
import { LocaleState } from "../types/locale";

import { I18NString } from "../types/modal";
import { LogInfo, PostgresState } from "../types/postgres";

const initialState: PostgresState = {
  logInfo: { backup: null, out: null, restore: null },
};

export const postgres = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendPostgresLogs(state, logInfo: LogInfo) {
      return { ...state, logInfo };
    },
    sendIsRestoring(state) {
      return { ...state, isRestoring: true };
    },
    sendDoneRestoring(state) {
      return { ...state, isRestoring: false };
    },
    sendIsCreatingBackup(state, isCreating: boolean) {
      return { ...state, isCreatingBackup: isCreating };
    },
    sendRestoreResult(state, result?: boolean | ServerError) {
      return { ...state, isRestoreSuccess: result };
    },
    sendLogLoading(state, loading: boolean) {
      return { ...state, logLoading: loading };
    },
    sendCreateBackupError(state, error: ServerError | undefined) {
      return { ...state, createBackupError: error };
    },
  },
  effects: (dispatch) => ({
    uploadBackup: async (file: any) => {
      const name: string = file.get("file")["name"];
      const dotIndex = name.lastIndexOf(".") + 1;
      const ext = name.slice(dotIndex, name.length);
      try {
        if (ext === "sql" || ext === "backup") {
          dispatch.postgres.sendIsRestoring();
          await Promise.resolve(fetchBackupUpload(file));
          dispatch.postgres.sendDoneRestoring();
          dispatch.postgres.sendRestoreResult(true);
        } else {
          console.log("error while upload");
        }
      } catch (e: any) {
        console.log("error while upload ", e);
        dispatch.postgres.sendDoneRestoring();
        dispatch.postgres.sendRestoreResult(e);
      }
    },
    createBackup: () => {
      dispatch.modal.openModal({
        id: "create.backup",
        type: "createBackup",
        options: {},
        okCallback: async (result) => {
          if (result) {
            try {
              dispatch.postgres.sendIsCreatingBackup(true);
              await Promise.resolve(downloadBackup());
              dispatch.postgres.sendIsCreatingBackup(false);
            } catch (e: any) {
              dispatch.postgres.sendIsCreatingBackup(false);
              dispatch.postgres.sendCreateBackupError(e);
            }
          }
        },
      });
    },
    fetchPostgresLogs: async () => {
      dispatch.postgres.sendPostgresLogs(await fetchPostgresLogsImpl());
    },
    displayPostgresLogs: async () => {
      dispatch.modal.openModal({
        id: "show.postgresLogs",
        type: "showPostgresLogs",
        options: {},
        okCallback: async (result) => {},
      });
      dispatch.postgres.sendLogLoading(true);
      await Promise.resolve(dispatch.postgres.fetchPostgresLogs());
      dispatch.postgres.sendLogLoading(false);
    },
  }),
});
