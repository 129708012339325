import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { RefObject } from "react";
import { InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { I18NString } from "../../types/modal";

export interface PasswordGroupProps {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  onChange: (value: string) => void;
  isCode: boolean; //if this input is code input
  label?: I18NString;
  id?: string;
  name?: string;
  className?: string;
}

export interface PasswordGroupStates {
  visible: boolean;
}

export default class PasswordGroup extends React.PureComponent<
  PasswordGroupProps,
  PasswordGroupStates
> {
  constructor(props: PasswordGroupProps) {
    super(props);
    this.state = {
      visible: false,
    };
    this.onChange = this.onChange.bind(this);
    this.toggleVisible = this.toggleVisible.bind(this);
  }
  componentDidMount(): void {
    document.addEventListener("mouseup", this.toggleVisible(false));
  }
  componentWillUnmount(): void {
    document.removeEventListener("mouseup", this.toggleVisible(false));
  }
  onChange(event: any) {
    this.props.onChange(event.target.value);
  }

  toggleVisible = (toggle: boolean) => () => {
    this.setState({ visible: toggle });
  };
  renderLabel() {
    const { label, isCode } = this.props;
    if (label) {
      return <FormattedMessage id={label.id} values={label.values} />;
    }
    return this.props.isCode ? (
      <FormattedMessage
        id="LOGIN_PANEL_CODE_LABEL"
        defaultMessage="Code:"
        description="Label for code field"
      />
    ) : (
      <FormattedMessage
        id="LOGIN_PANEL_PASSWORD_LABEL"
        defaultMessage="Password:"
        description="Label for password field"
      />
    );
  }
  getId() {
    const { isCode, id } = this.props;
    if (!id) {
      return isCode ? "codeInput" : "passwordInput";
    }
    return id;
  }
  getName() {
    const { isCode, name } = this.props;
    if (!name) {
      return isCode ? "code" : "password";
    }
    return name;
  }
  /* Use style props due to outdated bootstrap */
  render() {
    const { className } = this.props;
    return (
      <Form.Group>
        <Form.Label htmlFor="passwordInput">{this.renderLabel()}</Form.Label>
        <InputGroup className="mb-2">
          <input
            id={this.getId()}
            name={this.getName()}
            className={`form-control border ${className}`}
            type={this.state.visible ? "text" : "password"}
            ref={this.props.inputRef}
            onChange={this.onChange}
          />
          <InputGroup.Append>
            <Button
              variant="outline-warning"
              onMouseDown={this.toggleVisible(true)}
            >
              <FontAwesomeIcon icon={this.state.visible ? faEyeSlash : faEye} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    );
  }
}
