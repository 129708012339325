import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { FinderSearchSettings, FinderData } from "../../types/finder";

import DebounceInput from "../debounce/debounceinput";

import styles from "./Finder.module.css";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";
import { RootState } from "../../store";
import { DEFAULT_FINDER_STATE } from "../../model/finder";

interface FinderSearchStringProps {
  finderId: string;
  value: string | null;
  settings?: FinderSearchSettings;
  changes: FinderData | null;
  changeSearchString: (value: string) => void;
  confirmChanges?: () => void;
  confirmFinderChanges: () => void;
}

/*********************************
 * Finder Search String Component *
 *********************************/
const FinderSearchString: React.FunctionComponent<FinderSearchStringProps> =
  React.memo((props) => {
    const intl = useIntl();
    if (!props.settings || props.settings.hidden) {
      return null;
    }
    const onChange = (value?: string | {}) => {
      if (typeof value !== "string") {
        value = "";
      }
      const propsStringValue = props.value || "";
      if (value == propsStringValue) {
        return;
      }
      props.changeSearchString(value as string);
    };
    const onFinish = (value: string) => {
      onChange(value);
    };
    const onEnter = (value: string) => {
      onChange(value);
      if (typeof props.confirmChanges === "function") {
        props.confirmChanges();
      } else {
        props.confirmFinderChanges();
      }
    };
    let value = props.value;
    if (typeof props.changes?.searchString !== "undefined") {
      value = props.changes?.searchString;
    }
    return (
      <div className={`w-100 ${styles.search}`}>
        <div className="w-100 h-100 my-1">
          <div
            className={`h-100 position-absolute d-flex align-items-center ${styles.searchIcon}`}
          >
            <i className="fa fa-search" />
          </div>
          <DebounceInput
            value={value}
            className={`w-100 form-control ${styles.searchInput}`}
            placeholder={intl.formatMessage({ id: "NPT_TABLE_SEARCH" })}
            editable={true}
            debounce={props.settings.debounceTime}
            updateOnChange={!props.changes}
            change={onChange}
            onFinish={onFinish}
            onEnter={onEnter}
          />
        </div>
      </div>
    );
  });

export default connect(
  (state: RootState, ownProps: { finderId: string }) => {
    const finderState = state.finder[ownProps.finderId] || DEFAULT_FINDER_STATE;
    return {
      value: finderState.data.searchString,
      settings: finderState.options.search,
      changes: finderState.changes,
    };
  },
  (dispatch: RematchDispatch<RootModel>, ownProps: { finderId: string }) => {
    const { finderId } = ownProps;
    return {
      confirmFinderChanges: () => {
        dispatch.finder.sendFinderChangesConfirm(finderId);
      },
      changeSearchString: (value: string) => {
        dispatch.finder.sendFinderSearch({ finderId, value });
      },
    };
  }
)(FinderSearchString);
