import { Action } from "redux";

import { FetchError } from "./error";
import * as constants from "../constants/security";

export type UIVariant = "cimbrowser" | "cimportal" | "scm";

export interface SessionUserData {
  username: string;
  label: string;
  securityManager: boolean;
  adminUser: boolean;
  developerUser: boolean;
  superUser: boolean;
}

export interface SessionData {
  numberOfSessions: number;
  user: SessionUserData;
}

export interface SoftwareData {
  checksum: string;
  path: string;
  type: string;
  version: string;
}

export interface PointData {
  labelX: string;
  x: number;
  y: number;
}

export interface TrendData {
  name: string;
  points: PointData[];
  max: number;
  count: number;
}

export interface SecurityData {
  name: string;
  value: any | null;
  units?: string;
}
export interface SecurityDataResponse {
  settings: SecurityData[];
  trends?: TrendData[];
  sessions?: SessionData[];
  software?: SoftwareData[];
  totalSecurityEvents: number;
  totalSessions: number;
}

export interface SecurityItem {
  i: number;
  r: string;
  l: string;
  d: string;
  u?: string;
  a?: string;
  b?: boolean;
}
export interface SecurityItemResponse {
  total: number;
  itemList: SecurityItem[];
}

export interface RealtimeDataSource {
  id: string;
  type: string;
  mainAddress: string;
  backupAddress?: string;
  label?: string;
  description?: string;
}

export interface RealtimeData {
  sources?: RealtimeDataSource[];
}

export interface UIOptions {
  /**
   * CIM UI unifies interfaces of three applications
   * but has some differences depending on application variant
   */
  variant: UIVariant;

  /**
   * Some applications need to provide special runtime information
   * to display interface. Ex. SCM may have or not information about
   * telemechanics depending on the project configuration
   */
  info?: { [K: string]: any };

  /**
   * Server may provide information about realtime data sources available
   * on server side. On receiving this information we may connect to them
   * on client side.
   */
  rt?: RealtimeData;
}

export type DividerType = "after" | "before" | "both";

export interface MenuItem {
  //Name
  n?: string;
  //Hidden
  h?: boolean;
  //Reference
  r?: string;
  //Divider
  d?: DividerType;
  //Icon
  i?: string;
  //Title language -> title
  t?: { [LANG: string]: string };
  //type of backend (scm/cimbrowser/cimportal)
  v?: UIVariant;
}

export interface MenuData {
  logoByLang: { [LANG: string]: string };
  //Root menu item ids
  roots?: string[];
  //id -> children ids
  childrenById?: { [ID: string]: string[] };
  //id -> menu item
  itemById: { [ID: string]: MenuItem };
}
export interface MessagesStatistics {
  read: number;
  total: number;
  unread: number;
}
/**
 * Contains all menus available for the user
 */
export interface MenuContainer {
  mainMenu?: MenuData;
  adminMenu?: MenuData;
  systemMenu: MenuData;
  developerMenu?: MenuData;
  securityMenu?: MenuData;
  helpMenu?: MenuData;
  messages?: MessagesStatistics;
}

export interface SystemUser extends MenuContainer {
  username: string;
  label: string;
  superUser: boolean;
  adminUser: boolean;
  developerUser: boolean;
  securityManager: boolean;
  avatar: boolean;
  authorities: string[];
}

export interface DomainInfo {
  name: string;
  fqdn: string;
}

export interface LoginPageInfo {
  loggedIn: false;

  //UI options
  options: UIOptions;

  /**
   * Special fields to report the exception
   * which occured on server side trying
   * to get login page information.
   *
   * Normally this happens when database is not available (failed).
   *
   * In this case we should display alert on main
   * page and retry with /rest/login until database will be available.
   */
  exception?: string;
  message?: string;

  logo?: string;
  ldapAuth?: boolean;
  domains?: DomainInfo[];
  basicDomain?: string;
  netbiosMap?: { [K: string]: DomainInfo };
  fqdnMap?: { [K: string]: DomainInfo };
}

export interface LoggedInUser extends SystemUser {
  loggedIn: true;
  generalAccessRules?: string[];

  //UI options
  options: UIOptions;
}

/**
 * Login status comes from page (window.loginStatus) or
 * from /rest/login
 *
 * loggedIn = true => we have all system user information
 * loggedIn = false => we have login page information
 */
export type LoginStatus = LoginPageInfo | LoggedInUser;

export interface LoginRequestDetails {
  ad?: boolean; //Use active directory
  generateToken?: boolean; //Request server to generate token
  code?: string;
}

///////////
//Actions//
///////////
export interface SendLoginInfo extends Action {
  type: constants.SEND_LOGIN;
  payload: {
    info: LoginStatus;
  };
}
export interface SendMessageStatUpdate extends Action {
  type: constants.SEND_MESSAGE_STAT_UPDATE;
  payload: MessagesStatistics;
}

export interface SendLoginError extends Action {
  type: constants.SEND_LOGIN_ERROR;
  payload: {
    error: FetchError;
  };
}

export interface SendLoginLoading extends Action {
  type: constants.SEND_LOGIN_LOADING;
}

export interface SendLoginCodeRequired extends Action {
  type: constants.SEND_LOGIN_CODE_REQUIRED;
}

export type SecurityAction =
  | SendLoginInfo
  | SendMessageStatUpdate
  | SendLoginError
  | SendLoginLoading
  | SendLoginCodeRequired;

/////////
//State//
/////////
export interface SecurityState {
  info: SecurityData[];
  trends?: TrendData[];
  software?: SoftwareData[];
  sessions?: SessionData[];
  infoLoading?: boolean;
  loading?: boolean;
  logoutFinished?: boolean;
  loginStatus?: LoginStatus | FetchError;
  installation?: "cluster" | "db";
  totalSecurityEvents?: number;
  totalSessions?: number;
}

export function isLoginStatus(
  x: LoginStatus | FetchError | undefined
): x is LoggedInUser {
  return (
    typeof x != "undefined" && typeof (x as LoginStatus).loggedIn == "boolean"
  );
}

export function isLoggedInUser(
  x: LoginStatus | FetchError | undefined
): x is LoggedInUser {
  return isLoginStatus(x) && (x as LoginStatus).loggedIn == true;
}

export function isLoginPageInfo(
  x: LoginStatus | FetchError | undefined
): x is LoginPageInfo {
  return isLoginStatus(x) && (x as LoginStatus).loggedIn == false;
}
