import * as constants from "../constants/statistics";
import { ServerError } from "../actions/utils";
import {
  DeveloperData,
  StaticCpuInfo,
  ThreadStatData,
  ThreadData,
  ChartData,
  CpuInfo,
  DeveloperInfo,
  DynamiCpuInfoReq,
  MemoryInfo,
  MemoryState,
  Point,
  ThreadDataNormal,
  ThreadStatDataInfo,
} from "../types/statistics";

export async function fetchStatisticDataImpl(): Promise<DeveloperData> {
  const url = `/rest/statistics`;
  const response = await fetch(url);

  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  return await response.json();
}
export async function fetchStaticCpuInfoImpl(): Promise<StaticCpuInfo> {
  const url = `/rest/statistics/static`;
  const response = await fetch(url);

  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  return await response.json();
}

export async function fetchThreadStatisticDataImpl(
  init: boolean = false
): Promise<ThreadStatData> {
  const url = `/rest/statistics/threads`;
  const response = await fetch(url, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  return await response.json();
}
export async function fetchDeadlockedThreadsImpl(
  init: boolean = false
): Promise<ThreadData[]> {
  const url = `/rest/statistics/threads/deadlock`;
  const response = await fetch(url, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  return await response.json();
}

function fillArray(n: number) {
  return Array.from({ length: n }, (_, i) => i + 1).map((i) => ({
    x: i,
    y: 0,
  }));
}
function convertToMb(n: number) {
  if (n === -1) {
    return -1;
  }
  return n / constants.MB_DIV;
}
function convertToSec(n: number) {
  return n / constants.NANO_TO_SEC;
}

function getCpuInfo(cpuReqData: DynamiCpuInfoReq, info?: DeveloperInfo) {
  const {
    processCpuTime: cpuTime,
    systemCPULoad: sysCpuL,
    processCPULoad: procCpuL,
    committedVirtualMemorySize: cvm,
    freePhysicalMemSize: fpm,
    freeSwapSpaceSize: fss,
    totalPhysicalMemSize: tpm,
    systemLoadAvg: sla,
  } = cpuReqData;
  // const staticCpuInfo = statCpu || (info?.cpuStatistics?.staticCpuInfo)
  // console.log(convertToMb(cvm), cvm)
  const newCpuInfo: CpuInfo = {
    // staticCpuInfo: staticCpuInfo,
    dynamicCpuInfo: {
      committedVirtualMemorySize: cvm && cvm > 0 ? convertToMb(cvm) : null,
      freePhysicalMemSize: convertToMb(fpm),
      freeSwapSpaceSize: convertToMb(fss),
      totalPhysicalMemSize: convertToMb(tpm),
      systemLoadAvg: sla && sla > 0 ? sla : null,
      processCpuLoad: { count: 24, points: [] },
      processCpuTime: { count: 24, points: [] },
      systemCpuLoad: { count: 24, points: [] },
    },
  };

  let cpuTimeChartData: ChartData = newCpuInfo.dynamicCpuInfo.processCpuTime;
  let cpuSysLoadChartData: ChartData = newCpuInfo.dynamicCpuInfo.systemCpuLoad;
  let cpuProcLoadChartData: ChartData =
    newCpuInfo.dynamicCpuInfo.processCpuLoad;

  let x = 24;
  if (info && info.cpuStatistics) {
    x = info.cpuStatistics.dynamicCpuInfo.systemCpuLoad.points[23].x + 1;
    // cpuTimeChartData.points = info.cpuStatistics.dynamicCpuInfo.processCpuTime.points.slice(1);
    cpuSysLoadChartData.points =
      info.cpuStatistics.dynamicCpuInfo.systemCpuLoad.points.slice(1);
    cpuProcLoadChartData.points =
      info.cpuStatistics.dynamicCpuInfo.processCpuLoad.points.slice(1);
  } else {
    // cpuTimeChartData.points = fillArray(23);
    cpuSysLoadChartData.points = fillArray(23);
    cpuProcLoadChartData.points = fillArray(23);
  }
  // cpuTimeChartData.points.push({ x, y: convertToSec(cpuTime) })
  cpuSysLoadChartData.points.push({ x, y: sysCpuL > 0 ? sysCpuL * 100 : 0 });
  cpuProcLoadChartData.points.push({ x, y: procCpuL > 0 ? procCpuL * 100 : 0 });
  return newCpuInfo;
}

export function updateInfo(
  data: DeveloperData,
  info?: DeveloperInfo
): DeveloperInfo {
  const { cpuStat: cpuReqData, memStat: memReqData } = data;
  const points: Point[] = Array.from({ length: 24 }, (_, i) => i + 1).map(
    (i) => ({ x: i, y: 0 })
  );

  const { used: usedHeap, committed: hc, init: hi, max: hm } = memReqData.heap;
  const {
    used: usedNonHeap,
    committed: nhc,
    init: nhi,
    max: nhm,
  } = memReqData.nonHeap;

  let heapChartData: ChartData = { count: 24, points: [] };
  let nonHeapChartData: ChartData = { count: 24, points: [] };
  let x = 24;
  if (info && info.memStatistics) {
    x = info.memStatistics.heap.used.points[23].x + 1;
    heapChartData.points = info.memStatistics.heap.used.points.slice(1);
    nonHeapChartData.points = info.memStatistics.nonHeap.used.points.slice(1);
  } else {
    heapChartData.points = fillArray(23);
    nonHeapChartData.points = fillArray(23);
  }

  heapChartData.points.push({ x, y: convertToMb(usedHeap) });
  nonHeapChartData.points.push({ x, y: convertToMb(usedNonHeap) });

  const newHeapInfo: MemoryState = {
    committed: convertToMb(hc),
    init: hi && hi >= 0 ? convertToMb(hi) : null,
    max: hm && hm >= 0 ? convertToMb(hm) : null,
    used: heapChartData,
  };
  const newNonHeapInfo: MemoryState = {
    committed: convertToMb(nhc),
    init: nhi && nhi >= 0 ? convertToMb(nhi) : null,
    max: nhm && nhm >= 0 ? convertToMb(nhm) : null,
    used: nonHeapChartData,
  };

  const newMemoryInfo: MemoryInfo = {
    heap: newHeapInfo,
    nonHeap: newNonHeapInfo,
  };

  const newInfo: DeveloperInfo = {
    memStatistics: newMemoryInfo,
    cpuStatistics: getCpuInfo(cpuReqData, info),
  };
  return newInfo;
}

function parseThreadInfo(thData: ThreadStatData) {
  const { data, ...other } = thData;

  const normalizedData: ThreadDataNormal = {
    ids: [],
    suspended: {},
    lockNames: {},
    lockOwnerNames: {},
    names: {},
    states: {},
  };
  const info: ThreadStatDataInfo = { ...other, data: normalizedData };
  data.forEach((d) => {
    const { id, suspended, lockName, lockOwnerName, name, state } = d;
    console.log(lockName, lockOwnerName);
    normalizedData.ids.push(id);
    normalizedData.suspended[id] = suspended;
    // normalizedData.lockNames[id] = lockName||"";
    // normalizedData.lockNames[id] = lockOwnerName||"";
    normalizedData.names[id] = name;
    normalizedData.states[id] = state;
  });
  return info;
}
