import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { LocaleState } from "../types/locale";
import * as c from "../constants/model";
import { I18NString } from "../types/modal";
import {
  SubjectInfo,
  ProfileState,
  ProfileInfo,
  PageType,
  ModelState,
  SubjectState,
  SubjectData,
  ProfileData,
  TableResult,
  TableRequest,
  FetchSubjectParams,
} from "../types/model";
import { dispatchError, dispatchSuccess } from "../services/alert";
import { ModalResult } from "../components/admin/model/modals/AddCimUploadModal";
const DEFAULT_STATE = {
  offset: 0,
  limit: 20,
  totalNumberOfElements: 0,
};

const initialState: ModelState = {
  profiles: DEFAULT_STATE,
  subjects: DEFAULT_STATE,
};

export const cimmodel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendProfiles(state, profileTableResult: TableResult<ProfileData>) {
      const profiles = profileTableResult;
      const newSubjects: SubjectState = {
        ...state.subjects,
        selectLoading: false,
      };
      newSubjects.profiles = [...profiles.list];
      return { ...state, subjects: newSubjects };
    },
    sendProfilesWithSubjects(state, profiles: ProfileData[]) {
      const newSubjects: SubjectState = {
        ...state.subjects,
        selectLoading: false,
      };
      newSubjects.profiles = [...profiles];
      return { ...state, subjects: newSubjects };
    },
    sendSubjects(
      state,
      payload: {
        subjects: TableResult<SubjectData>;
        classId?: number;
      }
    ) {
      const { subjects, classId } = payload;
      const { list, total } = subjects;
      const newSubjects: SubjectState = { ...state.subjects };
      newSubjects.info = parseSubjects(list);
      newSubjects.totalNumberOfElements = total;
      newSubjects.loading = false;
      newSubjects.classId = classId;
      return { ...state, subjects: newSubjects };
    },
    sendRdfLoading(state, loading: boolean = true) {
      return { ...state, rdfExportLoading: loading };
    },
    sendRdfDeleteLoading(state, loading: boolean = true) {
      return { ...state, rdfDeleteLoading: loading };
    },
    sendLoading(
      state,
      payload: {
        page: PageType;
        loading?: boolean;
      }
    ) {
      const { page, loading: l } = payload;
      const loading = typeof l === "undefined" ? true : l;
      return setupPageField("loading", loading, page, state);
    },
    sendSelectLoading(state, loading: boolean = true) {
      const newSubjects: SubjectState = { ...state.subjects };
      newSubjects.selectLoading = loading;
      return { ...state, subjects: newSubjects };
    },
    sendLimit(state, payload: { limit: number; page: PageType }) {
      const { limit, page } = payload;
      return setupPageField("limit", limit, page, state);
    },
    sendOffset(state, payload: { offset: number; page: PageType }) {
      const { offset, page } = payload;
      return setupPageField("offset", offset, page, state);
    },
    sendSearch(state, payload: { search: string; page: PageType }) {
      const { search, page } = payload;
      return setupPageField("searchInput", search, page, state);
    },
  },
  effects: (dispatch) => ({
    exportRdfProfile: async () => {
      try {
        dispatch.cimmodel.sendRdfLoading();
        await fetchRdfExport();
        dispatch.cimmodel.sendRdfLoading(false);
      } catch (e) {
        dispatch.cimmodel.sendRdfLoading(false);
        dispatchError("MODEL_EDF_EXPORT_ERROR", e, dispatch);
      }
    },
    fetchProfiles: async (data: { tableRequest?: TableRequest }, s) => {
      const { tableRequest } = data;
      try {
        if (
          s &&
          s.cimmodel &&
          s.cimmodel.profiles &&
          s.cimmodel.profiles.loading
        ) {
          return;
        }
        let tableInfo: TableRequest | undefined = tableRequest;

        if (!tableInfo) {
          tableInfo = {};
          tableInfo.limit = 20;
          tableInfo.offset = 0;
          if (s.model && s.cimmodel.profiles) {
            const {
              limit: l,
              offset: o,
              searchInput: sh,
            } = s.cimmodel.profiles;
            tableInfo.limit = l;
            tableInfo.offset = o;
            tableInfo.search = sh;
          }
        }

        dispatch.cimmodel.sendLoading({ page: "profile" });
        dispatch.cimmodel.sendProfiles(await fetchProfilesImpl(tableInfo));
      } catch (e) {
        dispatch.cimmodel.sendLoading({ page: "profile", loading: false });
        dispatchError("MODEL_PROFILES_FETCH_ERROR", e, dispatch);
      }
    },
    fetchProfilesContainingSubjects: async (_: void, s) => {
      try {
        if (
          s &&
          s.cimmodel &&
          s.cimmodel.profiles &&
          s.cimmodel.profiles.loading
        ) {
          return;
        }

        dispatch.cimmodel.sendSelectLoading();
        dispatch.cimmodel.sendProfilesWithSubjects(
          await fetchProfilesContainingSubjectsImpl()
        );
      } catch (e) {
        dispatch.cimmodel.sendSelectLoading(false);
        dispatchError("MODEL_PROFILES_FETCH_ERROR", e, dispatch);
      }
    },
    exportClass: async (
      data: {
        id: number;
        fullName?: string;
      },
      s
    ) => {
      const { id, fullName } = data;
      try {
        await fetchRdfExportSingleClass(id, fullName);
      } catch (e) {
        dispatch.cimmodel.sendSelectLoading(false);
        dispatchError("MODEL_PROFILE_EXPORT_CLASS_ERROR", e, dispatch);
      }
    },
    fetchSubjectsByClassId: async (data: FetchSubjectParams, s) => {
      const { id, tableRequest } = data;
      try {
        if (
          s &&
          s.cimmodel &&
          s.cimmodel.subjects &&
          s.cimmodel.subjects.loading
        ) {
          return;
        }
        let tableInfo: TableRequest | undefined = tableRequest;
        if (!tableInfo) {
          tableInfo = { limit: 20, offset: 0, search: "" };
        }

        dispatch.cimmodel.sendLimit({
          limit: tableInfo.limit || 20,
          page: "subject",
        });
        dispatch.cimmodel.sendOffset({
          offset: tableInfo.offset || 0,
          page: "subject",
        });
        dispatch.cimmodel.sendSearch({
          search: tableInfo.search || "",
          page: "subject",
        });

        dispatch.cimmodel.sendLoading({ page: "subject" });
        dispatch.cimmodel.sendSubjects({
          subjects: await fetchSubjectsByClassIdImpl(tableInfo, id),
          classId: id,
        });
      } catch (e) {
        dispatch.cimmodel.sendLoading({ page: "subject", loading: false });
        dispatchError("MODEL_SUBJECTS_FETCH_ERROR", e, dispatch);
      }
    },
    importSubject: async (
      data: {
        file: FormData;
        result: ModalResult;
      },
      s
    ) => {
      const { file, result } = data;
      const name: string = (file.get("file") as any)["name"];
      const dotIndex = name.lastIndexOf(".") + 1;
      const ext = name.slice(dotIndex, name.length);

      let format = null;
      if (ext === "xlsx") {
        format = "xlssubjects";
      } else if (ext === "json") {
        format = "jsonsubjects";
      } else if (ext === "xml" || ext === "rdf") {
        format = "rdfsubjects";
      } else if (ext === "zip" || ext === "rar" || ext === "7z") {
        format = "zipsubjects";
      }
      if (!format) {
        dispatchError(
          "SUBJECT_UPLOAD_WRONG_TYPE",
          "unknown file format",
          dispatch
        );
        return;
      }
      if (result && result.withHistory) {
        try {
          await fetchRdfImportWithHistory(file, result.data);
          const model = s.cimmodel;
          if (model && model.subjects && model.subjects.classId) {
            dispatch.cimmodel.fetchSubjectsByClassId({
              id: model.subjects.classId,
            });
          }
          dispatchSuccess("SUBJECT_UPLOAD_SUCCESS", dispatch);
        } catch (e) {
          dispatchError("SUBJECT_UPLOAD_ERROR", e, dispatch);
        }
      } else {
        try {
          await fetchRdfImport(file, format);
          const model = s.cimmodel;
          if (model && model.subjects && model.subjects.classId) {
            dispatch.cimmodel.fetchSubjectsByClassId({
              id: model.subjects.classId,
            });
          }
          dispatchSuccess("SUBJECT_UPLOAD_SUCCESS", dispatch);
        } catch (e) {
          dispatchError("SUBJECT_UPLOAD_ERROR", e, dispatch);
        }
      }
    },
    setOffset: (
      data: {
        offset: number;
        page: PageType;
      },
      s
    ) => {
      const { offset, page } = data;
      dispatch.cimmodel.sendOffset({ offset, page });
    },
    setProfilesLimit: (limit: number, s) => {
      const modelState = s.model;
      if (!modelState) {
        return;
      }
      let tableInfo: TableRequest = { offset: 0, limit: limit };
      dispatch.cimmodel.sendLimit({ limit, page: "profile" });
      dispatch.cimmodel.fetchProfiles({ tableRequest: tableInfo });
    },
    setSubjectLimit: (
      data: {
        limit: number;
        classId?: number;
      },
      s
    ) => {
      const { limit, classId } = data;
      const modelState = s.cimmodel;
      if (!modelState) {
        return;
      }
      let tableInfo: TableRequest = { offset: 0, limit: limit };
      dispatch.cimmodel.sendLimit({ limit, page: "subject" });
      dispatch.cimmodel.fetchSubjectsByClassId({
        tableRequest: tableInfo,
        id: classId,
      });
    },
    setProfilesSearch: (value: string, s) => {
      const modelState = s.cimmodel;
      if (!modelState) {
        return;
      }
      let tableInfo: TableRequest = {
        offset: 0,
        search: value,
        limit: (modelState.profiles && modelState.profiles.limit) || 20,
      };
      dispatch.cimmodel.sendSearch({ search: value, page: "profile" });
      dispatch.cimmodel.fetchProfiles({ tableRequest: tableInfo });
    },
    setSubjectsSearch: (
      data: {
        value: string;
        classId?: number;
      },
      s
    ) => {
      const { value, classId } = data;
      const modelState = s.cimmodel;
      if (!modelState) {
        return;
      }
      let tableInfo: TableRequest = {
        offset: 0,
        search: value,
        limit: (modelState.subjects && modelState.subjects.limit) || 20,
      };
      dispatch.cimmodel.sendSearch({ search: value, page: "subject" });
      dispatch.cimmodel.fetchSubjectsByClassId({
        id: classId,
        tableRequest: tableInfo,
      });
    },
    deleteClassesModal: async (_: void, state) => {
      const model = state.cimmodel;
      if (model && model.profiles && model.profiles.info) {
        const info = model.profiles.info;
        const deletedClass: number[] = [];
        Object.entries(info.checked).forEach((e) => {
          const [fullName, checked] = e;
          if (checked) {
            deletedClass.push(info.ids[fullName]);
          }
        });
        if (deletedClass.length !== 0) {
          try {
            dispatch.cimmodel.sendRdfDeleteLoading();
            await Promise.resolve(fetchDeleteClassBatchImpl(deletedClass));
            dispatch.cimmodel.fetchProfiles({});
            dispatch.cimmodel.sendRdfDeleteLoading(false);
            dispatchSuccess("MODEL_PROFILE_REMOVE_CLASS_SUCCESS", dispatch);
          } catch (e) {
            dispatch.cimmodel.sendRdfDeleteLoading(false);
            dispatchError("MODEL_PROFILE_REMOVE_CLASS_ERROR", e, dispatch);
          }
        }
      }
    },
  }),
});

const parseSubjects = (subjects: SubjectData[]) => {
  const subjectInfo: SubjectInfo = {
    ids: [],
    classIds: {},
    classLabels: {},
    descriptions: {},
    fragmentLabels: {},
    labels: {},
    namespacePrefixes: {},
    rdfIds: {},
    checked: {},
  };
  subjects.forEach((s) => {
    const {
      id,
      label,
      rdfId,
      classId,
      classLabel,
      description,
      fragmentLabel,
      namespacePrefix,
    } = s;
    subjectInfo.ids.push(id);
    subjectInfo.classIds[id] = classId;
    subjectInfo.classLabels[id] = classLabel;
    subjectInfo.descriptions[id] = description;
    subjectInfo.fragmentLabels[id] = fragmentLabel;
    subjectInfo.labels[id] = label;
    subjectInfo.namespacePrefixes[id] = namespacePrefix;
    subjectInfo.rdfIds[id] = rdfId;
    subjectInfo.checked[id] = false;
  });

  return subjectInfo;
};

type Field = keyof ProfileState;
type ProfileInfoField = keyof ProfileInfo;
type SubjectInfoField = keyof SubjectInfo;

const setupPageField = (
  fieldName: Field,
  value: any,
  page: PageType,
  state: ModelState
) => {
  if (page === "profile") {
    const newProfiles: ProfileState = { ...state.profiles, [fieldName]: value };
    return { ...state, profiles: newProfiles };
  } else if (page === "subject") {
    const newSubjects: SubjectState = { ...state.subjects, [fieldName]: value };
    return { ...state, subjects: newSubjects };
  }
  return state;
};
const setupPageInfoField = (
  fieldName: ProfileInfoField | SubjectInfoField,
  value: any,
  id: string,
  page: PageType,
  state: ModelState
) => {
  if (page === "profile" && state.profiles && state.profiles.info) {
    const profileF = fieldName as ProfileInfoField;
    const checkedObj = { ...state.profiles.info[profileF], [id]: value };
    const newInfo: ProfileInfo = {
      ...state.profiles.info,
      [fieldName]: checkedObj,
    };
    const newProfiles: ProfileState = { ...state.profiles, info: newInfo };
    return { ...state, profiles: newProfiles };
  } else if (page === "subject" && state.subjects && state.subjects.info) {
    const subjectF = fieldName as SubjectInfoField;
    const checkedObj = { ...state.subjects.info[subjectF], [id]: value };
    const newInfo: SubjectInfo = {
      ...state.subjects.info,
      [fieldName]: checkedObj,
    };
    const newSubjects: SubjectState = { ...state.subjects, info: newInfo };
    return { ...state, subjects: newSubjects };
  }
  return state;
};

export async function fetchDeleteClassBatchImpl(ids: number[]) {
  const response = await fetch(
    `${c.PROFILE_REMOVE_CLASS_BATCH_URL}?classids=${ids.join(",")}`,
    {
      method: "DELETE",
    }
  );
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
}
export async function fetchRdfExport(packagesIds?: number[]): Promise<void> {
  // const search = `?package=${JSON.stringify(packagesIds)}`;
  const search = packagesIds ? `?package=${packagesIds.join(",")}` : "";
  const url = `${c.PROFILE_EXPORT_RDF_URL}${search}`;
  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json;charset=UTF-8" },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  const rdf = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(rdf);
  const contentDesp = response.headers.get("Content-Disposition");
  let filename = "rdfmodel.xml";
  if (contentDesp) {
    filename = contentDesp.split("filename=")[1].replace(/\+/g, " ");
    filename = filename.slice(1, filename.length);
  }
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}
async function fetchRdfExportSingleClass(
  classId: number,
  fullName?: string
): Promise<void> {
  const response = await fetch(
    `/rest/rdfmodel/export/class?classId=${classId}`
  );
  if (!response.ok) {
    const { status, statusText } = response;
    const cause = await response.text();
    throw new ServerError(status, cause);
  }
  const rdf = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(rdf);
  const contentDesp = response.headers.get("Content-Disposition");
  let filename = "class.xml";
  if (fullName) {
    const contaonsSemi = fullName.includes(":");
    filename = contaonsSemi ? fullName.replace(":", ".") : fullName;
    filename += ".xml";
  }
  if (contentDesp) {
    filename = contentDesp.split("filename=")[1].replace(/\+/g, " ");
    filename = filename.slice(1, filename.length - 1);
  }
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}

async function fetchRdfImport(file: any, format: string) {
  const response = await fetch(`/rest/rdfmodel/import/${format}`, {
    method: "POST",
    body: file,
  });

  const { status, statusText } = response;
  if (!response.ok) {
    const cause = await response.text();
    throw new ServerError(status, cause);
  }
}
async function fetchRdfImportWithHistory(file: FormData, options: any) {
  file.append("options", JSON.stringify(options));
  const response = await fetch(`/rest/rdfmodel/import/zipsubjectshistory`, {
    method: "POST",
    body: file,
  });

  const { status, statusText } = response;
  if (!response.ok) {
    const cause = await response.text();
    throw new ServerError(status, cause);
  }
}
async function fetchProfilesImpl(
  tableInfo: any
): Promise<TableResult<ProfileData>> {
  let url = "/rest/profileeditor/profiles";
  let query: string[] = [];
  if (tableInfo) {
    for (const k of Object.keys(tableInfo)) {
      const v = tableInfo[k];
      if (v === undefined || String(v).trim().length === 0) {
        continue;
      }
      query.push(`${k}=${v}`);
    }
  }
  if (query.length > 0) {
    url += `?${query.join("&")}`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  const profiles = await response.json();
  return profiles;
}
async function fetchProfilesContainingSubjectsImpl(): Promise<ProfileData[]> {
  let url = "/rest/profileeditor/profiles/subjects";

  const response = await fetch(url);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  const profiles = await response.json();
  return profiles;
}

async function fetchSubjectsByClassIdImpl(
  tableInfo: any,
  id?: number
): Promise<TableResult<SubjectData>> {
  let url = `/rest/subject/list`;
  let query: string[] = [];
  if (tableInfo) {
    for (const k of Object.keys(tableInfo)) {
      const v = tableInfo[k];
      if (v === undefined || String(v).trim().length === 0) {
        continue;
      }
      query.push(`${k}=${v}`);
    }
  }
  if (query.length > 0) {
    url += `?${query.join("&")}`;
    id && (url += `&classId=${id}`);
  } else if (id) {
    url += `?classId=${id}`;
  }

  const response = await fetch(url);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  const subjects = await response.json();
  return subjects;
}
