import { Action } from 'redux';

import { FetchError } from "./error";
import * as constants from '../constants/db';

export interface DbInfo {
    name: string,
    dbUrl: string,
    dbUsername: string,
    dbPassword: string
}

///////////
//Actions//
///////////
export interface SendDbInfo extends Action {
    type: constants.SEND_DB_INFO;
    payload: {
        dbInfo: DbInfo;
    }
}

export interface SendDbInfoError extends Action {
    type: constants.SEND_DB_INFO_ERROR;
    payload: {
        error: FetchError;
    }
}

export interface SendDbInfoLoading extends Action {
    type: constants.SEND_DB_INFO_LOADING;
}

export interface SendDbInfoChange extends Action {
    type: constants.SEND_DB_INFO_CHANGE;
    payload: {
        field: keyof DbInfo;
        value: any
    }
}

export interface SendDbChecked extends Action {
    type: constants.SEND_DB_CHECKED;
    payload: {
        checked: DbInfo;
    }
}

export interface SendDbIsChecking extends Action {
    type: constants.SEND_DB_IS_CHECKING;
}

export interface SendDbCheckClear extends Action {
    type: constants.SEND_DB_CHECK_CLEAR;
}

export interface SendDbCheckError extends Action {
    type: constants.SEND_DB_CHECK_ERROR;
    payload: {
        error: FetchError;
    }
}

export type DbAction = SendDbInfo | SendDbInfoError | SendDbInfoLoading | SendDbInfoChange
    | SendDbChecked | SendDbIsChecking | SendDbCheckError | SendDbCheckClear;

/////////
//State//
/////////
export interface DbState {
    //User list
    loading?: boolean,
    dbInfo?: DbInfo | FetchError,
    checking?: boolean,
    checked?: DbInfo | FetchError
}

export function isDbInfo(info: DbInfo | FetchError | undefined): info is DbInfo {
    return typeof info != 'undefined' && typeof (info as DbInfo).dbUrl == 'string';
}

export function isFetchError(info: DbInfo | FetchError | undefined): info is FetchError {
    return typeof info != 'undefined' && typeof (info as FetchError).message == 'string';
}

