import moment from "moment";
import React, { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import { FormattedMessage } from "react-intl";
import { TextInputValue } from "../../types/subject";
import DateTimePicker from "../datetimepicker/DateTimePicker";
import debounce from "./debounce";
import { fromRedux, toRedux, validate } from "./formatvalidator.js";
import { isEmptyObject } from "../../services/app";

const DEBOUNCE_INPUT_INVALID_FLOAT_FORMAT = (
  <FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_FLOAT"
    defaultMessage="Invalid float format"
    description="Input contains invalid float format"
  />
);

const DEBOUNCE_INPUT_INVALID_INTEGER_FORMAT = (
  <FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_INTEGER"
    defaultMessage="Invalid integer format"
    description="Input contains invalid integer format"
  />
);

const DEBOUNCE_INPUT_INVALID_DATE_FORMAT = (
  <FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_DATE"
    defaultMessage="Invalid date format"
    description="Input contains invalid date format"
  />
);

const DEBOUNCE_INPUT_INVALID_DATE_TIME_FORMAT = (
  <FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_DATE_TIME"
    defaultMessage="Invalid date/time format"
    description="Input contains invalid date/time format"
  />
);

const toolbarButtonStyle = {
  padding: "2px 8px",
};

interface ToolBtn {
  enabled?: boolean;
  icon?: string;
  iconStyle?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

interface DebounceInputCommonProps {
  editable?: boolean;
  valid?: boolean;
  focus?: boolean;
  password?: boolean;
  placeholder?: string;
  size?: string;
  options?: {};
  debounce?: number;
  className?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  /**Force update on value parameter change */
  updateOnChange?: boolean;
  tool?: ToolBtn | ToolBtn[] | null;
  debounceFactory?: (callback: () => void) => () => void;
  signalInvalidFormat?: (error: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}
interface DebounceInputNumberProps extends DebounceInputCommonProps {
  value?: number | null;
  format?: "float" | "double" | "integer" | "int";
  onEnter?: (value: number) => void;
  onFinish?: (value: number) => void;
  change?: (value: number) => void;
}
interface DebounceInputNumberWithNullProps extends DebounceInputNumberProps {
  onEnter?: (value: number | {}) => void;
  onFinish?: (value: number | {}) => void;
  change?: (value: number | {}) => void;
  withNull: true;
}
interface DebounceInputStringProps extends DebounceInputCommonProps {
  value?: string | TextInputValue | number | null;
  format?: "date" | "dateTime" | string;
  options?: { milliseconds?: boolean; multiline?: boolean; rows?: number };
  onEnter?: (value: string) => void;
  onFinish?: (value: string) => void;
  change?: (value: string | {}) => void;
}
type DebounceInputProps =
  | DebounceInputNumberProps
  | DebounceInputNumberWithNullProps
  | DebounceInputStringProps;

export function isNumberFormat(
  format: string
): format is "float" | "double" | "integer" | "int" {
  return (
    format === "float" ||
    format === "double" ||
    format === "integer" ||
    format === "int"
  );
}

function isNumberInput(
  props: DebounceInputCommonProps
): props is DebounceInputNumberProps {
  const format = (props as any).format;
  const value = (props as any).value;
  return isNumberFormat(format) || typeof value === "number";
}

function isNumberWithNullInput(
  props: DebounceInputCommonProps
): props is DebounceInputNumberWithNullProps {
  return isNumberInput(props) && (props as any).withNull;
}

interface DebounceInputState {
  /**Flag for debounce to force update curent value */
  updateValue: boolean;
}

class DebounceInput extends React.Component<
  DebounceInputProps,
  DebounceInputState
> {
  private parentInput: any;
  private debounceFunc: any;
  private syncValue: any;
  private wasChanged?: boolean;
  textInput?: any;

  constructor(props: DebounceInputProps) {
    super(props);

    this.state = {
      updateValue: false,
    };

    if (this.props.value) {
      this.changeSyncValue(this.props.value);
    }

    this.delayedHandleChange = this.delayedHandleChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeSyncValue = this.changeSyncValue.bind(this);
    this.blur = this.blur.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  finish(isEnterEvent?: boolean) {
    if (this.debounceFunc) {
      this.debounceFunc.flush(); //Send change on finish
    }
    if (this.props.onFinish) {
    }
    const prevValue = this.getValue();
    const result = validate(
      this.syncValue,
      this.props.format,
      this.props.options
    );
    if (!result.valid) {
      return;
    }
    if (isNumberWithNullInput(this.props)) {
      const value =
        typeof result.value !== "object" ? parseFloat(result.value) : {};
      if (result.value != prevValue && this.props.onFinish) {
        this.props.onFinish(value);
      }
      if (isEnterEvent && this.props.onEnter) {
        this.props.onEnter(value);
      }
    } else if (isNumberInput(this.props)) {
      const value =
        typeof result.value !== "object" ? parseFloat(result.value) : 0;
      if (result.value != prevValue && this.props.onFinish) {
        this.props.onFinish(value);
      }
      if (isEnterEvent && this.props.onEnter) {
        this.props.onEnter(value);
      }
    } else {
      if (result.value != prevValue && this.props.onFinish) {
        this.props.onFinish(result.value);
      }
      if (isEnterEvent && this.props.onEnter) {
        this.props.onEnter(result.value);
      }
    }
  }

  blur(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const { onBlur } = this.props;
    this.finish();

    if (onBlur) {
      onBlur();
    }
  }

  keyDown(event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (event.key == "Enter") {
      this.finish(true);
      // event.preventDefault();
    }
  }

  getValue() {
    return fromRedux(this.props.value, this.props.format, this.props.options);
  }

  debounce() {
    //Create debounce function on demand
    if (!this.debounceFunc) {
      if (this.props.debounceFactory) {
        //use supplied debounce factory
        this.debounceFunc = this.props.debounceFactory(
          this.delayedHandleChange
        );
      } else {
        //use default debounce implementaion
        const debounceTime =
          typeof this.props.debounce === "undefined"
            ? 200
            : this.props.debounce;
        this.debounceFunc = debounce(
          this.delayedHandleChange,
          debounceTime,
          false
        );
      }
    }
    if (this.props.editable) {
      //If user is too fast it will cause send data after editable is closed
      this.debounceFunc();
    }
  }

  delayedHandleChange() {
    if (!this) {
      return;
    }
    const { options } = this.props;

    //Context was lost
    if (!this) {
      return;
    }
    // console.log("Text.delayedHandleChange (value, editable): ", this.syncValue, this.props.editable);
    if (!this.props.editable) {
      //Do not send data to redux if element is already not editable
      console.error(
        "DebounceInput.delayedHandleChange() in non-editable mode!!!!"
      );
      return;
    }
    this.wasChanged = true;
    const prevValue = this.getValue();
    const result = validate(
      this.syncValue,
      this.props.format,
      this.props.options
    );
    if (!result.valid) {
      if (this.props.signalInvalidFormat) {
        this.props.signalInvalidFormat((result as any).error);
      }
      return;
    }
    if (!this.props.change) {
      return;
    }
    let value = toRedux(result.value, this.props.format, this.props.options);

    if (isNumberWithNullInput(this.props)) {
      value = typeof value === "string" ? parseFloat(value) : {};
    } else if (isNumberInput(this.props)) {
      value = typeof value === "string" ? parseFloat(value) : 0;
    }
    if (prevValue === value && this.props.valid) {
      //Do not send events if everything is equal
      return;
    }

    this.props.change(value);
  }

  handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.changeSyncValue(event.target.value);
  }

  changeSyncValue(value?: string | number | TextInputValue | null | {}) {
    //Save value in class property so that changes will be synchronious!!!!
    if (
      typeof value === "undefined" ||
      value === null ||
      isEmptyObject(value)
    ) {
      this.syncValue = "";
    } else {
      this.syncValue = value.toString() || "";
    }
    this.debounce(); //Schedule later redux update
  }

  willReceivePropsLegacy(
    nextProps: DebounceInputProps,
    props: DebounceInputProps
  ) {
    if (!this.textInput) {
      return;
    }
    const nextValue = fromRedux(
      nextProps.value,
      nextProps.format,
      props.options
    );
    if (nextProps.editable) {
      if (!props.editable) {
        //Set flag that data was not changed (user did not typed anything)
        this.wasChanged = false;
        //Update state in transition to editable mode
        //We also update DOM because editable is changed in the same time as predicates when obtaining lock
        this.textInput.value = this.syncValue = nextValue;
      } else if (!this.wasChanged && this.textInput.value != nextValue) {
        //Text was changed by fill
        this.textInput.value = this.syncValue = nextValue;
        console.log("Changed by fill: ", this.syncValue);
      }
    } else if (
      !nextProps.editable &&
      this.textInput &&
      this.textInput.value != nextValue
    ) {
      //Update DOM in non-editable mode (not as event but always for automation to work)
      this.textInput.value = nextValue;
    }
  }

  componentDidUpdate(prevProps: DebounceInputProps) {
    this.willReceivePropsLegacy(this.props, prevProps);
    /**Handle force update of input value */

    if (!this.props.updateOnChange) {
      return;
    }

    if (this.state.updateValue) {
      /**Reset force update flag after single render */
      this.changeSyncValue(this.props.value);
      this.setState({ ...this.state, updateValue: false });
      return;
    } else if (this.props.value !== prevProps.value) {
      /**Set force value update flag */
      this.setState({ ...this.state, updateValue: true });
    }
  }

  getPlaceholder() {
    if (this.props.placeholder) {
      return this.props.placeholder;
    }
    if (this.props.format == "float") {
      return "0.0";
    }
    if (this.props.format == "integer" || this.props.format == "int") {
      return "0";
    }
    return "";
  }

  getStyle(style?: React.CSSProperties) {
    if (!style) {
      style = {};
    }
    style.textAlign = "left";
    return style;
  }
  onFocus = () => {
    const { options, onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }
  };
  getLabel() {
    if (this.props.children) {
      return this.props.children;
    }
    return null;
  }
  getTextArea = (rows?: number) => {
    const style = this.getStyle(this.props.style);
    const value = this.getValue();
    let forceValue;
    if (this.state.updateValue) {
      forceValue = value;
    }
    return (
      <textarea
        rows={rows || 3}
        defaultValue={value}
        value={forceValue}
        ref={(input) => (this.textInput = input)}
        onChange={this.handleChange}
        onBlur={this.blur}
        autoFocus={this.props.focus}
        onKeyDown={this.keyDown}
        style={style}
        placeholder={this.getPlaceholder()}
        className={this.props.className ? this.props.className : ""}
        disabled={!this.props.editable}
      />
    );
  };
  getTextInput() {
    let type = "text";
    const { options } = this.props;
    let autocomplete;
    if (this.props.password) {
      type = "password";
      autocomplete = "new-password";
    }
    const style = this.getStyle(this.props.style);
    const value = this.getValue();
    let forceValue;
    if (this.state.updateValue) {
      forceValue = value;
    }

    //We use this hack with uncontrolled input to overcome IE problems:
    //updates from state are delayed because updates are handled in "another thread".
    //https://github.com/omcljs/om/issues/704
    return (
      <input
        autoComplete={autocomplete}
        type={type}
        onFocus={this.onFocus}
        defaultValue={value}
        value={forceValue}
        ref={(input) => (this.textInput = input)}
        onChange={this.handleChange}
        onBlur={this.blur}
        onKeyDown={this.keyDown}
        autoFocus={this.props.focus}
        style={style}
        placeholder={this.getPlaceholder()}
        className={this.props.className ? this.props.className : ""}
        disabled={!this.props.editable}
      />
    );
  }
  getDateInput(displayTime?: boolean) {
    const style = this.getStyle(this.props.style);
    /* Get value without formatting since datetime picker do parsing too */
    const value = fromRedux(this.props.value);
    const onDateChange = (date: Date | null) => {
      if (!date) {
        this.changeSyncValue("");
        return;
      }
      let format = "L";
      if (displayTime) {
        format += " HH:mm:ss";
      }
      date !== null && this.changeSyncValue(moment(date).format(format));
    };
    return (
      <DateTimePicker
        className={this.props.className ? this.props.className : ""}
        style={style}
        date={value}
        displayTime={displayTime}
        focus={this.props.focus}
        disabled={!this.props.editable}
        getCurrentDate={onDateChange}
        calendarPosition="fixed"
      />
    );
  }
  getInput() {
    if (!isNumberInput(this.props) && this.props?.options?.multiline) {
      return this.getTextArea(this.props?.options?.rows || 3);
    }
    return this.getTextInput();
  }
  getToolButton() {
    if (!this.props.tool) {
      return null;
    }
    let toolBtns = Array.isArray(this.props.tool)
      ? this.props.tool
      : [this.props.tool];
    return (
      <div className="input-group-append">
        {toolBtns.map((tool, idx) => (
          <button
            key={idx}
            type="button"
            disabled={!this.props.editable && !tool.enabled}
            style={toolbarButtonStyle}
            className={tool.className || "input-group-text btn btn-secondary"}
            onClick={tool.onClick}
          >
            <i
              className={`fa fa-${tool.icon}`}
              style={tool.iconStyle}
              aria-hidden="true"
            ></i>
          </button>
        ))}
      </div>
    );
  }

  render() {
    if (this.props.format === "date") {
      return this.getDateInput();
    }
    if (this.props.format === "dateTime") {
      return this.getDateInput(true);
    }
    const label = this.getLabel();
    const tool = this.getToolButton();
    if (!label && !tool) {
      return this.getInput();
    }
    return (
      <div
        className={`input-group input-group-${
          this.props.size ? this.props.size : "sm"
        }`}
        style={this.props.containerStyle}
      >
        {label}
        {this.getInput()}
        {tool}
      </div>
    );
  }
}

export default DebounceInput;
