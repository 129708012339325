import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { changeConfigImpl, fetchConfigInfoImpl } from "../services/config";
import {
  ConfigActionError,
  ConfigActionResult,
  ConfigState,
  NormalizedConfiguration,
  StaticConfiguration,
} from "../types/config";
import { FetchError } from "../types/error";

const initialState: ConfigState = {};

export const config = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendConfigInfo(state, configuration: StaticConfiguration) {
      return {
        ...state,
        loading: false,
        configuration: parseConfiguration(configuration),
      };
    },
    sendConfigInfoError(state, error: FetchError) {
      return { ...state, loading: false, configuration: error };
    },
    sendConfigClearInfo(state) {
      return { ...state, configuration: undefined };
    },
    sendConfigInfoLoading(state) {
      return { ...state, loading: true };
    },
    sendConfigActionResult(state, result: ConfigActionResult) {
      return { ...state, lastAction: result };
    },
    sendConfigActionError(state, error: ConfigActionError) {
      return { ...state, lastAction: error };
    },
    sendConfigClearAction(state) {
      return { ...state, lastAction: undefined };
    },
  },
  effects: (dispatch) => ({
    fetchConfigInfo: async (_: void, s) => {
      try {
        if (s && typeof s.config != "undefined" && s.config.loading) {
          return;
        }
        dispatch.config.sendConfigInfoLoading();
        dispatch.config.sendConfigInfo(await fetchConfigInfoImpl());
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.config.sendConfigInfoError({
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatch.config.sendConfigInfoError({ code: -1, message: e.message });
        } else {
          dispatch.config.sendConfigInfoError({
            code: -1,
            message: "Unknown error",
          });
        }
      }
    },
    changeConfig: async (r: ConfigActionResult) => {
      try {
        await changeConfigImpl(r);
        dispatch.config.sendConfigActionResult(r);
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.config.sendConfigActionError({
            code: e.code,
            message: e.message,
            action: r.action,
            address: r.address,
          });
        } else if (typeof e.message == "string") {
          dispatch.config.sendConfigActionError({
            code: -1,
            message: e.message,
            action: r.action,
            address: r.address,
          });
        } else {
          dispatch.config.sendConfigActionError({
            code: -1,
            message: "Unknown error",
            action: r.action,
            address: r.address,
          });
        }
      }
    },
    /*TODO:
export function startConfig(address: string): ThunkAction<void, ApplicationState, {}, ConfigAction> {
    return changeConfig({ action: "start", address });
}

export function stopConfig(address: string): ThunkAction<void, ApplicationState, {}, ConfigAction> {
    return changeConfig({ action: "stop", address });
}

export function blockConfig(address: string): ThunkAction<void, ApplicationState, {}, ConfigAction> {
    return changeConfig({ action: "block", address });
}

export function switchManual(): ThunkAction<void, ApplicationState, {}, ConfigAction> {
    return changeConfig({ action: "manual" });
}

export function switchAuto(): ThunkAction<void, ApplicationState, {}, ConfigAction> {
    return changeConfig({ action: "auto" });
}
*/
  }),
});

export function parseConfiguration(
  configuration: StaticConfiguration
): NormalizedConfiguration {
  const result: NormalizedConfiguration = {
    clustered: configuration.clustered,
    nodeId: configuration.nodeId,
    nodeList: configuration.nodeList,
    nodeMap: configuration.nodeMap,
    nodeHost: "",
    nodePort: 0,
    deployments: [],
  };

  const selfConfiguration = configuration.nodeMap[result.nodeId];
  if (typeof selfConfiguration == "undefined") {
    return result;
  }
  result.deployments = selfConfiguration.deployments;
  result.nodeHost = selfConfiguration.nodeHost;
  result.nodePort = selfConfiguration.nodePort;
  result.state = selfConfiguration.state;

  return result;
}
