import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import {
  AlertInfo,
  AlertLevelType,
  AlertOptions,
  AlertState,
} from "../types/alert";
import { I18NString } from "../types/modal";

const initialState: AlertState = { alertList: [] };

export const alert = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendAddAlert(
      state,
      payload: {
        id: string;
        type: AlertLevelType;
        message: string | I18NString;
        options?: { icon?: string; dismissable?: boolean; html?: boolean };
      }
    ) {
      const { id, type, message, options } = payload;
      const alertList: AlertInfo[] = [...state.alertList, payload];
      return { ...state, alertList };
    },
    sendRemoveAlert(state, id: string) {
      const alertList = state.alertList.filter((alert) => alert.id != id);
      return { ...state, alertList };
    },
  },
  effects: (dispatch) => ({
    addAlert: (
      data: {
        type: AlertLevelType;
        message: string | I18NString;
        options?: AlertOptions;
      },
      state
    ) => {
      const { type, message, options } = data;
      dispatch.alert.sendAddAlert({
        id: shortid.generate(),
        type,
        message,
        options,
      });
    },
  }),
});
