import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { getNavigatorLanguage } from "..";
import { LocaleState } from "../types/locale";
import { VersionState } from "../types/version";
export const DEFAULT_VERSION_STATE: VersionState = {
  branch: (window as any).__npt_version__?.branch,
  hash: (window as any).__npt_version__?.hash,
  version: (window as any).__npt_version__?.version,
  timestamp: (window as any).__npt_version__?.timestamp,
};

export const version = createModel<RootModel>()({
  state: DEFAULT_VERSION_STATE,
  reducers: {},
  effects: (dispatch) => ({}),
});
