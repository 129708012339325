import {
  faChevronRight,
  faExclamationTriangle,
  faFolder,
  faFolderOpen,
  faPencilAlt,
  faSpinner,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { CSSProperties } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { NodeDecorations, NodeProps, TreeProps } from "../../types/tree";
import { toParams } from "../../services/location";
import { retrieveFunction } from "../../services/automation";

import styles from "./ListTree.module.css";

const TREE_ID_SUFFIX = ".tree";
export const getTreeCtxMenuId = (id: string) => `${id}${TREE_ID_SUFFIX}`;
export const getTreeId = (id: string) => {
  if (id.includes(TREE_ID_SUFFIX)) {
    return id.replace(TREE_ID_SUFFIX, "");
  }
  return null;
};

export const MenuHeader = (props: {
  menuId: string;
  isDragging?: boolean;
  collect: () => any;
  children: any;
}) => (
  <ContextMenuTrigger
    holdToDisplay={-1}
    id={props.menuId}
    collect={props.collect}
    disable={props.isDragging ? true : false}
    renderTag="div"
    attributes={{ style: { display: "inline-block", width: "100%" } }}
  >
    {props.children}
  </ContextMenuTrigger>
);

export const DragHeader = (props: {
  id: string;
  dragType: string;
  data: any;
  isSuperUser?: boolean;
  drop: (data: object, isCopy: boolean) => void;
  children: (isDragging: boolean) => any;
}) => {
  const [collectedProps, drag] = useDrag({
    type: props.dragType,
    item: { ...props.data, type: props.dragType },
    end: (item, monitor) => {
      if (props.data.copyLock && props.data.moveLock && !props.isSuperUser) {
        return false;
      }
      const dropResult = monitor.getDropResult() as {
        dropEffect: "move" | "copy";
        isListTree?: boolean;
        targetId: string;
      };
      if (!dropResult || !dropResult.isListTree) {
        return;
      }
      props.drop(dropResult, dropResult.dropEffect === "copy");
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
    canDrag: (monitor) => {
      return true;
    },
  });
  return (
    <div ref={drag} style={{ display: "inline-block", width: "100%" }}>
      {props.children(collectedProps.isDragging)}
    </div>
  );
};

export const DropHeader = (props: {
  id: string;
  dropTypes: string[];
  pasteTypes?: string[];
  isSuperUser?: boolean;
  children: any;
  className?: string;
  style?: CSSProperties;
}) => {
  const [collectedProps, drop] = useDrop({
    accept: props.dropTypes,
    drop: (item) => ({ targetId: props.id, isListTree: true }),
    canDrop: (item: any, monitor) => {
      if (item.copyLock && item.moveLock && !props.isSuperUser) {
        return false;
      }
      if (!props.pasteTypes || props.pasteTypes.indexOf(item?.typeId) === -1) {
        return false;
      }
      return true;
    },
    collect: (monitor: DropTargetMonitor) => {
      return {
        hover: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const style = props.style || { display: "inline-block", width: "100%" };
  if (collectedProps.hover && collectedProps.canDrop) {
    style.border = "1px dashed #ccc";
  }
  return (
    <div className={`drop ${props.className}`} ref={drop} style={style}>
      {props.children}
    </div>
  );
};

export const UploadHeader = (props: {
  upload: (acceptedFiles: any) => void;
  acceptFormats: string[];
  children: any;
}) => {
  const { children, upload, acceptFormats } = props;
  const { getRootProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      onDropAccepted: upload,
      accept: acceptFormats,
      noDragEventsBubbling: true,
    });

  const color = isDragReject ? "red" : isDragAccept ? "green" : "";
  const border = (color && `1px solid ${color}`) || "";
  const opacity = isDragReject ? 0.8 : isDragAccept ? 0.8 : 1;
  return (
    <div
      className="position-relative rounded"
      style={{ display: "inline", opacity, backgroundColor: color, border }}
      {...getRootProps()}
    >
      {children}
    </div>
  );
};

export interface NodeState {
  //Previous value of inplace. Used to implement edge trigger inplace
  prevInplace: boolean;
  //toggle control if toggle() not defined
  expanded?: boolean;
  //Inplace editor text
  inplace?: string;
}

interface ChildInfo {
  id: string;
  decorations?: NodeDecorations;
}

/**
 * Node of List Tree that could
 * be easily connected to redux store
 *
 * Node contains two parts: toggle link (for branches only) and header link (icon & text).
 *
 * Those parts are incapsulated in list item (li)
 *
 */
export class Node extends React.PureComponent<NodeProps, NodeState> {
  decorations?: NodeDecorations;
  reverseDecorations?: NodeDecorations;
  childDataById: { [k: string]: ChildInfo };
  labelRef: React.RefObject<HTMLSpanElement>;

  constructor(props: NodeProps) {
    super(props);
    this.state = {
      expanded: this.props.expanded, //copy initial value
      prevInplace:
        typeof this.props.inplace == "boolean" ? this.props.inplace : false,
    };
    this.childDataById = {};
    this.labelRef = React.createRef();

    this.collect = this.collect.bind(this);
    this.updateChildrenDecorations = this.updateChildrenDecorations.bind(this);
  }

  /**
   *
   * Implement edge trigger inplace
   *
   * https://ru.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
   *
   * @param props component properties
   * @param state component state
   */
  static getDerivedStateFromProps(props: NodeProps, state: NodeState) {
    const currentInplace =
      typeof props.inplace == "boolean" ? props.inplace : false;
    if (currentInplace !== state.prevInplace) {
      //Edge trigger inplace
      if (currentInplace && props.edgeTriggerInplace) {
        return {
          prevInplace: true,
          inplace: props.name || props.id,
        };
      }
      return {
        prevInplace: currentInplace,
      };
    }
    return null;
  }

  toggle() {
    //Toggle
    const t =
      this.props.toggle || ((expanded: boolean) => this.setState({ expanded }));
    const s = this.isExpanded();
    const l = this.props.loadChildren;
    if (!s && this.props.childrenIds == null && l) {
      l();
    }
    t(!s);
  }

  toggleInplace(value: boolean) {
    if (value) {
      this.setState({ inplace: this.props.name || this.props.id });
    } else {
      const e = this.props.editInplace;
      if (e) {
        e(this.state.inplace || "");
      }
      this.setState({ inplace: undefined });
    }
  }

  contextMenu() {
    //Activate
    const cm = this.props.contextMenu;
    if (cm) {
      cm();
    }
  }

  linkHandle(link: string): void {
    //Handle links location
    const lh = this.props.linkHandle;
    if (lh) {
      lh(link);
    }
  }

  activate() {
    const { data } = this.props;
    //Activate
    const a = this.props.activate;
    if (a) {
      a(data);
    }
  }

  select() {
    //Activate
    const s = this.props.select;
    if (s) {
      s();
    }
  }
  range() {
    //Activate
    const r = this.props.range;
    if (r) {
      r();
    }
  }

  isLeaf() {
    return typeof this.props.childrenIds == "undefined";
  }

  isInplace() {
    return typeof this.state.inplace != "undefined";
  }

  isExpanded() {
    if (this.props.toggle) {
      //controlled externally
      return this.props.expanded || false;
    }
    return this.state.expanded || false; //self controlled
  }

  renderIcon() {
    let { selected, icon, iconColor, loading, error } = this.props;

    if (this.decorations?.icon) {
      icon = this.decorations?.icon;
    }

    if (this.decorations?.iconColor) {
      iconColor = this.decorations?.iconColor;
    }

    if (loading) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          className={`tree-node-spinner mr-2 mt-1`}
        />
      );
    }
    if (error) {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className={`mr-2 text-danger mt-1`}
        />
      );
    }
    if (icon) {
      if (typeof icon !== "string") {
        return icon;
      }
      const style: CSSProperties = this.decorations?.iconStyle || {};
      if (iconColor) {
        style.color = iconColor;
      }
      return (
        <i
          className={`fa ${icon} ${this.props.iconClass || ""} mr-1 mt-1`}
          style={style}
        />
      );
    }
    if (this.isLeaf()) {
      //leaf node
      return (
        <FontAwesomeIcon
          icon={faStickyNote}
          className={`tree-node-leaf mr-2 `}
        />
      );
    }
    if (this.isExpanded()) {
      //expanded branch node
      return (
        <FontAwesomeIcon
          icon={faFolderOpen}
          className={`tree-node-branch mr-2 `}
        />
      );
    }
    //collapsed branch node
    return (
      <FontAwesomeIcon icon={faFolder} className={`tree-node-branch mr-2 `} />
    );
  }

  renderToggle() {
    if (this.isLeaf()) {
      //leaf node
      return null;
    }

    const toggleClass = this.isExpanded() ? styles.toggled : styles.untoggled;
    return (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.toggle();
        }}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className={toggleClass + " tree-node-toggle mr-2"}
        />
      </a>
    );
  }

  renderEditToggle() {
    return (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          this.toggleInplace(true);
        }}
      >
        <FontAwesomeIcon icon={faPencilAlt} className="tree-node-leaf ml-2" />
      </a>
    );
  }

  collect() {
    return this.props.data || { id: this.props.id };
  }

  renderHeaderInplace() {
    return (
      <span>
        {this.renderIcon()}
        <input
          className="form-control w-auto h-auto p-1 d-inline"
          autoFocus
          type="text"
          value={this.state.inplace}
          onChange={(e) => this.setState({ inplace: e.currentTarget.value })}
          onBlur={(e) => this.toggleInplace(false)}
          onKeyUp={(e) => {
            if (e.keyCode == 13) this.toggleInplace(false);
          }}
        />
      </span>
    );
  }

  renderLinkContent() {
    const spanCls = this.props.changed ? "text-warning" : "";
    const style = this.decorations?.textStyle || {};
    if (this.decorations?.color) {
      style.color = this.decorations?.color;
    }
    return (
      <>
        {this.renderIcon()}
        <span className={spanCls} style={style} ref={this.labelRef}>
          {this.props.editing && " • "}
          {this.props.name || this.props.id}
        </span>
      </>
    );
  }
  clickHandler = (e: any) => {
    if (e.ctrlKey) {
      e.preventDefault();
      this.select();
    } else if (e.shiftKey) {
      e.preventDefault();
      this.range();
    } else {
      this.toggle();
      this.activate();
    }
  };

  /**
   * Pure header link (without wrappers)
   */
  renderHeaderLink() {
    const {
      contextPath: ctx,
      pathParam,
      searchParams,
      selected,
      active,
      loading,
      error,
    } = this.props;
    let link;
    let textColor = "";
    if (error && !loading) {
      textColor = "text-danger";
    } else if (
      (selected || active) &&
      !(this.decorations?.color || this.decorations?.textStyle?.color)
    ) {
      textColor = "text-white";
    }
    if (ctx) {
      link = ctx;
      if (pathParam) {
        link += `/${pathParam}`;
      }
      if (searchParams) {
        const search = toParams(searchParams);
        link += search ? `?${toParams(searchParams)}` : "";
      }
    }
    link && this.linkHandle(link);
    if (link) {
      return (
        <Link
          to={link}
          className={`${textColor} d-flex flex-grow-1 align-items-start`}
          onContextMenu={(e) => {
            this.contextMenu();
          }}
        >
          {this.renderLinkContent()}
        </Link>
      );
    }
    return (
      <span
        className={`${textColor} d-flex flex-grow-1 align-items-start`}
        onContextMenu={(e) => {
          this.contextMenu();
        }}
      >
        {this.renderLinkContent()}
      </span>
    );
  }

  /**
   * Header wrapped as menu trigger
   */
  renderMenuHeader(isDragging?: boolean) {
    const menuId = this.props.menuId;
    if (menuId) {
      return (
        <MenuHeader
          menuId={menuId}
          collect={this.collect}
          isDragging={isDragging}
        >
          {this.renderHeaderLink()}
        </MenuHeader>
      );
    }
    return this.renderHeaderLink();
  }

  /**
   * Header wrapped as drag source
   */
  renderDragHeader() {
    const { isSuperUser, uploadTypes, upload, dragType, drop } = this.props;
    if (dragType && drop) {
      return (
        <DragHeader
          id={this.props.id}
          dragType={dragType}
          isSuperUser={isSuperUser}
          drop={drop}
          data={this.collect()}
        >
          {(isDragging) => this.renderMenuHeader(isDragging)}
        </DragHeader>
      );
    }
    return this.renderMenuHeader();
  }
  /**
   * Header wrapped as upload target
   */
  renderUploadHeader() {
    const { uploadTypes, upload } = this.props;
    if (uploadTypes && upload) {
      return (
        <UploadHeader
          // isOverCallback={uploadOver}
          upload={upload}
          acceptFormats={uploadTypes}
        >
          {this.renderDropHeader()}
        </UploadHeader>
      );
    }
    return this.renderDropHeader();
  }

  /**
   * Header wrapped as drop target
   */
  renderDropHeader() {
    const { isSuperUser, dropTypes, pasteTypes } = this.props;
    if (dropTypes) {
      return (
        <DropHeader
          id={this.props.id}
          dropTypes={dropTypes}
          pasteTypes={pasteTypes}
          isSuperUser={isSuperUser}
          className="flex-grow-1"
        >
          <div className="w-100">{this.renderDragHeader()}</div>
        </DropHeader>
      );
    }
    return <div className="flex-grow-1">{this.renderDragHeader()}</div>;
  }

  renderChildren() {
    //do not render children for leafs and not expanded nodes
    if (this.isLeaf() || !this.props.expanded) {
      return null;
    }
    const l = this.props.childrenIds;
    const C = this.props.renderComponent || Node;
    if (!Array.isArray(l) || typeof C == "undefined") {
      return null;
    }
    const { onSelectHandler } = this.props;
    return (
      <ul className={"pl-3 " + styles.unstyled}>
        {l.map((id) => (
          <C
            key={id}
            onSelectHandler={onSelectHandler}
            treeId={this.props.treeId}
            id={id}
            disableSelection={this.props.disableSelection}
            readonly={this.props.readonly}
            menuId={this.props.menuId}
            dragType={this.props.dragType}
            dropTypes={this.props.dropTypes}
            uploadTypes={this.props.uploadTypes}
            pathParam={this.props.pathParam}
            searchParams={this.props.searchParams}
            contextPath={this.props.contextPath}
            renderComponent={this.props.renderComponent}
            selectHandler={this.props.selectHandler}
            scrollToNode={this.props.scrollToNode}
            sendDecorationsToParent={this.updateChildrenDecorations}
          />
        ))}
      </ul>
    );
  }

  updateDecorations() {
    if (!this.props.decorator) {
      if (this.decorations) {
        delete this.decorations;
      }
      return null;
    }
    const decoratorFunction = retrieveFunction(this.props.decorator);
    try {
      return (this.decorations = decoratorFunction(
        this.props.data,
        this.props.active,
        this.props.expanded,
        this.props.treeFilter
      ));
    } catch (e) {
      console.error("Decorator function failed for node:", this.props.data);
      console.error(e);
    }
    return null;
  }

  updateReverseDecorations() {
    if (!this.props.reverseDecorator) {
      if (this.reverseDecorations) {
        delete this.reverseDecorations;
      }
      return;
    }
    const reverseDecoratorFunction = retrieveFunction(
      this.props.reverseDecorator
    );
    try {
      const children = Object.values(this.childDataById).map((child) => ({
        ...child,
        decorations: child.decorations || {},
      }));
      this.reverseDecorations = reverseDecoratorFunction(
        this.props.data,
        this.props.active,
        this.props.expanded,
        this.props.treeFilter,
        children
      );
      this.forceUpdate();
    } catch (e) {
      console.error(
        "Reverse decorator function failed for node:",
        this.props.data
      );
      console.error(e);
    }
  }

  updateChildrenDecorations(nodeId: string, decorations?: NodeDecorations) {
    if (!this.childDataById[nodeId]) {
      if (!decorations) {
        return;
      }
      this.childDataById[nodeId] = {
        id: nodeId,
        decorations,
      };
      this.updateReverseDecorations();
      return;
    }
    if (
      this.childDataById[nodeId].decorations === decorations ||
      JSON.stringify(this.childDataById[nodeId].decorations) ===
        JSON.stringify(decorations)
    ) {
      return;
    }
    this.childDataById[nodeId] = {
      id: nodeId,
      decorations,
    };
    this.updateReverseDecorations();
  }

  componentDidMount() {
    if (this.props.active) {
      if (this.props.selectHandler) {
        this.props.selectHandler({
          node: this.props.data,
          decorations: this.decorations,
        });
      }
      if (this.props.scrollToNode) {
        this.props.scrollToNode({ elm: this.labelRef.current });
      }
    }
    if (typeof this.props.sendDecorationsToParent === "function") {
      this.props.sendDecorationsToParent(this.props.id, this.decorations);
    }
  }

  componentDidUpdate(prevProps: NodeProps) {
    if (prevProps === this.props) {
      return;
    }
    if (!prevProps.active && this.props.active) {
      if (this.props.selectHandler) {
        this.props.selectHandler({
          node: this.props.data,
          decorations: this.decorations,
        });
      }
      if (this.props.scrollToNode) {
        this.props.scrollToNode({ elm: this.labelRef.current });
      }
    }
    if (typeof this.props.sendDecorationsToParent === "function") {
      this.props.sendDecorationsToParent(this.props.id, this.decorations);
    }
  }

  render() {
    const { hidden, active, selected, inplace } = this.props;
    if (hidden) {
      return null;
    }
    this.updateDecorations();
    let colorClass = "tree-node-inactive";
    if (selected === false) {
      colorClass = "tree-node-inactive";
    } else if (selected) {
      colorClass = "tree-node-active";
    } else if (active) {
      colorClass = "tree-node-active";
    }
    this.decorations = Object.assign(
      {},
      this.decorations,
      this.reverseDecorations
    );
    return (
      <li
        className={`${styles.itemContainer}`}
        style={this.decorations.hidden ? { display: "none" } : undefined}
      >
        <div
          className={`${colorClass} d-flex ${styles.itemNode}`}
          onClick={(e) => {
            this.clickHandler(e);
          }}
        >
          {this.renderToggle()}
          {!this.isInplace() && this.renderDropHeader()}
          {this.isInplace() && this.renderHeaderInplace()}
          {inplace && !this.isInplace() && this.renderEditToggle()}
        </div>
        {this.renderChildren()}
      </li>
    );
  }
}

/**
 * High-order component of Node that
 * provides node work logic with context
 *
 * Set context active node on node activate. *
 */
interface ContextNodeProps extends NodeProps {}
class ContextNodeImpl extends React.Component<ContextNodeProps> {
  constructor(props: ContextNodeProps) {
    super(props);

    this.activate = this.activate.bind(this);
  }

  activate() {
    if (
      this.props.data &&
      this.props.data.data &&
      this.props.data.data.$rdfId
    ) {
      // this.props.setValues({ object: this.props.data.data.$rdfId, group: this.props.data.typeId });
    }
    if (this.props.activate) {
      this.props.activate(this.props.data);
    }
  }

  render() {
    return <Node {...this.props} activate={this.activate} />;
  }
}

export class ContextNode extends React.PureComponent<NodeProps> {
  render() {
    return <ContextNodeImpl {...this.props} />;
  }
}

/*
    List Tree Component itself

    http://cssdeck.com/labs/pure-css3-expand-collapse
    https://codeburst.io/how-to-make-a-collapsible-menu-using-only-css-a1cd805b1390
    https://developers.google.com/web/updates/2017/03/performant-expand-and-collapse
*/
export class ListTree extends React.Component<TreeProps> {
  componentDidMount() {
    const l = this.props.loadRoots;
    if (l && typeof this.props.roots == "undefined") {
      l();
    }
  }
  componentDidUpdate(prevProps: Readonly<TreeProps>) {
    const l = this.props.loadRoots;
    //Check if roots have been dropped to undefined state (so reload them)
    if (
      typeof prevProps.roots != "undefined" &&
      typeof this.props.roots == "undefined" &&
      l
    ) {
      l();
    }
  }
  render() {
    const { onSelectHandler } = this.props;
    const C = this.props.renderComponent || Node;
    const r = this.props.roots || [];
    const contextMenuId = this.props.menuId;

    if (this.props.loading) {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    return (
      <ul
        className={`p-1 npt-tree ${styles.unstyled} ${
          this.props.className ? this.props.className : ""
        }`}
      >
        {r.map((id) => (
          <C
            key={id}
            onSelectHandler={onSelectHandler}
            disableSelection={this.props.disableSelection}
            readonly={this.props.readonly}
            menuId={contextMenuId}
            id={id}
            treeId={this.props.treeId}
            renderComponent={this.props.renderComponent}
            selectHandler={this.props.selectHandler}
            scrollToNode={this.props.scrollToNode}
          />
        ))}
      </ul>
    );
  }
}

export interface TestInfiniteNodeState {
  expanded: boolean;
  loading: boolean;
  childrenIds: string[] | null;
}

/**
 * Test node implementation used for prototyping
 */
export class TestInfiniteNode extends React.Component<
  NodeProps,
  TestInfiniteNodeState
> {
  state = {
    expanded: false,
    loading: false,
    childrenIds: null,
  };

  loadChildrenImpl() {
    const childrenIds = [];
    for (let i = 0; i < 10; ++i) {
      childrenIds.push(this.props.id + ".id" + (i + 1));
    }
    this.setState({ childrenIds, loading: false });
  }

  loadChildren() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.loadChildrenImpl();
    }, 500);
  }

  render() {
    const expanded = this.state.expanded;
    const loading = this.state.loading;
    const childrenIds = this.state.childrenIds;
    const toggle = (expanded: boolean) => this.setState({ expanded });
    const loadChildren = () => this.loadChildren();
    return (
      <Node
        {...this.props}
        expanded={expanded}
        loading={loading}
        childrenIds={childrenIds}
        toggle={toggle}
        loadChildren={loadChildren}
      />
    );
  }
}

export class TestInfiniteTree extends React.Component {
  render() {
    return (
      <ListTree
        treeId="TestInfiniteTree"
        roots={["id1", "id2", "id3"]}
        renderComponent={TestInfiniteNode}
      />
    );
  }
}
