import * as React from "react";
import { getPortalCallback } from "../../../model/modal";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import ModalView from "../ModalView";

interface PortalModalProps {
  modal: ModalInfo;
  closeModal: (status: ModalStatus, result: any) => void;
}

export default class PortalModal extends React.Component<PortalModalProps> {
  private portalRef = React.createRef<HTMLDivElement>();

  constructor(props: PortalModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const callback = getPortalCallback(this.props.modal.id);
    if (typeof callback == "function" && this.portalRef.current) {
      callback(this.portalRef.current);
    }
  }

  renderTemplate(): React.ReactElement {
    const body = this.props.modal.options.body;
    if (typeof body == "string") {
      return <div ref={this.portalRef}>{body}</div>;
    }
    return <div ref={this.portalRef}></div>;
  }

  closeModal(status: ModalStatus, result: any) {
    this.props.closeModal(status, null);
  }

  render() {
    const modal = { ...this.props.modal };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}
