import { Action, AnyAction } from "redux";

import * as constants from '../constants/preference';
import { FetchError } from "./error";

export type PreferenceType = "preference";


export const DEFAULT_PREFERENCE_STATE = {
    info: {
        names: [],
        values: {},
        checked: {}
    },
    loading: false,
    error: null,
    totalNumberOfElements: 0,
    showSearchInput: false,
    searchInput: '',
    limit: 20,
    offset: 0
}

export interface PreferenceState {
    info: PreferenceInfo,
    totalNumberOfElements: number,
    showSearchInput?: boolean,
    limit: number,
    offset: number,
    searchInput?: string,
    isAllItemsChecked?:boolean,
    loading?: boolean,
    error?: null | FetchError
}

export interface PreferenceInfo {
    names: string[],
    values: { [id: string]: string },
    checked: { [id: string]: boolean },
    edited?: EditPreferenceInfo
}

export interface EditPreferenceInfo {
    //uniqColumnValue : when this object comes to server,uniqColumnValue is used to find object in DB to be edited,
    //for preferences uniqColumnValue it is name column 
    //We can edit column with uniq value,so we need uniqColumnValue field to find object in DB  
    //{uniqColumnValue:abc , fieldName: name , fieldValue: cba} 
    name: string,
    columnName: string,
    columnValue: string
}

export interface PreferenceTableResult {
    list: PreferenceData[],
    total: number
}


export interface PreferenceData {
    name: string,
    value: string,
    checked?: boolean
}
///////////
//Actions//
///////////
export interface SendPreferences extends Action<constants.SEND_PREFERENCES> {
    preferenceType: PreferenceType,
    payload: PreferenceTableResult
}

export interface SendPreferenceError extends Action<constants.SEND_PREFERENCE_ERROR> {
    preferenceType: PreferenceType,
    payload: {
        error: FetchError
    }
}

export interface SendPreferenceLoading extends Action<constants.SEND_PREFERENCE_LOADING> {
    preferenceType: PreferenceType,
} 

export interface SendLimit extends Action<constants.SEND_LIMIT> {
    preferenceType: PreferenceType,
    payload: number
}

export interface SendOffset extends Action<constants.SEND_OFFSET> {
    preferenceType: PreferenceType,
    payload: number
}

export interface SendItemChecked extends Action<constants.SEND_ITEM_CHECKED> {
    preferenceType: PreferenceType,
    payload: {
        name: string,
        checked: boolean
    }
}

export interface SendAllItemChecked extends Action<constants.SEND_ALL_ITEM_CHECKED> {
    preferenceType: PreferenceType,
    payload: boolean
}

export interface SendStartEdit extends Action<constants.SEND_START_EDIT> {
    preferenceType: PreferenceType,
    payload: {
        name: string,
        columnName: string
    }
}

export interface SendSearchInput extends Action<constants.SEND_SEARCH_INPUT_PREFERENCE> {
    preferenceType: PreferenceType,
    payload: string
}

export interface SendStopEdit extends Action<constants.SEND_STOP_EDIT> {
    preferenceType: PreferenceType,
}

export type PreferenceAction = SendPreferences |
    SendPreferenceLoading |
    SendPreferenceError | 
    SendLimit |
    SendOffset |
    SendItemChecked |
    SendAllItemChecked |
    SendStartEdit |
    SendStopEdit |
    SendStopEdit |
    SendSearchInput;

export function isPreferenceAction(action: AnyAction): action is PreferenceAction {
    return (typeof action.type == 'string');
}

export function isPreferenceData(data: any): data is PreferenceData {
    return typeof (data as PreferenceData).name !== undefined &&
            typeof (data as PreferenceData).value !== undefined;
}
