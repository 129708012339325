import {
  faCheck,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import ModalView from "../ModalView";
import { connect } from "react-redux";
import { Dispatch } from "../../../store";

interface ModalPasswordProps {
  modal: ModalInfo;
  closeModal: (status: ModalStatus, result: any) => void;
  alert: (id: string) => void;
}

interface ModalPasswordState {
  oldPass: string;
  repeatedOldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  containSymbols: boolean;
  acceptLenght: boolean;
  passMismatch: boolean | null;
  errorMessage: string | null;
}

class PasswordModal extends React.Component<
  ModalPasswordProps,
  ModalPasswordState
> {
  title = { id: "USERMENU_CHANGE_PASSWORD" };
  state = {
    oldPass: "",
    oldPassword: "",
    repeatedOldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    containSymbols: false,
    acceptLenght: false,
    passMismatch: null,
    errorMessage: null,
  };
  constructor(props: ModalPasswordProps) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  /* TODO: add password check */
  checkNewPassword(): boolean {
    return this.state.newPassword.length != 0;
  }

  checkConfirmPassword(): boolean {
    return (
      this.state.newPassword?.trim() == this.state.confirmNewPassword?.trim()
    );
  }
  checkConfirmOldPassword(): boolean {
    return !this.state.oldPassword?.trim();
  }
  changeInputValue = (key: string) => (event: any) => {
    const newValue = event.target.value;
    let newState: ModalPasswordState = { ...this.state, [key]: newValue };
    if (key === "newPassword") {
      newState = { ...newState, ...this.isNewValid(newValue) };
    }
    this.setState(newState);
  };
  passwordNotEmpty() {
    const { confirmNewPassword, newPassword } = this.state;
    return Boolean(confirmNewPassword) || Boolean(newPassword);
  }
  renderPasswordMismatch() {
    const { newPassword, confirmNewPassword } = this.state;

    if (!newPassword?.trim() && !confirmNewPassword?.trim()) {
      return null;
    }
    const match = newPassword?.trim() === confirmNewPassword?.trim();
    return (
      <Form.Text>
        <FontAwesomeIcon
          className={`mr-1 font-weight-bold text-success ${
            match ? "text-success" : "text-danger"
          }`}
          icon={match ? faCheck : faTimes}
        />
        <FormattedMessage id={"REQUIREMENT_SAME"} />
      </Form.Text>
    );
  }
  renderErrorMessage = () => {
    const { errorMessage } = this.state;
    if (!errorMessage) {
      return null;
    }
    return (
      <Alert className="my-2" variant="danger">
        <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
        <FormattedMessage id={errorMessage} />
      </Alert>
    );
  };
  newEqualsOldPassword = () => {
    const { oldPassword, newPassword } = this.state;
    return oldPassword && newPassword && oldPassword == newPassword;
  };
  renderSameOldPassword = () => {
    const isEquals = this.newEqualsOldPassword();
    if (isEquals) {
      return (
        <Form.Text className="text-danger">
          <FontAwesomeIcon className={"mr-1"} icon={faExclamationTriangle} />
          <FormattedMessage id={"REQUIREMENT_PASSWORD_NOT_CHANGED"} />
        </Form.Text>
      );
    }
    return null;
  };
  renderTemplate(): React.ReactElement {
    const { acceptLenght, containSymbols, oldPass } = this.state;
    return (
      <Form>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"CHANGE_PASSWORD_PREV_PWD"} />
          </Form.Label>
          <Form.Control
            type="password"
            style={{ display: "none" }}
            value={this.state.oldPass}
            onChange={this.changeInputValue("oldPass")}
          />
          <PasswordGroup
            value={this.state.oldPassword}
            onChange={this.changeInputValue("oldPassword")}
            isInvalid={this.checkConfirmOldPassword()}
          />
          {/* <Form.Control
            type="password"
            isInvalid={this.checkConfirmOldPassword()}
            value={this.state.oldPassword}
            onChange={this.changeInputValue("oldPassword")}
          /> */}
        </Form.Group>
        <Form.Group controlId="newPassword">
          <Form.Label>
            <FormattedMessage id={"CHANGE_PASSWORD_NEW_PWD"} />
          </Form.Label>
          <PasswordGroup
            isInvalid={!this.checkNewPassword()}
            value={this.state.newPassword}
            onChange={this.changeInputValue("newPassword")}
          />
          {/* <InputGroup className="mb-2">
            <Form.Control
              type="password"
              isInvalid={!this.checkNewPassword()}
              value={this.state.newPassword}
              onChange={this.changeInputValue("newPassword")}
            />
            <InputGroup.Append>
              <Button variant="outline-warning" onClick={this.toggleVisible}>
                <FontAwesomeIcon
                  icon={this.state.visible ? faEyeSlash : faEye}
                />
              </Button>
            </InputGroup.Append>
          </InputGroup> */}
        </Form.Group>
        <Form.Group controlId="confirmNewPassword">
          <Form.Label>
            <FormattedMessage id={"CHANGE_PASSWORD_CONF_NEW_PWD"} />
          </Form.Label>
          <PasswordGroup
            isInvalid={!this.checkConfirmPassword()}
            value={this.state.confirmNewPassword}
            onChange={this.changeInputValue("confirmNewPassword")}
          />
          {/* <Form.Control
            type="password"
            isInvalid={!this.checkConfirmPassword()}
            value={this.state.confirmNewPassword}
            onChange={this.changeInputValue("confirmNewPassword")}
          /> */}
        </Form.Group>

        <Form.Group controlId="confirmNewPassword">
          <Form.Text>
            <FontAwesomeIcon
              className={`mr-1 font-weight-bold ${
                containSymbols ? "text-success" : "text-danger"
              }`}
              icon={containSymbols ? faCheck : faTimes}
            />
            <FormattedMessage id={"REQUIREMENT_ONLY_NUMBERS_AND_LETTERS"} />
          </Form.Text>
          <Form.Text>
            <FontAwesomeIcon
              className={`mr-1 font-weight-bold ${
                acceptLenght ? "text-success" : "text-danger"
              }`}
              icon={acceptLenght ? faCheck : faTimes}
            />
            <FormattedMessage id={"REQUIREMENT_MIN_SIX_SYMBOLS"} />
          </Form.Text>
          {this.renderPasswordMismatch()}
          {this.renderSameOldPassword()}
          {this.renderErrorMessage()}
        </Form.Group>
      </Form>
    );
  }

  isNewValid(newPassword: string) {
    const lettersAndNumbers = new RegExp(/^[a-zA-Z0-9!@#$%^&*-]*$/);
    return {
      acceptLenght: newPassword.trim().length >= 10,
      containSymbols:
        (newPassword?.trim() && lettersAndNumbers.test(newPassword)) || false,
    };
  }

  sendPassword = (
    data: {
      currentPassword: string;
      newPassword: string;
    },
    status: ModalStatus
  ) => {
    fetch("/rest/self/changepassword", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => {
        if (!r.ok) {
          const { status } = r;
          if (status === 400) {
            this.setState({ errorMessage: "LOGIN_USE_OLD_PASSWORD" });
            // dispatchError("LOGIN_USE_OLD_PASSWORD", {}, dispatch);
          } else if (status === 403) {
            this.setState({ errorMessage: "PASSWORD_WRONG_OLD_PASSWORD" });
            // dispatchError("PASSWORD_WRONG_OLD_PASSWORD", {}, dispatch);
          } else {
            this.setState({ errorMessage: "PASSWORD_CHANGE_ERROR" });
            // dispatchError("PASSWORD_CHANGE_ERROR", {}, dispatch);
          }
        } else {
          this.props.closeModal(status, null);
          this.props.alert("PASSWORD_CHANGED_SUCCESSFULLY");
          // this.setState({errorMessage:"PASSWORD_CHANGED_SUCCESSFULLY"})
          // dispatchSuccess("PASSWORD_CHANGED_SUCCESSFULLY", dispatch);
        }
      })
      .catch((e) => {
        this.setState({ errorMessage: "PASSWORD_CHANGE_ERROR" });
        // dispatchError("PASSWORD_CHANGE_ERROR", {}, dispatch);
      });
  };

  closeModal(status: ModalStatus, result: any) {
    const { acceptLenght, containSymbols } = this.state;
    const oldNotEmpty = this.state.oldPassword?.trim();
    if (
      status == MODAL_STATUS_OK &&
      acceptLenght &&
      containSymbols &&
      this.checkConfirmPassword() &&
      oldNotEmpty &&
      !this.newEqualsOldPassword()
    ) {
      this.sendPassword(
        {
          currentPassword: this.state.oldPassword.trim(),
          newPassword: this.state.newPassword.trim(),
        },
        status
      );
      // this.props.closeModal(status, {
      //   currentPassword: this.state.oldPassword.trim(),
      //   newPassword: this.state.newPassword.trim(),
      // });
      // return;
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, result);
    }
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

export default connect(null, (dispatch: Dispatch) => {
  return {
    alert: (id: string) =>
      dispatch.alert.addAlert({
        message: { id },
        type: "success",
      }),
  };
})(PasswordModal);

interface PasswordGroupProps {
  isInvalid?: boolean;
  value: string;
  onChange: (e: any) => void;
}
const PasswordGroup: React.FC<PasswordGroupProps> = ({
  isInvalid,
  value,
  onChange,
}) => {
  React.useEffect(() => {
    document.addEventListener("mouseup", toggleVisible(false));
    return () => {
      document.removeEventListener("mouseup", toggleVisible(false));
    };
  });
  const [visible, setVisible] = React.useState(false);
  const toggleVisible = (show: boolean) => () => {
    setVisible(show);
  };
  const type = visible ? "text" : "password";
  return (
    <InputGroup className="mb-2">
      <Form.Control
        type={type}
        isInvalid={isInvalid}
        value={value}
        onChange={onChange}
      />
      <InputGroup.Append>
        <Button variant="outline-warning" onMouseDown={toggleVisible(true)}>
          <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};
