import React from 'react';

export interface WhenProps {
    when: string
    delegate?: React.ComponentType<any>
    [P: string]: any
}

function getCondition(repeat: string, props: any): any {
    let path = repeat.split('.');
    let payload = props;
    for (let p of path) {
        if (typeof payload == 'object' && payload != null) {
            payload = payload[p];
        } else {
            payload = null;
            break;
        }
    }
    return payload
}

const When: React.FunctionComponent<WhenProps> = React.memo((props: WhenProps) => {

    const { when,  delegate, children, ...rest } = props

    //By default use condition property
    let condition = props['condition']

    //If when property was set up then get field from props
    if (when) {
        condition = getCondition(when, props);
    }

    if (!condition) {
        return null
    }

    if (delegate) {
        const C = delegate
        return <C {...rest}/>
    }

    const el = React.Children.only(children);
    return React.cloneElement(el, { ...rest })
})

export default When;