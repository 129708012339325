import React, { FC } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { RootState } from "../../store";
import { isSubject } from "../../types/subject";
import { ConnectedSubjectModelToolbar } from "../admin/model/SubjectTab";
import ObjectCard from "./ObjectCard";
interface CardAsValueWrapperProps {
  nodeId: string;
  value: any;
}
const CardAsValue: FC<CardAsValueWrapperProps> = ({ nodeId, value }) => {
  if (!value) {
    return null;
  }
  if (!Array.isArray(value)) {
    const rdfId = value.$rdfId;
    return <ObjectCard key={nodeId} subjectKey={rdfId} />;
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: any) => (
      <div
        style={{
          top: ".2rem",
          height: "fit-content",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{ width: "fit-content" }}
          className="d-flex border  justify-content-center rounded shadow bg-white p-2"
        >
          <ul className="p-0 m-0"> {dots} </ul>
        </div>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className={"rounded  custom-slick-dot "}
        style={{
          color: "var(--primary)",
          border: "1px var(--primary) solid",
          borderRadius: "50%",
        }}
      >
        {i + 1}
      </div>
    ),
  };
  return (
    <Slider className={"card-slider"} {...settings}>
      {value.map((v: any, idx: number) => {
        const rdfId = v.$rdfId;
        return (
          <div key={idx}>
            <ObjectCard key={rdfId} subjectKey={rdfId} />{" "}
          </div>
        );
      })}
    </Slider>
  );
};

export const CardAsValueWrapper = connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId?: string }) => {
    const { subjectKey, nodeId } = ownProps;
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return {};
    }
    const node = nodeId && subject?.nodeById?.[nodeId];

    if (!node || !node?.options?.src) {
      return {};
    }

    let src = node.options.src.replace(":", ".");

    return {
      layout: subject,
      value: nodeId && subject?.values[src],
    };
  }
)(CardAsValue);
