import { createModel } from "@rematch/core"
import { RootModel } from "."
import { ServerError } from "../actions/utils"
import { FetchError } from "../types/error"
import * as constants from "../constants/preference"
import {
  DEFAULT_PREFERENCE_STATE,
  EditPreferenceInfo,
  PreferenceData,
  PreferenceInfo,
  PreferenceState,
  PreferenceTableResult,
} from "../types/preference"
import { PREFERENCE } from "../constants/preference"
import { I18NString } from "../types/modal"
import { dispatchError } from "../services/alert"
import { ALERT_LEVEL_SUCCESS } from "../constants/alert"

const initialState: PreferenceState = DEFAULT_PREFERENCE_STATE

function checkAll(checked: boolean, state: PreferenceState) {
  let checkedObject = { ...state.info.checked }
  for (let item in checkedObject) {
    checkedObject[item] = checked
  }
  return checkedObject
}

function sendItemChecked(state: PreferenceState, payload: any) {
  const checkItemInfo = {
    ...state.info,
    checked: { ...state.info.checked, [payload.name]: payload.checked },
  }
  return { ...state, info: checkItemInfo }
}

function stopEdit(state: PreferenceState) {
  if (state.info.edited && state.info.edited.name && state.info.edited.columnName) {
    let info = { ...state.info }
    delete info.edited
    return { ...state, info: info }
  }
  return state
}

function parseToPreferenceInfo(result: PreferenceData[]): PreferenceInfo {
  let info: PreferenceInfo = { names: [], checked: {}, values: {} }
  result.map((item: any) => {
    info.names.push(item.name)
    info.values[item.name] = item.value
    info.checked[item.name] = false
  })
  return info
}

export const preference = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendPreferences(state, payload: PreferenceTableResult) {
      return {
        ...state,
        loading: false,
        info: { ...parseToPreferenceInfo(payload.list) },
        totalNumberOfElements: payload.total,
      }
    },
    sendPreferenceLoading(state) {
      return { ...state, loading: true }
    },
    sendPreferenceError(state, payload: { error: FetchError }) {
      return { ...state, loading: false, error: payload.error }
    },
    sendLimit(state, payload: { limit: number }) {
      return { ...state, limit: payload.limit }
    },
    setOffset(state, payload: { offset: number }) {
      return { ...state, offset: payload.offset }
    },
    setItemChecked(state, payload: { name: string; checked: boolean }) {
      return sendItemChecked(state, payload)
    },
    setAllItemsChecked(state, payload: { checked: boolean }) {
      return {
        ...state,
        info: { ...state.info, checked: checkAll(payload.checked, state) },
        isAllItemsChecked: payload.checked,
      }
    },
    setStartEdit(state, payload: { name: string; columnName: string }) {
      return {
        ...state,
        info: {
          ...state.info,
          edited: { name: payload.name, columnName: payload.columnName, columnValue: "" },
        },
      }
    },
    setStopEdit(state) {
      return stopEdit(state)
    },
    sendSearchInput(state, payload: { searchInput: string }) {
      return { ...state, searchInput: payload.searchInput }
    },
  },
  effects: (dispatch) => ({
    async savePreferenceImpl(row: PreferenceData): Promise<PreferenceData> {
      const resp = await fetch(constants.PREFERENCE_SAVE_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(row),
      })
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
      const json = await resp.json()
      json.checked = false
      return json
    },
    async deletePreferencesImpl(names: string[]): Promise<void> {
      const resp = await fetch(constants.PREFERENCE_DELETE_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(names),
      })
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
    },
    async fetchPreferencesImpl(tableInfo: any): Promise<PreferenceTableResult> {
      let url = constants.PREFERENCE_LIST_URL
      let query: string[] = []
      if (tableInfo) {
        for (const k of Object.keys(tableInfo)) {
          const v = tableInfo[k]
          if (v === undefined || String(v).trim().length === 0) {
            continue
          }
          query.push(`${k}=${v}`)
        }
      }
      if (query.length > 0) {
        url += `?${query.join("&")}`
      }
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })

      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
      return await resp.json()
    },
    async editPreferenceImpl(editedObjectInfo: any): Promise<PreferenceData> {
      const resp = await fetch(constants.PREFERENCE_EDIT_URL, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedObjectInfo),
      })
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
      return resp.json()
    },
    async uploadImpl(preferences: PreferenceData[]): Promise<PreferenceData[]> {
      const response = await fetch(constants.PREFERENCE_UPLOAD_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(preferences),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      const savedPreferences = await response.json()
      return savedPreferences
    },
    async addPreference(preference: PreferenceData, state) {
      const s = state
      const preferenceState = s[PREFERENCE]
      if (!preferenceState) {
        return
      }
      try {
        await dispatch.preference.savePreferenceImpl(preference)
        let tableInfo: any = {
          pageable: true,
          offset: preferenceState.offset,
          limit: preferenceState.limit,
        }
        dispatch.preference.fetchPreferences(tableInfo)
        const messge: I18NString = { id: "PREFERENCE_SAVE_SUCCESS" }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
      } catch (e) {
        dispatchError("PREFERENCE_SAVE_ERROR", e, dispatch)
      }
    },
    async removePreferences(_: void, state) {
      const s = state
      const preferenceState = s[constants.PREFERENCE]
      if (!preferenceState) {
        return
      }

      let namesOnDelete: string[] = []
      let checkedPreferences = preferenceState.info.checked

      for (let name in checkedPreferences) {
        if (checkedPreferences[name]) {
          namesOnDelete.push(name)
        }
      }
      if (namesOnDelete.length === 0) {
        return
      }
      let oldOffset = preferenceState.offset
      let newOffset = preferenceState.totalNumberOfElements - namesOnDelete.length
      let limit = preferenceState.limit
      let tableInfo: any = { pageable: true, offset: oldOffset, limit: limit }

      try {
        await dispatch.preference.deletePreferencesImpl(namesOnDelete)
        // dispatch(sendCount(await getCountOfPreferencesImpl()));
        //if all items on current page were deleted go to previous page
        if (oldOffset === newOffset) {
          tableInfo.offset = newOffset - limit <= 0 ? 0 : newOffset - limit
          dispatch.preference.setOffset(tableInfo.offset)
          dispatch.preference.fetchPreferences(tableInfo)
        } else {
          dispatch.preference.fetchPreferences(tableInfo)
        }

        if (preferenceState.isAllItemsChecked) {
          dispatch.preference.setAllItemsChecked({ checked: false })
        }
        const messge: I18NString = { id: "PREFERENCE_REMOVE_SUCCESS" }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
      } catch (e) {
        dispatchError("PREFERENCE_SAVE_ERROR", e, dispatch)
      }
    },
    async fetchPreferences(tableInfo: any, state) {
      try {
        const s = state

        const preferenceState = s[constants.PREFERENCE]
        if (preferenceState && preferenceState.loading) {
          return
        }

        preferenceState && (tableInfo.search = preferenceState.searchInput)
        dispatch.preference.sendPreferenceLoading()
        dispatch.preference.sendPreferences(await dispatch.preference.fetchPreferencesImpl(tableInfo))
        dispatch.preference.setOffset({offset: tableInfo.offset})
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.preference.sendPreferenceError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.preference.sendPreferenceError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.preference.sendPreferenceError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    async setLimit(limit: number, state) {
      const s = state
      const preferenceState = s[constants.PREFERENCE]
      if (!preferenceState) {
        return
      }
      let tableInfo: any = { pageable: true, offset: 0, limit: limit }
      dispatch.preference.sendLimit({ limit })
      dispatch.preference.fetchPreferences(tableInfo)
    },
    async setStopEditSuccess(newValue: string, state) {
      const s = state
      const preferenceState = s[constants.PREFERENCE]
      if (preferenceState && preferenceState.info.edited) {
        try {
          let editedObject: EditPreferenceInfo = {
            ...preferenceState.info.edited,
            columnValue: newValue,
          }
          let tableInfo: any = {
            pageable: true,
            offset: preferenceState.offset,
            limit: preferenceState.limit,
          }
          await dispatch.preference.editPreferenceImpl(editedObject)
          dispatch.preference.setStopEdit()
          dispatch.preference.fetchPreferences(tableInfo)
          const messge: I18NString = { id: "PREFERENCE_EDIT_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("PREFERENCE_EDIT_ERROR", e, dispatch)
        }
      }
    },
    async setSearch(value: string, state) {
      const s = state
      const preferenceState = s[constants.PREFERENCE]
      if (!preferenceState) {
        return
      }
      let tableInfo: any = {
        pageable: true,
        offset: 0,
        limit: preferenceState.limit,
      }
      dispatch.preference.sendSearchInput({ searchInput: value })
      dispatch.preference.fetchPreferences(tableInfo)
    },
    async download(_: void, state) {
      const preferences = state.preference
      if (preferences) {
        const preferencesValues = preferences.info.values
        const preferencesList: PreferenceData[] = []
        for (const key in preferencesValues) {
          const preference: PreferenceData = {
            name: key,
            value: preferencesValues[key],
          }
          preferencesList.push(preference)
        }
        if (preferencesList.length === 0) {
          return
        }
        const a = document.createElement("a")
        a.download = "preferences.json"
        const preferencesText = JSON.stringify(preferencesList, null, "\t")
        const preferencesBlob = new Blob([preferencesText], {
          type: "text/plain",
        })
        a.href = URL.createObjectURL(preferencesBlob)
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    async upload(preferences: PreferenceData[], state) {
      const prefState = state.preference
      await Promise.resolve(dispatch.preference.uploadImpl(preferences))
      let tableInfo: any = { pageable: true, offset: prefState.offset ? prefState.offset : 0, limit: 10000 }
      dispatch.preference.fetchPreferences(tableInfo)
    },
    async confirmDelete(callback: () => void, state) {
      const info = state.preference?.info
      if (!info) {
        return null
      }
      const names: string[] = []
      if (info.checked) {
        Object.entries(info.checked).forEach((e) => {
          e[1] && names.push(e[0])
        })
      }
      if (!names.length) {
        return
      }
      dispatch.modal.openModal({
        id: "preference.confirmDelete",
        type: "confirmDelete",
        options: {
          title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
          body: {
            id: "PREFERENCE_CONFIRM_DELETE_MODAL",
            values: { name: names.join(", ") },
          },
        },

        okCallback: async (result) => {
          if (result) {
            callback()
          }
        },
      })
    },
  }),
})
