import { AlertLevelType, AlertMessage } from "../types/alert";
import {
  ALERT_LEVEL_DANGER,
  ALERT_LEVEL_INFO,
  ALERT_LEVEL_SUCCESS,
  ALERT_LEVEL_WARNING,
} from "../constants/alert";
import { ServerError, parseServerMessage } from "../actions/utils";
import { FetchError } from "../types/error";
import { I18NString } from "../types/modal";
import * as constants from "../constants/alert";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../model";
export function parseAlertMessage(
  message: string,
  type: AlertLevelType
): { message: string; type: AlertLevelType } {
  if (message.toLowerCase().startsWith("info::")) {
    message = message.substring(6);
    type = ALERT_LEVEL_INFO;
  } else if (message.toLowerCase().startsWith("success::")) {
    message = message.substring(9);
    type = ALERT_LEVEL_SUCCESS;
  } else if (message.toLowerCase().startsWith("warning::")) {
    message = message.substring(9);
    type = ALERT_LEVEL_WARNING;
  } else if (message.toLowerCase().startsWith("danger::")) {
    message = message.substring(8);
    type = ALERT_LEVEL_DANGER;
  }
  return { message, type };
}

/**Accepts only formatted message id as message */
export function dispatchError(
  messageId: string,
  e: any,
  dispatch: RematchDispatch<RootModel>,
  options?: any
): FetchError {
  return dispatchErrorV2(`formattedId::${messageId}`, e, dispatch, options);
}

export function dispatchErrorV2(
  message: string,
  e: any,
  dispatch: RematchDispatch<RootModel>,
  options?: any
): FetchError {
  let error: FetchError = { code: -1, message: "Unknown error" };
  if (e instanceof ServerError) {
    error = { code: e.code, message: e.message };
  } else if (typeof e.message == "string") {
    error = { code: -1, message: e.message };
  } else if (typeof e === "string") {
    error = { code: -1, message: e };
  }

  const { code, message: m } = error;

  let alertMessage: I18NString | null = null;
  let alertType: AlertLevelType = constants.ALERT_LEVEL_DANGER;
  /**Parse formatted id message */
  if (message.includes("formattedId::")) {
    const parsedData = parseAlertMessage(message, alertType);
    alertMessage = {
      id: parsedData.message.replace("formattedId::", ""),
      values: { error: `${parseServerMessage(code, m)}:${code}`, ...options },
    };
    alertType = parsedData.type;
  } else if (message.includes("<!doctype")) {
    /**Response text is error page */
    alertMessage = {
      id: "NPT_SERVER_MESSAGE_EMPTY_ERROR",
      values: { error: `${parseServerMessage(code, m)}:${code}`, ...options },
    };
  }
  /**Check if error message is plain text */
  dispatch.alert.addAlert({
    type: alertType,
    message: alertMessage || message,
  });
  return error;
}

/**Accepts only formatted message id as message */
export function dispatchSuccess(
  messageId: string,
  dispatch: RematchDispatch<RootModel>,
  options?: any
) {
  return dispatchSuccessV2(`formattedId::${messageId}`, dispatch, options);
}

export function dispatchSuccessV2(
  message: string,
  dispatch: RematchDispatch<RootModel>,
  options?: any
) {
  let alertMessage: I18NString | string = "";
  let alertType: AlertLevelType = constants.ALERT_LEVEL_SUCCESS;
  /**Parse formatted id message */
  if (message.includes("formattedId::")) {
    const parsedData = parseAlertMessage(message, alertType);
    alertMessage = {
      id: parsedData.message.replace("formattedId::", ""),
      values: { ...options },
    };
    alertType = parsedData.type;
  }
  dispatch.alert.addAlert({
    type: alertType,
    message: alertMessage || message,
  });
}

export function dispatchResponse(
  response: Response,
  dispatch: RematchDispatch<RootModel>,
  options?: { successMessage?: AlertMessage; failMessage?: AlertMessage }
): { valid: boolean } {
  if (response.ok || response.status === 200) {
    let message = "formattedId::NPT_SERVER_MESSAGE_SUCCESS";
    if (options?.successMessage) {
      message =
        typeof options.successMessage === "function"
          ? options.successMessage(response.status, response.statusText)
          : options.successMessage;
    }
    dispatchSuccessV2(message, dispatch);
    return { valid: true };
  }
  let message = "formattedId::NPT_SERVER_MESSAGE_EMPTY_ERROR";
  if (options?.failMessage) {
    message =
      typeof options.failMessage === "function"
        ? options.failMessage(response.status, response.statusText)
        : options.failMessage;
  }
  dispatchErrorV2(
    message,
    new ServerError(response.status, response.statusText),
    dispatch
  );
  return { valid: false };
}
