import { ServerError } from "../actions/utils";
import { getOperationValue } from "../components/subjecthistory/FilesHistory";
import {
  SubjectHistoryState,
  SubjectHistoryData,
  SubjectHistoryDataRaw,
  SubjectHistoryRecord,
  SubjectHistoryFileRecord,
  SubjectHistoryRelationRecord,
  SubjectHistoryTripleRecord,
  SubjectHistoryFragmentRecord,
  FragmentData,
} from "../types/subjecthistory";
import { buildUrl } from "./location";

/*********************
 * Utility functions *
 *********************/
export function isHistoryLoaded(
  historyState?: SubjectHistoryState,
  before?: number
): boolean {
  if (
    !historyState ||
    historyState.loadedFrom === null ||
    historyState.loadedTo === null
  ) {
    return false;
  }
  if (!before) {
    return true;
  }
  return historyState.loadedFrom < before;
}

export function fetchHistoryImpl(
  subjectKey: string,
  before?: number
): Promise<any> {
  const search: { [k: string]: string } = {
    limit: "100",
  };
  if (before) {
    search.before = before.toString();
  }

  let url = buildUrl({
    url: `/rest/subject/hist/${subjectKey.replace(":", "/")}`,
    search,
  });

  return fetch(url)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

/*******************
 * Parse functions *
 *******************/
export function parseSubjectHistoryDataOld(data: any): SubjectHistoryData {
  return {
    records: data,
    isEnd: data.length === 0,
  };
}

export function parseSubjectHistoryData(
  data: SubjectHistoryDataRaw
): SubjectHistoryData {
  const { predicateMap, records } = data;
  const parsedRecords: SubjectHistoryRecord[] = [];
  try {
    records.forEach((r, idx) => {
      const {
        fragment: fr,
        prevFragment: prevFr,
        relations,
        description,
        userId,
        files,
        fragments,
        label,
        prevDescription,
        prevLabel,
        primitives,
        when,
        who,
        id,
      } = r;
      const fileHistories: SubjectHistoryFileRecord[] = [];
      files?.forEach((f, idx) => {
        const { object, type, predicateId } = f;
        const fileHistory: SubjectHistoryFileRecord = {
          file: {
            originalName: object?.$originalName || "",
            sha1: object?.$sha1 || "",
          },
          id: idx,
          operation: getOperationValue(type),
          description: object?.$description,
          label: object?.$label,
          predicate: predicateMap[+predicateId] || undefined,
        };
        fileHistories.push(fileHistory);
      });
      const relationHistories: SubjectHistoryRelationRecord[] = [];
      relations?.forEach((r, idx) => {
        const { object, predicateId, type } = r;
        const relation: SubjectHistoryRelationRecord = {
          id: idx,
          operation: getOperationValue(type),
          value: object,
          predicate: predicateMap[+predicateId] || undefined,
          peerSubject: object,
        };
        relationHistories.push(relation);
      });
      const tripleHistories: SubjectHistoryTripleRecord[] = [];
      primitives?.forEach((p, idx) => {
        const { predicateId, prevValue, value } = p;
        const triple: SubjectHistoryTripleRecord = {
          id: idx,
          predicate: predicateMap[+predicateId] || undefined,
          value,
          prevValue,
        };
        tripleHistories.push(triple);
      });
      const fragmentHistories: SubjectHistoryFragmentRecord[] = [];
      fragments?.forEach((f, idx) => {
        const { object, predicateId, type } = f;
        const fragment: SubjectHistoryFragmentRecord = {
          id: idx,
          operation: getOperationValue(type),
          description: object?.$description || "",
          label: object?.$label || "",
          predicate: predicateMap[+predicateId] || undefined,
        };
        fragmentHistories.push(fragment);
      });
      const fragment: FragmentData = {
        description: fr?.$description,
        id: fr?.$id,
        label: fr?.$label,
        rdfId: fr?.$rdfId,
      };
      const prevFragment: FragmentData = {
        description: prevFr?.$description,
        id: prevFr?.$id,
        label: prevFr?.$label,
        rdfId: prevFr?.$rdfId,
      };
      const newRecord: SubjectHistoryRecord = {
        fileHistories,
        fragment,
        label: label || "",
        relationHistories,
        operationTimestamp: when || "",
        tableRelationRows: [],
        tripleHistories,
        user: who,
        userId,
        prevFragment,
        fragmentHistories,
        id,
      };
      parsedRecords.push(newRecord);
    });
  } catch (e) {
    console.log("error=========", e);
  }

  return {
    predicateMap,
    records: parsedRecords,
    isEnd: records.length === 0,
  };
}
