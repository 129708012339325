import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import {
  changeClusterInfoImpl,
  checkClusterImpl,
  fetchClusterInfoImpl,
} from "../services/cluster";
import {
  ClusterInfo,
  ClusterState,
  isClusterInfo,
  QuorumCriteria,
  QuorumTest,
} from "../types/cluster";
import { FetchError } from "../types/error";
const initialState: ClusterState = {};

export const cluster = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendClusterInfo(state, clusterInfo: ClusterInfo) {
      return {
        ...state,
        clusterInfo: clusterInfo,
        loading: false,
        checked: undefined,
      };
    },
    sendClusterInfoError(state, error: FetchError) {
      return {
        ...state,
        clusterInfo: error,
        loading: false,
        checked: undefined,
      };
    },
    sendClusterInfoLoading(state) {
      return { ...state, loading: true, checked: undefined };
    },
    sendClusterInfoChange(
      state,
      payload: { field: keyof ClusterInfo; value: any }
    ) {
      if (!isClusterInfo(state.clusterInfo)) {
        return state;
      }
      const clusterInfo = {
        ...state.clusterInfo,
        [payload.field]: payload.value,
        changed: true,
      };
      return { ...state, clusterInfo, checked: undefined };
    },
    sendClusterInfoAddTest(state, test: QuorumTest) {
      if (!isClusterInfo(state.clusterInfo)) {
        return state;
      }
      const quorumCriteria: QuorumCriteria = state.clusterInfo.quorumCriteria
        ? { ...state.clusterInfo.quorumCriteria }
        : { memberSize: 2, scoreMin: 1, quorumTests: [] };
      quorumCriteria.quorumTests = [...quorumCriteria.quorumTests, test];
      const clusterInfo = {
        ...state.clusterInfo,
        quorumCriteria,
        changed: true,
      };
      return { ...state, clusterInfo, checked: undefined };
    },
    sendClusterInfoRemoveTest(state, index: number) {
      if (!isClusterInfo(state.clusterInfo)) {
        return state;
      }
      const quorumCriteria = state.clusterInfo.quorumCriteria;
      if (!quorumCriteria) {
        return state;
      }
      quorumCriteria.quorumTests = quorumCriteria.quorumTests.filter(
        (_: any, idx) => idx != index
      );

      const clusterInfo =
        quorumCriteria.quorumTests.length == 0
          ? { ...state.clusterInfo, quorumCriteria: undefined, changed: true }
          : { ...state.clusterInfo, quorumCriteria, changed: true };
      return { ...state, clusterInfo, checked: undefined };
    },
    sendClusterInfoCriteriaChange(
      state,
      payload: { field: keyof QuorumCriteria; value: any }
    ) {
      if (!isClusterInfo(state.clusterInfo)) {
        return state;
      }
      const quorumCriteria = state.clusterInfo.quorumCriteria;
      if (!quorumCriteria) {
        return state;
      }
      const clusterInfo = {
        ...state.clusterInfo,
        quorumCriteria: { ...quorumCriteria, [payload.field]: payload.value },
        changed: true,
      };
      return { ...state, clusterInfo, checked: undefined };
    },
    sendClusterChecked(state, checked: ClusterInfo) {
      return { ...state, checked: checked, checking: false };
    },
    sendClusterIsChecking(state) {
      return { ...state, checking: true };
    },
    sendClusterCheckError(state, error: FetchError) {
      return { ...state, checked: error, checking: false };
    },
    sendClusterCheckClear(state) {
      return { ...state, checked: undefined };
    },
  },
  effects: (dispatch) => ({
    fetchClusterInfo: async (s) => {
      try {
        if (s && typeof s.cluster != "undefined" && s.cluster.loading) {
          return;
        }
        dispatch.cluster.sendClusterInfoLoading();
        dispatch.cluster.sendClusterInfo(await fetchClusterInfoImpl());
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.cluster.sendClusterInfoError({
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatch.cluster.sendClusterInfoError({
            code: -1,
            message: e.message,
          });
        } else {
          dispatch.cluster.sendClusterInfoError({
            code: -1,
            message: "Unknown error",
          });
        }
      }
    },
    changeClusterInfo: async (s) => {
      try {
        if (!s || typeof s.cluster == "undefined") {
          return;
        }
        if (s.cluster.loading) {
          return;
        }
        if (!isClusterInfo(s.cluster.clusterInfo)) {
          return;
        }
        dispatch.clusterInfo.sendClusterInfoLoading();
        dispatch.clusterInfo.sendClusterInfo(
          await changeClusterInfoImpl(s.clusterInfo.clusterInfo)
        );
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.clusterInfo.sendClusterInfoError({
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatch.clusterInfo.sendClusterInfoError({
            code: -1,
            message: e.message,
          });
        } else {
          dispatch.clusterInfo.sendClusterInfoError({
            code: -1,
            message: "Unknown error",
          });
        }
      }
    },
    checkClusterInfo: async (s) => {
      try {
        if (!s || typeof s.cluster == "undefined") {
          return;
        }
        if (s.cluster.checking) {
          return;
        }
        if (!isClusterInfo(s.cluster.clusterInfo)) {
          return;
        }
        dispatch.clusterInfo.sendClusterIsChecking();
        dispatch.clusterInfo.sendClusterChecked(
          await checkClusterImpl(s.cluster.clusterInfo)
        );
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.clusterInfo.sendClusterCheckError({
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatch.clusterInfo.sendClusterCheckError({
            code: -1,
            message: e.message,
          });
        } else {
          dispatch.clusterInfo.sendClusterCheckError({
            code: -1,
            message: "Unknown error",
          });
        }
      }
    },
  }),
});
