import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { dispatchError, dispatchSuccess } from "../services/alert";
import { getMenuDefaultState, storeMenuState } from "../services/menu";
import { LocaleState } from "../types/locale";
import { MenuState, MenuType } from "../types/menu";
export const DEFAULT_MENU_STATE: MenuState = getMenuDefaultState();
const initialState: MenuState = DEFAULT_MENU_STATE;

export const menu = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendMenuMinimized(state, payload: { minimized: boolean; type: MenuType }) {
      const minimized = {
        ...state.minimized,
        [payload.type]: payload.minimized,
      };
      const nextState = { ...state, minimized };
      storeMenuState(nextState);
      return nextState;
    },
    sendMenuClosed(state, payload: { closed: boolean; type: MenuType }) {
      const closed = { ...state.closed, [payload.type]: payload.closed };
      const nextState = { ...state, closed };
      storeMenuState(nextState);
      return nextState;
    },
    sendMenuActive(state, active: MenuType) {
      const minimized = { ...state.minimized };
      if (minimized[active] === undefined && state.closed[active] !== true) {
        // minimized[action.payload] = true
      }
      return { ...state, active, minimized };
    },
  },
  effects: (dispatch) => ({
    changePassword: () => {
      dispatch.modal.openModal({
        id: "changePassword",
        type: "password",
        options: {},
        okCallback: (result: any) => {
          if (result == null) {
            return;
          }
          // fetch("/rest/self/changepassword", {
          //   method: "POST",
          //   body: JSON.stringify(result),
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          // })
          //   .then((r) => {
          //     if (!r.ok) {
          //       const { status } = r;
          //       if (status === 400) {
          //         dispatchError("LOGIN_USE_OLD_PASSWORD", {}, dispatch);
          //       } else if (status === 403) {
          //         dispatchError("PASSWORD_WRONG_OLD_PASSWORD", {}, dispatch);
          //       } else {
          //         dispatchError("PASSWORD_CHANGE_ERROR", {}, dispatch);
          //       }
          //     } else {
          //       dispatchSuccess("PASSWORD_CHANGED_SUCCESSFULLY", dispatch);
          //     }
          //   })
          //   .catch((e) => {
          //     dispatchError("PASSWORD_CHANGE_ERROR", {}, dispatch);
          //   });
        },
      });
    },
    invokeAvatarHelp: () => {
      dispatch.model.openModal({
        id: "invokeAvatarHelp",
        type: "invokeAvatarHelp",
        options: {},
        okCallback: (result: any) => {},
      });
    },
  }),
});

export function setupHomePage(): void {
  fetch("/rest/user/sethomepage", {
    method: "POST",
    body: JSON.stringify({ link: window.location.href }),
  });
}
