import * as React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import {
  DateTimeFilterData,
  EqualityValue,
  FilterData,
} from "../../../types/table";

import { FilterTab, FilterTabContent, FilterTabs } from "./FilterTabs";
import EqualitySelector from "./EqualitySelection";
import DateTimePicker from "../../datetimepicker/DateTimePicker";
import EmptyCellsFilter from "./EmptyCellsFilter";

import styles from "../Table.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function parseFromUtcValue(value: string | null): string | null {
  if (!value) {
    return null;
  }
  const timezoneIdx = value.toLowerCase().indexOf("z");
  if (timezoneIdx === -1) {
    value = value + "z";
  }
  return moment(value).format("YYYY-MM-DDTHH:mm:ss.SSS");
}

function parseToUtcValue(value: string | null): string | null {
  if (!value) {
    return null;
  }
  return moment(value).utc().toISOString();
}

function dateToString(value: Date | string): string {
  return moment(value).format("YYYY-MM-DDTHH:mm:ss.SSS");
}

interface DateTimeFilterProps {
  loading: boolean;
  error: boolean;
  filterData: DateTimeFilterData | null;
  range: { min: any; max: any } | null;
  onChange: (filterData: DateTimeFilterData) => void;
  fetchColumnRange: (filterData: FilterData | null) => void;
}
const DateTimeFilter: React.FunctionComponent<DateTimeFilterProps> = React.memo(
  (props) => {
    const propsOperation = props.filterData
      ? props.filterData.operation
      : "equal";
    const propsValue = props.filterData
      ? parseFromUtcValue(props.filterData.value)
      : null;
    const propsValueFrom = props.filterData
      ? parseFromUtcValue(props.filterData.valueFrom)
      : null;
    const propsValueTo = props.filterData
      ? parseFromUtcValue(props.filterData.valueTo)
      : null;
    const propsAllowNulls = props.filterData
      ? props.filterData.allowNulls
      : false;

    const [activeTab, setActiveTab] = React.useState(
      propsOperation === "between" ? "range" : "single"
    );
    const [operation, setOperation] = React.useState<EqualityValue>(
      propsOperation === "between" ? "equal" : propsOperation
    );
    const [value, setValue] = React.useState(propsValue);
    const [valueFrom, setValueFrom] = React.useState(propsValueFrom);
    const [valueTo, setValueTo] = React.useState(propsValueTo);
    const [allowNulls, setAllowNulls] =
      React.useState<boolean>(propsAllowNulls);

    let displayValueFrom = valueFrom;
    let displayValueTo = valueTo;
    if (displayValueFrom === null && props.range) {
      displayValueFrom = dateToString(props.range.min);
    }
    if (displayValueTo === null && props.range) {
      displayValueTo = dateToString(props.range.max);
    }

    React.useEffect(() => {
      props.onChange({
        type: "date_time",
        operation: activeTab == "range" ? "between" : operation,
        value: parseToUtcValue(value),
        valueFrom: parseToUtcValue(displayValueFrom),
        valueTo: parseToUtcValue(displayValueTo),
        allowNulls,
      });
    }, [
      activeTab,
      operation,
      value,
      valueFrom,
      valueTo,
      allowNulls,
      props.range,
    ]);
    const changeValue = (value: Date | null) => {
      if (value === null) {
        setValue(null);
        return;
      }
      setValue(dateToString(value));
    };
    const changeValueFrom = (value: Date | null) => {
      if (value === null) {
        setValueFrom(null);
        return;
      }
      setValueFrom(dateToString(value));
    };
    const changeValueTo = (value: Date | null) => {
      if (value === null) {
        setValueTo(null);
        return;
      }
      setValueTo(dateToString(value));
    };
    React.useEffect(() => {
      if (activeTab !== "range") {
        return;
      }
      props.fetchColumnRange(null);
    }, [activeTab]);
    return (
      <div className={`w-100 d-flex flex-column`}>
        <FilterTabs>
          <FilterTab
            tabName="single"
            activeTab={activeTab}
            changeTab={setActiveTab}
          >
            <FormattedMessage
              id="NPT_TABLE_FILTER_VALUE"
              defaultMessage="Value"
              description="Single value"
            />
          </FilterTab>
          <FilterTab
            tabName="range"
            activeTab={activeTab}
            changeTab={setActiveTab}
          >
            <FormattedMessage
              id="NPT_TABLE_FILTER_RANGE"
              defaultMessage="Range"
              description="Range value"
            />
          </FilterTab>
        </FilterTabs>
        <FilterTabContent tabName="single" activeTab={activeTab}>
          <DateTimePicker
            displayTime={true}
            focus={true}
            date={value || undefined}
            getCurrentDate={changeValue}
          >
            <div className="input-group-prepend">
              <span className="input-group-text p-0">
                <EqualitySelector value={operation} onChange={setOperation} />
              </span>
            </div>
          </DateTimePicker>
        </FilterTabContent>
        <FilterTabContent tabName="range" activeTab={activeTab}>
          <div className="d-flex flex-column">
            <div className="w-100">
              {props.loading && <Loading />}
              <DateTimePicker
                displayTime={true}
                focus={true}
                disabled={valueFrom == null && props.loading}
                date={displayValueFrom || undefined}
                getCurrentDate={changeValueFrom}
              />
              <span className="d-flex align-items-center px-2">-</span>
              <DateTimePicker
                displayTime={true}
                disabled={valueTo == null && props.loading}
                date={displayValueTo || undefined}
                getCurrentDate={changeValueTo}
              />
            </div>
            {props.error && <ErrorPlaceholder />}
          </div>
        </FilterTabContent>
        <EmptyCellsFilter value={allowNulls} onChange={setAllowNulls} />
      </div>
    );
  }
);

const Loading = () => {
  return (
    <div className="alert alert-info p-1 w-100">
      <FontAwesomeIcon className="mr-2" icon={faSpinner} />
      <FormattedMessage id="NPT_TABLE_LOADING" />
    </div>
  );
};
const ErrorPlaceholder: React.FunctionComponent = React.memo((props) => (
  <div className="alert alert-danger my-2">
    <FormattedMessage
      id="NPT_TABLE_FILTER_LOADING_ERROR"
      defaultMessage="Error occured during data fetch"
      description="Fetch error placeholder"
    />
  </div>
));

export default DateTimeFilter;
