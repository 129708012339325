import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import {
  ClipboardItem,
  ClipboardState,
  CLIPBOARD_ACTION_COPY,
} from "../types/clipboard";
import { LocaleState } from "../types/locale";

import { I18NString } from "../types/modal";

const initialState: ClipboardState = {};

export const clipboard = createModel<RootModel>()({
  state: initialState,
  reducers: {
    copyToClipboard(state, items: ClipboardItem[]) {
      return { ...state, data: { items, action: CLIPBOARD_ACTION_COPY } };
    },
  },
  effects: (dispatch) => ({}),
});
