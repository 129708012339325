import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import {
  isI18NString,
  ModalInfo,
  ModalStatus,
  ViewModalOptions,
} from "../../../types/modal";
import ModalView from "../ModalView";
import { ConnectedView } from "../../view/View";

interface ViewModalData {
  url: string;
  viewData?: any;
  subjectData?: any;
}

interface ViewModalProps {
  modal: ModalInfo;

  closeModal: (status: ModalStatus, result: any) => void;
}
interface ViewModalState {
  result: any;
}
export default class ViewModal extends React.Component<
  ViewModalProps,
  ViewModalState
> {
  private title: any = { id: "View modal" };
  constructor(props: ViewModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    const title = this.props.modal.options.title;

    this.title.id = isI18NString(title) ? title.id : title || "";
    this.state = {
      result: null,
    };
  }

  setResult = (result: any) => {
    this.setState({ result });
  };
  renderTemplate(): React.ReactElement {
    const viewOptions = this.props.modal.options as ViewModalOptions;
    if (!viewOptions) {
      return <></>;
    }
    let url = viewOptions.url;
    if (url[0] != "/") {
      url = "/" + url;
    }
    return (
      <ConnectedView
        key={"viewModal"}
        viewPath={`/view${url}`}
        subjectData={viewOptions?.subjectData}
        // editable={editable}
        viewData={viewOptions?.viewData}
        setResult={this.setResult}
        closeModal={this.closeModal}
      />
    );
  }

  closeModal(status: ModalStatus) {
    if (status == MODAL_STATUS_OK) {
      const { result } = this.state;
      this.props.closeModal(status, result);
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, undefined);
    }
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}
