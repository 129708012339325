import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import {
  fetchHistoryImpl,
  isHistoryLoaded,
  parseSubjectHistoryData,
} from "../services/subjecthistory";
import { FetchError } from "../types/error";
import {
  SubjectHistoryData,
  SubjectHistoryReducerState,
  SubjectHistoryState,
} from "../types/subjecthistory";

const initialState: SubjectHistoryReducerState = {};

const initialSubjectHistory: SubjectHistoryState = {
  subjectKey: "",
  loading: false,
  moreLoading: false,
  loadedFrom: null,
  loadedTo: null,
  error: null,
  moreError: null,
  data: {
    records: [],
    predicateMap: {},
    isEnd: false,
  },
  filteredRecords: null,
  filterPredicateId: null,
};
export const subjecthistory = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendSubjectHistoryLoading(
      state,
      payload: { subjectKey: string; before?: number }
    ) {
      const { subjectKey, ...data } = payload;
      return receiveSubjectHistoryLoading(state, data, subjectKey);
    },
    sendSubjectHistoryData(
      state,
      payload: {
        subjectKey: string;
        historyData: SubjectHistoryData;
        before?: number;
      }
    ) {
      const { subjectKey, ...data } = payload;
      return receiveSubjectHistoryData(state, data, subjectKey);
    },
    sendSubjectHistoryError(
      state,
      payload: { subjectKey: string; error: FetchError; before?: number }
    ) {
      const { subjectKey, ...data } = payload;
      return receiveSubjectHistoryError(state, data, subjectKey);
    },
  },
  effects: (dispatch) => ({
    fetchHistory: async (data: { subjectKey: string; before?: number }, s) => {
      const { subjectKey, before } = data;
      try {
        const historyState = s.subjecthistory?.[subjectKey];
        if (historyState && historyState.loading) {
          return;
        }

        if (!isHistoryLoaded(historyState, before)) {
          dispatch.subjecthistory.sendSubjectHistoryLoading({
            subjectKey,
            before,
          });
          dispatch.subjecthistory.sendSubjectHistoryData({
            subjectKey,
            historyData: parseSubjectHistoryData(
              await fetchHistoryImpl(subjectKey, before)
            ),
            before,
          });
        }
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.subjecthistory.sendSubjectHistoryError({
            subjectKey,
            error: { code: e.code, message: e.message },
            before,
          });
        } else if (typeof e.message == "string") {
          dispatch.subjecthistory.sendSubjectHistoryError({
            subjectKey,
            error: { code: -1, message: e.message },
            before,
          });
        } else {
          dispatch.subjecthistory.sendSubjectHistoryError({
            subjectKey,
            error: { code: -1, message: "Unknown error" },
            before,
          });
        }
      }
    },
  }),
});

/*********************
 * Reducer funcitons *
 *********************/

function receiveSubjectHistoryLoading(
  state: SubjectHistoryReducerState,
  payload: { before?: number },
  subjectKey: string
): SubjectHistoryReducerState {
  const nextState = { ...state };
  const historyState = {
    ...(nextState[subjectKey] || initialSubjectHistory),
    subjectKey: subjectKey,
  };
  if (payload.before) {
    historyState.moreLoading = true;
    historyState.moreError = null;
  } else {
    historyState.loading = true;
    historyState.error = null;
  }
  nextState[subjectKey] = historyState;
  return nextState;
}

function receiveSubjectHistoryData(
  state: SubjectHistoryReducerState,
  payload: { historyData: SubjectHistoryData; before?: number },
  subjectKey: string
): SubjectHistoryReducerState {
  const nextState = { ...state };
  const historyState = {
    ...(nextState[subjectKey] || initialSubjectHistory),
    subjectKey: subjectKey,
  };
  if (payload.before) {
    historyState.moreLoading = false;
    historyState.moreError = null;
  } else {
    historyState.loading = false;
    historyState.error = null;
  }
  const sortedRecords = payload.historyData.records.sort(
    (recordA, recordB) => recordB.id - recordA.id
  );
  historyState.data = {
    records: historyState.data.records.concat(sortedRecords),
    predicateMap: historyState.data.predicateMap,
    isEnd: payload.historyData.isEnd,
  };
  if (sortedRecords.length !== 0) {
    const recordFromId = sortedRecords[sortedRecords.length - 1].id;
    const recordToId = sortedRecords[0].id;
    if (
      historyState.loadedFrom === null ||
      recordFromId < historyState.loadedFrom
    ) {
      historyState.loadedFrom = recordFromId;
    }
    if (historyState.loadedTo === null || recordToId > historyState.loadedTo) {
      historyState.loadedTo = recordToId;
    }
  }
  nextState[subjectKey] = historyState;
  return nextState;
}

function receiveSubjectHistoryError(
  state: SubjectHistoryReducerState,
  payload: { error: FetchError; before?: number },
  subjectKey: string
): SubjectHistoryReducerState {
  const nextState = { ...state };
  const historyState = {
    ...(nextState[subjectKey] || initialSubjectHistory),
    subjectKey: subjectKey,
  };
  if (payload.before) {
    historyState.moreLoading = false;
    historyState.moreError = payload.error;
  } else {
    historyState.loading = false;
    historyState.error = payload.error;
  }
  nextState[subjectKey] = historyState;
  return nextState;
}
