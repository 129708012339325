import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import {
  changeProcessImpl,
  fetchProcessInfoImpl,
  fetchProcessLogImpl,
  filterLogFile,
  findModule,
  parseProcessLabels,
} from "../services/process";
import { FetchError } from "../types/error";
import {
  LogFile,
  LogInfo,
  LogRecordLevel,
  ProcessActionError,
  ProcessActionResult,
  ProcessManagerInfo,
  ProcessState,
} from "../types/process";

const initialState: ProcessState = {};

export const process = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendProcessInfo(state, processInfo: ProcessManagerInfo) {
      return {
        ...state,
        loading: false,
        processInfo: processInfo,
        processLabels: parseProcessLabels(processInfo),
      };
    },
    sendProcessInfoError(state, error: FetchError) {
      return { ...state, loading: false, processInfo: error };
    },
    sendProcessClearInfo(state) {
      return { ...state, processInfo: undefined };
    },
    sendProcessInfoLoading(state) {
      return { ...state, loading: true };
    },
    sendProcessActionResult(state, result: ProcessActionResult) {
      return { ...state, lastAction: result };
    },
    sendProcessActionError(state, error: ProcessActionError) {
      return { ...state, lastAction: error };
    },
    sendProcessClearAction(state) {
      return { ...state, lastAction: undefined };
    },
    sendProcessLogLoading(
      state,
      payload: { fc: number; src: number; dev: number }
    ) {
      const foundModule = findModule(
        state.processInfo,
        payload.fc,
        payload.src,
        payload.dev
      );
      if (!foundModule) {
        return state;
      }
      const logInfo: LogInfo = {
        fc: payload.fc,
        src: payload.src,
        module: foundModule,
        loading: true,
      };
      return { ...state, logInfo };
    },
    sendProcessLog(
      state,
      payload: { fc: number; src: number; dev: number; logFile: LogFile }
    ) {
      const logInfo = state.logInfo;
      if (!logInfo) {
        return state;
      }
      if (
        logInfo.fc != payload.fc ||
        logInfo.src != payload.src ||
        logInfo.module.dev != payload.dev
      ) {
        return state;
      }
      return {
        ...state,
        logInfo: {
          ...logInfo,
          loading: undefined,
          logFile: filterLogFile(payload.logFile, state.prefferedLogLevels),
        },
      };
    },
    sendProcessLogError(
      state,
      payload: { fc: number; src: number; dev: number; error: FetchError }
    ) {
      const logInfo = state.logInfo;
      if (!logInfo) {
        return state;
      }
      if (
        logInfo.fc != payload.fc ||
        logInfo.src != payload.src ||
        logInfo.module.dev != payload.dev
      ) {
        return state;
      }
      return {
        ...state,
        logInfo: { ...logInfo, loading: undefined, logFile: payload.error },
      };
    },
    sendProcessClearLog(state) {
      return { ...state, logInfo: undefined };
    },
    sendPrefferedLogLevels(state, levels: LogRecordLevel[]) {
      return { ...state, prefferedLogLevels: [...levels] };
    },
  },
  effects: (dispatch) => ({
    fetchProcessInfo: async (_: void, s) => {
      try {
        if (s && typeof s.process != "undefined" && s.process.loading) {
          return;
        }

        dispatch.process.sendProcessInfoLoading();
        dispatch.process.sendProcessInfo(await fetchProcessInfoImpl());
        console.log(s.process);
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.process.sendProcessInfoError({
            code: e.code,
            message: e.message,
          });
        } else if (typeof e.message == "string") {
          dispatch.process.sendProcessInfoError({
            code: -1,
            message: e.message,
          });
        } else {
          dispatch.process.sendProcessInfoError({
            code: -1,
            message: "Unknown error",
          });
        }
      }
    },
    changeProcess: async (r: ProcessActionResult) => {
      try {
        await changeProcessImpl(r);
        dispatch.process.sendProcessActionResult(r);
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.process.sendProcessActionError({
            code: e.code,
            message: e.message,
            action: r.action,
            address: r.address,
          });
        } else if (typeof e.message == "string") {
          dispatch.process.sendProcessActionError({
            code: -1,
            message: e.message,
            action: r.action,
            address: r.address,
          });
        } else {
          dispatch.process.sendProcessActionError({
            code: -1,
            message: "Unknown error",
            action: r.action,
            address: r.address,
          });
        }
      }
    },
    startProcess(address: string) {
      dispatch.process.changeProcess({ action: "start", address });
    },
    stopProcess(address: string) {
      dispatch.process.changeProcess({ action: "stop", address });
    },
    blockProcess(address: string) {
      dispatch.process.changeProcess({ action: "block", address });
    },
    switchManual() {
      dispatch.process.changeProcess({ action: "manual" });
    },
    switchAuto() {
      dispatch.process.changeProcess({ action: "auto" });
    },
    fetchProcessLog: async (
      data: { fc: number; src: number; dev: number },
      s
    ) => {
      const { fc, src, dev } = data;
      try {
        if (!s.process) {
          await Promise.resolve(dispatch.process.fetchProcessInfo());
        }
        dispatch.process.sendProcessLogLoading({ fc, src, dev });
        dispatch.process.sendProcessLog({
          fc,
          src,
          dev,
          logFile: await fetchProcessLogImpl(fc, src, dev),
        });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.process.sendProcessLogError({
            fc,
            src,
            dev,
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.process.sendProcessLogError({
            fc,
            src,
            dev,
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.process.sendProcessLogError({
            fc,
            src,
            dev,
            error: {
              code: -1,
              message: "Unknown error",
            },
          });
        }
      }
    },
  }),
});
