export const NAMESPACE = "namespaces"

export const SEND_NAMESPACES = 'SEND_NAMESPACES';
export type SEND_NAMESPACES = typeof SEND_NAMESPACES;

export const SEND_NAMESPACE_LOADING = 'SEND_NAMESPACE_LOADING';
export type SEND_NAMESPACE_LOADING = typeof SEND_NAMESPACE_LOADING;

export const SEND_NAMESPACE_ERROR = 'SEND_NAMESPACE_ERROR';
export type SEND_NAMESPACE_ERROR = typeof SEND_NAMESPACE_ERROR;

export const SEND_NAMESPACE_TOTAL = 'SEND_NAMESPACE_TOTAL';
export type SEND_NAMESPACE_TOTAL = typeof SEND_NAMESPACE_TOTAL; 

export const SEND_LIMIT = 'SEND_LIMIT';
export type SEND_LIMIT = typeof SEND_LIMIT;

export const SEND_OFFSET = 'SEND_OFFSET';
export type SEND_OFFSET = typeof SEND_OFFSET;

export const SEND_ITEM_CHECKED = 'SEND_ITEM_CHECKED';
export type SEND_ITEM_CHECKED = typeof SEND_ITEM_CHECKED;

export const SEND_ALL_ITEM_CHECKED = 'SEND_ALL_ITEM_CHECKED';
export type SEND_ALL_ITEM_CHECKED = typeof SEND_ALL_ITEM_CHECKED;

export const SEND_STOP_EDIT = 'SEND_STOP_EDIT';
export type SEND_STOP_EDIT = typeof SEND_STOP_EDIT;

export const SEND_START_EDIT = 'SEND_START_EDIT';
export type SEND_START_EDIT = typeof SEND_START_EDIT;

export const SEND_SEARCH_INPUT_NAMESPACE = 'SEND_SEARCH_INPUT_NAMESPACE';
export type SEND_SEARCH_INPUT_NAMESPACE = typeof SEND_SEARCH_INPUT_NAMESPACE;


export const NAMESPACE_SAVE_URL = '/rest/namespace/entity';
export const NAMESPACE_DELETE_URL = '/rest/namespace/deletebatch';
export const NAMESPACE_LIST_URL = '/rest/namespace/list';
export const NAMESPACE_EDIT_URL = "/rest/namespace/entity";
export const NAMESPACE_UPLOAD_URL = "/rest/namespace/entity/list";