export const LOGVIEWER = 'logviewer';

export const SEND_LOG_RECORDS = 'SEND_LOG_RECORDS';
export type SEND_LOG_RECORDS = typeof SEND_LOG_RECORDS;

export const SEND_LOG_VIEWER_ERROR = 'SEND_LOG_VIEWER_ERROR';
export type SEND_LOG_VIEWER_ERROR = typeof SEND_LOG_VIEWER_ERROR;

export const SEND_LOG_VIEWER_LOADING = 'SEND_LOG_VIEWER_LOADING';
export type SEND_LOG_VIEWER_LOADING = typeof SEND_LOG_VIEWER_LOADING; 

export const SEND_LOG_LIMIT = 'SEND_LOG_LIMIT';
export type SEND_LOG_LIMIT = typeof SEND_LOG_LIMIT;

export const SEND_LOG_OFFSET = 'SEND_LOG_OFFSET';
export type SEND_LOG_OFFSET = typeof SEND_LOG_OFFSET; 

export const SEND_SEARCH_INPUT_LOG = 'SEND_SEARCH_INPUT_LOG';
export type SEND_SEARCH_INPUT_LOG = typeof SEND_SEARCH_INPUT_LOG;

export const SEND_CURRENT_LOG_RECORD = 'SEND_CURRENT_LOG_RECORD';
export type  SEND_CURRENT_LOG_RECORD = typeof SEND_CURRENT_LOG_RECORD;

export const SEND_LOG_LEVELS = 'SEND_LOG_LEVELS';
export type  SEND_LOG_LEVELS = typeof SEND_LOG_LEVELS;

export const SEND_LOG_TIME_RANGE = 'SEND_LOG_TIME_RANGE';
export type  SEND_LOG_TIME_RANGE = typeof SEND_LOG_TIME_RANGE;
 
export const TRACE_LEVEL = 'trace'; 
export const INFO_LEVEL = 'info'; 
export const DEBUG_LEVEL = 'debug';
export const WARN_LEVEL = 'warn'; 
export const ERROR_LEVEL = 'error';
export const FATAL_LEVEL = 'fatal'; 
 