import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ScadaState, Signal } from "../types/scada";

const DEFAULT_STATE = {
  signals: {},
};

const initialState: ScadaState = DEFAULT_STATE;

export const scada = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendScadaSignals(state, payload: { [K: string]: Signal }) {
      const signals = { ...state.signals, ...payload };
      return { ...state, signals };
    },
  },
});
