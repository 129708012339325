import { createModel } from "@rematch/core"
import { RootModel } from "."
import {
  CronInfo,
  CronState,
  CronTaskData,
  CronTaskDataBase,
  CronTasksTableResult,
  DEFAULT_CRON_STATE,
  EditCronTaskInfoBatch,
} from "../types/cron"
import { FetchError } from "../types/error"
import * as constants from "../constants/cron"
import { ServerError } from "../actions/utils"
import { dispatchError, dispatchSuccess } from "../services/alert"
import { cronExpressionCreator, validateTimeSegment } from "../components/admin/crontask/modals/cron"
import { store } from "../store"
import { I18NString } from "../types/modal"
import { ALERT_LEVEL_SUCCESS } from "../constants/alert"

const initialState: CronState = DEFAULT_CRON_STATE

export function parseScriptsObject(scripts: any): string[] {
  const scriptsArray: string[] = []
  for (let num of scripts) {
    scriptsArray.push(scripts[num])
  }
  console.log(scripts)
  return scriptsArray
}

function checkAll(checked: boolean, state: CronState) {
  let checkedObject = { ...state.info.checked }
  for (let item in checkedObject) {
    checkedObject[item] = checked
  }
  return checkedObject
}

function sendItemChecked(state: CronState, payload: any): CronState {
  const checkItemInfo = {
    ...state.info,
    checked: { ...state.info.checked, [payload.label]: payload.checked },
  }
  return { ...state, info: checkItemInfo }
}

export function isBrokenCronRule(day: string, weekDay: string) {
  if ((day === "?" && weekDay === "?") || (day !== "?" && weekDay !== "?") || (day === "" && weekDay === "")) {
    return true
  } else {
    return false
  }
}

function parseToCronTaskInfo(result: CronTaskData[]): CronInfo {
  let info: CronInfo = {
    labels: [],
    scriptPaths: {},
    seconds: {},
    minutes: {},
    hours: {},
    days: {},
    months: {},
    weekDays: {},
    cronTasks: {},
    descriptions: {},
    checked: {},
  }

  result.map((item: any) => {
    console.log(item)
    let timeSegment: string[] = item.cron.split(" ")
    if (timeSegment.length == 6) {
      info.labels.push(item.label)
      info.scriptPaths[item.label] = item.scriptPath
      info.seconds[item.label] = timeSegment[0]
      info.minutes[item.label] = timeSegment[1]
      info.hours[item.label] = timeSegment[2]
      info.days[item.label] = timeSegment[3]
      info.months[item.label] = timeSegment[4]
      info.weekDays[item.label] = timeSegment[5]
      info.cronTasks[item.label] = item.cron
      info.descriptions[item.label] = item.description
      info.checked[item.label] = false
    }
  })
  return info
}

function createEditableObject(state: CronState, label: string) {
  let ediabeleObject: CronTaskData = {
    label: label,
    scriptPath: state.info.scriptPaths[label],
    second: state.info.seconds[label],
    minute: state.info.minutes[label],
    hour: state.info.hours[label],
    day: state.info.days[label],
    month: state.info.months[label],
    weekDay: state.info.weekDays[label],
    cron: state.info.cronTasks[label],
    description: state.info.descriptions[label],
    checked: false,
  }
  let newInfo = { ...state.info, editableTask: ediabeleObject }
  return { ...state, info: newInfo }
}
function deleteEditableObject(state: CronState) {
  let withoutEditableObject = { ...state.info }
  delete withoutEditableObject.editableTask
  return { ...state, info: withoutEditableObject }
}

export const crontask = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendCronTasks(state, payload: CronTasksTableResult) {
      return {
        ...state,
        loading: false,
        info: { ...parseToCronTaskInfo(payload.list) },
        totalNumberOfElements: payload.total,
      }
    },
    sendCronTaskLoading(state) {
      return { ...state, loading: true }
    },
    sendCronTaskError(state, error: FetchError) {
      return { ...state, loading: false, error: error }
    },
    sendLimit(state, limit: number) {
      return { ...state, limit: limit }
    },
    setOffset(state, offset: number) {
      return { ...state, offset: offset }
    },
    setItemChecked(state, label: string, checked: boolean) {
      return sendItemChecked(state, {label,checked})
    },
    setAllItemsChecked(state, checked: boolean) {
      return {
        ...state,
        info: { ...state.info, checked: checkAll(checked, state) },
        isAllItemsChecked: checked,
      }
    },
    sendCreateEditableObject(state, id: string) {
      return createEditableObject(state, id)
    },
    deleteEditableObject(state) {
      return deleteEditableObject(state)
    },
    sendSearchInput(state, searchInput: string) {
      return { ...state, searchInput: searchInput }
    },
    sendSourceTreeInfo(state, tree: any) {
      return { ...state, scripts: tree, loading: false }
    },
  },
  effects: (dispatch) => ({
    async saveCronTaskImpl(row: CronTaskData): Promise<void> {
      const { checked, day, hour, minute, month, second, weekDay, ...other } = row
      const response = await fetch(constants.CRON_SAVE_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(other),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async saveCronTaskListImpl(tasks: CronTaskDataBase[]): Promise<void> {
      const response = await fetch(constants.CRON_LIST_SAVE_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tasks),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async editCronTaskImplInModal(editBatch: EditCronTaskInfoBatch): Promise<void> {
      const response = await fetch(constants.CRON_EDIT_URL, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editBatch),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async deleteCronTasksImpl(labels: string[]): Promise<void> {
      const response = await fetch(constants.CRON_DELETE_BATCH_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(labels),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
    },
    async fetchCronTasksImpl(tableInfo: any): Promise<CronTasksTableResult> {
      let url = constants.CRON_LIST_URL
      let query: string[] = []
      if (tableInfo) {
        for (const k of Object.keys(tableInfo)) {
          const v = tableInfo[k]
          if (v === undefined || String(v).trim().length === 0) {
            continue
          }
          query.push(`${k}=${v}`)
        }
      }
      if (query.length > 0) {
        url += `?${query.join("&")}`
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })

      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      return await response.json()
    },
    async fetchSourceTreeInfoImpl(): Promise<string[]> {
      const response = await fetch(constants.CRON_SCRIPT_LIST_URL)

      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      return await response.json()
    },
    async addCronTask(result: CronTaskData, state) {
      const s = state.crontask
      const cronTaskState = s
      console.log("cronTaskState,result", cronTaskState, result)
      if (cronTaskState && result != null) {
        try {
          await dispatch.crontask.saveCronTaskImpl(result)
          let tableInfo: any = {
            pageable: true,
            offset: cronTaskState.offset,
            limit: cronTaskState.limit,
          }
          dispatch.crontask.fetchCronTasks(tableInfo)
          const messge: I18NString = { id: "PREFERENCE_SAVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("CRON_TASK_SAVE_ERROR", e, dispatch)
        }
      }
    },
    async removeCronTasks(_:void, state) {
      const s = state.crontask
      const cronTaskState = s
      if (!cronTaskState) {
        return
      }
      let labelsOnDelete: string[] = []
      let checkedCronTasks = cronTaskState.info.checked

      for (let label in checkedCronTasks) {
        if (checkedCronTasks[label]) {
          labelsOnDelete.push(label)
        }
      }
      if (labelsOnDelete.length === 0) {
        return
      }
      let oldOffset = cronTaskState.offset
      let newOffset = cronTaskState.totalNumberOfElements - labelsOnDelete.length
      let limit = cronTaskState.limit
      let tableInfo: any = { pageable: true, offset: oldOffset, limit: limit }
      try {
        await dispatch.crontask.deleteCronTasksImpl(labelsOnDelete)
        //if all items were deleted on current page go to previous page
        if (oldOffset === newOffset) {
          tableInfo.offset = newOffset - limit <= 0 ? 0 : newOffset - limit
          dispatch.crontask.setOffset(tableInfo.offset)
          dispatch.crontask.fetchCronTasks(tableInfo)
        } else {
          dispatch.crontask.fetchCronTasks(tableInfo)
        }
        if (cronTaskState.isAllItemsChecked) {
          dispatch.crontask.setAllItemsChecked(false)
        }
        const messge: I18NString = { id: "CRON_TASK_REMOVE_SUCCESS" }
        dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
      } catch (e) {
        dispatchError("CRON_TASK_SAVE_ERROR", e, dispatch)
      }
    },
    async fetchCronTasks(tableInfo: any, state) {
      try {
        const s = state.crontask
        const cronTaskState = s
        if (cronTaskState && cronTaskState.loading) {
          return
        }
        cronTaskState && (tableInfo.search = cronTaskState.searchInput)
        dispatch.crontask.sendCronTaskLoading()
        dispatch.crontask.sendCronTasks(await dispatch.crontask.fetchCronTasksImpl(tableInfo))
        dispatch.crontask.setOffset(tableInfo.offset)
        dispatch.crontask.sendSourceTreeInfo(await dispatch.crontask.fetchSourceTreeInfoImpl())
      } catch (e) {
        dispatchError("CRON_TASK_FETCH_ERROR", e, dispatch)
      }
    },
    async setEditInModal(result: EditCronTaskInfoBatch, state) {
      const s = state.crontask
      const cronTaskState = s

      if (cronTaskState) {
        try {
          await dispatch.crontask.editCronTaskImplInModal(result)
          let tableInfo: any = {
            pageable: true,
            offset: cronTaskState.offset,
            limit: cronTaskState.limit,
          }
          dispatch.crontask.fetchCronTasks(tableInfo)
          const messge: I18NString = { id: "CRON_TASK_EDIT_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("CRON_TASK_EDIT_ERROR", e, dispatch)
        }
      }
    },
    async setLimit(limit: number, state) {
      const s = state.crontask
      const cronTaskState = s
      if (!cronTaskState) {
        return
      }
      let tableInfo: any = {
        pageable: true,
        offset: cronTaskState.offset,
        limit: limit,
      }
      dispatch.crontask.sendLimit(limit)
      dispatch.crontask.fetchCronTasks(tableInfo)
    },
    async setSearch(value: string, state) {
      const s = state.crontask
      const cronTaskState = s
      if (!cronTaskState) {
        return
      }
      let tableInfo: any = {
        pageable: true,
        offset: 0,
        limit: cronTaskState.limit,
      }
      dispatch.crontask.sendSearchInput(value)
      dispatch.crontask.fetchCronTasks(tableInfo)
    },
    async downloadCronTasks(_:void, state) {
      const tasks = state.crontask
      if (tasks) {
        const tasksValues = tasks.info.labels
        const tasksList: CronTaskData[] = []
        for (const label of tasksValues) {
          const description = tasks.info.descriptions[label]
          const scriptPath = tasks.info.scriptPaths[label]
          const second = tasks.info.seconds[label]
          const minute = tasks.info.minutes[label]
          const hour = tasks.info.hours[label]
          const day = tasks.info.days[label]
          const weekDay = tasks.info.weekDays[label]
          const month = tasks.info.months[label]
          const task = {
            label,
            description,
            scriptPath,
            day,
            hour,
            minute,
            month,
            second,
            weekDay,
          }
          tasksList.push(task as CronTaskData)
        }
        if (tasksList.length === 0) {
          return
        }
        const a = document.createElement("a")
        a.download = "periodic_tasks.json"
        const tasksText = JSON.stringify(tasksList, null, "\t")
        const tasksBlob = new Blob([tasksText], { type: "text/plain" })
        a.href = URL.createObjectURL(tasksBlob)
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    async upload(tasksRaw: any, state) {
      let isSegmentsValid = true
      let tasks: CronTaskData[] = []
      const cronTaskState = state.crontask
      try {
        tasks = JSON.parse(tasksRaw)
      } catch (e) {
        dispatchError("CRON_TASK_EMPTY_FILE", e, dispatch)
        return
      }
      if (!tasks || !tasks.length || tasks.length === 0) {
        dispatchError("CRON_TASK_EMPTY_FILE", "file is empty", dispatch)
      }
      let resultTasks: CronTaskDataBase[] = []
      for (const task of tasks) {
        const { checked, cron, description, label, scriptPath, day, weekDay, ...timeSegments } = task
        const isCronRuleBroken = isBrokenCronRule(day, weekDay)
        let dayValid = isCronRuleBroken ? false : validateTimeSegment("day", day)
        let weekDayValid = isCronRuleBroken ? false : validateTimeSegment("weekDay", weekDay)
        isSegmentsValid = dayValid && weekDayValid
        if (!isSegmentsValid) {
          dispatchError("CRON_TASK_IS_INVALID", "One of day or weekDay fields must be equal to '?' symbol", dispatch, {
            name: label,
          })
          break
        }
        for (let se of Object.entries(timeSegments)) {
          const [name, value] = se
          isSegmentsValid = validateTimeSegment(name, value)
          if (!isSegmentsValid) {
            dispatchError("CRON_TASK_IS_INVALID", name + " field is not valid ", dispatch, { name: label })
            break
          }
        }
        if (!isSegmentsValid) {
          break
        }
        const cronExpression = cronExpressionCreator(task)
        resultTasks.push({
          label,
          description,
          scriptPath,
          cron: cronExpression,
        })
      }
      if (isSegmentsValid && resultTasks) {
        try {
          await dispatch.crontask.saveCronTaskListImpl(resultTasks)
          if (cronTaskState) {
            let tableInfo: any = {
              pageable: true,
              offset: cronTaskState.offset,
              limit: cronTaskState.limit,
            }
            dispatch.crontask.fetchCronTasks(tableInfo)
          }
          dispatchSuccess("CRON_TASK_IMPORT_SUCCESS", dispatch)
        } catch (e) {
          dispatchError("CRON_TASK_IMPORT_ERROR", e, dispatch)
        }
        console.log("uploaded")
      }
    },
  }),
})
