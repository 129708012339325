import * as React from "react";
import { FormattedMessage } from "react-intl";

import { GantDisplayScale, GantHeaderCell, RowData } from "../../types/table";

import styles from "./Table.module.css";

/**Properties of Table gant fiagram */
interface TableGantHeaderProps {
  /**Actual width of diagram (can be larger than visible part) */
  gantWidth: number;
  /**Current horizontal scroll position*/
  scrollLeft: number;
  /**Order of displaying scales in header*/
  displayScales: GantDisplayScale[];
  /**Cells info for each display scale*/
  cellsByScale: {
    year?: GantHeaderCell[];
    month?: GantHeaderCell[];
    week?: GantHeaderCell[];
    day?: GantHeaderCell[];
    hour?: GantHeaderCell[];
  };
}

/*********************************
 *  Table Gant Header Component  *
 *********************************/
export default class TableGantHeader extends React.PureComponent<TableGantHeaderProps> {
  render() {
    /**TODO: get scales height from redux */
    console.log("croll left", this.props.scrollLeft);
    const scaleHeight = 25;
    return (
      <div
        className={`${styles.nptTableHeader} ${styles.nptGantHeader}`}
        style={{ width: this.props.gantWidth, left: -this.props.scrollLeft }}
      >
        {this.props.displayScales.map((scale, index) => (
          <ScaleLine
            key={index}
            width={this.props.gantWidth}
            height={scaleHeight}
            cells={this.props.cellsByScale[scale]}
          />
        ))}
      </div>
    );
  }
}

interface ScaleLineProps {
  width: number;
  height: number;
  cells?: GantHeaderCell[];
}
const ScaleLine: React.FunctionComponent<ScaleLineProps> = React.memo(
  (props: ScaleLineProps) => {
    if (!props.cells) {
      return null;
    }
    return (
      <div className={styles.nptGantScale} style={{ height: props.height }}>
        {props.cells.map((cell, index) => (
          <ScaleCell key={index} gantWidth={props.width} cell={cell} />
        ))}
      </div>
    );
  }
);

interface ScaleCellProps {
  gantWidth: number;
  cell: GantHeaderCell;
}
const ScaleCell: React.FunctionComponent<ScaleCellProps> = React.memo(
  (props: ScaleCellProps) => {
    return (
      <div
        className={`${styles.nptGantScaleCell} bg-dark-primary border-darker-primary`}
        style={{ width: props.cell.width * props.gantWidth }}
      >
        {props.cell.formattedValue ? (
          <FormattedMessage id={props.cell.formattedValue} />
        ) : (
          <span>{props.cell.value}</span>
        )}
      </div>
    );
  }
);
