import { createModel } from "@rematch/core"
import { RootModel } from "."
import { store } from "../store"
import * as constants from "../constants/namespace"
import { FetchError } from "../types/error"
import { DEFAULT_NAMESPACE_STATE, EditNamespaceInfo, NamespaceData, NamespaceInfo, NamespaceState, NamespaceTableResult } from "../types/namespace"
import { dispatchError } from "../services/alert"
import { I18NString } from "../types/modal"
import { ServerError } from "../actions/utils"
import { ALERT_LEVEL_SUCCESS } from "../constants/alert"

const initialState: NamespaceState = DEFAULT_NAMESPACE_STATE

function sendItemChecked(state: NamespaceState, payload: any): NamespaceState {
  const checkItemInfo = {
    ...state.info,
    checked: { ...state.info.checked, [payload.url]: payload.checked },
  }
  return { ...state, info: checkItemInfo }
}

function checkAll(checked: boolean, state: NamespaceState) {
  let checkedObject = { ...state.info.checked }
  for (let item in checkedObject) {
    checkedObject[item] = checked
  }
  return checkedObject
}

function stopEdit(state: NamespaceState) {
  if (state.info.edited && state.info.edited.url && state.info.edited.columnName) {
    let info = { ...state.info }
    delete info.edited
    return { ...state, info: info }
  }
  return state
}

function parseToNamespaceInfo(result: NamespaceData[]): NamespaceInfo {
  let info: NamespaceInfo = { urls: [], checked: {}, prefixes: {} }
  result.map((item: any) => {
    info.urls.push(item.url)
    info.prefixes[item.url] = item.prefix
    info.checked[item.url] = false
  })
  return info
}

export const namespaces = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendNamespaces(state, payload: NamespaceTableResult) {
      return { ...state, loading: false, info: { ...parseToNamespaceInfo(payload.list) }, totalNumberOfElements: payload.total }
    },
    sendNamespaceLoading(state) {
      return { ...state, loading: true }
    },
    sendNamespaceError(state, payload: { error: FetchError }) {
      return { ...state, loading: false, error: payload.error }
    },
    sendLimit(state, payload: { limit: number }) {
      return { ...state, limit: payload.limit }
    },
    sendOffset(state, payload: { offset: number }) {
      return { ...state, offset: payload.offset }
    },
    sendItemChecked(state, payload: { url: string; checked: boolean }) {
      return sendItemChecked(state, payload)
    },
    sendAllItemChecked(state, payload: { checked: boolean }) {
      return { ...state, info: { ...state.info, checked: checkAll(payload.checked, state) }, isAllItemsChecked: payload.checked }
    },
    sendStartEdit(state, payload: { url: string; columnName: string }) {
      return { ...state, info: { ...state.info, edited: { url: payload.url, columnName: payload.columnName, columnValue: "" } } }
    },
    sendStopEdit(state) {
      return stopEdit(state)
    },
    sendSearchInput(state, payload: { searchInput: string }) {
      return { ...state, searchInput: payload.searchInput }
    },
  },

  effects: (dispatch) => ({
    async addNamespace(namespace: NamespaceData, state) {
      const s = state.namespaces
      const namespaceState = s
      if (namespaceState && namespace != null) {
        try {
          await dispatch.namespaces.saveNamespaceImpl(namespace)
          let tableInfo = {
            pageable: true,
            offset: namespaceState.offset,
            limit: namespaceState.limit,
          }
          dispatch.namespaces.fetchNamespaces(tableInfo)
          const messge: I18NString = { id: "NAMESPACE_SAVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("NAMESPACE_SAVE_ERROR", e, dispatch)
        }
      } else {
        return
      }
    },
    async removeNamespaces(_:void, state) {
      const s = state.namespaces
      const namespaceState = s
      if (namespaceState) {
        let urlsOnDelete: string[] = []

        let checkedNamespaces = namespaceState.info.checked
        for (let url in checkedNamespaces) {
          if (checkedNamespaces[url]) {
            urlsOnDelete.push(url)
          }
        }
        if (urlsOnDelete.length === 0) {
          return
        }
        let oldOffset = namespaceState.offset
        let newOffset = namespaceState.totalNumberOfElements - urlsOnDelete.length
        let limit = namespaceState.limit
        let tableInfo: any = { pageable: true, offset: oldOffset, limit: limit }
        try {
          await dispatch.namespaces.deleteNamespacesImpl(urlsOnDelete)
          //if all items were deleted on current page go to previous page
          if (oldOffset === newOffset) {
            tableInfo.offset = newOffset - limit <= 0 ? 0 : newOffset - limit
            dispatch.namespaces.sendOffset(tableInfo.offset)
            dispatch.namespaces.fetchNamespaces(tableInfo)
          } else {
            dispatch.namespaces.fetchNamespaces(tableInfo)
          }
          if (namespaceState.isAllItemsChecked) {
            dispatch.namespaces.sendAllItemChecked({ checked: false })
          }
          const messge: I18NString = { id: "NAMESPACE_REMOVE_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("NAMESPACE_REMOVE_ERROR", e, dispatch)
        }
      }
    },
    async fetchNamespaces(tableInfo: any, state) {
      try {
        const s = state.namespaces
        const namespaceState = s
        if (namespaceState && namespaceState.loading) {
          return
        }
        namespaceState && (tableInfo.search = namespaceState.searchInput)
        dispatch.namespaces.sendNamespaceLoading()
        dispatch.namespaces.sendNamespaces(await dispatch.namespaces.fetchNamespacesImpl(tableInfo))
        dispatch.namespaces.setOffset(tableInfo.offset)
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.namespaces.sendNamespaceError({ error: { code: e.code, message: e.message } })
        } else if (typeof e.message == "string") {
          dispatch.namespaces.sendNamespaceError({ error: { code: -1, message: e.message } })
        } else {
          dispatch.namespaces.sendNamespaceError({ error: { code: -1, message: "Unknown error" } })
        }
      }
    },
    setOffset(offset: number) {
      dispatch.namespaces.sendOffset({ offset })
    },
    async setLimit(limit: number, state) {
      const s = state.namespaces
      const namespaceState = s
      if (!namespaceState) {
        return
      }
      let tableInfo: any = { pageable: true, offset: 0, limit: limit }
      dispatch.namespaces.sendLimit({ limit })
      dispatch.namespaces.fetchNamespaces(tableInfo)
    },
    async setItemChecked(payload: { url: string; checked: boolean }) {
      dispatch.namespaces.sendItemChecked({ url: payload.url, checked: payload.checked })
    },
    async setAllItemsChecked(checked: boolean) {
      dispatch.namespaces.sendAllItemChecked({ checked })
    },
    async setStartEdit(payload: { url: string; field: string }) {
      dispatch.namespaces.sendStartEdit({ url: payload.url, columnName: payload.field })
    },
    async setStopEditSuccess(newValue: string, state) {
      const s = state.namespaces
      const namespaceState = s
      if (namespaceState && namespaceState.info.edited) {
        try {
          let editedObject: EditNamespaceInfo = {
            ...namespaceState.info.edited,
            columnValue: newValue,
          }
          let tableInfo: any = {
            pageable: true,
            offset: namespaceState.offset,
            limit: namespaceState.limit,
          }
          await dispatch.namespaces.editNamespaceImpl(editedObject)
          dispatch.namespaces.sendStopEdit()
          dispatch.namespaces.fetchNamespaces(tableInfo)
          const messge: I18NString = { id: "NAMESPACE_EDIT_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("NAMESPACE_EDIT_ERROR", e, dispatch)
        }
      }
    },
    async setStopEditCancelled() {
      dispatch.namespaces.sendStopEdit()
    },
    async confirmDelete(payload: { callback: () => void }, state) {
      const info = state.namespaces?.info
      if (!info) {
        return null
      }
      const names: string[] = []
      if (info.checked) {
        Object.entries(info.checked).forEach((e) => {
          e[1] && names.push(e[0])
        })
      }
      if (!names.length) {
        return
      }
      dispatch.modal.openModal({
        id: "namespace.confirmDelete",
        type: "confirmDelete",
        options: {
          title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
          body: {
            id: "NAMESPACE_CONFIRM_DELETE_MODAL",
            values: { name: names.join(", ") },
          },
        },
        okCallback: async (result) => {
          if (result) {
            payload.callback()
          }
        },
      })
    },
    async downloadNamespaces(_:void, state) {
      const namespaces = state.namespaces
      if (namespaces) {
        const namespacesValues = namespaces.info.urls
        const namespacesList: NamespaceData[] = []
        for (const key of namespacesValues) {
          const namespace: NamespaceData = {
            url: key,
            prefix: namespaces.info.prefixes[key],
          }
          namespacesList.push(namespace)
        }
        if (namespacesList.length === 0) {
          return
        }
        const a = document.createElement("a")
        a.download = "namespaces.json"
        const namespacesText = JSON.stringify(namespacesList, null, "\t")
        const namespacesBlob = new Blob([namespacesText], { type: "text/plain" })
        a.href = URL.createObjectURL(namespacesBlob)
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    async upload(namespaces: NamespaceData[], state) {
      await Promise.resolve(dispatch.namespaces.uploadImpl(namespaces))
      const ns = state.namespaces
      let tableInfo: any = { offset: 0, limit: 20 }
      if (ns) {
        tableInfo.offset = ns.offset
        tableInfo.limit = ns.limit
      }

      dispatch.namespaces.fetchNamespaces(tableInfo)
    },
    async uploadImpl(namespaces: NamespaceData[]): Promise<NamespaceData[]> {
      const response = await fetch(constants.NAMESPACE_UPLOAD_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(namespaces),
      })
      if (!response.ok) {
        throw new ServerError(response.status, response.statusText)
      }
      const savedNamespaces = await response.json()
      return savedNamespaces
    },
    async saveNamespaceImpl(row: NamespaceData): Promise<NamespaceData> {
      return fetch(constants.NAMESPACE_SAVE_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(row),
      })
        .then((resp) => {
          if (!resp.ok) {
            throw new ServerError(resp.status, resp.statusText)
          }
          return resp.json()
        })
        .then((json) => {
          json.checked = false
          return json
        })
    },
    async deleteNamespacesImpl(urls: string[]): Promise<void> {
      const resp = await fetch(constants.NAMESPACE_DELETE_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(urls),
      })
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
    },
    async fetchNamespacesImpl(tableInfo: any): Promise<NamespaceTableResult> {
      let url = constants.NAMESPACE_LIST_URL
      let query: string[] = []
      if (tableInfo) {
        for (const k of Object.keys(tableInfo)) {
          const v = tableInfo[k]
          if (v === undefined || String(v).trim().length === 0) {
            continue
          }
          query.push(`${k}=${v}`)
        }
      }
      if (query.length > 0) {
        url += `?${query.join("&")}`
      }
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })

      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
      return await resp.json()
    },
    async editNamespaceImpl(editedObjectInfo: EditNamespaceInfo): Promise<NamespaceData> {
      const resp = await fetch(constants.NAMESPACE_EDIT_URL, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedObjectInfo),
      })
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText)
      }
      return resp.json()
    },
    async setSearch(value: string, state) {
      const s = state.namespaces
      const namespaceState = s
      if (!namespaceState) {
        return
      }
      let tableInfo: any = {
        pageable: true,
        offset: 0,
        limit: namespaceState.limit,
      }
      dispatch.namespaces.sendSearchInput({ searchInput: value })
      dispatch.namespaces.fetchNamespaces(tableInfo)
    },
  }),
})
