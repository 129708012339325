import { Action, AnyAction } from "redux";

import * as constants from '../constants/namespace';
import { FetchError } from "./error";

export type NamespaceType = "namespace";

export const DEFAULT_NAMESPACE_STATE = {
    info: {
        urls: [],
        prefixes: {},
        checked: {}
    },
    totalNumberOfElements: 0,
    loading: false,
    error: null, 
    searchInput: '',
    limit: 20,
    offset: 0
}

export interface NamespaceState {
    info: NamespaceInfo,
    totalNumberOfElements: number, 
    loading?: boolean,
    error?: null | FetchError,
    searchInput?: string,
    isAllItemsChecked?: boolean,
    limit: number,
    offset: number
}

export interface NamespaceInfo {
    urls: string[],
    prefixes: { [url: string]: string },
    checked: { [url: string]: boolean },
    edited?: EditNamespaceInfo
}
export interface EditNamespaceInfo {
    url: string,
    columnName: string,
    columnValue: string
}

export interface NamespaceTableResult {
    list: NamespaceData[],
    total: number
}

export type NamespaceData = {
    url: string,
    prefix: string,
    checked?: boolean
}

///////////
//Actions//
///////////

export interface SendNamespaces extends Action<constants.SEND_NAMESPACES> {
    namespaceType: NamespaceType,
    payload: NamespaceTableResult
}

export interface SendNamespaceError extends Action<constants.SEND_NAMESPACE_ERROR> {
    namespaceType: NamespaceType,
    payload: {
        error: FetchError
    }
}

export interface SendNamespaceLoading extends Action<constants.SEND_NAMESPACE_LOADING> {
    namespaceType: NamespaceType
} 

export interface SendLimit extends Action<constants.SEND_LIMIT> {
    namespaceType: NamespaceType,
    payload: number
}

export interface SendOffset extends Action<constants.SEND_OFFSET> {
    namespaceType: NamespaceType,
    payload: number
}


export interface SendItemChecked extends Action<constants.SEND_ITEM_CHECKED> {
    namespaceType: NamespaceType,
    payload: {
        url: string,
        checked: boolean
    }
}

export interface SendAllItemChecked extends Action<constants.SEND_ALL_ITEM_CHECKED> {
    namespaceType: NamespaceType,
    payload: boolean
}

export interface SendStartEdit extends Action<constants.SEND_START_EDIT> {
    namespaceType: NamespaceType,
    payload: {
        url: string,
        columnName: string
    }
}
export interface SendStopEdit extends Action<constants.SEND_STOP_EDIT> {
    namespaceType: NamespaceType
}

export interface SendSearchInput extends Action<constants.SEND_SEARCH_INPUT_NAMESPACE> {
    namespaceType: NamespaceType,
    payload: string
}

export type NamespaceAction = SendNamespaces |
    SendNamespaceError |
    SendNamespaceLoading | 
    SendLimit |
    SendOffset |
    SendItemChecked |
    SendAllItemChecked |
    SendStartEdit |
    SendStopEdit |
    SendSearchInput;

export function isNamespaceAction(action: AnyAction): action is NamespaceAction {
    return (typeof action.type == 'string');
}

export function isNamespaceData(data: any): data is NamespaceAction {
    return typeof (data as NamespaceData).url !== undefined &&
        typeof (data as NamespaceData).prefix !== undefined;
}