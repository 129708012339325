import { Action, AnyAction } from "redux";

import { FetchError } from "./error";
import * as constants from "../constants/cron";

export type CronTaskType = "cron";

export const DEFAULT_CRON_STATE = {
  info: {
    labels: [],
    scriptPaths: {},
    seconds: {},
    minutes: {},
    hours: {},
    days: {},
    months: {},
    weekDays: {},
    cronTasks: {},
    descriptions: {},
    checked: {},
  },
  scripts: [],
  totalNumberOfElements: 0,
  loading: false,
  error: null,
  searchInput: "",
  limit: 50,
  offset: 0,
};

export interface CronState {
  info: CronInfo;
  scripts: string[];
  totalNumberOfElements: number;
  loading?: boolean;
  error?: null | FetchError;
  searchInput?: string;
  isAllItemsChecked?: boolean;
  limit: number;
  offset: number;
}

export interface CronInfo {
  labels: string[];
  scriptPaths: { [label: string]: string };
  seconds: { [label: string]: string };
  minutes: { [label: string]: string };
  hours: { [label: string]: string };
  days: { [label: string]: string };
  months: { [label: string]: string };
  weekDays: { [label: string]: string };
  cronTasks: { [label: string]: string };
  descriptions: { [label: string]: string };
  checked: { [label: string]: boolean };
  editableTask?: CronTaskData;
}

export interface EditCronTaskInfoBatch {
  label: string;
  editedFields: EditCronTaskInfo[];
}

export interface EditCronTaskInfo {
  label?: string;
  columnName: string;
  columnValue: string;
}

export interface CronTasksTableResult {
  list: CronTaskData[];
  total: number;
}

export interface CronTaskDataBase {
  label: string;
  scriptPath: string;
  description: string;
  cron: string;
}

export interface CronTaskData extends CronTaskDataBase {
  // label: string,
  // scriptPath: string,
  second: string;
  minute: string;
  hour: string;
  day: string;
  month: string;
  weekDay: string;
  // cron: string,
  // description: string,
  checked: boolean;
}
///////////
//Actions//
///////////

export interface SendCronTasks extends Action<constants.SEND_CRON_TASKS> {
  cronTaskType: CronTaskType;
  payload: CronTasksTableResult;
}

export interface SendCronTaskError
  extends Action<constants.SEND_CRON_TASK_ERROR> {
  cronTaskType: CronTaskType;
  payload: {
    error: FetchError;
  };
}

export interface SendCronTaskLoading
  extends Action<constants.SEND_CRON_TASK_LOADING> {
  cronTaskType: CronTaskType;
}

export interface SendLimit extends Action<constants.SEND_LIMIT> {
  cronTaskType: CronTaskType;
  payload: number;
}

export interface SendOffset extends Action<constants.SEND_OFFSET> {
  cronTaskType: CronTaskType;
  payload: number;
}

export interface SendItemChecked extends Action<constants.SEND_ITEM_CHECKED> {
  cronTaskType: CronTaskType;
  payload: {
    label: string;
    checked: boolean;
  };
}

export interface SendAllItemChecked
  extends Action<constants.SEND_ALL_ITEM_CHECKED> {
  cronTaskType: CronTaskType;
  payload: boolean;
}

export interface SendCreateEditableObject
  extends Action<constants.SEND_CREATE_EDITABLE_OBJECT> {
  cronTaskType: CronTaskType;
  payload: string;
}

export interface SendRemoveEditableObject
  extends Action<constants.SEND_REMOVE_EDITABLE_OBJECT> {
  cronTaskType: CronTaskType;
}

export interface SendSearchInput
  extends Action<constants.SEND_SEARCH_INPUT_CRON> {
  cronTaskType: CronTaskType;
  payload: string;
}

export interface SendSourceTreeInfo
  extends Action<constants.SEND_SOURCE_TREE_INFO> {
  cronTaskType: CronTaskType;
  payload: any;
}

export type CronTaskAction =
  | SendCronTasks
  | SendCronTaskError
  | SendCronTaskLoading
  | SendLimit
  | SendOffset
  | SendItemChecked
  | SendAllItemChecked
  | SendCreateEditableObject
  | SendRemoveEditableObject
  | SendSearchInput
  | SendSourceTreeInfo;

export function isCronTaskAction(action: AnyAction): action is CronTaskAction {
  return typeof action.type == "string";
}
