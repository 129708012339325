import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckBoxKeys } from "../../../constants/subject";
import { CheckVariant } from "../../../types/subject";
import styles from "./CheckboxInput.module.css";

type CheckBoxSize = "lg" | "sm";

interface CheckBoxProps {
  variants: CheckVariant[];
  change: (data: any, options?: any) => void;
  value?: any;
  disabled?: boolean;
}

const getRoundSize = (size: CheckBoxSize): CSSProperties => {
  return {
    width: size === "lg" ? "20px" : "13px",
    height: size === "lg" ? "20px" : "13px",
  };
};
const checked = (size: CheckBoxSize) => ({
  left: "calc(100% - 2px)",
  transform: "translateX(-100%)",
  ...getRoundSize(size),
});
const neutral = (containerWidth: number, size: CheckBoxSize) => ({
  left: "calc(100% - 2px)",
  transform: `translateX(-${containerWidth / 2 + 8}px)`,
  ...getRoundSize(size),
});

const unchecked = (size: CheckBoxSize) => ({
  left: "2px",
  ...getRoundSize(size),
});

interface SliderCheckboxProps extends CheckBoxProps {
  size?: CheckBoxSize;
}
export const SliderCheckbox = (props: SliderCheckboxProps) => {
  const { change, variants, value, disabled, size } = props;
  const intl = useIntl();
  let chkSize = size || "lg";
  const ref = useRef<HTMLSpanElement | null>(null);
  const chooseValue = () => {
    let variant: CheckVariant = variants[0];
    if (value === true) {
      variant = variants.length === 3 ? variants[2] : variants[1];
    } else if (value === false) {
      variant = variants.length === 3 ? variants[1] : variants[0];
    }
    return variant;
  };
  useEffect(() => {
    setCurrentVariant(chooseValue());
  }, [value]);
  const [currVariant, setCurrentVariant] = useState(chooseValue());
  const [prevVariant, setPrevVariant] = useState<CheckVariant | null>(null);
  const [cbWidth, setWidth] = useState<number>(0);

  let labelLength = 0;
  let longestVariant = variants[1];
  variants.forEach((v) => {
    const label =
      typeof v.label === "string"
        ? v.label
        : intl.formatMessage({ id: v.label.id }, v.label.values);
    if (label.length > labelLength && v.key !== CheckBoxKeys.NOTHING) {
      labelLength = label.length;
      longestVariant = v;
    }
  });

  const clickHandler = () => {
    if (disabled) {
      return;
    }
    if (variants.length === 2) {
      let newVariant = variants[1];
      if (currVariant.key === CheckBoxKeys.YES) {
        newVariant = variants[0];
      }
      setCurrentVariant(newVariant);
      change(newVariant.value);
    } else {
      if (currVariant.key === CheckBoxKeys.NO) {
        setPrevVariant(variants[1]);
        setCurrentVariant(variants[0]);
        change(variants[0].value);
        return;
      }
      if (currVariant.key === CheckBoxKeys.YES) {
        setPrevVariant(variants[2]);
        setCurrentVariant(variants[0]);
        change(variants[0].value);
        return;
      }
      if (!prevVariant) {
        setPrevVariant(variants[0]);
        setCurrentVariant(variants[2]);
        change(variants[2].value);
        return;
      }

      let newVariant =
        prevVariant.key === CheckBoxKeys.NO ? variants[2] : variants[1];
      setCurrentVariant(newVariant);
      change(newVariant.value);
    }
  };
  const containerColorCss =
    currVariant.key === CheckBoxKeys.YES
      ? "bg-success border border-success"
      : currVariant.key === CheckBoxKeys.NO
      ? "bg-danger border border-danger"
      : "bg-secondary border border-primary";

  const roundStyle =
    currVariant.key === CheckBoxKeys.YES
      ? checked(chkSize)
      : currVariant.key === CheckBoxKeys.NO
      ? unchecked(chkSize)
      : neutral(cbWidth, chkSize);

  const roundBackGround = currVariant.key === "nothing" ? "bg-primary" : "";
  let label = null;
  if (currVariant.key !== "nothing") {
    label =
      typeof currVariant.label === "string" ? (
        currVariant.label
      ) : (
        <FormattedMessage
          id={currVariant.label.id}
          values={currVariant.label.values}
        />
      );
  }
  let longestElement =
    typeof longestVariant.label === "string" ? (
      longestVariant.label
    ) : (
      <FormattedMessage
        id={longestVariant.label.id}
        values={longestVariant.label.values}
      />
    );
  let containerStyle: any = {
    width: `${cbWidth}px`,
    height: chkSize === "lg" ? "26px" : "19px",
  };
  if (currVariant.key === CheckBoxKeys.NO) {
    containerStyle = { ...containerStyle, ["textAlign"]: "right" };
  }
  if (disabled) {
    containerStyle = { ...containerStyle, ["cursor"]: "not-allowed" };
  }

  return (
    <div>
      <span
        className={`position-absolute ${styles.hiddenLabel}`}
        ref={(ref) => {
          if (ref) {
            let width = ref.offsetWidth || 0;
            if (width) {
              width += 40;
            } else {
              width = chkSize === "lg" ? 50 : 30;
            }
            setWidth(width);
          }
        }}
      >
        {longestElement}
      </span>
      <div
        style={containerStyle}
        onClick={() => clickHandler()}
        className={`${styles.container} ${containerColorCss}  shadow-sm text-white pb-2 px-2`}
      >
        {label}
        <div
          style={roundStyle}
          className={`${styles.round} ${roundBackGround}`}
        ></div>
      </div>
    </div>
  );
};

export const ButtonsCheckbox = (props: CheckBoxProps) => {
  const { variants, change, value, disabled } = props;
  const chooseValue = () => {
    let variant: CheckVariant = variants[0];

    if (variants.length === 2) {
      variant = value === true ? variants[1] : variants[0];
    } else if (variants.length === 3) {
      variant =
        value === true
          ? variants[2]
          : value === false
          ? variants[1]
          : variants[0];
    }

    return variant;
  };
  useEffect(() => {
    setVariant(chooseValue());
  }, [value]);
  const [variant, setVariant] = useState(chooseValue());
  let firstBtnClass = "secondary";
  let secondBtnClass = "secondary";
  switch (variant.key) {
    case CheckBoxKeys.YES:
      firstBtnClass = "success";
      break;
    case CheckBoxKeys.NO:
      secondBtnClass = "danger";
      break;
  }
  const firstLabel =
    variants.length === 3 ? variants[2].label : variants[1].label;
  const secondLabel =
    variants.length === 3 ? variants[1].label : variants[0].label;
  const clickYesHandler = () => {
    let newVariant = variants.length === 3 ? variants[2] : variants[1];
    if (variant.key === CheckBoxKeys.YES) {
      newVariant = variants[0];
    }
    setVariant(newVariant);
    const { value } = newVariant;
    change(value);
  };
  const clickNoHandler = () => {
    let newVariant = variants.length === 3 ? variants[1] : variants[0];
    if (variant.key === CheckBoxKeys.NO) {
      newVariant = variants.length === 3 ? variants[0] : variants[1];
    }
    setVariant(newVariant);
    const { value } = newVariant;
    change(value);
  };
  const disabledStyle = disabled ? { cursor: "not-allowed" } : {};
  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      <button
        style={disabledStyle}
        disabled={disabled}
        type="button"
        onClick={() => clickYesHandler()}
        className={`btn btn-${firstBtnClass}`}
      >
        {firstLabel}
      </button>
      <button
        style={disabledStyle}
        disabled={disabled}
        type="button"
        onClick={() => clickNoHandler()}
        className={`btn btn-${secondBtnClass}`}
      >
        {secondLabel}
      </button>
    </div>
  );
};

export const DropDownCheckbox = (props: CheckBoxProps) => {
  const { variants, change, value, disabled } = props;
  const chooseValue = () => {
    let variant: CheckVariant = variants[0];
    if (value === true) {
      variant = variants.length === 3 ? variants[2] : variants[1];
    }
    return variant;
  };
  useEffect(() => {
    setLabel(chooseValue().label);
  }, [value]);

  const [label, setLabel] = useState(chooseValue().label);
  const [visible, setVisible] = useState(false);
  const handleValueChange = (idx: number) => {
    const { value, label } = variants[idx];
    setLabel(label);
    change(value);
    setVisible(false);
  };
  const disabledStyle = disabled ? { cursor: "not-allowed" } : {};
  return (
    <Dropdown style={disabledStyle}>
      <Dropdown.Toggle
        disabled={disabled}
        variant="outline-primary"
        id="dropdown-basic"
      >
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {variants.map((v: CheckVariant, idx: number) => (
          <Dropdown.Item
            key={idx}
            onClick={() => handleValueChange(idx)}
            href="#"
          >
            {v.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const SLIDER: string = "slider";
export const BUTTONS: string = "buttons";
export const DROPDOWN: string = "dropdown";
