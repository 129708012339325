import { Action } from 'redux';

import { FetchError } from "./error";
import * as constants from '../constants/cluster';

export type QuorumTestType = "PING" | "SHELL"

export interface QuorumTest {
    type: QuorumTestType
    argument: string
    score: number
    nscore: number
}

export interface QuorumCriteria {
    memberSize: number
    scoreMin: number
    quorumTests: QuorumTest[]
}

export interface ClusterInfo {
    name: string
    password: string
    ip: string
    mask?: number
    clusterExchangePort: number
    quorumCriteria?: QuorumCriteria
}

///////////
//Actions//
///////////
export interface SendClusterInfo extends Action {
    type: constants.SEND_CLUSTER_INFO;
    payload: {
        clusterInfo: ClusterInfo;
    }
}

export interface SendClusterInfoError extends Action {
    type: constants.SEND_CLUSTER_INFO_ERROR;
    payload: {
        error: FetchError;
    }
}

export interface SendClusterInfoLoading extends Action {
    type: constants.SEND_CLUSTER_INFO_LOADING;
}

export interface SendClusterInfoChange extends Action {
    type: constants.SEND_CLUSTER_INFO_CHANGE;
    payload: {
        field: keyof ClusterInfo;
        value: any
    }
}

export interface SendClusterInfoAddTest extends Action {
    type: constants.SEND_CLUSTER_INFO_ADD_TEST;
    payload: {
        test: QuorumTest;
    }
}

export interface SendClusterInfoRemoveTest extends Action {
    type: constants.SEND_CLUSTER_INFO_REMOVE_TEST;
    payload: {
        index: number;
    }
}

export interface SendClusterInfoCriteriaChange extends Action {
    type: constants.SEND_CLUSTER_INFO_CRITERIA_CHANGE;
    payload: {
        field: keyof QuorumCriteria;
        value: any
    }
}

export interface SendClusterChecked extends Action {
    type: constants.SEND_CLUSTER_CHECKED;
    payload: {
        checked: ClusterInfo;
    }
}

export interface SendClusterIsChecking extends Action {
    type: constants.SEND_CLUSTER_IS_CHECKING;
}

export interface SendClusterCheckClear extends Action {
    type: constants.SEND_CLUSTER_CHECK_CLEAR;
}

export interface SendClusterCheckError extends Action {
    type: constants.SEND_CLUSTER_CHECK_ERROR;
    payload: {
        error: FetchError;
    }
}

export type ClusterAction = SendClusterInfo | SendClusterInfoError | SendClusterInfoLoading 
    | SendClusterInfoChange | SendClusterInfoAddTest | SendClusterInfoRemoveTest | SendClusterInfoCriteriaChange
    | SendClusterChecked | SendClusterIsChecking | SendClusterCheckError | SendClusterCheckClear;

/////////
//State//
/////////
export interface ClusterState {
    loading?: boolean,
    clusterInfo?: ClusterInfo | FetchError,
    checking?: boolean,
    checked?: ClusterInfo | FetchError
}

export function isClusterInfo(info: ClusterInfo | FetchError | undefined): info is ClusterInfo {
    return typeof info != 'undefined' && typeof (info as ClusterInfo).clusterExchangePort == 'number';
}

export function isFetchError(info: ClusterInfo | FetchError | undefined): info is FetchError {
    return typeof info != 'undefined' && typeof (info as FetchError).message == 'string';
}

