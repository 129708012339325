import React from "react";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { isSubject, LayoutNode } from "../../../types/subject";
import CardArea from "../CardArea";
import styles from "./Panel.module.css";
import { RootState } from "../../../store";

export interface PanelProps {
  subjectKey: string;
  nodeId: string;
  label?: string;
  visible?: boolean;
  node: LayoutNode;
}

class Panel extends React.Component<PanelProps> {
  render() {
    const { node } = this.props;
    if (!this.props.visible) {
      return null;
    }
    const fullHeight = node.options?.fullHeight;

    return (
      <div className={`card-row mx-1 ${fullHeight ? "h-100" : ""}`}>
        <Card
          className={`mb-1 border border-primary ${fullHeight ? "h-100" : ""}`}
        >
          <Card.Header className="bg-primary font-weight-bold px-1 card-title text-white ">
            {this.props.label}
            {/* <Card.Title >{this.props.label}</Card.Title>                */}
          </Card.Header>
          <Card.Body className={styles.body + "  "}>
            <div className={`card-row ${fullHeight ? "h-100" : ""}`}>
              <CardArea
                subjectKey={this.props.subjectKey}
                nodeId={this.props.nodeId}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return null;
    }
    const node = subject && subject.nodeById[ownProps.nodeId];
    const visible = subject.visibility[ownProps.nodeId] ? true : false;
    return {
      label: node && node.label,
      visible,
      node,
    };
  }
)(Panel);
