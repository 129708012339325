import { Action } from 'redux';

import * as constants from '../constants/clipboard';


export const CLIPBOARD_ACTION_COPY = "copy"
export type CLIPBOARD_ACTION_COPY = typeof CLIPBOARD_ACTION_COPY

export const CLIPBOARD_ACTION_CUT = "cut"
export type CLIPBOARD_ACTION_CUT = typeof CLIPBOARD_ACTION_CUT

export type ClipboardDataAction = CLIPBOARD_ACTION_COPY | CLIPBOARD_ACTION_CUT

export interface ClipboardItem {
    mime?: string
    type: string
    data: any
}

export interface ClipboardData {
    action: ClipboardDataAction
    items: ClipboardItem[]
}

///////////
//Actions//
///////////
export interface SaveToClipboard extends Action {
    type: constants.SAVE_TO_CLIPBOARD;
    payload: ClipboardData
}

//It may be combined  with |
export type ClipboardAction = SaveToClipboard;

/////////
//State//
/////////
export interface ClipboardState  {
    data?: ClipboardData
}