import { ServerError } from "../actions/utils";
import { FetchError } from "../types/error";
import {
  ProcessManagerInfo,
  ProcessActionResult,
  LogFile,
  getModuleAddress,
  isProcessInfo,
  Module,
  Process,
} from "../types/process";

export function fetchProcessInfoImpl(): Promise<ProcessManagerInfo> {
  return fetch("/scm/process/info")
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as ProcessManagerInfo;
    });
}

export function changeProcessImpl(command: ProcessActionResult): Promise<any> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(command),
  };
  return fetch("/scm/process/change", opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

export function fetchProcessLogImpl(
  fc: number,
  src: number,
  dev: number
): Promise<LogFile> {
  return fetch(`/scm/process/log/${getModuleAddress(fc, src, dev)}.log`)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as LogFile;
    });
}

export function findModule(
  processInfo: ProcessManagerInfo | FetchError | undefined,
  fc: number,
  src: number,
  dev: number
): Module | null {
  if (!isProcessInfo(processInfo)) {
    return null;
  }
  let foundProcess: Process | null = null;
  for (let p of processInfo.proc) {
    if (p.fc == fc && p.src == src) {
      foundProcess = p;
      break;
    }
  }
  if (!foundProcess) {
    return null;
  }
  for (let m of foundProcess.modules) {
    if (m.dev == dev) {
      return m;
    }
  }
  return null;
}

export function filterLogFile(logFile: LogFile, prefferedLevels?: any[]) {
  if (!prefferedLevels) {
    return logFile;
  }
  const recrdList = logFile.recordList.filter((record) => {
    return prefferedLevels.includes(record.l);
  });
  return { ...logFile, recordList: recrdList };
}

export function parseProcessLabels(processInfo: ProcessManagerInfo) {
  const procLabels: { [ID: string]: string } = {};
  processInfo.proc.forEach((p) => {
    const { fc, src, modules } = p;
    modules.forEach((m) => {
      const { dev, descr } = m;
      const label = getModuleAddress(fc, src, dev);
      procLabels[label] = descr;
    });
  });
  return procLabels;
}
