import { ServerError } from "../actions/utils";
import { SCMInformation } from "../types/scm";

export function fetchSCMInfoImpl(): Promise<SCMInformation> {
  return fetch("/scm/system/info")
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}
