import { FetchError } from './error';
import { Action } from 'redux';

import * as constants from '../constants/role';
import { FragmentData, FragmentDataAccess, FragmentDataAccessRaw, FragmentTableInfo } from './fragment';
import { SecurityItem } from './security';
import { BaseTableInfo } from './user';

export interface BriefRoleCommon {
    rdfId?: string
    label: string
    alias:string
}

export interface BriefRoleRaw extends BriefRoleCommon {
    // roleList: SecurityItem[]
    fragmentList: FragmentDataAccessRaw[]
    accessList: SecurityItem[]
}

export interface BriefRole extends BriefRoleCommon {
    fragmentInfo: FragmentTableInfo
    accessInfo: BaseTableInfo
}

export enum RoleCardType {
    SIMPLE = 'simple',
    CARD = "card"
}

export interface RoleDataNode {
    rdfId?: string
    label: string
    alias:string
    parentId?: string
}

export interface RoleData extends RoleDataNode {
    id: number
    description: string
}



export type RoleNodeMap = { [ID: string]: RoleDataNode };

export interface RoleTreeData {
    rootNodesIds?: string[]
    nodeById?: RoleNodeMap
    childrenIds?: { [ID: string]: string[] }

}

export interface RoleTreeState extends RoleTreeData {
    treeLoading?: boolean,
    treeError?: FetchError,
    expanded?: { [ID: string]: boolean },
    active?: string
    inplace?: string
    filterLoading?: boolean
    filterValue?: string
    filter?: { [ID: string]: boolean }
    loading?: { [ID: string]: boolean }
    error?: { [ID: string]: FetchError }
    selected?: string[] | null
    pivotPoint?: string
}




export interface RoleCard {
    loading?: boolean
    error?: FetchError
    data?: BriefRole
}

export interface RoleTableInfo {
    rdfId: string[]
    id: { [KEY: string]: number }
    label: { [KEY: string]: string }
    alias: { [KEY: string]: string }
    description: { [KEY: string]: string }
}

export interface PaginationInfo {
    offset?: number
    limit?: number
    total?: number
}
export interface RoleInfo {
    table: RoleTableInfo
    pagination: PaginationInfo
    search: string
}


///////////
//Actions//


export interface SendRoleList extends Action {
    type: constants.SEND_ROLE_LIST;
    payload: {
        roleList: RoleData[],
        total: number
    }
}
export interface SendRoleTree extends Action<constants.SEND_ROLE_TREE> {
    payload: {
        list: RoleDataNode[],
        parentId?: string
    }
}


export interface SendBriefRole extends Action {
    type: constants.SEND_ROLE_BRIEF;
    payload: BriefRoleRaw
}
export interface SendRoleTableSearch extends Action {
    type: constants.SEND_ROLE_TABLE_SEARCH;
    payload: string
}

export interface SendRoleCardType extends Action {
    type: constants.SEND_ROLE_CARD_TYPE;
    payload: RoleCardType
}

export interface SendBriefRoleSave extends Action {
    type: constants.SEND_ROLE_BRIEF_SAVE;
    payload?: boolean
}
export interface SendBriefRoleLoading extends Action {
    type: constants.SEND_ROLE_BRIEF_LOADING;
    payload: boolean
}

export interface SendRoleEdit extends Action {
    type: constants.SEND_ROLE_EDIT;
    payload: boolean
}

export interface SendRoleListLoading extends Action {
    type: constants.SEND_ROLE_LIST_LOADING
    payload: boolean
}
export interface SendRoleTreeLoading extends Action {
    type: constants.SEND_ROLE_TREE_LOADING
    payload: boolean
}

export interface SendRoleActive extends Action<constants.SEND_ROLE_ACTIVE> {
    payload: string
}
export interface SendRoleSelect extends Action<constants.SEND_ROLE_SELECT> {
    payload?: string
}
export interface SendRoleClearSelect extends Action<constants.SEND_ROLE_CLEAR_SELECTED> {
    payload?: null
}
export interface SendRoleRange extends Action<constants.SEND_ROLE_RANGE> {
    payload?: string
}

export interface SendRoleUpdateFilter extends Action {
    type: constants.SEND_ROLE_FILTER_UPDATE;
    payload: RoleFilter
}

export type RoleAction = SendRoleList |
    SendRoleCardType |
    SendBriefRole |
    SendBriefRoleLoading |
    SendRoleEdit |
    SendBriefRoleSave |
    SendRoleListLoading |
    SendRoleActive |
    SendRoleSelect |
    SendRoleRange |
    SendRoleTree |
    SendRoleClearSelect |
    SendRoleUpdateFilter|
    SendRoleTableSearch |
    SendRoleTreeLoading;


export interface RoleFilter {
    fragments: string[]
    rights: string[]
}

/////////
//State//
/////////
export interface RoleState {
    //Role list
    tree?: RoleTreeState
    filter?:RoleFilter
    loading?: boolean,
    roles?: RoleInfo
    search?:string
    roleCard?: RoleCard
    saved?: boolean
    cardType: RoleCardType
    editRole?: boolean
}

// export function isRoleInfo(info: FetchError | RoleInfo | undefined): info is RoleInfo {
//     return typeof info != 'undefined' && Array.isArray((info as RoleInfo).rights);
// }

// export function isFetchError(info: FetchError | RoleInfo | undefined): info is FetchError {
//     return typeof info != 'undefined' && typeof (info as FetchError).message == 'string';
// }