import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { changeDbInfoImpl, checkDbImpl, fetchDbInfoImpl } from "../services/db";
import { DbInfo, DbState, isDbInfo } from "../types/db";
import { FetchError } from "../types/error";

const initialState: DbState = {};

export const db = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendDbInfo(state, dbInfo: DbInfo) {
      return { ...state, dbInfo: dbInfo, loading: false, checked: undefined };
    },
    sendDbInfoError(state, error: FetchError) {
      return { ...state, dbInfo: error, loading: false, checked: undefined };
    },
    sendDbInfoLoading(state) {
      return { ...state, loading: true, checked: undefined };
    },
    sendDbInfoChange(state, payload: { field: keyof DbInfo; value: any }) {
      if (!isDbInfo(state.dbInfo)) {
        return state;
      }
      const dbInfo = {
        ...state.dbInfo,
        [payload.field]: payload.value,
        changed: true,
      };
      return { ...state, dbInfo, checked: undefined };
    },
    sendDbChecked(state, checked: DbInfo) {
      return { ...state, checked: checked, checking: false };
    },
    sendDbIsChecking(state) {
      return { ...state, checking: true };
    },
    sendDbCheckError(state, error: FetchError) {
      return { ...state, checked: error, checking: false };
    },
    sendDbCheckClear(state) {
      return { ...state, checked: undefined };
    },
  },
  effects: (dispatch) => ({
    fetchDbInfo: async (_: void, s) => {
      try {
        if (s && typeof s.db != "undefined" && s.db.loading) {
          return;
        }
        dispatch.db.sendDbInfoLoading();
        dispatch.db.sendDbInfo(await fetchDbInfoImpl());
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.db.sendDbInfoError({ code: e.code, message: e.message });
        } else if (typeof e.message == "string") {
          dispatch.db.sendDbInfoError({ code: -1, message: e.message });
        } else {
          dispatch.db.sendDbInfoError({ code: -1, message: "Unknown error" });
        }
      }
    },
    changeDbInfo: async (_: void, s) => {
      try {
        if (!s || typeof s.db == "undefined") {
          return;
        }
        if (s.db.loading) {
          return;
        }
        if (!isDbInfo(s.db.dbInfo)) {
          return;
        }
        dispatch.db.sendDbInfoLoading();
        dispatch.db.sendDbInfo(await changeDbInfoImpl(s.db.dbInfo));
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.db.sendDbInfoError({ code: e.code, message: e.message });
        } else if (typeof e.message == "string") {
          dispatch.db.sendDbInfoError({ code: -1, message: e.message });
        } else {
          dispatch.db.sendDbInfoError({ code: -1, message: "Unknown error" });
        }
      }
    },
    checkDbInfo: async (_: void, s) => {
      try {
        if (!s || typeof s.db == "undefined") {
          return;
        }
        if (s.db.checking) {
          return;
        }
        if (!isDbInfo(s.db.dbInfo)) {
          return;
        }
        dispatch.db.sendDbIsChecking();
        dispatch.db.sendDbChecked(await checkDbImpl(s.db.dbInfo));
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.db.sendDbCheckError({ code: e.code, message: e.message });
        } else if (typeof e.message == "string") {
          dispatch.db.sendDbCheckError({ code: -1, message: e.message });
        } else {
          dispatch.db.sendDbCheckError({ code: -1, message: "Unknown error" });
        }
      }
    },
  }),
});
