import React, { FC } from "react";
import { connect } from "react-redux";
import { UI_INPUT_FORMAT } from "../../../constants/subject";
import { round } from "../../../services/app";
import { isEditable } from "../../../services/layout";
import { Dispatch, RootState } from "../../../store";
import { I18NString } from "../../../types/modal";
import {
  isSubject,
  LayoutNode,
  SubjectComment,
  TextInputValue,
} from "../../../types/subject";
import { AutocompleteWrapper } from "../../autocomplete/AutocompleteWrapper";
import debounce from "../../debounce/debounce";
import DebounceInput, { isNumberFormat } from "../../debounce/debounceinput";
import BasicInput from "./BasicInput";

export interface TextInputProps {
  subjectKey: string;
  nodeId: string;
  visible: boolean;
  error?: string | I18NString;
  node?: LayoutNode;
  value?: TextInputValue;
  editable?: boolean;
  cardEditable?: boolean;
  valid?: boolean;
  comment?: SubjectComment;
  change: (data: any, options?: any) => void;
}
interface TestInputState {
  autocompleteList: string[] | null;
  autocompleteLoading: boolean;
  autocompleteError: string | null;
}
const getFormat = (node: LayoutNode): string => {
  if (node.options && typeof node.options.format == "string") {
    return node.options.format;
  }
  return "text";
};

class TextInput extends React.Component<TextInputProps, TestInputState> {
  private debounce: any;
  constructor(props: TextInputProps) {
    super(props);
    this.state = {
      autocompleteList: null,
      autocompleteError: null,
      autocompleteLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.debounceFactory = this.debounceFactory.bind(this);
    this.signalInvalidFormat = this.signalInvalidFormat.bind(this);
  }

  debounceFactory(handleChange: any) {
    this.debounce = debounce(handleChange, 200, false); //200 ms by default
    return this.debounce;
  }

  handleChange(value: any) {
    const { node, change } = this.props;
    //May be set from options
    if (node) {
      //for server side transformation
      const transform = node.options && node.options.transform;
      transform
        ? change({ $value: value, $transform: transform })
        : change(value);
    }
  }
  signalInvalidFormat(error: any) {
    this.props.change({ invalid: true }, { invalidFormat: { id: error } });
  }

  processValue = (value: TextInputValue | undefined) => {
    if (typeof value === "undefined") {
      return value;
    }
    const { node } = this.props;
    const accuracy = node?.options?.accuracy;
    if (typeof accuracy !== "undefined") {
      if (typeof value === "string" || typeof value === "number") {
        return round(+value, accuracy);
      }
      if (!value) {
        return value;
      } else {
        const newValue = { ...value };
        if (typeof newValue.$value === "undefined") {
          return newValue;
        }
        round(+newValue.$value, accuracy);
        return newValue;
      }
    }
    return value;
  };

  render() {
    const {
      node,
      subjectKey,
      nodeId,
      editable,
      cardEditable,
      value,
      valid,
      error,
      visible,
      comment,
    } = this.props;
    if (!node || !node.ui) {
      return null;
    }
    let className = `form-control card-input `;
    !valid && editable && (className += " is-invalid");
    const format = UI_INPUT_FORMAT[node.ui];

    if (isNumberFormat(format)) {
      return (
        <BasicInput
          editable={editable}
          cardEditable={cardEditable}
          id={subjectKey + "." + nodeId}
          node={node}
          error={error}
          visible={visible}
          comment={comment}
        >
          <DebounceInput
            editable={editable}
            valid={valid}
            value={this.processValue(value)}
            className={className}
            password={node.options && node.options.password}
            options={node.options}
            format={format}
            withNull={true}
            debounceFactory={this.debounceFactory}
            change={this.handleChange}
            signalInvalidFormat={this.signalInvalidFormat}
          />
        </BasicInput>
      );
    }
    const inputValue = this.processValue(value);
    const autocompleteScript = node.options?.autocomplete;
    return (
      <BasicInput
        editable={editable}
        cardEditable={cardEditable}
        id={subjectKey + "." + nodeId}
        node={node}
        error={error}
        visible={visible}
        comment={comment}
      >
        <AutocompleteWrapper
          value={value}
          autocomplete={autocompleteScript}
          onAutocomplete={(e) => {
            this.handleChange(e.target.value.value);
          }}
        >
          <DebounceInput
            editable={editable}
            valid={valid}
            value={inputValue}
            className={className}
            password={node.options && node.options.password}
            options={node.options}
            updateOnChange={true}
            format={format}
            debounceFactory={this.debounceFactory}
            change={this.handleChange}
            signalInvalidFormat={this.signalInvalidFormat}
          />
        </AutocompleteWrapper>
      </BasicInput>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    const subject = state.subject && state.subject.subjects[subjectKey];

    if (!isSubject(subject)) {
      return { visible: false };
    }

    const value = subject.values[nodeId];

    const serverLock = subject.subjectData.$lock;
    const serverLockReady = serverLock && serverLock.status;
    const isNew = subject.isNew;
    const cardEditable = serverLockReady || isNew;

    const node = subject.nodeById[nodeId];
    const editable = isEditable(subject, nodeId, cardEditable);

    const error = subject.validation[nodeId];

    let valid = !error || !cardEditable ? true : false;

    let mandatory = subject.mandatorySet[node.id];

    if (
      mandatory &&
      (typeof value === "undefined" ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      valid = false;
    }
    const visible = subject.visibility[nodeId] ? true : false;

    return {
      error: error,
      node,
      value: value,
      // value: typeof value == 'string' ? value : undefined,
      editable,
      cardEditable,
      valid,
      visible,
      comment: subject.comment[nodeId],
    };
  },
  (dispatch: Dispatch, ownProps: { subjectKey: string; nodeId: string }) => {
    return {
      change: (data: TextInputValue, options?: any) =>
        dispatch.subject.change({
          subjectKey: ownProps.subjectKey,
          nodeId: ownProps.nodeId,
          data,
          options,
        }),
    };
  }
)(TextInput);
