import * as React from "react";
import { CSSProperties, FC } from "react";
import { ReactReduxContext, connect } from "react-redux";
import { AnyAction, Store } from "redux";
import { cutOffModel, isSHA1 } from "../../services/app";
import { parseTableModelPath } from "../../services/table";
import {
  Column,
  ColumnTemplateMap,
  instanceOfLocalPageableTableApi,
  isExternalTable,
  isLocalTable,
  LocalPageableTableApi,
  LocalSimpleTableApi,
  ModelParameters,
  OnTableSelect,
  RowData,
  RowDataMap,
  TableDragOptions,
  TableDropOptions,
  TableParameter,
  TableRequest,
  ToolbarItem,
  ToolbarReport,
} from "../../types/table";
import { SessionContext } from "../helpers/Session";
import ExternalTable from "./ExternalTable";
import LocalTable from "./LocalTable";
import SessionListener, {
  SessionChangeEvent,
} from "../helpers/SessionListener";
import { Dispatch, RootState } from "../../store";

interface AsyncTableProps {
  autoresize?: boolean;
  toolbar?: FC | ToolbarItem[];
  body?: FC;
  footer?: FC;
  column?: ColumnTemplateMap;
  table?: string;
  gantOptions?: any;
  finderOptions?: any;
  forceLoadData?: boolean;
  forcedFields?: any;
  initialFields?: any;
  forceLoadHeader?: boolean;
  initialSorting?: any;
  paramsReadOnly?: boolean;
  clientSideFilter?: boolean;
  resetFields?: boolean;
  scrollable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  drag?: TableDragOptions;
  drop?: TableDropOptions;
  toolbarHidden?: boolean;
  toolbarHiddenItems?: { [k: string]: boolean };
  localApi?: LocalSimpleTableApi | LocalPageableTableApi;
  columns?: NptTableColumnProps[];
  rows?: RowData[] | RowDataMap[];
  automation?: (() => void) | string;
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
  children?: any;
  onSelect?: OnTableSelect;
}

export default class AsyncTable extends React.Component<AsyncTableProps> {
  constructor(props: AsyncTableProps) {
    super(props);
  }
  componentDidMount() {
    console.log("table mount");
  }
  renderChildren() {
    const { columns, toolbar } = this.props;

    const elements = [];
    if (columns) {
      elements.push(
        <NptTableColumns>
          {columns.map((columnProps, idx) => (
            <NptTableColumn {...columnProps} key={idx as any} />
          ))}
        </NptTableColumns>
      );
    }
    if (toolbar && Array.isArray(toolbar)) {
      elements.push(
        <NptTableToolbarItems>
          {toolbar.map((toolbarItem, idx) => (
            <NptTableToolbarItem {...toolbarItem} key={idx as any} />
          ))}
        </NptTableToolbarItems>
      );
    }
    return elements.length > 0 ? elements : null;
  }

  getTablePartsProps = () => {
    const { toolbar, body, footer, column } = this.props;
    const externalProps: any = {};
    if (toolbar) {
      externalProps.toolbar = toolbar;
    }
    if (column) {
      externalProps.column = column;
    }
    if (body) {
      externalProps.body = body;
    }
    if (footer) {
      externalProps.footer = footer;
    }
    return externalProps;
  };
  renderExternalTableContent = (
    tableId: string,
    modelParameters?: ModelParameters
  ) => {
    const {
      localApi,
      toolbar,
      body,
      footer,
      column,
      className,
      style,
      autoresize,
    } = this.props;
    return (
      <TableStyleWrapper className={className} style={style}>
        <ExternalTable
          {...this.getTablePartsProps()}
          onSelect={this.props.onSelect}
          tableId={tableId}
          autoresize={autoresize}
          modelParameters={modelParameters}
          scrollable={this.props.scrollable}
          gantViewOptions={this.props.gantOptions}
          forceLoadData={this.props.forceLoadData}
          forceLoadHeader={this.props.forceLoadHeader}
          finderViewOptions={this.props.finderOptions}
          initialFields={this.props.initialFields}
          forcedFields={this.props.forcedFields}
          initialSorting={this.props.initialSorting}
          paramsReadOnly={this.props.paramsReadOnly}
          clientSideFilter={this.props.clientSideFilter}
          resetFields={this.props.resetFields}
          drag={this.props.drag}
          drop={this.props.drop}
          toolbarHidden={this.props.toolbarHidden}
          toolbarHiddenItems={this.props.toolbarHiddenItems}
          onFetch={this.props.onFetch}
          onSelectionChange={this.props.onSelectionChange}
        />
      </TableStyleWrapper>
    );
  };
  renderExternalTable = () => {
    const { localApi, toolbar, body, footer, column, className, style, table } =
      this.props;
    const { model, path: tableId } = parseTableModelPath(table || "");
    if (!model && tableId) {
      return this.renderExternalTableContent(tableId);
    }

    const isSHA1Value = isSHA1(model || "");
    if (!isSHA1Value) {
      return (
        <SessionContext.Consumer>
          {(parent) => {
            const path = parent.path ? cutOffModel(parent.path) : undefined;
            const modelParameters: ModelParameters = {
              path,
              model: model || undefined,
              sessionModel: parent.model || undefined,
              sessionSystemObject: parent.systemObject,
            };
            return (
              parent.path &&
              parent.model && (
                <>
                  <ConnectedTableSessionListener
                    modelName={model || ""}
                    table={tableId}
                    modelParameters={modelParameters}
                  />
                  {this.renderExternalTableContent(tableId, {
                    path: parent.path,
                    model: model || undefined,
                    sessionModel: parent.model,
                    sessionSystemObject: parent.systemObject,
                  })}
                </>
              )
            );
          }}
        </SessionContext.Consumer>
      );
    } else {
      return this.renderExternalTableContent(tableId, {
        model: model || undefined,
      });
    }
  };

  render() {
    const {
      localApi,
      toolbar,
      body,
      footer,
      column,
      className,
      style,
      autoresize,
    } = this.props;
    let tableId = this.props.table || "";
    if (tableId && tableId[0] !== "/") {
      tableId = `/${tableId}`;
    }
    if (!localApi) {
      return this.renderExternalTable();
    }
    if (this.props.localApi) {
      return (
        <TableStyleWrapper className={className} style={style}>
          <LocalTable
            tableId={tableId}
            {...this.props.localApi}
            scrollable={this.props.localApi.scrollable}
            data={this.props.rows || []}
            automation={this.props.automation}
            onSelect={this.props.onSelect}
            autoresize={autoresize}
            toolbarHidden={this.props.toolbarHidden}
          >
            {this.renderChildren()}
          </LocalTable>
        </TableStyleWrapper>
      );
    }
    return null;
  }
}

interface TableStyleWrapperProps {
  className?: string;
  style?: CSSProperties;
  children: any;
}

const TableStyleWrapper: FC<TableStyleWrapperProps> = ({
  className,
  style,
  children,
}) => {
  if (className || style) {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }
  return children;
};

interface TableSessionListenerProps {
  modelName?: string;
  table?: string;
  modelParameters?: ModelParameters;
  reloadTable: () => void;
}

const TableSessionListener: FC<TableSessionListenerProps> = ({
  modelName,
  table,
  reloadTable,
}) => {
  return (
    <SessionListener
      modelName={modelName || ""}
      onChange={(evt: SessionChangeEvent) => {
        console.log("chagned==============", evt);
        reloadTable();
      }}
    />
  );
};

const ConnectedTableSessionListener = connect(
  (
    state: RootState,
    ownProps: {
      table?: string;
      modelParameters?: ModelParameters;
    }
  ) => {
    const { table } = ownProps;
    const fields = state.table[table || ""]?.fields;
    return {
      fields,
    };
  },

  (
    dispatch: Dispatch,
    ownProps: {
      table?: string;
      modelParameters?: ModelParameters;
      fields?: {
        [k: string]: string;
      };
    }
  ) => {
    const { modelParameters, table, fields } = ownProps;
    return {
      reloadTable: () => {
        dispatch.table.fetchTableData({
          tableId: table || "",
          options: {
            fields,
            finder: null,
            modelParameters: modelParameters,
            reset: false,
          },
        });
      },
    };
  }
)(TableSessionListener);

/**********************************
 * Local Table Options Components *
 **********************************/
/**
 * ALERT: this components using only to declare local table
 *        options.
 * DO NOT use them standalone.
 */

/** Columns component */
export interface NptTableColumnsProps {
  children:
    | React.ReactElement<NptTableColumnProps, typeof NptTableColumn>
    | React.ReactElement<NptTableColumnProps, typeof NptTableColumn>[];
}
export class NptTableColumns extends React.PureComponent<NptTableColumnsProps> {
  render() {
    return this.props.children;
  }
}
export type NptTableColumnProps = Column & { isKey?: boolean };
export const NptTableColumn = React.memo((props: NptTableColumnProps) => {
  return null;
});

/** Parameters component */
export interface NptTableParametersProps {
  children:
    | React.ReactElement<NptTableParameterProps, typeof NptTableParameter>
    | React.ReactElement<NptTableParameterProps, typeof NptTableParameter>[];
}
export class NptTableParameters extends React.PureComponent<NptTableParametersProps> {
  render() {
    return this.props.children;
  }
}
interface NptTableParameterProps extends TableParameter {
  value?: string;
}
export const NptTableParameter = React.memo((props: NptTableParameterProps) => {
  return null;
});

/** Reports component */
export interface NptTableReportsProps {
  children:
    | React.ReactElement<ToolbarReport, typeof NptTableReport>
    | React.ReactElement<ToolbarReport, typeof NptTableReport>[];
}
export class NptTableReports extends React.PureComponent<NptTableReportsProps> {
  render() {
    return this.props.children;
  }
}
export const NptTableReport = React.memo((props: ToolbarReport) => {
  return null;
});

/** Toolbar items component */
export interface NptTableToolbarItemsProps {
  children:
    | React.ReactElement<ToolbarItem, typeof NptTableToolbarItem>
    | React.ReactElement<ToolbarItem, typeof NptTableToolbarItem>[];
}
export class NptTableToolbarItems extends React.PureComponent<NptTableToolbarItemsProps> {
  render() {
    return this.props.children;
  }
}
export const NptTableToolbarItem = React.memo((props: ToolbarItem) => {
  return null;
});
