import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { MODAL_STATUS_OK } from "../../../constants/modal";
import { RootState } from "../../../store";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import { ClassInfo } from "../../../types/profile";
import ModalView from "../ModalView";

interface RefTablePasteModalProps {
  modal: ModalInfo;
  activePredicateId?: number;
  data?: ClassInfo;
  closeModal: (status: ModalStatus, result: any) => void;
}

interface RefTablePasteModalState {
  closeModal: boolean;
  refFormat: string;
}

class RefTablePasteModal extends React.Component<
  RefTablePasteModalProps,
  RefTablePasteModalState
> {
  state = { closeModal: false, refFormat: "" };
  title = { id: "OBJECTCARD_MANUAL_PASTE" };
  references: any[] = [];
  constructor(props: RefTablePasteModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  convertAndPasteRefs = (text: string) => {
    try {
      this.references = JSON.parse(text);

      if (!Array.isArray(this.references)) {
        this.closeModal(MODAL_STATUS_OK, null);
        return;
      }
      // if(className && multiple && refs !== null){
      //     this.props.pasteRefs(className, multiple ? refs : refs.slice(0, 1));
      // }
      this.setState({ closeModal: true });
    } catch (e) {
      this.setState({ refFormat: "OBJECTCARD_INVALID_REF_FORMAT" });
    }
  };

  handleManualPaste = (reactEvent: any) => {
    reactEvent.preventDefault();
    const clipboardData = reactEvent.clipboardData; /*|| window.clipboardData*/
    const pastedData = clipboardData.getData("Text");
    this.convertAndPasteRefs(pastedData);
  };

  renderError() {
    const { refFormat } = this.state;
    if (!refFormat) {
      return null;
    }
    return (
      <Form.Group as={Row} controlId="namespace">
        <Col md={12}>
          <Form.Label className="text-danger">
            <FormattedMessage id={refFormat as any} />
          </Form.Label>
        </Col>
      </Form.Group>
    );
  }

  renderTemplate(): React.ReactElement {
    const { refFormat } = this.state;
    return (
      <Form>
        <Form.Group as={Row} controlId="namespace">
          <Col md={12}>
            <Form.Label>
              <FormattedMessage
                id="OBJECTCARD_AUTO_PASTE_PROHIBITED"
                defaultMessage="Auto paste is prohibited, please use form below to manually paste references."
                description="Text that showns is paste from clipboard failed"
              />
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="identifier">
          <Col md={12}>
            <Form.Control
              as="textarea"
              style={{ resize: "none", width: "100%" }}
              onPaste={this.handleManualPaste}
            />
          </Col>
        </Form.Group>
        {this.renderError()}
      </Form>
    );
  }

  closeModal(status: ModalStatus, result: any) {
    this.props.closeModal(status, result);
  }

  render() {
    if (this.state.closeModal) {
      this.closeModal(MODAL_STATUS_OK, this.references);
    }
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

export default connect((state: RootState) => {
  return {};
}, null)(RefTablePasteModal);
