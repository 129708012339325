import React from "react";
import { connect } from "react-redux";
import {
  FILE_SELECTION,
  FILE_UPLOADING,
  READY,
} from "../../../constants/rpasetpoints";
import { RootState } from "../../../store";
import { isSubject, LayoutNode } from "../../../types/subject";
import ControlWindow from "./ControlWindow";
import FileUploader from "./FileUploader";
import styles from "./RPASetpoints.module.css";
import Toolbar from "./Toolbar";

interface RPASetpointsProps {
  width?: number;
  height?: number;
  contextPath: string;
  subjectKey: string;
  nodeId: string;
  node?: LayoutNode;
  value?: string;

  state?: number;
  file?: string;

  importFile: (file: string) => any;
  uploadSetpoints: (file: File, vendor: string, encode: string) => any;
  uploadSignals: (file: File, encode: string) => any;
}

class RPASetpointsWrapper extends React.Component<RPASetpointsProps> {
  render() {
    return <RPASetpoints {...this.props} width={1000} height={500} />;
    // <AutoSizer disableWidth>
    //   {({ width, height }) => (

    // )}
    // </AutoSizer>
  }
}

class RPASetpoints extends React.Component<RPASetpointsProps> {
  constructor(props: RPASetpointsProps) {
    super(props);

    if (props.file) {
      props.importFile(props.file);
    }
  }

  printState() {
    switch (this.props.state) {
      case FILE_SELECTION:
      case FILE_UPLOADING:
        return (
          <FileUploader
            uploadState={{ setpoints: FILE_SELECTION, signals: FILE_SELECTION }}
            uploadSetpoints={this.props.uploadSetpoints}
            uploadSignals={this.props.uploadSignals}
          />
        );
      case READY:
        return <ControlWindow width={this.props.width} />;
    }
    return null;
  }

  render() {
    return (
      <div className={styles.rpasetpointsContainer}>
        <Toolbar />
        {this.printState()}
      </div>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return {
        contextPath: "/",
      };
    }
    const node = subject && subject.nodeById[ownProps.nodeId];
    const value = subject && subject.values[ownProps.nodeId];
    return {
      node,
      contextPath: "/",
      //contextPath: globalState[LOCATION].contextPath,
      value: typeof value == "string" ? value : undefined,
      state: FILE_SELECTION,
    };
  },
  (dispatch) => {
    /* TODO */
    return {
      importFile: (file: string) => {},
      uploadSetpoints: (file: File, vendor: string, encode: string) => {},
      uploadSignals: (file: File, encode: string) => {},
    };
  }
)(RPASetpointsWrapper);
