export const SEND_USER_LIST = 'SEND_USER_LIST';
export type SEND_USER_LIST = typeof SEND_USER_LIST;

export const SEND_USER_FILTER_UPDATE = 'SEND_USER_FILTER_UPDATE';
export type SEND_USER_FILTER_UPDATE = typeof SEND_USER_FILTER_UPDATE;

export const SEND_USER_TABLE_SEARCH = 'SEND_USER_TABLE_SEARCH'
export type SEND_USER_TABLE_SEARCH = typeof SEND_USER_TABLE_SEARCH;

export const SEND_USER_BRIEF = 'SEND_USER_BRIEF';
export type SEND_USER_BRIEF = typeof SEND_USER_BRIEF;

export const SEND_USER_BRIEF_SAVE = 'SEND_USER_BRIEF_SAVE';
export type SEND_USER_BRIEF_SAVE = typeof SEND_USER_BRIEF_SAVE;

export const SEND_USER_EDIT = 'SEND_USER_EDIT';
export type SEND_USER_EDIT = typeof SEND_USER_EDIT;

export const SEND_USER_EDITED_OBJ = 'SEND_USER_EDITED_OBJ';
export type SEND_USER_EDITED_OBJ = typeof SEND_USER_EDITED_OBJ;

export const SEND_USER_BRIEF_LOADING = 'SEND_USER_BRIEF_LOADING';
export type SEND_USER_BRIEF_LOADING = typeof SEND_USER_BRIEF_LOADING;

export const SEND_USER_LIST_ERROR = 'SEND_USER_LIST_ERROR';
export type SEND_USER_LIST_ERROR = typeof SEND_USER_LIST_ERROR;

export const SEND_USER_LIST_LOADING = 'SEND_USER_LIST_LOADING';
export type SEND_USER_LIST_LOADING = typeof SEND_USER_LIST_LOADING;

export const SEND_USER_INFO = 'SEND_USER_INFO';
export type SEND_USER_INFO = typeof SEND_USER_INFO;

export const SEND_USER_INFO_ERROR = 'SEND_USER_INFO_ERROR';
export type SEND_USER_INFO_ERROR = typeof SEND_USER_INFO_ERROR;

export const SEND_USER_INFO_CHANGE = 'SEND_USER_INFO_CHANGE';
export type SEND_USER_INFO_CHANGE = typeof SEND_USER_INFO_CHANGE;

export const SEND_USER_CLEAR_INFO = 'SEND_USER_CLEAR_INFO';
export type SEND_USER_CLEAR_INFO = typeof SEND_USER_CLEAR_INFO;

export const SEND_USER_ACTIVE_RIGHT = 'SEND_USER_ACTIVE_RIGHT';
export type SEND_USER_ACTIVE_RIGHT = typeof SEND_USER_ACTIVE_RIGHT;

export const SEND_USER_CARD_TYPE = 'SEND_USER_CARD_TYPE';
export type SEND_USER_CARD_TYPE = typeof SEND_USER_CARD_TYPE;
 
 

export enum UserCardType {
    SIMPLE = 'simple',
    CARD = "card"
}