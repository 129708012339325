export const CRON = "cron";

export const SEND_CRON_TASKS = 'SEND_CRON_TASKS';
export type SEND_CRON_TASKS = typeof SEND_CRON_TASKS;

export const SEND_CRON_TASK_ERROR = 'SEND_CRON_TASK_ERROR';
export type SEND_CRON_TASK_ERROR = typeof SEND_CRON_TASK_ERROR;

export const SEND_CRON_TASK_LOADING = 'SEND_CRON_TASK_LOADING';
export type SEND_CRON_TASK_LOADING = typeof SEND_CRON_TASK_LOADING; 

export const SEND_LIMIT = 'SEND_LIMIT';
export type SEND_LIMIT = typeof SEND_LIMIT;

export const SEND_OFFSET = 'SEND_OFFSET';
export type SEND_OFFSET = typeof SEND_OFFSET;

export const SEND_ITEM_CHECKED = 'SEND_ITEM_CHECKED';
export type SEND_ITEM_CHECKED = typeof SEND_ITEM_CHECKED;

export const SEND_ALL_ITEM_CHECKED = 'SEND_ALL_ITEM_CHECKED';
export type SEND_ALL_ITEM_CHECKED = typeof SEND_ALL_ITEM_CHECKED;

export const SEND_CREATE_EDITABLE_OBJECT = 'SEND_CREATE_EDITABLE_OBJECT';
export type SEND_CREATE_EDITABLE_OBJECT = typeof SEND_CREATE_EDITABLE_OBJECT;

export const SEND_REMOVE_EDITABLE_OBJECT = 'SEND_REMOVE_EDITABLE_OBJECT';
export type SEND_REMOVE_EDITABLE_OBJECT = typeof SEND_REMOVE_EDITABLE_OBJECT;

export const SEND_SEARCH_INPUT_CRON = 'SEND_SEARCH_INPUT_CRON';
export type SEND_SEARCH_INPUT_CRON = typeof SEND_SEARCH_INPUT_CRON;

export const SEND_SOURCE_TREE_INFO = 'SEND_SOURCE_TREE_INFO';
export type SEND_SOURCE_TREE_INFO = typeof SEND_SOURCE_TREE_INFO;

export const CRON_SAVE_URL = '/rest/cron/entity';
export const CRON_LIST_SAVE_URL = '/rest/cron/entity/batch';
export const CRON_EDIT_URL = '/rest/cron/entity';
export const CRON_DELETE_BATCH_URL = '/rest/cron/deletebatch';
export const CRON_LIST_URL = "/rest/cron/list";
export const CRON_SCRIPT_LIST_URL = `/rest/cron/script/list`;