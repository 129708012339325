import moment from "moment";
import { isEmptyObject } from "../../services/app";

export const INVALID_FLOAT = "FORMAT_VALIDATOR_INVALID_FLOAT";
export const INVALID_INTEGER = "FORMAT_VALIDATOR_INVALID_INTEGER";
export const INVALID_DATE = "FORMAT_VALIDATOR_INVALID_DATE";
export const INVALID_DATE_TIME = "FORMAT_VALIDATOR_INVALID_DATE_TIME";

export const getFormatById = (id) => {
  if (!id) {
    return null;
  }
  switch (id) {
    case INVALID_FLOAT:
      return "float";
    case INVALID_INTEGER:
      return "integer";
    case INVALID_DATE:
      return "date";
    case INVALID_DATE_TIME:
      return "dateTime";
  }
  return null;
};

export function validate(_value, format, options) {
  const r = { value: _value, valid: true }; //By default value is valid
  if (typeof r.value === "undefined" || r.value == "") {
    //Empty value means: no value so it is value for all formats
    return r;
  }
  if (format == "float" || format == "double") {
    r.value = r.value.replace(",", ".");
    //https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/parseFloat
    if (!/^(\-|\+)?([0-9]+(\.[0-9]*)?)$/.test(r.value)) {
      r.valid = false;
      r.error = INVALID_FLOAT;
    }
  } else if (format == "integer" || format == "int") {
    if (!/^(\-|\+)?([0-9]+)$/.test(r.value)) {
      r.valid = false;
      r.error = INVALID_INTEGER;
    }
  } else if (format == "date") {
    //'L'
    if (!moment(r.value, "L", true).isValid()) {
      r.valid = false;
      r.error = INVALID_DATE;
    }
  } else if (format == "dateTime") {
    //'L LTS'
    const formatString =
      options && options.milliseconds ? "L HH:mm:ss.SSS" : "L LTS";
    if (!moment(r.value, formatString, true).isValid()) {
      r.valid = false;
      r.error = INVALID_DATE_TIME;
    }
  }
  return r;
}

/**Convert value when we receive it from redux*/
export function fromRedux(value, format, options) {
  if (typeof value == "undefined" || value == null || isEmptyObject(value)) {
    return "";
  }
  if (
    typeof value == "object" &&
    value != null &&
    typeof value.$value != "undefined"
  ) {
    //if object check $value field
    value = value.$value;
  }
  if (format == "date") {
    return moment(value).format("L");
  } else if (format == "dateTime") {
    if (options && options.milliseconds) {
      return moment(value).format("L HH:mm:ss.SSS");
    }
    return moment(value).format("L LTS");
  }
  return value;
}

/**Convert value when we send it to redux*/
export function toRedux(value, format, options) {
  if (!value || isEmptyObject(value)) {
    return {};
  }
  if (format == "date") {
    return moment(value, "L").format("YYYY-MM-DD");
  } else if (format == "dateTime") {
    if (options && options.milliseconds) {
      return moment(value, "L HH:mm:ss.SSS").toISOString();
    }
    return moment(value, "L LTS").toISOString();
  }
  return value;
}
