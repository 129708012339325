import React from "react";
import { connect } from "react-redux";
import { LayoutNode, Layout, isSubject } from "../../../types/subject";

import Col from "react-bootstrap/Col";
import CardArea from "../CardArea";
import { RootState } from "../../../store";

export interface GridCellProps {
  subjectKey: string;
  nodeId: string;
  layout?: Layout;
  childrenIds?: string[];
  visible?: boolean;
}

const getColsWithOffset = (
  node: LayoutNode
): { span: number; offset: number } => {
  let cols = 12;
  let offset = 0;
  if (node.options && typeof node.options.cols == "number") {
    if (typeof node.options.cols == "number") {
      cols = node.options.cols;
    }
    if (typeof node.options.offset == "number") {
      offset = node.options.offset;
    }
  }
  return {
    span: cols,
    offset,
  };
};

class GridCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.layout || !this.props.childrenIds || !this.props.visible) {
      return null;
    }
    const node = this.props.layout.nodeById[this.props.nodeId];

    return (
      <Col md={getColsWithOffset(node)} className="px-1">
        <CardArea
          subjectKey={this.props.subjectKey}
          nodeId={this.props.nodeId}
        />
      </Col>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return null;
    }
    const childrenIds = subject && subject.childrenNodesById[ownProps.nodeId];
    const visible = subject.visibility[ownProps.nodeId] ? true : false;
    return {
      layout: subject,
      childrenIds: childrenIds,
      visible,
    };
  }
)(GridCell);
