import { RematchDispatch } from "@rematch/core";
import * as React from "react";
import { connect } from "react-redux";
import { RootModel } from "../../model";
import { RootState } from "../../store";
import { AlertInfo } from "../../types/alert";
import AlertList from "./AlertList";

export interface AlertStackProps {
  alertList: AlertInfo[];
  removeAlert: (id: string) => void;
}

class AlertStack extends React.Component<AlertStackProps> {
  constructor(props: AlertStackProps) {
    super(props);
  }
  render() {
    const len = this.props.alertList.length;
    if (len == 0) {
      return null;
    }
    return (
      <AlertList
        alerts={this.props.alertList}
        onDismiss={this.props.removeAlert}
        timeout={5000}
      />
    );
  }
}

export default connect(
  (state: RootState) => {
    return {
      alertList: state.alert.alertList,
    };
  },
  (dispatch: RematchDispatch<RootModel>) => {
    return {
      removeAlert: (id: string) => dispatch.alert.sendRemoveAlert(id),
    };
  }
)(AlertStack);
