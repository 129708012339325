import { FetchError } from "./error";

export interface SessionAction {
  name: string;
}

export interface SessionHeaderStateEntry {
  key: string;
  version: number;
  value: any;
}

export interface SessionHeader {
  uuid?: string;
  token?: string;
  version: number;
  workersVersion: number;
  prototypeKey?: string;
  state: SessionHeaderStateEntry[];
  //Action name -> worker UUID
  workers: { [ACTION: string]: string };
  actionList: SessionAction[];
  //Map between model names and dependent keys
  modelNamesToKeys: { [KEY: string]: string[] };
}

export interface SessionStateEvent {
  token?: string;
  key: string;
  value: any;
  version: number;
  //This is notification without value inside
  notification?: boolean;
}

export interface SessionWorkerEvent {
  action: string;
  worker: string;
  workerEventType: "STARTED" | "SUCCESS" | "ERROR" | "REMOVED";
  version: number;
  //Action name -> worker UUID
  workers: { [ACTION: string]: string };
  label?: string;
}

export type SessionUpdateEvent = SessionStateEvent | SessionWorkerEvent;

export type SessionActionFunction = (...args: any[]) => void;

export type SessionActionDispatcher = {
  [ACTION: string]: SessionActionFunction;
};

export interface SessionValueChange {
  type: "SET" | "TOGGLE" | "ADD" | "PUSH" | "CLEAR";
  key: string;
  field?: string;
  value: any;
  token?: string;
  systemObject?: string;
}

export interface SessionContextInterface {
  path: string | null;
  model: string | null;
  systemObject?: string;
}

export function isSessionWorkerEvent(
  event: SessionUpdateEvent
): event is SessionWorkerEvent {
  return (event as SessionWorkerEvent).workers !== undefined;
}

export interface SessionDropEvent {
  uuid: string;
}

export interface SessionCreateEvent {
  prototypeKey: string;
}

export interface OneSessionState {
  //Marker that session is loading
  loading: boolean;
  //Session model
  model: string;
  //Session system object
  systemObject?: string;
  //Error while loading session
  error?: FetchError;
  //Session uuid is server side session identification
  uuid: string;
  //Session token (token that we receive each time session is loading)
  token: string;
  //Prototype key (for on-demand sessions)
  prototypeKey?: string;
  //List of available actions
  actionList: SessionAction[];
  //Session state from server
  sessionState: { [P: string]: any };
  //Versions of session keys
  sessionVersion: { [P: string]: number };
  //Action name -> worker UUID
  workersState: { [ACTION: string]: string };
  //State version
  version: number;
  //Worker version
  workersVersion: number;
  //Map between model names and dependent keys
  modelNamesToKeys: { [KEY: string]: string[] };
}

export interface SessionInfoEntry {
  correct: boolean;
  live: boolean;
  path: string;
  systemObject: string;
  userId: number;
  username: string;
}

export interface SessionInfo {
  list?: SessionInfoEntry[];
  loading?: boolean;
}

export interface SessionState {
  sessions: { [PATH: string]: OneSessionState };
  info?: SessionInfo;
}

export interface SessionReloadAction {
  //Session model
  model: string;
  //Session system object
  systemObject?: string;
  //Do not set loading flag so components will not remount
  smooth?: boolean;
}

export interface SessionUpdateStateChanges {
  //Session path
  path: string;
  //changes
  change: SessionValueChange;
}

export interface SessionLoadingAction {
  //Session path
  path: string;
  //Session model
  model: string;
  //Session system object
  systemObject?: string;
}

export interface SessionLoadErrorAction {
  //Session path
  path: string;
  //Session fetch error
  error: FetchError;
}

export interface SessionSetHeaderAction {
  //Session path
  path: string;
  //Session header
  header: SessionHeader;
  //Session model
  model: string;
  //Session system object
  systemObject?: string;
}

export interface SessionCreateDropAction {
  //Session model
  model: string;
  //Session system object
  systemObject?: string;
}

export interface SessionDispatchAction {
  //Session model
  model: string;
  //Session system object
  systemObject?: string;
  //Action name
  name: string;
  //Action parameters
  parameters: any[];
}

export interface SessionStateEventAction extends SessionStateEvent {
  path: string;
}

export interface SessionWorkerEventAction extends SessionWorkerEvent {
  path: string;
}

export interface SessionDropEventAction extends SessionDropEvent {
  path: string;
}
