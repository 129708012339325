import * as React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import styles from "./Finder.module.css";
import { RootState } from "../../store";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";
import { DEFAULT_FINDER_STATE } from "../../model/finder";

interface FinderChangesConfirmProps {
  visible?: boolean;
  finderChanges: boolean;
  confirmFinderChanges: () => void;
  confirm?: () => void;
  denyFinderChanges: () => void;
  deny?: () => void;
}

/*********************************
 * Finder Confirmation Component *
 *********************************/
const FinderChangesConfirm: React.FunctionComponent<FinderChangesConfirmProps> =
  React.memo((props: FinderChangesConfirmProps) => {
    if (!props.visible) {
      return null;
    }
    return (
      <div className={`${styles.confirmPanel} d-flex`}>
        <div
          className={`${styles.confirmPanelItem} d-flex`}
          onClick={props.confirm || props.confirmFinderChanges}
        >
          <i className="fa fa fa-check" aria-hidden="true"></i>
          <FormattedMessage
            id="NPT_FINDER_CONFIRM"
            defaultMessage="Confirm"
            description="Confirm toolbar changes button"
          />
        </div>
        <div
          className={`${styles.confirmPanelItem} d-flex`}
          onClick={props.deny || props.denyFinderChanges}
        >
          <i className="fa fa fa-times" aria-hidden="true"></i>
          <FormattedMessage
            id="NPT_FINDER_DENY"
            defaultMessage="Deny"
            description="Deny toolbar changes button"
          />
        </div>
      </div>
    );
  });

export default connect(
  (state: RootState, ownProps: { finderId: string }) => {
    const finderState = state.finder[ownProps.finderId] || DEFAULT_FINDER_STATE;
    return {
      finderChanges: Boolean(finderState.changes),
    };
  },
  (dispatch: RematchDispatch<RootModel>, ownProps: { finderId: string }) => {
    const { finderId } = ownProps;
    return {
      confirmFinderChanges: () => {
        dispatch.finder.sendFinderChangesConfirm(finderId);
      },
      denyFinderChanges: () => {
        dispatch.finder.sendFinderChangesDeny(finderId);
      },
    };
  }
)(FinderChangesConfirm);
