import { createModel } from "@rematch/core"
import { RootModel } from "."
import { ServerError } from "../actions/utils"
import UploadedFileInfoModal from "../components/admin/filearchive/modals/UploadedFileInfo"
import { openNamedModal } from "../components/helpers/NamedModal"
import { ALERT_LEVEL_SUCCESS } from "../constants/alert"
import { dispatchError } from "../services/alert"
import { store } from "../store"
import {
  DEFAULT_FILEARCHIVE_STATE,
  EditFilearchiveInfo,
  FilearchiveInfo,
  FilearchiveState,
  FilearchiveTableResult,
  FileData,
  FileInfo,
} from "../types/filearchive"
import { I18NString } from "../types/modal"

const initialState: FilearchiveState = DEFAULT_FILEARCHIVE_STATE

const request = (row: any) => {
  return {
    method: "POST",
    body: JSON.stringify(row),
  }
}

function checkAll(checked: boolean, state: FilearchiveState) {
  let checkedObject = { ...state.info.checked }
  for (let item in checkedObject) {
    checkedObject[item] = checked
  }
  return checkedObject
}

function parseToFragmentInfo(result: FileData[]): FilearchiveInfo {
  let info: FilearchiveInfo = {
    sha1s: [],
    checked: {},
    contentLengths: {},
    contentTypes: {},
    originalNames: {},
    storageUrls: {},
    uploadTimestamps: {},
  }
  result.map((item: FileData) => {
    info.sha1s.push(item.sha1)
    info.contentLengths[item.sha1] = item.contentLength
    info.contentTypes[item.sha1] = item.contentType
    info.originalNames[item.sha1] = item.originalName
    info.storageUrls[item.sha1] = item.storageUrl
    info.uploadTimestamps[item.sha1] = item.uploadTimestamp
    info.checked[item.sha1] = false
  })
  return info
}

export const filearchive = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendItemChecked(state: FilearchiveState, payload: any) {
      const checkItemInfo = {
        ...state.info,
        checked: { ...state.info.checked, [payload.rdfId]: payload.checked },
      }
      return { ...state, info: checkItemInfo }
    },
    sendOffset(state: FilearchiveState, payload: number) {
      return { ...state, offset: payload }
    },
    sendFilearchiveLoading(state) {
      return { ...state, loading: true }
    },
    sendFilearchiveError(state, payload: any) {
      return { ...state, loading: false, error: payload }
    },
    sendStopEdit(state) {
      return { ...state, loading: true }
    },
    sendStartEdit(state, payload: { sha1: string; columnName: string }) {
      return {
        ...state,
        info: { ...state.info, edited: { sha1: payload.sha1, columnName: payload.columnName, columnValue: "" } },
      }
    },
    sendSearchInput(state, payload: { searchInput: string }) {
      return { ...state, searchInput: payload.searchInput }
    },
    sendAllItemChecked(state, payload: { checked: boolean }) {
      return { ...state, info: { ...state.info, checked: checkAll(payload.checked, state) }, isAllItemsChecked: payload.checked }
    },
    sendLimit(state, payload: { limit: number }) {
      return { ...state, limit: payload.limit }
    },
    sendFiles(state, payload: FilearchiveTableResult) {
      return { ...state, loading: false, info: { ...parseToFragmentInfo(payload.list) }, totalNumberOfElements: payload.total }
    },
  },
  effects: (dispatch) => ({
    async saveFileImpl(payload: { file: FormData }) {
      try {
        const response = await fetch("/rest/file/upload", {
          method: "POST",
          body: payload.file,
        })

        if (!response.ok) {
          throw new ServerError(response.status, response.statusText)
        }

        return response.json()
      } catch (error) {
        console.error(error)
      }
    },
    async fetchFilesImpl(payload: { tableInfo: any }) {
      try {
        const tableInfo  = payload.tableInfo
        let url = "/rest/file/list"
        let query: string[] = []
        if (tableInfo) {
          for (const k of Object.keys(tableInfo)) {
            console.log(`const k of Object.keys(tableInfo)`, k)
            const v = tableInfo[k]
            if (v === undefined || String(v).trim().length === 0) {
              continue
            }
            query.push(`${k}=${v}`)
          }
        }
        if (query.length > 0) {
          url += `?${query.join("&")}`
        }

        const response = await fetch(url, {
          method: "GET",
          // headers: {
          //     'Accept': 'application/json'
          // }
        })
        if (!response.ok) {
          throw new ServerError(response.status, response.statusText)
        }
        const data = await response.json()
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async downloadFileImpl(payload: { sha1: string }) {
      try {
        return fetch("/rest/file/download/" + payload.sha1).then((resp) => {
          if (!resp.ok) {
            throw new ServerError(resp.status, resp.statusText)
          }
          const contentDesp = resp.headers.get("Content-Disposition")
          let filename = "default.txt"
          if (contentDesp) {
            filename = decodeURI(contentDesp.split("filename=")[1])
            filename = filename.slice(1, filename.length - 1)
          }

          resp.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement("a")
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          })
        })
      } catch (error) {
        console.error(error)
      }
    },
    async fetchFileBlobImpl(payload: { sha1: string }) {
      try {
        const resp = await fetch("/rest/file/download/" + payload.sha1)
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText)
        }
        return await resp.blob()
      } catch (error) {
        console.error(error)
      }
    },
    async editFileImpl(payload: { editedObjectInfo: any }) {
      try {
        return fetch("/rest/file/edit", request(payload.editedObjectInfo))
          .then((resp) => {
            if (!resp.ok) {
              throw new ServerError(resp.status, resp.statusText)
            }
            return resp.json()
          })
          .then((json) => {
            return
          })
      } catch (error) {
        console.error(error)
      }
    },
    async saveFile(payload: { file: any }, state) {
      const filearchiveState = state.filearchive
      if (filearchiveState) {
        try {
          const fileInfo = await dispatch.filearchive.saveFileImpl({file: payload.file})
          const uploadedFile: FileInfo = {
            sha1: fileInfo?.sha1 || "",
            contentType: fileInfo.contentType || "",
            contentLength: fileInfo.contentLength || 0,
            originalName: fileInfo.originalName || "",
            uploadTimestamp: fileInfo.uploadTimestamp || "",
            storageUrl: fileInfo.storageUrl || "",
          }

          const tableInfo: any = {
            pageable: true,
            offset: filearchiveState.offset,
            limit: filearchiveState.limit,
          }
          dispatch.filearchive.fetchFiles(tableInfo)
          // FIXME: not sure
          openNamedModal(UploadedFileInfoModal.ID, { fileInfo: uploadedFile })
        } catch (e) {
          dispatchError("FILEARCHIVE_UPLOAD_ERROR", e, dispatch)
        }
      }
    },
    async downloadFile(payload: { sha1: string }) {
      try {
        const file = await dispatch.filearchive.downloadFileImpl({ sha1: payload.sha1 })
      } catch (e) {
        dispatchError("FILEARCHIVE_NOT_FOUND", e, dispatch)
      }
    },
    async fetchFiles(payload: any, state) {
      try {
        const filearchiveState = state.filearchive
        if (filearchiveState && filearchiveState.loading) {
          return
        }
        let newPayload = { ...payload.tableInfo }
        filearchiveState && (newPayload.search = filearchiveState?.searchInput)
        dispatch.filearchive.sendFilearchiveLoading()
        dispatch.filearchive.sendFiles(await dispatch.filearchive.fetchFilesImpl({ tableInfo: { ...payload, ...newPayload } }))
        dispatch.filearchive.sendOffset(payload.offset)
      } catch (e) {
        dispatchError("FILEARCHIVE_FETCH_ERROR", e, dispatch)
      }
    },
    async setOffset(payload: { offset: number }) {
      dispatch.filearchive.sendOffset(payload.offset)
    },
    async setItemChecked(payload: { rdfid: string; checked: boolean }) {
      dispatch.filearchive.sendItemChecked({ rdfId: payload.rdfid, checked: payload.checked })
    },
    async setAllItemsChecked(payload: { checked: boolean }) {
      dispatch.filearchive.sendAllItemChecked({ checked: payload.checked })
    },
    async setLimit(payload: { limit: number }, state) {
      const filearchivState = state.filearchive
      if (!filearchivState) {
        return
      }
      let tableInfo: any = { pageable: true, offset: 0, limit: payload.limit }
      dispatch.filearchive.sendLimit({ limit: payload.limit })
      dispatch.filearchive.fetchFiles(tableInfo)
    },
    async setStartEdit(payload: { sha1: string; field: string }) {
      dispatch.filearchive.sendStartEdit({ sha1: payload.sha1, columnName: payload.field })
    },
    async setStopEditSuccess(payload: { newValue: string }, state) {
      const filearchiveState = state.filearchive
      if (filearchiveState && filearchiveState.info.edited) {
        try {
          let editedObject: EditFilearchiveInfo = {
            ...filearchiveState.info.edited,
            columnValue: payload.newValue,
          }
          let tableInfo: any = {
            pageable: true,
            offset: filearchiveState.offset,
            limit: filearchiveState.limit,
          }
          await dispatch.filearchive.editFileImpl({ editedObjectInfo: editedObject })
          dispatch.filearchive.sendStopEdit()
          dispatch.filearchive.fetchFiles(tableInfo)
          const messge: I18NString = { id: "FILE_EDIT_SUCCESS" }
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge })
        } catch (e) {
          dispatchError("FILE_EDIT_ERROR", e, dispatch)
        }
      }
    },
    async setStopEditCancelled() {
      dispatch.filearchive.sendStopEdit()
    },
    async setSearch(payload: { value: string }, state) {
      const filearchiveState = state.filearchive
      if (!filearchiveState) {
        return
      }
      let tableInfo: any = {
        pageable: true,
        offset: 0,
        limit: filearchiveState.limit,
      }
      dispatch.filearchive.sendSearchInput({ searchInput: payload.value })
      dispatch.filearchive.fetchFiles(tableInfo)
    },
  }),
})
