import * as React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import {
  isI18NString,
  ModalInfo,
  ModalStatus,
  QuizModalOptions,
} from "../../../types/modal";
import ModalView from "../ModalView";

const DEFAULT_QUIZ_OPTIONS: QuizModalOptions = {
  type: "checkbox",
  options: [],
};

const getQuizOptions = (modal: ModalInfo, intl: IntlShape) => {
  const rawOptions = modal.options;
  if (!rawOptions) {
    return DEFAULT_QUIZ_OPTIONS;
  }
  const options = { ...rawOptions } as QuizModalOptions;
  if (Array.isArray(options.options)) {
    options.parsedOptions = options.options.map((option) => {
      const label =
        typeof option.label !== "object"
          ? option.label
          : intl.formatMessage({ id: option.label.id });
      return {
        value: option.value,
        label,
      };
    });
  }
  return options;
};

interface QuizModalProps {
  modal: ModalInfo;
  intl: IntlShape;
  closeModal: (status: ModalStatus, result: any) => void;
}
interface QuizModalState {
  selected: string[];
}
class QuizModal extends React.Component<QuizModalProps, QuizModalState> {
  options: QuizModalOptions;

  constructor(props: QuizModalProps) {
    super(props);
    let selected: string[] = [];

    this.options = getQuizOptions(this.props.modal, this.props.intl);
    if (this.options.selected) {
      if (this.options.type === "radio") {
        selected = Array.isArray(this.options.selected)
          ? [this.options.selected[0]]
          : [this.options.selected];
      } else {
        selected = Array.isArray(this.options.selected)
          ? this.options.selected
          : [this.options.selected];
      }
    }

    this.state = {
      selected,
    };

    this.select = this.select.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  select(value: string) {
    if (this.state.selected.indexOf(value) !== -1) {
      if (this.options.type === "radio") {
        if (this.options.allowDeselect) {
          this.setState({ selected: [] });
        }
        return;
      }
      this.setState({
        selected: this.state.selected.filter(
          (selectedValue) => value !== selectedValue
        ),
      });
      return;
    }
    if (this.options.type === "radio") {
      this.setState({ selected: [value] });
      return;
    }
    const selected = this.state.selected.slice();
    selected.push(value);
    this.setState({ selected: selected });
    return;
  }

  renderMessage(): React.ReactElement {
    const { body } = this.props.modal.options;

    let message = <></>;
    if (isI18NString(body)) {
      message = (
        <FormattedMessage
          id={body.id}
          values={body.values}
          defaultMessage="Select values"
        />
      );
    } else if (typeof body === "string") {
      message = <>{body}</>;
    }

    return <p className="row">{message}</p>;
  }

  renderTemplate(): React.ReactElement {
    return (
      <div className="container-fluid">
        {this.renderMessage()}
        {this.options.parsedOptions?.map((option, index) => (
          <Row
            key={index}
            radio={this.options.type === "radio"}
            option={option}
            selected={this.state.selected}
            select={this.select}
          />
        ))}
      </div>
    );
  }

  getResult() {
    if (this.state.selected.length !== 0) {
      return this.state.selected;
    }
    if (this.options.allowEmpty) {
      return [];
    }
    return null;
  }

  closeModal(status: ModalStatus, result: any) {
    if (status == MODAL_STATUS_OK) {
      this.props.closeModal(status, this.getResult());
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, null);
    }
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = {
      ...modal.options,
      title: modal.options.title || { id: "QUIZ_MODAL_TITLE" },
    };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

interface RowProps {
  radio: boolean;
  option: { value: string; label: string };
  selected: string[];
  select: (value: string) => void;
}
const Row: React.FunctionComponent<RowProps> = React.memo((props) => {
  const type = props.radio ? "radio" : "checkbox";
  return (
    <div className="row d-flex flex-nowrap align-items-center">
      <input
        className="mr-2"
        type={type}
        checked={props.selected.indexOf(props.option.value) !== -1}
        onChange={() => props.select(props.option.value)}
      />
      <span>{props.option.label}</span>
    </div>
  );
});

export default injectIntl(QuizModal);
