import { createModel } from "@rematch/core";
import { RootModel } from ".";
import {
  OneSessionState,
  SessionUpdateStateChanges,
  SessionHeader,
  SessionLoadErrorAction,
  SessionLoadingAction,
  SessionReloadAction,
  SessionSetHeaderAction,
  SessionState,
  SessionValueChange,
  SessionStateEventAction,
  SessionWorkerEventAction,
  SessionDispatchAction,
  SessionDropEventAction,
  SessionInfoEntry,
  SessionInfo,
} from "../types/session";
import { buildUrl } from "../services/location";
import { ServerError } from "../actions/utils";
import { dispatchError } from "../services/alert";

export const createSessionPath = (model: string, systemObject?: string) => {
  let search: { [k: string]: string } | null = null;
  if (systemObject) {
    search = {};
    search.obj = systemObject;
  }
  return buildUrl({ url: "/" + model, search });
};

const updateSessionState = (
  current: { [P: string]: any },
  change: SessionValueChange
): { [P: string]: any } => {
  if (change.type == "CLEAR" && typeof change.field != "string") {
    const sessionState: { [P: string]: any } = {};
    for (let p of Object.getOwnPropertyNames(current)) {
      if (p != change.key) {
        sessionState[p] = current[p];
      }
    }
    return sessionState;
  }

  const sessionState = { ...current };
  if (typeof change.field == "string") {
    const curMap = sessionState[change.key];
    if (change.type == "CLEAR") {
      if (typeof curMap == "object" && curMap != null) {
        const map: { [FIELD: string]: any } = {};
        for (let p of Object.getOwnPropertyNames(curMap)) {
          if (p != change.field) {
            map[p] = curMap[p];
          }
        }
        sessionState[change.key] = map;
      }
    } else {
      const map =
        typeof curMap == "object" && curMap != null ? { ...curMap } : {};
      updateValueInMap(change, change.field, map);
      sessionState[change.key] = map;
    }
  } else {
    updateValueInMap(change, change.key, sessionState);
  }

  return sessionState;
};

const updateValueInMap = (
  change: SessionValueChange,
  field: string,
  map: { [P: string]: any }
) => {
  const current = map[field];
  switch (change.type) {
    case "SET":
      map[field] = change.value;
      break;
    case "ADD":
      if (typeof change.value == "number") {
        if (typeof current == "number") {
          map[field] = current + change.value;
        } else {
          map[field] = change.value;
        }
      }
      break;
    case "TOGGLE":
      if (typeof current == "undefined") {
        map[field] = true;
      } else if (typeof current == "boolean") {
        map[field] = !current;
      }
      break;
    case "PUSH":
      if (typeof current == "undefined") {
        map[field] = [change.value];
      } else if (Array.isArray(current)) {
        map[field] = [...current, change.value];
      }
      break;
    default:
      console.error("Unknown session state operation: ", change);
  }
};

export const createEmptySession = (
  model: string,
  systemObject?: string
): OneSessionState => {
  return {
    model,
    systemObject,
    loading: false,
    uuid: "",
    token: "",
    actionList: [],
    sessionState: {},
    sessionVersion: {},
    workersState: {},
    version: 0,
    workersVersion: 0,
    modelNamesToKeys: {},
  };
};

const initialState: SessionState = {
  sessions: {},
};

export const session = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSessionLoading(
      state,
      { path, model, systemObject }: SessionLoadingAction
    ) {
      const session = createEmptySession(model, systemObject);
      session.loading = true;
      const sessions = { ...state.sessions };
      sessions[path] = session;
      return { ...state, sessions };
    },
    setSessionLoadError(state, { path, error }: SessionLoadErrorAction) {
      const prevSession = state.sessions[path];
      if (typeof prevSession == "undefined") {
        return state;
      }
      const session = { ...prevSession };
      const sessions = { ...state.sessions };
      sessions[path] = session;

      session.loading = false;
      session.error = error;

      return { ...state, sessions };
    },
    setSessionHeader(
      state,
      { path, header, model, systemObject }: SessionSetHeaderAction
    ) {
      const prevSession = state.sessions[path];
      const session =
        typeof prevSession != "undefined"
          ? { ...prevSession }
          : createEmptySession(model, systemObject);
      const sessions = { ...state.sessions };
      sessions[path] = session;

      session.loading = false;
      session.error = undefined;

      const { uuid, prototypeKey } = header;

      session.modelNamesToKeys = header.modelNamesToKeys;
      session.actionList = header.actionList;

      if (session.uuid != uuid || session.prototypeKey != prototypeKey) {
        session.uuid = typeof uuid == "string" ? uuid : "";
        session.prototypeKey = prototypeKey;
      }

      const sessionState: { [P: string]: any } = {};
      const sessionVersion: { [P: string]: number } = {};

      for (let entry of header.state) {
        sessionState[entry.key] = entry.value;
        sessionVersion[entry.key] = entry.version;
      }

      session.sessionState = sessionState;
      session.sessionVersion = sessionVersion;

      return { ...state, sessions };
    },

    syncSessionState(state, { path, header }: SessionSetHeaderAction) {
      const prevSession = state.sessions[path];
      if (typeof prevSession == "undefined") {
        return state;
      }

      const session = { ...prevSession };
      const sessions = { ...state.sessions };
      sessions[path] = session;

      if (session.uuid != header.uuid && typeof header.uuid == "string") {
        session.uuid = header.uuid;
      }

      if (session.version < header.version) {
        session.version = header.version;
      }

      const sessionState = { ...session.sessionState };
      const sessionVersion = { ...session.sessionVersion };

      if (session.workersVersion < header.workersVersion) {
        console.log("Update state on resync");
        session.workersVersion = header.workersVersion;
        session.workersState = header.workers;
      }

      for (let entry of header.state) {
        //Update to the new versions only!
        const version = sessionVersion[entry.key];
        if (typeof version != "number" || version < entry.version) {
          sessionState[entry.key] = entry.value;
          sessionVersion[entry.key] = entry.version;
        }
      }

      session.sessionState = sessionState;
      session.sessionVersion = sessionVersion;

      return { ...state, sessions };
    },
    updateSessionState(state, { path, change }: SessionUpdateStateChanges) {
      const prevSession = state.sessions[path];
      if (typeof prevSession == "undefined") {
        return state;
      }
      const sessions = { ...state.sessions };
      const session = { ...prevSession };
      session.sessionState = updateSessionState(
        prevSession.sessionState,
        change
      );
      sessions[path] = session;
      return { ...state, sessions };
    },
    handleStateEvent(state, event: SessionStateEventAction) {
      const prevSession = state.sessions[event.path];
      if (typeof prevSession == "undefined") {
        return state;
      }

      if (event.version <= prevSession.version) {
        return state;
      }

      const sessions = { ...state.sessions };
      const session = { ...prevSession };
      sessions[event.path] = session;

      //Always track state version to resync if required
      session.version = event.version;

      //Update only if it's not notification and not our own event
      if (!event.notification && event.token != session.token) {
        //Update to the new versions only!
        const version = session.sessionVersion[event.key];
        if (typeof version != "number" || version < event.version) {
          const sessionState = { ...session.sessionState };
          const sessionVersion = { ...session.sessionVersion };
          sessionState[event.key] = event.value;
          sessionVersion[event.key] = event.version;
          session.sessionState = sessionState;
          session.sessionVersion = sessionVersion;
        }
      } else {
        //Update to the new versions only!
        const version = session.sessionVersion[event.key];
        if (typeof version != "number" || version < event.version) {
          const sessionVersion = { ...session.sessionVersion };
          sessionVersion[event.key] = event.version;
          session.sessionVersion = sessionVersion;
        }
      }

      return { ...state, sessions };
    },
    handleWorkerEvent(state, event: SessionWorkerEventAction) {
      const prevSession = state.sessions[event.path];
      if (typeof prevSession == "undefined") {
        return state;
      }

      if (event.version <= prevSession.workersVersion) {
        return;
      }

      const sessions = { ...state.sessions };
      const session = { ...prevSession };
      sessions[event.path] = session;

      //Always track workers version to resync if required
      session.workersVersion = event.version;
      session.workersState = event.workers;

      return { ...state, sessions };
    },
    handleDropEvent(state, event: SessionDropEventAction) {
      const prevSession = state.sessions[event.path];
      if (typeof prevSession == "undefined") {
        return state;
      }

      const sessions = { ...state.sessions };
      delete sessions[event.path];

      return { ...state, sessions };
    },
    sendSessionInfo(state, payload: SessionInfoEntry[]) {
      const newSessionInfo: SessionInfo = { ...state.info };
      newSessionInfo.list = payload;
      return { ...state, info: newSessionInfo };
    },
    sendSessionInfoLoading(state, payload: boolean = true) {
      const newSessionInfo: SessionInfo = { ...state.info };
      newSessionInfo.loading = payload;
      return { ...state, info: newSessionInfo };
    },
  },
  effects: (dispatch) => ({
    async reload({ model, systemObject, smooth }: SessionReloadAction) {
      const path = createSessionPath(model, systemObject);
      const url = "/rest/sess/header" + path;
      if (!smooth) {
        dispatch.session.setSessionLoading({ path, model, systemObject });
      }
      try {
        const resp = await fetch(url);
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText);
        }
        const header = (await resp.json()) as SessionHeader;
        dispatch.session.setSessionHeader({
          path,
          model,
          systemObject,
          header,
        });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async sync({ model, systemObject }: SessionReloadAction, state) {
      const path = createSessionPath(model, systemObject);
      const session = state.session.sessions[path];
      if (typeof session == "undefined") {
        return;
      }
      const token = session.token;
      const uuid = session.uuid;
      const version = session.version;
      try {
        const resp = await fetch("/rest/sess/sync" + model, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ systemObject, token, uuid, version }),
        });
        const header = (await resp.json()) as SessionHeader;
        dispatch.session.syncSessionState({
          path,
          model,
          systemObject,
          header,
        });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    applyStateChanges({ path, change }: SessionUpdateStateChanges, state) {
      const session = state.session.sessions[path];
      if (typeof session == "undefined") {
        return;
      }

      //Update values locally
      dispatch.session.updateSessionState({ path, change });

      //Send changes to server
      const payload = { ...change };
      payload.token = session.token;
      payload.systemObject = session.systemObject;

      fetch("/rest/sess/chg" + session.model, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).then(
        (resp) => {
          if (resp.ok) {
            //Read for console
            resp.json();
          } else {
            //Read for console
            resp.text().then((error) => {
              console.error("Failed change value", change, error);
            });
          }
        },
        (error) => {
          console.error("Failed change value", change, error);
        }
      );
    },
    async drop({ model, systemObject }: SessionReloadAction) {
      const fetchUrl = "/rest/sess/header" + model;

      let search: { [k: string]: string } | null = null;
      if (systemObject) {
        search = {};
        search.obj = systemObject;
      }

      try {
        const url = buildUrl({ url: fetchUrl, search });
        const resp = await fetch(url, {
          method: "DELETE",
        });
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText);
        }
        const header = (await resp.json()) as SessionHeader;
        console.log("Session drop request executed: ", header);
        dispatch.session.reload({ model, systemObject });
      } catch (e) {
        console.error(e);
      }
    },
    async create({ model, systemObject }: SessionReloadAction) {
      const path = createSessionPath(model, systemObject);
      const fetchUrl = "/rest/sess/create" + model;
      try {
        const resp = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ systemObject }),
        });
        if (!resp.ok) {
          throw new ServerError(resp.status, resp.statusText);
        }
        const header = (await resp.json()) as SessionHeader;
        dispatch.session.setSessionHeader({
          path,
          model,
          systemObject,
          header,
        });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.session.setSessionLoadError({
            path,
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    sessionDispatchAction({
      model,
      systemObject,
      name,
      parameters,
    }: SessionDispatchAction) {
      fetch("/rest/sess/act" + model, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          systemObject,
          parameters,
        }),
      }).then(
        (resp) => {
          if (resp.ok) {
            //Read for console
            resp.json();
          } else {
            //Read for console
            resp.text().then((error) => {
              console.error("Failed to execute action", name, error);
            });
          }
        },
        (error) => {
          console.error("Failed to execute action", name, error);
        }
      );
    },
    notifyWorkerEvent(event: SessionWorkerEventAction) {
      dispatch.session.handleWorkerEvent(event);
      const label = event.label;
      if (typeof label == "string") {
        switch (event.workerEventType) {
          case "STARTED": {
            dispatch.alert.addAlert({
              type: "info",
              message: {
                id: "SESION_WORKER_STARTED",
                defaultMessage: "Action started: {label}",
                values: {
                  label,
                },
              },
            });
            break;
          }
          case "REMOVED": {
            dispatch.alert.addAlert({
              type: "warning",
              message: {
                id: "SESION_WORKER_REMOVED",
                defaultMessage: "Action canceled: {label}",
                values: {
                  label,
                },
              },
            });
            break;
          }
          case "SUCCESS": {
            dispatch.alert.addAlert({
              type: "success",
              message: {
                id: "SESION_WORKER_SUCCESS",
                defaultMessage: "Action success: {label}",
                values: {
                  label,
                },
              },
            });
            break;
          }
          case "ERROR": {
            dispatch.alert.addAlert({
              type: "danger",
              message: {
                id: "SESION_WORKER_ERROR",
                defaultMessage: "Action error: {label}",
                values: {
                  label,
                },
              },
            });
            break;
          }
          default: {
            console.error("Unknown worker event type", event.workerEventType);
          }
        }
      }
    },
    async getSessionInfo(_: void, state) {
      const loading = state.session?.info?.loading;
      if (loading) {
        return;
      }
      dispatch.session.sendSessionInfoLoading();
      try {
        const response = await fetch("/rest/sesseditor/list");
        if (!response.ok) {
          throw new ServerError(response.status, response.statusText);
        }
        const info = await response.json();
        const list = info?.entryList;
        list && dispatch.session.sendSessionInfo(list);
      } catch (e) {
        dispatchError("NAMESPACE_EDIT_ERROR", e, dispatch);
      } finally {
        dispatch.session.sendSessionInfoLoading(false);
      }
    },
  }),
});
