import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { MODAL_STATUS_OK } from "../../../constants/modal";
import { RootState } from "../../../store";
import { ModalInfo, ModalStatus } from "../../../types/modal";
import { Link } from "../../../types/subject";
import ModalView from "../ModalView";

interface ConfirmRefTablePasteModalProps {
  modal: ModalInfo;
  contextPath?: string;
  closeModal: (status: ModalStatus, result: any) => void;
}

interface ConfirmRefTablePasteModalState {
  validLinks: Link[];
}

class ConfirmRefTablePasteModal extends React.Component<
  ConfirmRefTablePasteModalProps,
  ConfirmRefTablePasteModalState
> {
  title = { id: "OBJECTCARD_CONFIRM_REFERENCES_PASTE" };
  validLinks: Link[] = [];

  constructor(props: ConfirmRefTablePasteModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    const body = this.props.modal.options.body;
    if (body && typeof body === "string") {
      const links = JSON.parse(body);

      const validLinks = Array.isArray(links) ? links : [];
      this.validLinks = validLinks;
      this.state = { validLinks: [...validLinks] };
    }
  }
  getObjectRef = (namespace: string | null, rdfId: string) => {
    const contextPath = this.props.contextPath || "/";
    let link = `${contextPath}objectcard/`;
    if (namespace) {
      link += `${namespace}/`;
    }
    link += rdfId;
    return (
      <a href={link} target="_blank">
        <i className="fa fa-eye" aria-hidden="true"></i>
      </a>
    );
  };
  remove = (rdfId: string) => {
    const newState = this.state.validLinks.filter((l) => rdfId !== l.rdfId);
    this.setState({ validLinks: newState });
  };
  undo = () => {
    if (this.validLinks.length !== 0) {
      this.setState({ validLinks: [...this.validLinks] });
    }
  };
  renderUndo() {
    if (
      this.validLinks.length === 0 ||
      this.validLinks.length <= this.state.validLinks.length
    ) {
      return null;
    }
    return (
      <Row className="  mb-1  ">
        <Col className="py-2 mx-1" md={4}>
          <Button variant="outline-success" onClick={() => this.undo()}>
            <FormattedMessage id="OBJECTCARD_REFTABLE_UNDO" />
          </Button>
        </Col>
        <Col className="py-2" md={8}>
          {" "}
        </Col>
      </Row>
    );
  }
  renderTemplate(): React.ReactElement {
    const { validLinks } = this.state;
    const style = { borderRight: "2px solid white" };
    return (
      <>
        {this.renderUndo()}
        <Row className="bg-primary text-white font-weight-bold mx-1   shadow-sm rounded ">
          <Col style={style} className="py-2" md={1}>
            <FormattedMessage id="OBJECTCARD_REFTABLE_NUMBER" />
          </Col>
          <Col style={style} className="py-2" md={3}>
            <FormattedMessage id="OBJECTCARD_REFTABLE_NAME" />
          </Col>
          <Col style={style} className="py-2" md={4}>
            <FormattedMessage id="OBJECTCARD_REFTABLE_DESCRIPTION" />
          </Col>
          <Col style={style} className="py-2" md={2}>
            <FormattedMessage id="OBJECTCARD_REFTABLE_CARD" />
          </Col>
          <Col className="py-2" md={2}>
            <FormattedMessage id="OBJECTCARD_REFTABLE_REMOVE_BUTTON" />
          </Col>
        </Row>
        {validLinks.length > 0 &&
          validLinks.map((linkInfo, index) => (
            <Row
              key={index}
              className={"npt-table-row mx-1 mt-1 py-1 bg-secondary rounded"}
            >
              <Col
                style={style}
                md={1}
                className="npt-table-cell table-cell py-1"
              >
                {index + 1}
              </Col>
              <Col
                style={style}
                md={3}
                className="npt-table-cell table-cell py-1"
              >
                {linkInfo.label}
              </Col>
              <Col
                style={style}
                md={4}
                className="npt-table-cell table-cell py-1"
                dangerouslySetInnerHTML={{ __html: linkInfo.description }}
              ></Col>
              <Col
                style={style}
                md={2}
                className="npt-table-cell table-cell py-1 text-center col"
              >
                {this.getObjectRef(linkInfo.namespace, linkInfo.rdfId)}
              </Col>
              <Col
                md={2}
                className="npt-table-cell table-cell py-1 text-center col "
              >
                <span onClick={() => this.remove(linkInfo.rdfId)}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    className="text-danger"
                    icon={faTrash}
                  />
                </span>
              </Col>
            </Row>
          ))}
      </>
    );
  }

  closeModal(status: ModalStatus, result: any) {
    if (status === MODAL_STATUS_OK && this.state.validLinks.length !== 0) {
      this.props.closeModal(status, this.state.validLinks);
    } else {
      this.props.closeModal(status, false);
    }
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = { title: this.title, ...modal.options };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

export default connect((state: RootState) => {
  return {
    contextPath: state.location.contextPath,
  };
}, null)(ConfirmRefTablePasteModal);
