import { Action, AnyAction } from "redux";

import { FetchError } from "./error";
import * as constants from '../constants/filearchive';

export type FilearchiveType = "filearchive";

export const DEFAULT_FILEARCHIVE_STATE = {
    info: {
        sha1s: [],
        contentLengths: {},
        contentTypes: {},
        storageUrls: {},
        uploadTimestamps: {},
        originalNames: {},
        checked: {}
    },
    totalNumberOfElements: 0,
    loading: false,
    error: null,
    searchInput: '', 
    limit: 20,
    offset: 0
}

export interface FilearchiveState {
    info: FilearchiveInfo,
    totalNumberOfElements: number,
    loading?: boolean,
    error?: null | FetchError, 
    searchInput?: string,
    isAllItemsChecked?: boolean,
    limit: number,
    offset: number 
}

export interface FilearchiveInfo {
    sha1s: string[],
    contentLengths: { [sha1: string]: string },
    contentTypes: { [sha1: string]: string },
    originalNames: { [sha1: string]: string },
    storageUrls: { [sha1: string]: string },
    uploadTimestamps: { [sha1: string]: string },
    checked: { [sha1: string]: boolean },
    edited?: EditFilearchiveInfo
}

export interface EditFilearchiveInfo {
    sha1: string,
    columnName: string,
    columnValue: string
}

export interface FilearchiveTableResult {
    list: FileData[],
    total: number
}

export interface FileInfo{
    sha1:string
    contentType:string
    contentLength:number
    originalName:string
    uploadTimestamp:string
    storageUrl:string
}

export type FileData = {
    sha1: string,
    contentLength: string,
    contentType: string,
    originalName: string,
    storageUrl: string,
    uploadTimestamp: string,
    checked: boolean
}
///////////
//Actions//
///////////

export interface SendFiles extends Action<constants.SEND_FILES> {
    fragmentType: FilearchiveType,
    payload: FilearchiveTableResult
}

export interface SendFilearchiveError extends Action<constants.SEND_FILEARCHIVE_ERROR> {
    fragmentType: FilearchiveType,
    payload: {
        error: FetchError
    }
}

export interface SendFilearchiveLoading extends Action<constants.SEND_FILEARCHIVE_LOADING> {
    fragmentType: FilearchiveType,
} 

export interface SendLimit extends Action<constants.SEND_LIMIT> {
    fragmentType: FilearchiveType,
    payload: number
}

export interface SendOffset extends Action<constants.SEND_OFFSET> {
    fragmentType: FilearchiveType,
    payload: number
}

export interface SendItemChecked extends Action<constants.SEND_ITEM_CHECKED> {
    fragmentType: FilearchiveType,
    payload: {
        rdfId: string,
        checked: boolean
    }
}

export interface SendAllItemChecked extends Action<constants.SEND_ALL_ITEM_CHECKED> {
    fragmentType: FilearchiveType,
    payload: boolean
}

export interface SendStartEdit extends Action<constants.SEND_START_EDIT> {
    fragmentType: FilearchiveType,
    payload: {
        sha1: string,
        columnName: string
    }
}
export interface SendStopEdit extends Action<constants.SEND_STOP_EDIT> {
    fragmentType: FilearchiveType,
}

export interface SendSearchInput extends Action<constants.SEND_SEARCH_INPUT_FILE> {
    fragmentType: FilearchiveType,
    payload: string
}
 

export type FilearchiveAction = SendFiles |
    SendFilearchiveError | 
    SendFilearchiveLoading | 
    SendLimit |
    SendOffset |
    SendItemChecked |
    SendAllItemChecked |
    SendStartEdit |
    SendStopEdit |
    SendSearchInput;

export function isFilearchiveAction(action: AnyAction): action is FilearchiveAction {
    return (typeof action.type == 'string');
}



