import React from "react";
import { connect } from "react-redux";
import { Link, LinkProps } from "react-router-dom";
import { SearchData } from "../../../types/location";
import { getQueryParamsFromLink } from "../../../services/location";
import { Dispatch } from "../../../store";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../../model";

interface SubjectLinkProps extends LinkProps {
  changeQueryParams: (queryParams: SearchData) => void;
}

class SubjectLink extends React.PureComponent<SubjectLinkProps> {
  constructor(props: SubjectLinkProps) {
    super(props);
  }

  render() {
    const changeSelection = (e: any) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }

      const queryParams = getQueryParamsFromLink(this.props.to.toString());
      if (queryParams === null) {
        return;
      }
      this.props.changeQueryParams(queryParams);
    };
    const { className } = this.props;
    return (
      <Link
        style={{ textDecoration: "none" }}
        to={this.props.to}
        className={className}
        onClick={changeSelection}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default connect(null, (dispatch: RematchDispatch<RootModel>) => {
  return {
    changeQueryParams: (queryParams: SearchData) => {
      dispatch.location.changeSearch({ dataChanges: queryParams });
    },
  };
})(SubjectLink);
