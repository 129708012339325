import { createModel, RematchDispatch } from "@rematch/core";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import {
  fetchDeadlockedThreadsImpl,
  fetchStaticCpuInfoImpl,
  fetchStatisticDataImpl,
  fetchThreadStatisticDataImpl,
  updateInfo,
} from "../services/statistics";
import { I18NString } from "../types/modal";
import {
  DeveloperState,
  ActiveDevDashboard,
  StaticCpuInfo,
  DeveloperData,
  ThreadStatData,
  ThreadData,
  ThreadStatInfo,
} from "../types/statistics";

const initialState: DeveloperState = {
  info: {},
  activeDashboard: ActiveDevDashboard.COMMON,
};

export const statistics = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendStaticCpuInfo(state, payload: StaticCpuInfo) {
      return {
        ...state,
        staticCpuInfo: { ...payload },
        staticCpuInfoLoading: false,
      };
    },
    sendStaticCpuLoading(state, data: boolean = true) {
      return {
        ...state,
        staticCpuInfoLoading: data,
      };
    },
    sendDeveloperData(state, data: DeveloperData) {
      return {
        ...state,
        info: updateInfo(data, state.info),
        loading: false,
        error: undefined,
      };
    },
    sendDeveloperDataLoading(state, loading: boolean = true) {
      return { ...state, loading };
    },
    sendDeveloperDashboardActive(state, name: ActiveDevDashboard) {
      return { ...state, activeDashboard: name };
    },
    sendDeveloperDataError(state, error: I18NString) {
      return { ...state, error };
    },
    sendThreadStatData(state, data: ThreadStatData) {
      return {
        ...state,
        threadStatInfo: {
          ...state.threadStatInfo,
          error: undefined,
          loading: false,
          info: data,
        },
      };
    },
    sendThreadStatLoading(state, loading: boolean = true) {
      return {
        ...state,
        threadStatInfo: { ...state.threadStatInfo, loading },
      };
    },
    sendThreadStatDataError(state, error: I18NString) {
      return {
        ...state,
        threadStatInfo: {
          ...state.threadStatInfo,
          error,
          loading: false,
        },
      };
    },
    sendDeadlockedThreadList(state, data: ThreadData[]) {
      const threadStatInfo: ThreadStatInfo = { ...state.threadStatInfo };
      threadStatInfo.deadlocked = {
        ...threadStatInfo.deadlocked,
        loading: false,
        data,
      };
      return { ...state, threadStatInfo };
    },
  },
  effects: (dispatch) => ({
    fetchStaticCpuInfo: async (_: void, s) => {
      dispatch.statistics.sendStaticCpuLoading();
      if (!s.statistics.info) {
        return;
      }
      try {
        const info = await fetchStaticCpuInfoImpl();
        console.log("info", info);
        dispatch.statistics.sendStaticCpuInfo(info);
      } catch (e: any) {
        dispatch.statistics.sendStaticCpuLoading(false);
      }
    },
    fetchStatisticData: async (_: void, s) => {
      dispatch.statistics.sendDeveloperDataLoading();

      if (!s || !s.statistics.info) {
        return;
      }
      try {
        dispatch.statistics.sendDeveloperData(await fetchStatisticDataImpl());
      } catch (e: any) {
        console.log(e);
        const message: I18NString = { id: "DEVELOPER_STATISTICS_FETCH_ERROR" };
        if (e instanceof ServerError) {
          message.values = { error: `${e.code} ${e.message}` };
        } else if (typeof e.message == "string") {
          message.values = { error: e.message };
          message.id = "DEVELOPER_STATISTICS_PROCESS_ERROR";
        } else {
          message.values = { error: "unknown error" };
        }
        dispatch.statistics.sendDeveloperDataError(message);
      }
    },
    fetchThreadsStatisticData: async (_: void, s) => {
      dispatch.statistics.sendThreadStatLoading();
      if (!s || !s.statistics.info) {
        return;
      }
      try {
        dispatch.statistics.sendThreadStatData(
          await fetchThreadStatisticDataImpl()
        );
      } catch (e: any) {
        console.log(e);
        const message: I18NString = { id: "DEVELOPER_STATISTICS_FETCH_ERROR" };
        if (e instanceof ServerError) {
          message.values = { error: `${e.code} ${e.message}` };
        } else if (typeof e.message == "string") {
          message.values = { error: e.message };
          message.id = "DEVELOPER_STATISTICS_PROCESS_ERROR";
        } else {
          message.values = { error: "unknown error" };
        }
        dispatch.statistics.sendThreadStatDataError(message);
      }
    },
    fetchDeadlockedThreads: async (_: void, s) => {
      if (!s || !s.statistics.info) {
        return;
      }
      try {
        dispatch.statistics.sendDeadlockedThreadList(
          await fetchDeadlockedThreadsImpl()
        );
      } catch (e: any) {
        console.log(e);
        const message: I18NString = { id: "DEVELOPER_STATISTICS_FETCH_ERROR" };
        if (e instanceof ServerError) {
          message.values = { error: `${e.code} ${e.message}` };
        } else if (typeof e.message == "string") {
          message.values = { error: e.message };
          message.id = "DEVELOPER_STATISTICS_PROCESS_ERROR";
        } else {
          message.values = { error: "unknown error" };
        }
        dispatch.statistics.sendThreadStatDataError(message);
      }
    },
  }),
});
