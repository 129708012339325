import { ServerError } from "../actions/utils";
import { StaticConfiguration, ConfigActionResult } from "../types/config";

export function fetchConfigInfoImpl(): Promise<StaticConfiguration> {
  return fetch("/rest/config/all")
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json as StaticConfiguration;
    });
}

export function changeConfigImpl(command: ConfigActionResult): Promise<any> {
  const opt: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(command),
  };
  return fetch("/rest/config/change", opt)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}
