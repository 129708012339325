import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  MODAL_STATUS_CANCEL,
  MODAL_STATUS_CLOSE,
  MODAL_STATUS_OK,
} from "../../../constants/modal";
import { RootState } from "../../../store";
import {
  ModalInfo,
  ModalStatus,
  TableModalOptions,
} from "../../../types/modal";
import { Column, ColumnSortInfo, SortDirection } from "../../../types/table";
import DndOptions from "../../common/DndOptions";
import ModalView from "../ModalView";

interface Option {
  value: string;
  label: string;
}

interface SortOption extends Option {
  direction: SortDirection;
}

interface TableSortOptionsModalProps {
  modal: ModalInfo;
  columns: Column[];
  sortColumns: ColumnSortInfo[];
  filterColumns: string[];
  closeModal: (
    status: ModalStatus,
    result: { sortColumns: ColumnSortInfo[]; filterColumns: string[] } | null
  ) => void;
}
interface TableSortOptionsModalState {
  sortColumns: SortOption[];
  filterColumns: Option[];
}

class TableSortOptionsModal extends React.Component<
  TableSortOptionsModalProps,
  TableSortOptionsModalState
> {
  constructor(props: TableSortOptionsModalProps) {
    super(props);

    this.state = {
      sortColumns: this.getSortOptions(props.sortColumns),
      filterColumns: this.getFilterOptions(props.filterColumns),
    };

    this.closeModal = this.closeModal.bind(this);
  }

  getSortOptions(columnSortInfoList: ColumnSortInfo[]): SortOption[] {
    const options: SortOption[] = [];
    for (let columnSortInfo of columnSortInfoList) {
      const tableColumn = this.props.columns.find(
        (column) => column.field === columnSortInfo.field
      );
      options.push({
        value: columnSortInfo.field,
        label: tableColumn ? tableColumn.name : columnSortInfo.field,
        direction: columnSortInfo.direction,
      });
    }
    return options;
  }

  getFilterOptions(fields: string[]): Option[] {
    const options: Option[] = [];
    for (let columnField of fields) {
      const tableColumn = this.props.columns.find(
        (column) => column.field === columnField
      );
      options.push({
        value: columnField,
        label: tableColumn ? tableColumn.name : columnField,
      });
    }
    return options;
  }

  renderTemplate(): React.ReactElement {
    const changeSortColumns = (columns: Option[]) => {
      this.setState(Object.assign({}, this.state, { sortColumns: columns }));
    };
    const changeFilterColumns = (columns: Option[]) => {
      this.setState(Object.assign({}, this.state, { filterColumns: columns }));
    };
    return (
      <TableSortOptionsBody
        sortColumns={this.state.sortColumns}
        filterColumns={this.state.filterColumns}
        changeSortColumns={changeSortColumns}
        changeFilterColumns={changeFilterColumns}
      />
    );
  }

  closeModal(status: ModalStatus, result: any) {
    if (status == MODAL_STATUS_OK) {
      this.props.closeModal(status, {
        sortColumns: this.state.sortColumns.map((option) => {
          return { field: option.value, direction: option.direction };
        }),
        filterColumns: this.state.filterColumns.map((option) => option.value),
      });
    } else if (status == MODAL_STATUS_CANCEL || status == MODAL_STATUS_CLOSE) {
      this.props.closeModal(status, null);
    }
  }

  componentDidUpdate(prevProps: TableSortOptionsModalProps) {
    if (this.props.columns !== prevProps.columns) {
      this.setState(
        Object.assign(
          {},
          { ...this.state },
          {
            sortColumns: this.getSortOptions(this.props.sortColumns),
            filterColumns: this.getFilterOptions(this.props.filterColumns),
          }
        )
      );
    }
  }

  render() {
    const modal = { ...this.props.modal };
    modal.options = {
      ...modal.options,
      title: { id: "NPT_TABLE_SORT_OPTIONS_MODAL_TITLE" },
    };
    return (
      <ModalView
        modal={modal}
        template={this.renderTemplate()}
        closeModal={this.closeModal}
      />
    );
  }
}

interface TableSortOptionsBodyProps {
  sortColumns: Option[];
  filterColumns: Option[];
  changeSortColumns: (sortColumns: Option[]) => void;
  changeFilterColumns: (filterColumns: Option[]) => void;
}
const TableSortOptionsBody: React.FunctionComponent<TableSortOptionsBodyProps> =
  React.memo((props) => {
    const [activeTab, setActiveTab] = React.useState("sort");

    const sortTitle = (
      <FormattedMessage
        id="NPT_TABLE_SORT_OPTIONS_SORTING"
        defaultMessage="Sorting"
        description="Sorting"
      />
    );
    const filterTitle = (
      <FormattedMessage
        id="NPT_TABLE_SORT_OPTIONS_FILTER"
        defaultMessage="Filter"
        description="Filter"
      />
    );
    return (
      <Tabs
        id="cim-tabs"
        variant="tabs"
        onSelect={(k: string | null) => setActiveTab(k || "sort")}
        defaultActiveKey={activeTab}
      >
        <Tab eventKey={"sort"} title={sortTitle}>
          <DndOptions
            type="tableSortOptionsModal"
            options={props.sortColumns}
            onChange={props.changeSortColumns}
          />
        </Tab>
        <Tab eventKey={"filter"} title={filterTitle}>
          <DndOptions
            type="tableSortOptionsModal"
            options={props.filterColumns}
            onChange={props.changeFilterColumns}
          />
        </Tab>
      </Tabs>
    );
  });

export default connect((state: RootState, ownProps: { modal: ModalInfo }) => {
  const tableState =
    state.table[(ownProps.modal.options as TableModalOptions).tableId];
  const filterColumns: string[] = [];
  const sortColumns: ColumnSortInfo[] = [];
  if (!tableState) {
    return {
      columns: [],
      sortColumns: sortColumns,
      filterColumns: filterColumns,
    };
  }
  for (let columnSortInfo of tableState.sortInfo.columns) {
    sortColumns.push(columnSortInfo);
  }
  for (let columnFilterInfo of tableState.filterInfo.columns) {
    filterColumns.push(columnFilterInfo.field);
  }
  return {
    columns: tableState.columns,
    sortColumns: sortColumns,
    filterColumns: filterColumns,
  };
})(TableSortOptionsModal);
