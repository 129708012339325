import { Models } from "@rematch/core";
import { subject } from "./subject";
import { alert } from "./alert";
import { filearchive } from "./filearchive";
import { modal } from "./modal";
import { location } from "./location";
import { selection } from "./selection";
import { tree } from "./tree";
import { table } from "./table";
import { locale } from "./locale";
import { finder } from "./finder";
import { security } from "./security";
import { subjecthistory } from "./subjecthistory";
import { cluster } from "./cluster";
import { config } from "./config";
import { db } from "./db";
import { postgres } from "./postgres";
import { process } from "./process";
import { scada } from "./scada";
import { scm } from "./scm";
import { statistics } from "./statistics";
import { user } from "./user";
import { preference } from "./prefrences";
import { namespaces } from "./namespaces";
import { fragments } from "./fragments";
import { role } from "./role";
import { right } from "./right";
import { sporadictask } from "./sporadictask";
import { cimmodel } from "./cimmodel";
import { menu } from "./menu";
import { clipboard } from "./clipboard";
import { cache } from "./cache";
import { logviewer } from "./logviewer";
import { crontask } from "./crontask";
import { version } from "./version";
import { session } from "./session";

export interface RootModel extends Models<RootModel> {
  subject: typeof subject;
  alert: typeof alert;
  modal: typeof modal;
  finder: typeof finder;
  location: typeof location;
  selection: typeof selection;
  filearchive: typeof filearchive;
  tree: typeof tree;
  table: typeof table;
  locale: typeof locale;
  postgres: typeof postgres;
  security: typeof security;
  subjecthistory: typeof subjecthistory;
  cluster: typeof cluster;
  config: typeof config;
  db: typeof db;
  process: typeof process;
  scada: typeof scada;
  scm: typeof scm;
  statistics: typeof statistics;
  user: typeof user;
  fragments: typeof fragments;
  preference: typeof preference;
  namespaces: typeof namespaces;
  role: typeof role;
  crontask: typeof crontask;
  right: typeof right;
  sporadictask: typeof sporadictask;
  cimmodel: typeof cimmodel;
  menu: typeof menu;
  clipboard: typeof clipboard;
  cache: typeof cache;
  logviewer: typeof logviewer;
  version: typeof version;
  session: typeof session;
}

export const models: RootModel = {
  subject,
  selection,
  version,
  menu,
  subjecthistory,
  statistics,
  postgres,
  cluster,
  user,
  config,
  cimmodel,
  cache,
  right,
  process,
  table,
  logviewer,
  finder,
  role,
  locale,
  db,
  clipboard,
  session,

  alert,
  modal,
  location,
  filearchive,
  tree,
  scada,
  security,
  scm,
  fragments,
  preference,
  namespaces,
  crontask,
  sporadictask,
};
