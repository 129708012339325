import {
  faDoorClosed,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faHome,
  faKey,
  faMoon,
  faQuestion,
  faSignOutAlt,
  faSpinner,
  faSun,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { DividerType, MenuData } from "../../types/security";
import { attachDivider, getItemTitle } from "./DropDownMenu";
import { MenuControls } from "./MenuControls";
import { OverflowDropDown } from "./OverflowDropDown";
import UnreadMessages from "./UnreadMessages";
import Plugin from "../plugin/Plugin";
import { SelectionState } from "../../types/selection";

interface NavLinkProperties
  extends RouteComponentProps<any, StaticContext, any> {
  to: string;
  target?: string;
  exact?: boolean;
  children: any;
  dropDown: boolean;
  style?: React.CSSProperties;
  active?: boolean;
  className?: string;
  onClick?: (e: any) => void;
}

interface NavLinkState {
  active: boolean;
}

//Class to render links in menu
class NavLinkRenderer extends React.Component<NavLinkProperties, NavLinkState> {
  unlisten?: () => void;
  state = {
    active: false,
  };

  constructor(props: NavLinkProperties) {
    super(props);
  }

  onLocationChange(path: string) {
    //Set state to force rendering
    const active = this.props.exact
      ? path == this.props.to
      : path.startsWith(this.props.to);
    this.setState({ active: active });
  }

  componentDidMount() {
    const history = this.props.history;
    const onLocationChange = this.onLocationChange.bind(this);
    this.unlisten = history.listen((location, action) => {
      onLocationChange(location.pathname);
    });
    /* Set first active value */
    onLocationChange(this.props.location.pathname);
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }
  clickHandler(e: any) {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  }
  render() {
    const path = this.props.to;
    const history = this.props.history;
    const { active, className, onClick, target } = this.props;
    if (this.props.dropDown) {
      return (
        <NavDropdown.Item
          onClick={(e: any) => this.clickHandler(e)}
          style={this.props.style}
          active={this.state.active || active}
          as={Link}
          to={path}
          target={target}
          className={className}
        >
          {this.props.children}
        </NavDropdown.Item>
      );
    }
    return (
      <Nav.Item
        style={this.props.style}
        onClick={(e: any) => this.clickHandler(e)}
      >
        <Nav.Link
          target={target}
          active={this.state.active || active}
          className={className}
          as={Link}
          to={path}
        >
          {this.props.children}
        </Nav.Link>
      </Nav.Item>
    );
  }
}

export const NavLink = withRouter(NavLinkRenderer);

interface MenuLogoProps {
  logo?: string;
}
export const MenuLogo: React.FunctionComponent<MenuLogoProps> = React.memo(
  (props) => {
    if (!props.logo) {
      return <FontAwesomeIcon key="menu-logo" icon={faExclamationTriangle} />;
    }
    return (
      <Link key="menu-logo" to="/" className="navbar-brand px-2">
        <img src={props.logo} className="App-logo" alt="logo" />
      </Link>
    );
  }
);

export interface MenuProps {
  language: string;
  menu?: MenuData;
  isAvatar?: boolean;
  selection?: SelectionState;
  authorities?: string[];
  isSuperUser?: boolean;
  systemMenu?: MenuData;
  userLabel: string;
  dark: boolean;
  setupHomePage: () => void;
  changePassword: () => void;
  logout: () => void;
  invokeAvatarHelp?: () => void;
}

export default class Menu extends React.Component<MenuProps> {
  renderMenuItem(id: string, menu: MenuData) {
    const item = menu.itemById[id];
    if (typeof item == "undefined") {
      return null;
    }
    const title = (item.t && item.t[this.props.language]) || item.n || id;
    const ref = item.r || "";
    const icon = item.i ? (
      <i className={item.i + " mr-2"} aria-hidden="true" />
    ) : null;
    return (
      <NavLink to={ref} dropDown={true} key={id}>
        {icon}
        {title}
      </NavLink>
    );
  }

  renderSystemMenu() {
    const m = this.props.systemMenu;
    if (typeof m == "undefined" || typeof m.roots == "undefined") {
      return null;
    }
    return m.roots.map((id) => this.renderMenuItem(id, m));
  }
  wrapHorizontalWithDivider = (
    divider: DividerType | undefined,
    element: any
  ) => {
    if (!divider) {
      return element;
    }
    const dividerElement = <hr className="my-1 bg-secondary mx-1" />;

    return (
      <>
        {(divider === "before" || divider === "both") && dividerElement}
        {element}
        {(divider === "after" || divider === "both") && dividerElement}
      </>
    );
  };
  renderItem(
    id: string,
    dropDown: boolean,
    menu: MenuData,
    vertical: boolean = false
  ) {
    const item = menu.itemById[id];
    const { language } = this.props;
    if (typeof item == "undefined") {
      return null;
    }

    const { d, h, r, v } = item;
    const title = getItemTitle(item, id, language);
    const children = menu.childrenById && menu.childrenById[id];
    let itemElement = null;

    if (h) {
      return itemElement;
    }
    if (typeof children != "undefined") {
      itemElement = (
        <OverflowDropDown
          root={vertical}
          rootActiveEnable={true}
          className={`nav-item pl-1 ${
            vertical ? "npt-main-menu-root-item" : ""
          }`}
          direction="down"
          title={title}
          id={id}
          key={`mi-${id}`}
        >
          {children.map((childId) =>
            childId == id ? null : this.renderItem(childId, true, menu)
          )}
        </OverflowDropDown>
      );
      // itemElement = <CustomNavDropdown root={vertical} title={title} id={id} key={`mi-${id}`}>
      //     {children.map((childId) => childId == id ? null : this.renderItem(childId, true, menu))}
      // </CustomNavDropdown>
    } else {
      itemElement = (
        <NavLink exact={true} to={r || ""} dropDown={dropDown} key={`mi-${id}`}>
          {title}
        </NavLink>
      );
    }
    if (!vertical) {
      return this.wrapHorizontalWithDivider(d, itemElement);
    }
    return itemElement;
    // return attachDivider(itemElement, d, vertical,`mi-${id}`);
  }

  renderNav() {
    const m = this.props.menu;
    if (typeof m == "undefined" || typeof m.roots == "undefined") {
      return null;
    }
    return (
      <Nav
        key="user-menu-nav"
        className="mr-auto npt-main-menu d-flex flex-wrap"
      >
        {m.roots.map((id) => (
          <div
            key={id}
            className=" position-relative   text-white  d-flex flex-row justify-content-center "
          >
            {this.renderItem(id, false, m, true)}
          </div>
        ))}
      </Nav>
    );
  }

  renderChildren() {
    return [
      <Navbar.Toggle key="toggle" aria-controls="additional-navbar-nav" />,
      <Navbar.Collapse key="collapse" id="additional-navbar-nav">
        {this.props.children}
      </Navbar.Collapse>,
    ];
  }

  render() {
    const {
      language,
      systemMenu,
      userLabel,
      dark,
      setupHomePage,
      changePassword,
      authorities,
      selection,
      isSuperUser,
      logout,
      isAvatar,
    } = this.props;
    const avatarNavbar = isAvatar ? "avatar-navbar" : "";
    const unreadMessagesBadge = <UnreadMessages />;
    return (
      <Navbar
        className={`${avatarNavbar} main-navbar bg-primary py-1 `}
        expand="lg"
      >
        <MenuLogo logo={this.props.menu?.logoByLang?.[language]} />

        <Navbar.Toggle key="nb-toggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse key="nb-col" id="basic-navbar-nav">
          {this.props.children}
          {this.props.children && (
            <FontAwesomeIcon
              key="menu-icon"
              icon={faEllipsisV}
              size="2x"
              className="text-white ml-2"
            />
          )}
          {this.renderNav()}
          <MenuControls
            isSuperUser={isSuperUser}
            selection={selection}
            badge={unreadMessagesBadge}
            invokeAvatarHelp={this.props.invokeAvatarHelp}
            isAvatar={isAvatar}
            changePassword={changePassword}
            language={language}
            logout={logout}
            setupHomePage={setupHomePage}
            userLabel={userLabel}
            systemMenu={systemMenu}
            authorities={authorities}
          />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export interface LogoutProps {
  className?: string;
  title?: any;
  logout: () => void;
}

export const Logout = (props: LogoutProps) => {
  const { title, logout } = props;
  const cls = "d-flex flex-column   justify-content-center";
  if (!title) {
    return (
      <Nav.Item className={cls} onClick={() => logout()}>
        <Nav.Link className={`${props.className}  py-0`} href="#">
          <FontAwesomeIcon className=" " icon={faSignOutAlt} />
        </Nav.Link>
      </Nav.Item>
    );
  }
  return (
    <Nav.Item
      onClick={() => logout()}
      className={`${props.className} ${cls}  py-0`}
    >
      {title}
    </Nav.Item>
  );
};
