import * as React from "react";
import { connect } from "react-redux";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import Tab from "react-bootstrap/Tab";

import "./Tabs.module.css";
import { RootState } from "../../store";

export interface TabsSwitchProps {
  tabsSwitch?: string;
  defaultActiveKey?: string;
  activeKey?: string;
  tabClassName?: string;
  map?: (value: any) => string;
  value: any; //value from store
  children: React.ReactElement[];
}

const defaultMap = (value: any): string => {
  if (!value) {
    return "";
  }
  return value.toString();
};

const TabsSwitch = (props: TabsSwitchProps) => {
  const { map, value, children } = props;

  const parseChild = (child: React.ReactElement) => {
    if (child?.type === Tab) {
      const childProps = { ...child.props };
      delete childProps.title;
      delete childProps.disabled;
      delete childProps.tabClassName;
      return <TabPane key={childProps.eventKey} {...childProps} />;
    }
    return child;
  };

  if (!Array.isArray(children)) {
    return parseChild(children as React.ReactElement);
  }

  //Find all available active keys
  const activeKeySet: { [K: string]: boolean } = {};
  for (let child of children) {
    if (
      typeof child.props == "object" &&
      child.props != null &&
      typeof child.props.eventKey == "string"
    ) {
      activeKeySet[child.props.eventKey] = true;
    }
  }

  let activeKey = props.defaultActiveKey;
  if (props.activeKey) {
    activeKey = props.activeKey;
  } else if (map) {
    activeKey = map(value);
  } else {
    activeKey = defaultMap(value);
  }

  if (!activeKeySet[activeKey]) {
    activeKey = props.defaultActiveKey;
  }

  /**TODO: Solve without unmountOnExit */
  return (
    <TabContainer
      {...props}
      activeKey={activeKey}
      unmountOnExit={true}
      mountOnEnter={true}
      transition={false}
    >
      <TabContent className={props.tabClassName}>
        {children.map((child) => parseChild(child))}
      </TabContent>
    </TabContainer>
  );
};

const ConnectedTabsSwitch = connect(
  (state: RootState, ownProps: TabsSwitchProps) => {
    const { tabsSwitch } = ownProps;
    if (!tabsSwitch) {
      return {
        value: "",
      };
    }
    let path = tabsSwitch.split(".");
    let value = state as any;
    for (let p of path) {
      if (typeof value == "object" && value != null) {
        value = value[p];
      } else {
        value = null;
        break;
      }
    }
    return { value };
  }
)(TabsSwitch);

export default ConnectedTabsSwitch;
